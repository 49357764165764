import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import {
  Rpc,
  RpcDomainMask,
  RpcMetrics,
  RpcSecurityToken,
  RpcState,
  RpcWhiteList,
} from "../../types/rpc";
import { IThunkRejectValue, RootState } from "../../types";
import {
  createRpcContractAddressApi,
  createRpcDomainMaskApi,
  createRpcJwtTokenApi,
  createRpcSourceIpApi,
  createRpcTokenApi,
  createRpcWhiteListApi,
  deleteRpcDomainMaskApi,
  deleteRpcTokenApi,
  deleteRpcWhiteListApi,
  getRpcApi,
  getRpcDomainMasksApi,
  getRpcMetricsApi,
  getRpcTokensApi,
  getRpcWhiteListApi,
} from "../../apis/rpcAPI";
import { getExtractErrorCode, getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: RpcState = {
  rpc: null,
  loading: false,
  securityTokens: [],
  securityTokensLoading: false,
  tokenActionLoading: false,
  whiteList: [],
  whiteListLoading: false,
  whiteListActionLoading: false,
  domainMasks: [],
  domainMasksLoading: false,
  domainMaskActionLoading: false,
  isOpenJwtEditrModal: false,
  jwtCreateLoading: false,
  sourceIpCreateLoading: false,
  contractAddressCreateLoading: false,
  metrics: null,
  metricsLoading: false,
};

export const getRpcAsync = createAsyncThunk<
  { rpc: Rpc },
  { id: number; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "rpc",
  async (
    { id, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getRpcAsync.pending(requestId, {
          id,
          withoutLoading,
        })
      );

      const response = await getRpcApi(id);
      const rpc = response.data.Result;

      return fulfillWithValue({ rpc });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
        code: getExtractErrorCode(e),
      });
    }
  }
);

export const getRpcMetricsAsync = createAsyncThunk<
  { rpcMetrics: RpcMetrics },
  { id: number; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "rpc/metrics",
  async (data, { rejectWithValue, fulfillWithValue, dispatch, requestId }) => {
    try {
      const { id } = data;
      dispatch(getRpcMetricsAsync.pending(requestId, data));

      const response = await getRpcMetricsApi(id);
      const rpcMetrics = response.data.Result;

      return fulfillWithValue({ rpcMetrics });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
        code: getExtractErrorCode(e),
      });
    }
  }
);

export const getRpcTokensAsync = createAsyncThunk<
  { tokens: Array<RpcSecurityToken> },
  { id: number },
  IThunkRejectValue
>("rpc/tokens", async ({ id }, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getRpcTokensApi(id);
    const tokens = response.data.Result || [];
    return fulfillWithValue({ tokens });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createRpcTokenAsync = createAsyncThunk<
  boolean,
  { id: number },
  IThunkRejectValue
>("rpc/tokens/create", async ({ id }, { rejectWithValue }) => {
  try {
    await createRpcTokenApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteRpcTokenAsync = createAsyncThunk<
  boolean,
  { id: number; tokenId: number },
  IThunkRejectValue
>("rpc/tokens/delete", async ({ id, tokenId }, { rejectWithValue }) => {
  try {
    await deleteRpcTokenApi(id, tokenId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createRpcJwtTokenAsync = createAsyncThunk<
  boolean,
  { id: number; name: string; publicKey: string },
  IThunkRejectValue
>(
  "rpc/jwt-token/create",
  async ({ id, name, publicKey }, { rejectWithValue }) => {
    try {
      await createRpcJwtTokenApi(id, name, publicKey);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getRpcWhiteListAsync = createAsyncThunk<
  { whiteList: Array<RpcWhiteList> },
  { id: number },
  IThunkRejectValue
>("rpc/whitelist", async ({ id }, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getRpcWhiteListApi(id);
    const whiteList = response.data.Result || [];
    return fulfillWithValue({ whiteList });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createRpcWhiteListAsync = createAsyncThunk<
  boolean,
  { id: number; value: string; type: number },
  IThunkRejectValue
>("rpc/whitelist/create", async ({ id, value, type }, { rejectWithValue }) => {
  try {
    await createRpcWhiteListApi(id, value, type);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteRpcWhitelistAsync = createAsyncThunk<
  boolean,
  { id: number; tokenId: number },
  IThunkRejectValue
>("rpc/whitelist/delete", async ({ id, tokenId }, { rejectWithValue }) => {
  try {
    await deleteRpcWhiteListApi(id, tokenId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getRpcDomainMasksAsync = createAsyncThunk<
  { domainMasks: Array<RpcDomainMask> },
  { id: number },
  IThunkRejectValue
>("rpc/domain-masks", async ({ id }, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getRpcDomainMasksApi(id);
    const domainMasks = response.data.Result || [];
    return fulfillWithValue({ domainMasks });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createRpcDomainMaskAsync = createAsyncThunk<
  boolean,
  { id: number; value: string },
  IThunkRejectValue
>("rpc/domain-mask/create", async ({ id, value }, { rejectWithValue }) => {
  try {
    await createRpcDomainMaskApi(id, value);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteRpcDomainMaskAsync = createAsyncThunk<
  boolean,
  { id: number; tokenId: number },
  IThunkRejectValue
>("rpc/domain-mask/delete", async ({ id, tokenId }, { rejectWithValue }) => {
  try {
    await deleteRpcDomainMaskApi(id, tokenId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createRpcSourceIpAsync = createAsyncThunk<
  boolean,
  { id: number; value: string },
  IThunkRejectValue
>("rpc/source-ip/create", async ({ id, value }, { rejectWithValue }) => {
  try {
    await createRpcSourceIpApi(id, value);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createRpcContractAddressAsync = createAsyncThunk<
  boolean,
  { id: number; value: string },
  IThunkRejectValue
>("rpc/contract-address/create", async ({ id, value }, { rejectWithValue }) => {
  try {
    await createRpcContractAddressApi(id, value);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const rpcSlice = createSlice({
  name: "rpc",
  initialState,
  reducers: {
    openJwtEditorModal: (state) => {
      state.isOpenJwtEditrModal = true;
    },
    closeJwtEditorModal: (state) => {
      state.isOpenJwtEditrModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRpcAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getRpcAsync.fulfilled, (state, action) => {
        const { rpc } = action.payload;
        state.rpc = rpc;
        state.loading = false;
      })
      .addCase(getRpcAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.code !== 404)
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getRpcMetricsAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.metricsLoading = true;
      })
      .addCase(getRpcMetricsAsync.fulfilled, (state, action) => {
        const { rpcMetrics } = action.payload;
        state.metrics = rpcMetrics;
        state.metricsLoading = false;
      })
      .addCase(getRpcMetricsAsync.rejected, (state, { payload }) => {
        state.metricsLoading = false;
        if (payload?.code !== 404)
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getRpcTokensAsync.pending, (state) => {
        state.securityTokensLoading = true;
      })
      .addCase(getRpcTokensAsync.fulfilled, (state, action) => {
        state.securityTokens = action.payload.tokens;
        state.securityTokensLoading = false;
      })
      .addCase(getRpcTokensAsync.rejected, (state, { payload }) => {
        state.securityTokensLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createRpcTokenAsync.pending, (state) => {
        state.tokenActionLoading = true;
      })
      .addCase(createRpcTokenAsync.fulfilled, (state, action) => {
        state.tokenActionLoading = false;
      })
      .addCase(createRpcTokenAsync.rejected, (state, { payload }) => {
        state.tokenActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteRpcTokenAsync.pending, (state) => {
        state.tokenActionLoading = true;
      })
      .addCase(deleteRpcTokenAsync.fulfilled, (state, action) => {
        state.tokenActionLoading = false;
      })
      .addCase(deleteRpcTokenAsync.rejected, (state, { payload }) => {
        state.tokenActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createRpcJwtTokenAsync.pending, (state) => {
        state.jwtCreateLoading = true;
      })
      .addCase(createRpcJwtTokenAsync.fulfilled, (state, action) => {
        state.jwtCreateLoading = false;
      })
      .addCase(createRpcJwtTokenAsync.rejected, (state, { payload }) => {
        state.jwtCreateLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getRpcWhiteListAsync.pending, (state) => {
        state.whiteListLoading = true;
      })
      .addCase(getRpcWhiteListAsync.fulfilled, (state, action) => {
        state.whiteList = action.payload.whiteList;
        state.whiteListLoading = false;
      })
      .addCase(getRpcWhiteListAsync.rejected, (state, { payload }) => {
        state.whiteListLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createRpcWhiteListAsync.pending, (state) => {
        state.whiteListActionLoading = true;
      })
      .addCase(createRpcWhiteListAsync.fulfilled, (state, action) => {
        state.whiteListActionLoading = false;
      })
      .addCase(createRpcWhiteListAsync.rejected, (state, { payload }) => {
        state.whiteListActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteRpcWhitelistAsync.pending, (state) => {
        state.whiteListActionLoading = true;
      })
      .addCase(deleteRpcWhitelistAsync.fulfilled, (state, action) => {
        state.whiteListActionLoading = false;
      })
      .addCase(deleteRpcWhitelistAsync.rejected, (state, { payload }) => {
        state.whiteListActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getRpcDomainMasksAsync.pending, (state) => {
        state.domainMasksLoading = true;
      })
      .addCase(getRpcDomainMasksAsync.fulfilled, (state, action) => {
        state.domainMasks = action.payload.domainMasks;
        state.domainMasksLoading = false;
      })
      .addCase(getRpcDomainMasksAsync.rejected, (state, { payload }) => {
        state.domainMasksLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createRpcDomainMaskAsync.pending, (state) => {
        state.domainMaskActionLoading = true;
      })
      .addCase(createRpcDomainMaskAsync.fulfilled, (state, action) => {
        state.domainMaskActionLoading = false;
      })
      .addCase(createRpcDomainMaskAsync.rejected, (state, { payload }) => {
        state.domainMaskActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteRpcDomainMaskAsync.pending, (state) => {
        state.domainMaskActionLoading = true;
      })
      .addCase(deleteRpcDomainMaskAsync.fulfilled, (state, action) => {
        state.domainMaskActionLoading = false;
      })
      .addCase(deleteRpcDomainMaskAsync.rejected, (state, { payload }) => {
        state.domainMaskActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createRpcSourceIpAsync.pending, (state) => {
        state.sourceIpCreateLoading = true;
      })
      .addCase(createRpcSourceIpAsync.fulfilled, (state, action) => {
        state.sourceIpCreateLoading = false;
      })
      .addCase(createRpcSourceIpAsync.rejected, (state, { payload }) => {
        state.sourceIpCreateLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createRpcContractAddressAsync.pending, (state) => {
        state.contractAddressCreateLoading = true;
      })
      .addCase(createRpcContractAddressAsync.fulfilled, (state, action) => {
        state.contractAddressCreateLoading = false;
      })
      .addCase(createRpcContractAddressAsync.rejected, (state, { payload }) => {
        state.contractAddressCreateLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectRpc = (state: RootState) => state.rpc.rpc;
export const selectLoading = (state: RootState) => state.rpc.loading;

//metrics
export const selectRpcMetrics = (state: RootState) => state.rpc.metrics;
export const selectRpcMetricsLoading = (state: RootState) =>
  state.rpc.metricsLoading;

//tokens
export const selectRpcSecurityTokens = (state: RootState) =>
  state.rpc.securityTokens;
export const selectRpcSecurityTokensLoading = (state: RootState) =>
  state.rpc.securityTokensLoading;
export const selectRpcTokenActionLoading = (state: RootState) =>
  state.rpc.tokenActionLoading;

//jwt tokens
export const selectIsOpenJwtEditorModal = (state: RootState) =>
  state.rpc.isOpenJwtEditrModal;
export const selectJwtCreateLoading = (state: RootState) =>
  state.rpc.jwtCreateLoading;

//referrer
export const selectRpcWhiteList = (state: RootState) => state.rpc.whiteList;
export const selectRpcWhiteListLoading = (state: RootState) =>
  state.rpc.whiteListActionLoading;
export const selectRpcWhiteListActionLoading = (state: RootState) =>
  state.rpc.whiteListActionLoading;

//domain-masks
export const selectRpcDomainMasks = (state: RootState) => state.rpc.domainMasks;
export const selectRpcDomainMasksLoading = (state: RootState) =>
  state.rpc.domainMasksLoading;
export const selectRpcDomainMaskActionLoading = (state: RootState) =>
  state.rpc.domainMaskActionLoading;

//source-ip
export const selectSourceIpCreateLoading = (state: RootState) =>
  state.rpc.sourceIpCreateLoading;

//contract-address
export const selectContractAddressCreateLoading = (state: RootState) =>
  state.rpc.contractAddressCreateLoading;

export const { openJwtEditorModal, closeJwtEditorModal } = rpcSlice.actions;
export default rpcSlice.reducer;
