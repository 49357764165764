import {
  BlockEventChain,
  BlockEventNetwork,
  BlockEventTemplate,
} from "../types/block-events";
import { Service, ServiceGroup } from "../types/service";

export const groupServicesByBlockchain = (
  services: Service[]
): ServiceGroup[] => {
  const groupedServices: { [key: string]: ServiceGroup } = {};

  services.forEach((service) => {
    const { Blockchain, Icon, Id, NetworkType, NetworkTypeDescription } =
      service;

    if (!groupedServices[Blockchain]) {
      groupedServices[Blockchain] = {
        Blockchain,
        Icon,
        Networks: [],
      };
    }

    groupedServices[Blockchain].Networks.push({
      Id,
      NetworkType,
      NetworkTypeDescription,
    });
  });

  return Object.values(groupedServices);
};

export const getNetworkTypeName = (networkType: number): string => {
  const networkNames = ["MainNet", "TestNet", "DevNet"];
  return networkNames[networkType] || "";
};

export const groupNetworksByChain = (
  networks: BlockEventNetwork[],
  templates: BlockEventTemplate[]
): BlockEventChain[] => {
  const chainsMap: { [key: string]: BlockEventNetwork[] } = {};

  // Group networks by chain name
  for (const network of networks) {
    const chainName = network.NetworkName.split("-")[0];
    if (!chainsMap[chainName]) {
      chainsMap[chainName] = [];
    }
    const networkTemplates = makeNetworkTemplates(templates, network);
    if (networkTemplates.length > 0) {
      const finalNetwork: BlockEventNetwork = {
        ...network,
        Templates: networkTemplates,
      };
      chainsMap[chainName].push(finalNetwork);
    }
  }

  // Convert the map to the desired array format
  const chains: BlockEventChain[] = [];
  for (const chainName in chainsMap) {
    const networks = chainsMap[chainName];
    if (networks.length > 0) {
      chains.push({
        ChainName: chainName,
        Networks: networks,
      });
    }
  }

  return chains;
};

const makeNetworkTemplates = (
  templates: BlockEventTemplate[],
  network: BlockEventNetwork
) => {
  return templates.filter((temp) =>
    temp.BlockEventNetworks.find(
      (net) => net.NetworkName === network.NetworkName
    )
  );
};
