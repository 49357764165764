import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectWeb3AuthShowUserDataInfoModal,
  selectWeb3AuthSelectedUser,
  handleUserDataInfoModal,
} from "../../../store/web3-auth/web3AuthEndpointSlice";
import { Button, EmptyState, Modal, Typography } from "djuno-design";

const Web3UserDataInfoModal = () => {
  const isOpen = useAppSelector(selectWeb3AuthShowUserDataInfoModal);
  const user = useAppSelector(selectWeb3AuthSelectedUser);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(handleUserDataInfoModal({}));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      //   containerClassName="!items-start"
      title={"User data info"}
    >
      <div className="my-7 flex flex-col w-full gap-5">
        {user &&
          (user.JsonUserData === null ||
            Object.keys(user.JsonUserData).length === 0) && <EmptyState />}
        {user && user.JsonUserData && user.JsonUserData !== null && (
          <>
            {Object.keys(user.JsonUserData).map((key: string) => {
              const userData = user.JsonUserData
                ? user.JsonUserData[key as keyof typeof user.JsonUserData]
                : "";
              return (
                <div className="grid grid-cols-6">
                  <div className="col-span-2 md:col-span-1">
                    <Typography.Text className="!text-sm">
                      {key} :
                    </Typography.Text>
                  </div>
                  <div className="col-span-4 md:col-span-5">
                    <Typography.Text className="!text-sm">
                      {userData.toString()}
                    </Typography.Text>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="mt-4 flex justify-end">
        <Button uiType="light" className="w-[100px]" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default Web3UserDataInfoModal;
