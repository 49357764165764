import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../../hooks";
import {
  deleteWebAppDiskAsync,
  getWebAppDiskAsync,
  saveWebAppDiskAsync,
  selectWebApp,
  selectWebAppDisk,
  selectWebAppDiskActionLoading,
  selectWebAppDiskLoading,
  selectWebAppLoading,
} from "../../../store/web-app/webAppSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppDiskSchema } from "../../../utils/validations";
import QuestionModal from "../../modals/QuestionModal";
import { Button, Card, Flex, Input, Loading, Typography } from "djuno-design";

const WebAppDisksTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const [hasDisk, setHasDisk] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [deleteQuestion, setDeleteQuestion] = useState<boolean>(false);

  const disk = useAppSelector(selectWebAppDisk);
  const diskLoading = useAppSelector(selectWebAppDiskLoading);
  const diskActionLoading = useAppSelector(selectWebAppDiskActionLoading);

  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(WebAppDiskSchema),
  });

  useEffect(() => {
    if (webApp) {
      // dispatch(getWebAppDiskAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  useEffect(() => {
    setHasDisk(!!disk);
    if (disk) {
      setValue("DiskName", disk.DiskName);
      setValue("DiskMountPath", disk.DiskMountPath);
      setValue("DiskSize", disk.DiskSize);
    } else {
      reset({ DiskSize: 10 });
    }
  }, [dispatch, disk, reset, setValue]);

  const handleSubmitForm = (data: any) => {
    if (webApp)
      dispatch(
        saveWebAppDiskAsync({
          webAppId: webApp.Id.toString(),
          data,
        })
      ).then((action) => {
        if (action.type === "web-app/disk/save/fulfilled") {
          setEditable(false);
          dispatch(getWebAppDiskAsync({ webAppId: webApp.Id.toString() }));
        }
      });
  };

  return (
    <div className="flex flex-col gap-6">
      {diskLoading && (
        <Flex
          items="center"
          justify="center"
          className="h-full w-full px-4 min-h-[200px]"
        >
          <Loading borderSize={2} />
        </Flex>
      )}
      {!diskLoading && !hasDisk && (
        <Flex
          direction="col"
          items="center"
          className="antialiased font-sans font-normal text-sm leading-6 space-y-4 max-w-lg mx-auto mt-5"
        >
          <Typography.Text size="sm" className="text-center">
            Create a disk to store application data that needs to persist across
            deploys. You can mount it on any absolute path and read and write to
            it using standard filesystem primitives. Disks are charged at
            $0.25/GB per month.
          </Typography.Text>
          <Typography.Text size="sm">
            Adding a disk prevents zero downtime deploys.{" "}
            <Typography.Link className="!text-sm">Learn more.</Typography.Link>
          </Typography.Text>
          <Button
            onClick={() => {
              setHasDisk(true);
              setEditable(true);
            }}
          >
            Add Disk
          </Button>
        </Flex>
      )}

      {!diskLoading && hasDisk && (
        <Card>
          <form onSubmit={handleSubmit(handleSubmitForm)} className="w-full">
            <Flex direction="col" className="gap-4 !w-full">
              <Flex direction="col" className="gap-5 md:gap-10 mt-5 w-full">
                <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                  <Flex
                    direction="col"
                    className="antialiased leading-6 md:w-1/2"
                  >
                    <Typography.Text size="sm">Name</Typography.Text>
                    <Typography.Text uiType="secondary" size="xs">
                      An identifying name for this disk. Used only for display.
                    </Typography.Text>
                  </Flex>
                  <div className="flex-1">
                    <Input
                      {...register("DiskName")}
                      readOnly={!editable}
                      error={errors.DiskName?.message}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                  <Flex
                    direction="col"
                    className="antialiased leading-6 md:w-1/2"
                  >
                    <Typography.Text size="sm">Mount Path</Typography.Text>
                    <Typography.Text uiType="secondary" size="xs">
                      The absolute path to mount this disk, like /var/lib/data.
                      Can not be the root (/) directory.
                    </Typography.Text>
                  </Flex>
                  <div className="flex-1">
                    <Input
                      {...register("DiskMountPath")}
                      readOnly={!editable}
                      error={errors.DiskMountPath?.message}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full md:flex-row gap-0.5 md:gap-x-5">
                  <Flex
                    direction="col"
                    className="antialiased leading-6 md:w-1/2"
                  >
                    <Typography.Text size="sm">Size (GB)</Typography.Text>
                    <Typography.Text uiType="secondary" size="xs">
                      You can increase the size later, but you can't decrease
                      it. Pick the lowest value that works for your app.
                    </Typography.Text>
                  </Flex>
                  <div className="flex-1">
                    <Input
                      {...register("DiskSize")}
                      type="number"
                      readOnly={!editable}
                      error={errors.DiskSize?.message}
                    />
                  </div>
                </div>
              </Flex>
              <Flex justify="end" className="w-full">
                {disk && !editable && (
                  <div className="flex gap-2 items-center">
                    <Button
                      uiType="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        setDeleteQuestion(true);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      uiType="light"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditable(true);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                )}
                {(!disk || editable) && (
                  <div className="flex gap-2 items-center">
                    <Button
                      uiType="simple" //TODO: danger-light
                      onClick={(e) => {
                        e.preventDefault();
                        setEditable(false);
                        if (disk) {
                          setHasDisk(true);
                          reset({
                            DiskName: disk.DiskName,
                            DiskMountPath: disk.DiskMountPath,
                            DiskSize: disk.DiskSize,
                          });
                        } else {
                          setHasDisk(false);
                          reset({ DiskSize: 10 });
                        }
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      uiType="primary"
                      disabled={!isValid}
                      type="submit"
                      loading={diskActionLoading}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </Flex>
            </Flex>
          </form>
        </Card>
      )}

      <QuestionModal
        isOpen={deleteQuestion}
        title="Delete disk"
        onClose={() => {
          setDeleteQuestion(false);
        }}
        onConfirm={() =>
          webApp &&
          dispatch(
            deleteWebAppDiskAsync({ webAppId: webApp.Id.toString() })
          ).then((action) => {
            if (action.type === "web-app/disk/dalete/fulfilled") {
              reset({ DiskSize: 10 });
              setHasDisk(false);
              setEditable(false);
            }
          })
        }
        confirmButtonType="danger"
        loading={diskActionLoading}
        description={
          <Flex direction="col" items="center" className="gap-2 my-4">
            <Typography.Text size="sm">
              All data for test-disk will be deleted immediately. This action
              cannot be undone.
            </Typography.Text>
            <Typography.Text size="sm">
              Are you sure you want to delete this disk?
            </Typography.Text>
          </Flex>
        }
      />
    </div>
  );
};

export default WebAppDisksTab;
