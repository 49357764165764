import { useAnimation, motion } from "framer-motion";
import React from "react";

export const fadeInVariants = {
  visible: { opacity: 1, x: 0, transition: { delay: 0.4, duration: 0.4 } },
  hidden: { opacity: 0, x: -30 },
};

export const AnimatedFadeInBox: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  const controls = useAnimation();
  return (
    <motion.div
      animate={controls}
      variants={fadeInVariants}
      viewport={{ once: true }}
      whileInView="visible"
      initial="hidden"
      className={className}
    >
      {children}
    </motion.div>
  );
};
