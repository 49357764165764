import {
  Button,
  cn,
  Flex,
  getLocalStorage,
  setLocalStorage,
  ThemeChanger,
  ThemeSwitcher,
  Typography,
  useDjunoDesign,
  useWindowOnClick,
} from "djuno-design";
import Container from "../layouts/Container";
import { ReactComponent as Logo } from "./../../assets/icons/logo-text.svg";
import { ReactComponent as OvhColorLogo } from "./../../assets/icons/ovh/ovh-color.svg";
import { ReactComponent as OvhWhiteLogo } from "./../../assets/icons/ovh/ovh-white.svg";
import { ReactComponent as SigninIcon } from "./../../assets/icons/arrow-right-end-on-rectangle.svg";
// import { ReactComponent as LogoutIcon } from "./../../assets/icons/arrow-left-start-on-rectangle.svg";
import { ReactComponent as MegaphoneIcon } from "./../../assets/icons/megaphone.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { Link, useLocation } from "react-router-dom";
import { AboutUsUrl, HomeUrl, SignInUrl, SignUpUrl } from "../../utils/urls";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectIsAuthenticate } from "../../store/auth/authSlice";
import { useEffect, useState } from "react";
import { ReactComponent as MenuIcon } from "./../../assets/icons/menu.svg";
import { motion, AnimatePresence } from "framer-motion";
import {
  handleCloseTopMessage,
  selectHasTopMessage,
} from "../../store/publicSlice";

const WebHeader = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isTransparent, setIsTransparent] = useState<boolean>(true);
  const location = useLocation();
  const isAuth = useAppSelector(selectIsAuthenticate);
  const hasTopMessage = useAppSelector(selectHasTopMessage);
  const dispatch = useAppDispatch();

  const { theme } = useDjunoDesign();

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const scrollNavBar = () => {
      const width = window.innerWidth;
      if (width > 991) {
        const scroll = window.scrollY;
        if (scroll >= 30) {
          if (isTransparent) {
            setIsTransparent(false);
          }
        } else {
          if (!isTransparent) {
            setIsTransparent(true);
          }
        }
      }
    };
    window.addEventListener("scroll", scrollNavBar);
    return () => {
      window.removeEventListener("scroll", scrollNavBar);
    };
  }, [isTransparent]);

  useWindowOnClick(
    () => {
      setMenuOpen(false);
    },
    [],
    { capture: true }
  );

  const SignInSection = () => {
    return (
      <>
        {isAuth ? (
          // <Button
          //   uiType="light"
          //   className="group"
          //   onClick={() => dispatch(logout())}
          // >
          //   <LogoutIcon className="w-4 h-4 translate-x-0 group-hover:-translate-x-0.5 transition-all duration-200" />
          //   <Typography.Text
          //     className="!font-semibold"
          //     size="sm"
          //     uiType="transparent"
          //   >
          //     Logout
          //   </Typography.Text>
          // </Button>
          <></>
        ) : (
          <Link to={SignInUrl} className="group">
            <Button uiType="light">
              <SigninIcon className="w-4 h-4 -translate-x-0.5 group-hover:translate-x-0 transition-all duration-200" />
              <Typography.Text
                className="!font-semibold"
                size="sm"
                uiType="transparent"
              >
                Sign in
              </Typography.Text>
            </Button>
          </Link>
        )}
      </>
    );
  };

  return (
    <header
      className={cn(
        "header-area h-20 fixed inset-0 z-[100] transition-all duration-300 top-0 bg-white dark:bg-dark-2 shadow-[0_2px_28px_0_rgba(0,0,0,0.06)]",
        {
          "lg:!bg-transparent lg:!shadow-none": isTransparent,
          "!h-28": hasTopMessage,
        }
      )}
    >
      {hasTopMessage && (
        <HeaderMessage handleClose={() => dispatch(handleCloseTopMessage())} />
      )}
      <div className="h-20 w-full">
        <Container>
          <Flex direction="col" className="w-full h-full relative inset-0">
            <Flex className="w-full h-full items-center justify-between">
              <Flex items="center" className="flex-1 gap-12">
                <Flex items="center" className="gap-2">
                  <Link to={"/"}>
                    <Logo className="h-9 text-slate-800 dark:text-slate-200" />
                  </Link>
                  {theme.mode === "dark" ? (
                    <OvhWhiteLogo className="w-20 lg:hidden" />
                  ) : (
                    <OvhColorLogo className="w-20 lg:hidden" />
                  )}
                </Flex>
                <div className="items-center hidden lg:flex lg:gap-1 xl:gap-5">
                  <Navbar />
                </div>
              </Flex>
              <Flex items="center" className="gap-4">
                <Flex items="center" className="gap-4 !hidden md:!flex">
                  <ThemeChanger
                    anchor="top end"
                    itemsClassName="!z-[101]"
                    buttonClassName={cn("", {
                      "lg:!text-slate-100":
                        isTransparent && location.pathname === "/",
                    })}
                  />
                  <Flex items="center" className="gap-2">
                    <SignInSection />
                    <Link to={isAuth ? HomeUrl : SignUpUrl} className="group">
                      <Button uiType="primary">
                        <Typography.Text
                          className="!font-semibold !text-xs md:!text-sm"
                          uiType="transparent"
                        >
                          {isAuth ? "Dashboard" : "Get started"}
                        </Typography.Text>
                      </Button>
                    </Link>
                  </Flex>
                </Flex>
                <MenuIcon
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="block lg:hidden w-7 h-7 cursor-pointer text-slate-800 dark:text-slate-200"
                />
              </Flex>
              <AnimatePresence>
                {menuOpen && (
                  <motion.div
                    initial={{ display: "hidden", opacity: 0 }}
                    animate={{ display: "flex", opacity: 1 }}
                    exit={{ display: "hidden", opacity: 0 }}
                    transition={{ ease: "easeOut", type: "tween" }}
                    className="flex flex-col md:hidden absolute top-20 left-0 right-0 bg-white dark:bg-dark-2 shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] dark:shadow-[0_40px_40px_0_rgba(0,0,0,0.7)] px-6 overflow-hidden rounded-b-3xl"
                  >
                    <Flex direction="col" className="gap-3">
                      <Navbar />
                      <Link
                        to={isAuth ? HomeUrl : SignUpUrl}
                        className="text-slate-600 dark:text-slate-300 hover:text-primary-500 dark:hover:text-primary-500 text-sm px-2 tracking-wider flex items-center h-[48px] bg-primary-50 dark:bg-primary-400/10 rounded-md"
                      >
                        {isAuth ? "Dashboard" : "Get started"}
                      </Link>
                      <Flex
                        items="center"
                        justify="end"
                        className="w-full md:hidden mb-5 mt-2"
                      >
                        {/* <SignInSection /> */}
                        <Flex items="center" className="gap-1">
                          <Typography.Text
                            size="sm"
                            className="!text-slate-600 dark:!text-slate-300"
                          >
                            Theme
                          </Typography.Text>
                          <ThemeSwitcher />
                        </Flex>
                      </Flex>
                    </Flex>
                  </motion.div>
                )}
              </AnimatePresence>
            </Flex>
            {theme.mode === "dark" ? (
              <OvhWhiteLogo
                className={cn("w-24 absolute left-0 bottom-0", {
                  "hidden lg:block": isTransparent,
                  hidden: !isTransparent,
                })}
              />
            ) : (
              <OvhColorLogo
                className={cn("w-24 absolute left-0 bottom-0", {
                  "hidden lg:block": isTransparent,
                  hidden: !isTransparent,
                })}
              />
            )}
          </Flex>
        </Container>
      </div>
    </header>
  );
};

const Navbar = () => {
  const blogUrl = process.env.REACT_APP_BLOGS_URL || "";
  const docsUrl = process.env.REACT_APP_DOCS_URL || "";
  return (
    <>
      <Link
        to={"/#products"}
        className="text-slate-600 dark:text-slate-300 hover:text-primary-500 dark:hover:text-primary-500 text-sm px-2 tracking-wider flex items-center h-[48px]"
      >
        Products
      </Link>
      <Link
        to={docsUrl}
        target="_blank"
        className="text-slate-600 dark:text-slate-300 hover:text-primary-500 dark:hover:text-primary-500 text-sm px-2 tracking-wider flex items-center h-[48px]"
      >
        Docs
      </Link>
      <Link
        to="/#use-cases"
        className="text-slate-600 dark:text-slate-300 hover:text-primary-500 dark:hover:text-primary-500 text-sm px-2 tracking-wider flex items-center h-[48px]"
      >
        Use Cases
      </Link>
      <Link
        to={`${docsUrl}/en/collections/10816823-pricing`}
        target="_blank"
        className="text-slate-600 dark:text-slate-300 hover:text-primary-500 dark:hover:text-primary-500 text-sm px-2 tracking-wider flex items-center h-[48px]"
      >
        Pricing
      </Link>
      <Link
        to={blogUrl}
        target="_blank"
        className="text-slate-600 dark:text-slate-300 hover:text-primary-500 dark:hover:text-primary-500 text-sm px-2 tracking-wider flex items-center h-[48px]"
      >
        Blogs
      </Link>
      <Link
        to={AboutUsUrl}
        className="text-slate-600 dark:text-slate-300 hover:text-primary-500 dark:hover:text-primary-500 text-sm px-2 tracking-wider flex items-center h-[48px]"
      >
        About us
      </Link>
    </>
  );
};

const HeaderMessage: React.FC<{ handleClose?: () => void }> = ({
  handleClose,
}) => {
  return (
    <div className="w-full bg-sky-800 h-8 relative">
      <Container>
        <Flex className="w-full h-full gap-1" items="center" justify="center">
          <MegaphoneIcon className="w-4 h-4 text-slate-300" />
          <Typography.Text size="sm" className="!text-slate-300 !font-medium">
            <Link to={SignUpUrl}>
              <Typography.Text size="sm" uiType="transparent" underline>
                Sign up
              </Typography.Text>
            </Link>{" "}
            now with $50 sign up bonus
          </Typography.Text>
        </Flex>
      </Container>
      {typeof handleClose === "function" && (
        <CloseIcon
          onClick={handleClose}
          className="w-4 h-4 absolute right-10 top-4 -translate-y-1/2 text-slate-300 hover:text-slate-100 hover:scale-110 transition-all duration-300 cursor-pointer"
        />
      )}
    </div>
  );
};
export default WebHeader;
