import { useEffect, useState } from "react";
import { ReactComponent as MoreIcon } from "./../../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "./../../../../assets/icons/pencil-square.svg";
import {
  selectBucketDetails,
  selectBucketDetailsLoading,
} from "../../../../store/s3/buckets/bucketSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { S3NotActiveTab } from "../../../layouts/NotData";
import { DeleteModal } from "../../../modals/QuestionModal";
import {
  deleteBucketReplicationAsync,
  getBucketReplicationsAsync,
  handleShowReplicationEditor,
  selectBucketLReplicationsLoading,
  selectBucketReplicationActionLoading,
  selectReplications,
} from "../../../../store/s3/buckets/setting/replicationsSlice";
import S3ReplicationEditorModal from "../modals/buckets/S3ReplicationEditorModal";
import { selectS3Status } from "../../../../store/s3/s3PublicSlice";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";

const S3BucketReplicationsTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const replications = useAppSelector(selectReplications);
  const loading = useAppSelector(selectBucketLReplicationsLoading);
  const actionLoading = useAppSelector(selectBucketReplicationActionLoading);

  const [deleteReplication, setDeleteReplication] = useState<string | null>(
    null
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketReplicationsAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, bucketDetailsLoading, dispatch]);

  const handleDelete = () => {
    if (deleteReplication && bucketDetails)
      dispatch(
        deleteBucketReplicationAsync({
          bucketName: bucketDetails.name,
          id: deleteReplication,
        })
      ).then((action) => {
        if (action.type === "bucket/lifecycle/delete/fulfilled") {
          dispatch(getBucketReplicationsAsync({ name: bucketDetails.name }));
          setDeleteReplication(null);
        }
      });
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Typography.Title
          level={5}
          className="!mb-0 whitespace-nowrap font-medium"
        >
          Replications
        </Typography.Title>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            uiType="primary"
            onClick={() => dispatch(handleShowReplicationEditor({}))}
          >
            Add Replication
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Priority" />
              <SimpleTable.TH lable="Destination" />
              <SimpleTable.TH lable="Prefix" />
              <SimpleTable.TH lable="Tags" />
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {replications?.map((replication, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm font-semibold whitespace-nowrap">
                    {replication.priority}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {replication.destination.bucket.slice(0, 30) + "..."}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {replication.prefix}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {replication.tags ? "Yes" : "No"}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {replication.status}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-32 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        itemsClassName="!w-40"
                        anchor="bottom end"
                        menu={[
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: () =>
                              dispatch(
                                handleShowReplicationEditor({ replication })
                              ),
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: () => setDeleteReplication(replication.id),
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {replications?.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={7} className="!border-0">
                  <EmptyState text="You have not added any replication yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <S3ReplicationEditorModal />
      <DeleteModal
        isOpen={deleteReplication !== null}
        onClose={() => setDeleteReplication(null)}
        onConfirm={handleDelete}
        loading={actionLoading || bucketDetailsLoading}
        title="Delete Replication"
        // description={`Are you sure you want to delete the ${deleteReplication} rule?`}
        confirmButtonType="danger"
        // confirmString={
        //   replications.find((r) => r.id === deleteReplication)
        //     ?.id
        // }
      />
    </>
  );
};

export default S3BucketReplicationsTab;
