import { jwtEnvAxios } from ".";

//workflows urls
const GET_WORKFLOWS_API_URL = `/workflows`;
const CREATE_WORKFLOWS_API_URL = `/workflows`;
const UPDATE_WORKFLOW_API_URL = (id: string) => `/workflows/${id}`;
const DEPLOY_WORKFLOW_API_URL = (id: string) => `/workflows/deploy/${id}`;
const DELETE_WORKFLOW_API_URL = (id: string) => `/workflows/${id}`;
const GET_WORKFLOW_API_URL = (id: string) => `/workflows/${id}`;

//nodes-urls
const GET_NODES_API_URL = `/nodes`;
const GET_NODE_API_URL = (nodeName: string) => `/nodes/${nodeName}`;
const GET_NODE_ICON_API_URL = (nodeName: string) => `/node-icon/${nodeName}`;
const GET_NODE_LOAD_METHOD_API_URL = (nodeName: string) =>
  `/node-load-method/${nodeName}`;

//node credentials
const GET_NODE_CREDENTIALS_API_URL = `/credentials`;
const GET_NODE_CREDENTIAL_PARAMS_API_URL = (nodeName: string) =>
  `/node-credentials/${nodeName}`;
const CREATE_NEW_CREDENTIAL_API_URL = `/credentials`;
const UPDATE_CREDENTIAL_API_URL = (nodeName: string) =>
  `/credentials/${nodeName}`;
const GET_CREDENTIAL_API_URL = (nodeName: string) => `/credentials/${nodeName}`;

//api-keys urls
const GET_WORKFLOWS_API_KEYS_API_URL = `/apiKey`;
const CREATE_WORKFLOWS_API_KEY_API_URL = `/apiKey`;
const UPDATE_WORKFLOWS_API_KEY_API_URL = (id: string) => `/apiKey/${id}`;
const DELETE_WORKFLOWS_API_KEY_API_URL = (id: string) => `/apiKey/${id}`;

//workflows-apis
export function getWorkflowsApi() {
  return jwtEnvAxios("wf").get(GET_WORKFLOWS_API_URL);
}

export function createWorkflowApi(data: any) {
  return jwtEnvAxios("wf").post(CREATE_WORKFLOWS_API_URL, data);
}

export function updateWorkflowApi(id: string, data: any) {
  return jwtEnvAxios("wf").put(UPDATE_WORKFLOW_API_URL(id), data);
}

export function deployWorkflowApi(id: string, data: any) {
  return jwtEnvAxios("wf").post(DEPLOY_WORKFLOW_API_URL(id), data);
}

export function deleteWorkflowApi(id: string) {
  return jwtEnvAxios("wf").delete(DELETE_WORKFLOW_API_URL(id));
}

export function getWorkflowApi(id: string) {
  return jwtEnvAxios("wf").get(GET_WORKFLOW_API_URL(id));
}

export function testWorkflowApi(startingNodeId: string, body: any) {
  return jwtEnvAxios("wf").post(`/workflows/test/${startingNodeId}`, body);
}

//excutions
export function deleteWorkflowExecutionApi(shortId: string) {
  return jwtEnvAxios("wf").delete(`/executions/${shortId}`);
}

//nodes
export function getNodesApi() {
  return jwtEnvAxios("wf").get(GET_NODES_API_URL);
}

export function getNodeApi(name: string) {
  return jwtEnvAxios("wf").get(GET_NODE_API_URL(name));
}

export function getNodeIconApi(nodeName: string) {
  return jwtEnvAxios("wf").get(GET_NODE_ICON_API_URL(nodeName));
}

export function getNodeLoadMethodApi(nodeName: string, data: any) {
  return jwtEnvAxios("wf").post(GET_NODE_LOAD_METHOD_API_URL(nodeName), data);
}

export function removeTestTriggersApi() {
  return jwtEnvAxios("wf").post(`/remove-test-triggers`);
}

//node credentials
export function getNodeRegisteredCredentialsApi(nodeName: string) {
  return jwtEnvAxios("wf").get(GET_NODE_CREDENTIALS_API_URL, {
    params: { nodeCredentialName: nodeName },
  });
}

export function getNodeCredentialParamsApi(nodeName: string) {
  return jwtEnvAxios("wf").get(GET_NODE_CREDENTIAL_PARAMS_API_URL(nodeName));
}

export function createNewCredentialApi(data: any) {
  return jwtEnvAxios("wf").post(CREATE_NEW_CREDENTIAL_API_URL, data);
}

export function updateCredentialApi(id: string, data: any) {
  return jwtEnvAxios("wf").put(UPDATE_CREDENTIAL_API_URL(id), data);
}

export function getSpecificCredentialApi(id: string, isEncrypted?: boolean) {
  return jwtEnvAxios("wf").get(GET_CREDENTIAL_API_URL(id), {
    params: { isEncrypted },
  });
}

export function deleteCredentialApi(id: string) {
  return jwtEnvAxios("wf").delete(GET_CREDENTIAL_API_URL(id));
}

export function testNodeApi(nodeName: string, data: any) {
  return jwtEnvAxios("wf").post(`/node-test/${nodeName}`, data);
}

//webhooks
export function deleteAllTestWebhooksApi() {
  return jwtEnvAxios("wf").post(`/remove-test-webhooks`);
}

export function getTunnelURLApi() {
  return jwtEnvAxios("wf").get(`/get-tunnel-url`);
}

//oauth
export function geOAuth2PopupUrlApi(credentialId: string) {
  return jwtEnvAxios("wf").get("/oauth2", { params: { credentialId } });
}
export function geOAuth2RedirectUrlApi() {
  return jwtEnvAxios("wf").get("/oauth2-redirecturl");
}

//api-keys
export function getWorkflowsApiKeysApi() {
  return jwtEnvAxios("wf").get(GET_WORKFLOWS_API_KEYS_API_URL);
}

export function createWorkflowApiKeyApi(keyName: string) {
  return jwtEnvAxios("wf").post(CREATE_WORKFLOWS_API_KEY_API_URL, {
    keyName,
  });
}

export function updateWorkflowApiKeyApi(id: string, keyName: string) {
  return jwtEnvAxios("wf").put(UPDATE_WORKFLOWS_API_KEY_API_URL(id), {
    keyName,
  });
}

export function deleteWorkflowsApiKeyApi(id: string) {
  return jwtEnvAxios("wf").delete(DELETE_WORKFLOWS_API_KEY_API_URL(id));
}

//wallets
export function getWorkflowWalletsApi() {
  return jwtEnvAxios("wf").get("/wallets");
}

export function getWorkflowWalletApi(id: string) {
  return jwtEnvAxios("wf").get(`/wallets/${id}`);
}

export function getWorkflowWalletCredentialsApi(id: string) {
  return jwtEnvAxios("wf").get(`/wallets/credential/${id}`);
}

export function createWorkflowWalletApi(body: any) {
  return jwtEnvAxios("wf").post(`/wallets`, body);
}

export function updateWorkflowWalletApi(id: string, body: any) {
  return jwtEnvAxios("wf").put(`/wallets/${id}`, body);
}

export function deleteWorkflowWalletApi(id: string) {
  return jwtEnvAxios("wf").delete(`/wallets/${id}`);
}

export function getWorkflowWalletCredentialApi(id: string) {
  return jwtEnvAxios("wf").get(`/wallets/credential/${id}`);
}

//contracts
export function getWorkflowContractsApi() {
  return jwtEnvAxios("wf").get("/contracts");
}

export function getWorkflowContractApi(id: string) {
  return jwtEnvAxios("wf").get(`/contracts/${id}`);
}

export function createWorkflowContractApi(body: any) {
  return jwtEnvAxios("wf").post(`/contracts`, body);
}

export function updateWorkflowContractApi(id: string, body: any) {
  return jwtEnvAxios("wf").put(`/contracts/${id}`, body);
}

export function deleteWorkflowContractApi(id: string) {
  return jwtEnvAxios("wf").delete(`/contracts/${id}`);
}

export function getWorkflowContractAbiApi(body: any) {
  return jwtEnvAxios("wf").post(`/contracts/getabi`, body);
}
