import { PlansTab } from "../../plans/UniversalPlansBox";

const RpcEndpointsPlansTab = () => {
  const rpcServiceType = Number(process.env.REACT_APP_RPC_SERVICE_TYPE) || 1;
  return (
    <>
      <PlansTab serviceType={rpcServiceType} />
    </>
  );
};

export default RpcEndpointsPlansTab;
