import { Button, Input, Modal } from "djuno-design";
import { WorkflowNameSchema } from "../../../../utils/validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const SaveWorkflowDialog = ({ show, dialogProps, onCancel, onConfirm }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(WorkflowNameSchema),
  });

  const onSubmit = (data) => {
    onConfirm(data.name);
  };

  return (
    <Modal
      isOpen={show}
      onClose={onCancel}
      contentClassName="max-w-lg"
      title={dialogProps.title}
    >
      <div className="flex flex-col gap-2 mt-7">
        <Input
          label=""
          {...register("name")}
          error={errors.name?.message}
          id="workflow-name"
          type="text"
          placeholder="My New Workflow"
        />

        <div className="flex items-center gap-2 justify-end mt-7">
          <Button uiType="light" onClick={onCancel}>
            {dialogProps.cancelButtonName}
          </Button>
          <Button uiType="primary" onClick={handleSubmit(onSubmit)}>
            {dialogProps.confirmButtonName}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SaveWorkflowDialog;
