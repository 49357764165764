import { getBezierPath, EdgeText } from "reactflow";
import { EdgeProps } from "reactflow";
import { useAppDispatch } from "../../../../hooks";
import { handleSetRemoveEdge } from "../../../../store/workflows/workflowSlice";

const foreignObjectSize = 40;

const ButtonEdge: React.FC<EdgeProps> = (edgeProps) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    markerEnd,
  } = edgeProps;

  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const dispatch = useAppDispatch();

  const onEdgeClick = (evt: any, id: string) => {
    evt.stopPropagation();
    dispatch(handleSetRemoveEdge({ edgeId: `${id}:${Date.now()}` }));
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {data && data.label && (
        <EdgeText
          x={sourceX + 10}
          y={sourceY + 10}
          label={data.label}
          labelStyle={{ fill: "black" }}
          labelBgStyle={{ fill: "transparent" }}
          labelBgPadding={[2, 4]}
          labelBgBorderRadius={2}
        />
      )}
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div className="!bg-transparent !w-[40px] !h-[40px] !flex items-center justify-center min-h-[40px]">
          <button
            className="!w-[20px] !h-[20px] bg-secondary-200 dark:bg-gray-700 border dark:text-gray-200 border-white dark:border-gray-500 cursor-pointer rounded-full hover:shadow-[0_0_6px_2px_rgba(0,0,0,0.08)] dark:hover:shadow-[0_0_6px_2px_rgba(255,255,255,0.2)] flex justify-center items-center text-[11px] "
            onClick={(event) => onEdgeClick(event, id)}
          >
            ×
          </button>
        </div>
      </foreignObject>
    </>
  );
};

export default ButtonEdge;
