import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";

import {
  selectFiles,
  selectLoading,
  updateBreadCrumb,
  fetchFilesAysnc,
  deleteIpfsFileAsync,
  deleteIpfsFolderAsync,
} from "../../../store/ipfs/filesSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IpfsFile } from "../../../types/ipfs-file";
import File from "./File";
import { useSearch } from "../../../providers/SearchProvider";
import {
  deleteIpfsFileApi,
  GET_IPFS_FILE_URL,
  getExportApi,
} from "../../../apis/filesAPI";
import { selectOnStageEnv } from "../../../store/auth/authSlice";
import {
  downloadFileAsync,
  selectBreadCrumbItems,
} from "../../../store/ipfs/filesSlice";
import { FileTypes } from "../../../types";
import { fileParentFinder } from "../../../utils/file";
import { Modal, EmptyState, copyToClipboard, SimpleTable } from "djuno-design";
import { EmptyFileList } from "../../layouts/NotData";

function FileList() {
  const files = useAppSelector(selectFiles);
  const [filteredFiles, setSearchedFiles] = useState<IpfsFile[]>([]);
  const filesLoading = useAppSelector(selectLoading);

  const breadCrumbItems = useAppSelector(selectBreadCrumbItems);
  const onStageEnv = useAppSelector(selectOnStageEnv);

  const [showFile, setShowFile] = useState<IpfsFile | undefined>(undefined);
  const [downloadLoadings, setDownloadLoadings] = useState<string[]>([]);
  const [exportLoadings, setExportLoadings] = useState<string[]>([]);
  const [copyLoadings, setCopyLoadings] = useState<string[]>([]);
  const [deleteLoadings, setDeleteLoadings] = useState<string[]>([]);

  const { value: searchValue } = useSearch();
  const dispatch = useAppDispatch();

  const currentPath = useMemo(() => {
    return fileParentFinder(breadCrumbItems);
  }, [breadCrumbItems]);

  useEffect(() => {
    const lookedUpFiles = files?.filter((file) =>
      file.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSearchedFiles(lookedUpFiles);
  }, [dispatch, searchValue, files]);

  const handleFile = useCallback(
    (
      file: IpfsFile,
      e: MouseEvent<HTMLDivElement>,
      fileType: FileTypes | undefined
    ) => {
      e.preventDefault();
      e.stopPropagation();
      if (file.is_folder) {
        dispatch(updateBreadCrumb({ title: file.name }));
        dispatch(fetchFilesAysnc());
      }

      if (fileType === "photo") {
        setShowFile(file);
      }
    },
    [dispatch]
  );

  const handleDownload = useCallback(
    async (file: IpfsFile): Promise<any> => {
      setDownloadLoadings((prev) => [...prev, file.name]);
      try {
        return await dispatch(downloadFileAsync({ file, path: currentPath }));
      } finally {
        setDownloadLoadings((prev) => {
          return prev.filter((d) => d !== file.name);
        });
      }
    },
    [currentPath, dispatch]
  );

  const isDownloadLoading = useCallback(
    (fileName: string) => {
      return !!downloadLoadings.find((dl) => dl === fileName);
    },
    [downloadLoadings]
  );

  const handleExport = useCallback(
    async (file: IpfsFile): Promise<any> => {
      setExportLoadings((prev) => [...prev, file.name]);
      try {
        return await getExportApi(file, currentPath);
      } finally {
        setExportLoadings((prev) => {
          return prev.filter((d) => d !== file.name);
        });
      }
    },
    [currentPath]
  );

  const isExportLoading = useCallback(
    (fileName: string) => {
      return !!exportLoadings.find((dl) => dl === fileName);
    },
    [exportLoadings]
  );

  const handleCopyToClipboard = useCallback(
    async (file: IpfsFile): Promise<any> => {
      if (file.is_folder || file.public_cid === null) return;
      try {
        return await copyToClipboard(
          GET_IPFS_FILE_URL(file.public_cid, onStageEnv?.IpfsAddress),
          { successMessage: "Copied" }
        );
      } catch (e) {}
    },
    [onStageEnv?.IpfsAddress]
  );

  const handleDelete = useCallback(
    async (file: IpfsFile): Promise<any> => {
      setDeleteLoadings((prev) => [...prev, file.name]);
      const filePath = `${currentPath}/${file.name}`;
      try {
        if (file.is_folder) {
          await dispatch(deleteIpfsFolderAsync({ path: filePath }));
        } else {
          await dispatch(deleteIpfsFileAsync({ path: filePath }));
        }
        dispatch(fetchFilesAysnc());
      } catch (e) {
      } finally {
        setDeleteLoadings((prev) => prev.filter((name) => name !== file.name));
      }
    },
    [dispatch, currentPath]
  );

  const isDeleteLoading = useCallback(
    (fileName: string) => {
      return !!deleteLoadings.find((dl) => dl === fileName);
    },
    [deleteLoadings]
  );

  return (
    <>
      <SimpleTable loading={filesLoading} containerClassName="min-h-[240px]">
        <SimpleTable.Head>
          <SimpleTable.Row>
            <SimpleTable.TH lable="Name" />
            <SimpleTable.TH lable="Size" />
            <SimpleTable.TH lable="Options" />
          </SimpleTable.Row>
        </SimpleTable.Head>
        <SimpleTable.Body>
          {filteredFiles?.map((file, i) => (
            <File
              key={`file-${file.public_cid}-${i}`}
              file={file}
              name={file.name}
              size={file.size_byte || 0}
              downloadLoading={isDownloadLoading(file.name)}
              exportLoading={isExportLoading(file.name)}
              deleteLoading={isDeleteLoading(file.name)}
              onOpenFile={handleFile}
              onDownload={handleDownload}
              onExport={handleExport}
              onCopy={handleCopyToClipboard}
              onDelete={handleDelete}
            />
          ))}

          {files.length === 0 && (
            <SimpleTable.Row withoutHoverStyle className="h-[200px]">
              <SimpleTable.TD colSpan={3} className="!border-0">
                <EmptyFileList />
              </SimpleTable.TD>
            </SimpleTable.Row>
          )}
        </SimpleTable.Body>
      </SimpleTable>
      <Modal
        title={""}
        contentClassName="w-full max-w-2xl"
        // containerStyle={{ alignItems: "flex-start", overflowY: "auto" }}
        isOpen={!!showFile}
        onClose={() => setShowFile(undefined)}
      >
        <div className="w-full min-h-[100px]">
          {showFile && showFile.public_cid && (
            <img
              alt={showFile?.name}
              src={`${GET_IPFS_FILE_URL(
                showFile.public_cid,
                onStageEnv?.IpfsAddress
              )}`}
            />
          )}
        </div>
      </Modal>
    </>
  );
}

export default FileList;
