import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import {
  Dialog,
  DialogContent,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { getNodeIconUrl } from "../../../../utils/wfHelper";
import { Typography, Input } from "djuno-design";

const TestWorkflowDialog = ({ show, dialogProps, onCancel, onItemClick }) => {
  const portalElement = document.getElementById("portal");
  const theme = useTheme();

  const [searchValue, setSearchValue] = useState("");
  const [nodes, setNodes] = useState([]);

  const filterSearch = (value) => {
    setSearchValue(value);
    setTimeout(() => {
      if (value) {
        const returnData = dialogProps.nodes.filter((nd) =>
          nd.data.label.toLowerCase().includes(value.toLowerCase())
        );
        setNodes(returnData);
      } else if (value === "") {
        setNodes(dialogProps.nodes);
      }
    }, 500);
  };

  useEffect(() => {
    if (dialogProps.nodes) {
      setNodes(dialogProps.nodes);
    }
  }, [dialogProps]);

  const component = show ? (
    <Dialog
      open={show}
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        className:
          "!border-0 dark:!bg-dark-3 dark:!border-2 dark:!border-dark-2 !shadow-xl !transition-all !rounded-2xl",
      }}
    >
      <DialogContent>
        <Stack>
          <Typography.Text className="" type="title">
            {dialogProps.title}
          </Typography.Text>
          <Typography.Text className="text-sm">
            Select a starting point to test from. Workflow will be executed from
            the starting point till the end.
          </Typography.Text>
        </Stack>
        <Box sx={{ py: 2 }}>
          <Input
            className=""
            value={searchValue}
            onChange={(e) => filterSearch(e.target.value)}
            placeholder="Search nodes"
          />
        </Box>
        <div>
          <Box sx={{ p: 2 }}>
            <List
              sx={{
                width: "100%",
                py: 0,
                borderRadius: "10px",
                [theme.breakpoints.down("md")]: {
                  maxWidth: 300,
                },
                "& .MuiListItemSecondaryAction-root": {
                  top: 22,
                },
                "& .MuiDivider-root": {
                  my: 0,
                },
                "& .list-container": {
                  pl: 7,
                },
              }}
            >
              {nodes.map((node) => (
                <div key={node.id}>
                  <ListItemButton
                    sx={{
                      p: 0,
                      borderRadius: `8px`,
                    }}
                    onClick={() => onItemClick(node.id)}
                  >
                    <ListItem alignItems="center">
                      <ListItemAvatar>
                        <div
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: "50%",
                            backgroundColor: "white",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              padding: 10,
                              objectFit: "contain",
                            }}
                            alt={node.data.name}
                            src={getNodeIconUrl(node.data.icon)}
                          />
                        </div>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ ml: 1 }}
                        primary={node.data.label}
                        secondary={node.data.description}
                      />
                    </ListItem>
                  </ListItemButton>
                  <Divider />
                </div>
              ))}
            </List>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;

  return createPortal(component, portalElement);
};

TestWorkflowDialog.propTypes = {
  show: PropTypes.bool,
  dialogProps: PropTypes.object,
  onCancel: PropTypes.func,
  onItemClick: PropTypes.func,
};

export default TestWorkflowDialog;
