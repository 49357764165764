import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getServicesApi } from "../../apis/rpcAPI";
import { Service, ServicesState } from "../../types/service";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: ServicesState = {
  services: [],
  loading: false,
};

export const getServicesAsync = createAsyncThunk<
  { services: Service[] },
  undefined,
  IThunkRejectValue
>("rpc-services/get", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getServicesApi();
    const services = response.data.Result;

    return fulfillWithValue({ services });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const servicesSlice = createSlice({
  name: "rpc-services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServicesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServicesAsync.fulfilled, (state, action) => {
        const { services } = action.payload;
        state.services = services;
        state.loading = false;
      })
      .addCase(getServicesAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectServices = (state: RootState) => state.services.services;
export const selectLoading = (state: RootState) => state.services.loading;

// export const {} = rpcSlice.actions;
export default servicesSlice.reducer;
