import { Button, Flex, Input } from "djuno-design";
import { useState } from "react";

const AddressInput = ({
  placeholder,
  loading,
  disabled,
  onAdd,
  regex,
  errorMessage,
}: {
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  onAdd?: (value: string) => void;
  regex?: RegExp;
  errorMessage?: string;
}) => {
  const [value, setValue] = useState("");
  const handleApp = () => {
    if (onAdd && value.length > 0) {
      if (regex) {
        if (regex.test(value)) {
          onAdd(value);
          setValue("");
        }
      } else {
        onAdd(value);
        setValue("");
      }
    }
  };

  const hasError = regex ? !regex.test(value) : false;

  const saveIsdesabled =
    loading || disabled || value === "" || (regex && !regex.test(value));

  return (
    <Flex className="gap-2 pt-2 w-full">
      <div className="flex-1">
        <Input
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
          placeholder={placeholder}
          error={
            hasError && errorMessage && value !== "" ? errorMessage : undefined
          }
        />
      </div>
      <Button
        loading={loading}
        disabled={saveIsdesabled}
        onClick={handleApp}
        uiType="primary"
      >
        Add
      </Button>
    </Flex>
  );
};
export default AddressInput;
