import { Button, Modal } from "djuno-design";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  handleSetWebAppCredentialId,
  handleSetWebAppImageURL,
  selectWebAppIsValidateImage,
  selectWebAppValidationResponse,
} from "../../../../store/web-app/webAppCreateSlice";
import {
  handleHideWebAppUpdateImageModal,
  selectWebAppShowUpdateImageModal,
} from "../../../../store/web-app/webAppSlice";
import {
  DeployingImageFrom,
  WebAppImageValidationMessage,
} from "../../create-page-steps/SelectDeployingSourceStep";

const WebAppImageEditorModal = () => {
  const isOpen = useAppSelector(selectWebAppShowUpdateImageModal);
  const isValidateImage = useAppSelector(selectWebAppIsValidateImage);
  const validationResponse = useAppSelector(selectWebAppValidationResponse);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(handleHideWebAppUpdateImageModal());
    dispatch(handleSetWebAppImageURL(""));
    dispatch(handleSetWebAppCredentialId(null));
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Update Image"
      contentClassName="!max-w-3xl"
      onClose={handleCloseModal}
    >
      <DeployingImageFrom />
      {validationResponse && (
        <div className="mt-4 text-sm">
          <WebAppImageValidationMessage {...validationResponse} />
        </div>
      )}
      <div className="mt-4 flex justify-end gap-2">
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleCloseModal();
          }}
          uiType="light"
          className="w-[70px]"
        >
          Cancel
        </Button>
        <Button
          // loading={retentionLoading}
          uiType="primary"
          disabled={!isValidateImage}
          type="submit"
          className="w-[70px]"
        >
          Save Changes
        </Button>
      </div>
    </Modal>
  );
};

export default WebAppImageEditorModal;
