export type S3EventsState = {
  events: Array<S3Event>;
  loading: boolean;
  arns: string[];
  arnsLoading: boolean;
  actionLoading: boolean;
  showEditor: boolean;
  selectedEvent: null | S3Event;
};

export interface S3Event {
  // Id: number;
  account_id: string;
  status: string;
  service: string;
}

export const eventDestinationNames = [
  "kafka",
  "amqp",
  "mqtt",
  "redis",
  "nats",
  "nsq",
  "postgres",
  "mysql",
  "elasticsearch",
  "webhook",
] as const;

export type EventDestinationNames = (typeof eventDestinationNames)[number];
export interface EventDestinationOption {
  key: EventDestinationNames;
  name: string;
  iconUrl: string;
}
