import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Enable2FASchema } from "../../../../utils/validations";
import {
  enableTwoFactorAsync,
  generateOTPAsync,
  getMeAsync,
  handleHideEnable2fa,
  handleShowRecoveryModal,
  selectEnable2FAloading,
  selectGenerateOTPloading,
  selectShowEnable2fa,
} from "../../../../store/auth/authSlice";
import { useCallback, useState } from "react";
import QRCode from "qrcode";
import { Generate2FaResponse } from "../../../../types/auth";
import { Button, Typography, Modal, Loading, Input, Flex } from "djuno-design";

const SetupTwoFactorAuthModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowEnable2fa);
  const generateOTPloading = useAppSelector(selectGenerateOTPloading);
  const enable2faLoading = useAppSelector(selectEnable2FAloading);

  const [qrcodeUrl, setQrCodeUrl] = useState("");
  const [base32, setBase32] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Enable2FASchema),
  });

  const handleGenerateOTP = useCallback(() => {
    dispatch(generateOTPAsync()).then((action) => {
      if (action.type === "auth/generate-otp/fulfilled") {
        const res = action.payload as Generate2FaResponse;
        QRCode.toDataURL(res.QrcodeUrl).then(setQrCodeUrl);
        setBase32(res.Base32Url);
      }
    });
  }, [dispatch]);

  const onSubmit = (data: any) => {
    if (!enable2faLoading) {
      dispatch(
        enableTwoFactorAsync({
          code: data.code,
        })
      ).then((action) => {
        if (action.type === "auth/enable-2fa/fulfilled") {
          const res = action.payload.Result as string[];
          reset();
          dispatch(getMeAsync({}));
          dispatch(handleHideEnable2fa());
          dispatch(
            handleShowRecoveryModal({
              recoveryString: res,
              canRegenerateRecovery: false,
            })
          );
        }
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    reset();
    dispatch(handleHideEnable2fa());
    setQrCodeUrl("");
    setBase32("");
  }, [dispatch, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      contentClassName="!max-w-lg"
      title="Enable Two-Factor Authentication"
      rendered={handleGenerateOTP}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col gap-4">
          <Typography.Text className="!text-sm">
            Scan the QR code in your authenticator app or browser extention and
            enter the authentication code blowe.
          </Typography.Text>

          <Flex items="center" justify="center">
            <Flex
              items="center"
              justify="center"
              className="flex justify-center items-center my-3 w-56 h-56"
            >
              {generateOTPloading && <Loading borderSize={2} />}
              {!generateOTPloading && qrcodeUrl !== "" && (
                <img
                  className="block w-full h-full object-contain"
                  src={qrcodeUrl}
                  alt="qrcode url"
                />
              )}
              {!generateOTPloading && qrcodeUrl === "" && (
                <Typography.Text size="sm">
                  Faild to generate QR code
                </Typography.Text>
              )}
            </Flex>
          </Flex>

          {/* <div>
            <Typography.Text className="!text-sm">
              Or Enter Code Into Your App
            </Typography.Text>
            <Typography.Text className="!text-sm !flex !items-center !gap-1">
              SecretKey:{" "}
              <Input
                className="!w-56 md:!w-64"
                value={base32}
                loading={generateOTPloading}
                copyable
              />
            </Typography.Text>
          </div> */}
          <Input
            label="Code"
            {...register("code")}
            error={errors.code?.message}
            required
            placeholder="000000"
          />
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            uiType="primary"
            disabled={generateOTPloading}
            type="submit"
            loading={enable2faLoading}
          >
            Enable 2FA
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SetupTwoFactorAuthModal;
