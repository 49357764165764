import {
  InstanceVolumeTypeGroup,
  InstanceVolumeTypes,
  ProductAvalibilityPlan,
} from "../../../types/instance";

export const makeInstanceVolumeTypes = (plans: ProductAvalibilityPlan[]) => {
  let volumeTypeGroup: InstanceVolumeTypeGroup[] = [];

  let planCodes = plans.map((p) => p.code);
  planCodes = planCodes.filter((pc) => pc.endsWith(".consumption"));

  for (const planCode of planCodes) {
    const plan = plans.find((p) => p.code === planCode);
    if (plan) {
      volumeTypeGroup.push({
        title: getVolumePlanTypeFromPlanCode(planCode),
        plans: [plan],
      });
    }
  }
  return volumeTypeGroup;
};

export const getVolumePlanTypeFromPlanCode = (planCode: string) => {
  return planCode.split(".")[1] as InstanceVolumeTypes;
};
