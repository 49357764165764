import { SelectOption } from "djuno-design";
import moment, { utc } from "moment";

export const dateConvertor = (date: number | string) => {
  return new Date(
    typeof date === "number" ? date * 1000 : date
  ).toLocaleDateString("en-us", {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const yearsOptions: SelectOption[] = [
  {
    label: "2023",
    value: "2023",
  },
  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2025",
    value: "2025",
  },
  {
    label: "2026",
    value: "2026",
  },
  {
    label: "2027",
    value: "2027",
  },
  {
    label: "2028",
    value: "2028",
  },
  {
    label: "2029",
    value: "2029",
  },
  {
    label: "2030",
    value: "2030",
  },
];

export const monthOptions: SelectOption<number>[] = [
  {
    label: "January",
    value: "1",
  },
  {
    label: "February",
    value: "2",
  },
  {
    label: "March",
    value: "3",
  },
  {
    label: "April",
    value: "4",
  },
  {
    label: "May",
    value: "5",
  },
  {
    label: "June",
    value: "6",
  },
  {
    label: "July",
    value: "7",
  },
  {
    label: "August",
    value: "8",
  },
  {
    label: "September",
    value: "9",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];

export const getDaysInMonth = (
  month?: string,
  year?: string
): SelectOption[] => {
  const currentDate = new Date();
  const currentMonth =
    month === undefined ? (currentDate.getMonth() + 1).toString() : month;
  const currentYear =
    year === undefined ? currentDate.getFullYear().toString() : year;

  const daysInMonth = new Date(+currentYear, +currentMonth, 0).getDate();
  const daysArray: SelectOption[] = [];

  for (let day = 1; day <= daysInMonth; day++) {
    daysArray.push({ label: day.toString(), value: day.toString() });
  }

  return daysArray;
};

export const createDateString = (
  year: number,
  month: number,
  day: number
): string => {
  const dateObject = new Date(year, month - 1, day);
  // Use toISOString() to get the date string in the 'YYYY-MM-DDTHH:mm:ss.sssZ' format
  const dateString: string = dateObject.toISOString();

  return dateString;
};

export const timeAgo = (dateString: string | Date) => {
  // Convert the string to a moment object
  const dateObject: moment.Moment = moment.utc(
    dateString,
    "YYYY-MM-DD HH:mm:ss"
  );

  // Get the current date and time
  const currentDateTime: moment.Moment = moment.utc();

  // Calculate the time difference
  const timeDifference: moment.Duration = moment.duration(
    currentDateTime.diff(dateObject)
  );

  // Extract days, hours, minutes, months, and years from the time difference
  const days: number = timeDifference.days();
  const hours: number = timeDifference.hours();
  const minutes: number = timeDifference.minutes();
  const months: number = currentDateTime.diff(dateObject, "months");
  const years: number = currentDateTime.diff(dateObject, "years");

  const timeDifferenceInSeconds: number = currentDateTime.diff(
    dateObject,
    "seconds"
  );

  if (timeDifferenceInSeconds < 60) {
    return "a few seconds ago";
  }

  if (years > 0) {
    return `${years} ${years === 1 ? "year" : "years"} ago`;
  } else if (months > 0) {
    return `${months} ${months === 1 ? "month" : "months"} ago`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  }
};

export const fullDate = (dateString: string) => {
  return moment.utc(dateString).format("MM-DD-YYYY-HH-mm-ss");
};

export function formatTimestamp(
  timestamp: number | string,
  format: string = "MM/DD/YYYY HH:mm:ss A",
  options: { isUTC?: boolean } = { isUTC: true }
) {
  let date;

  if (typeof timestamp === "number") {
    // If the timestamp is a number, treat it as a Unix timestamp
    date = moment.unix(timestamp);
  } else if (typeof timestamp === "string") {
    // If the timestamp is a string, treat it as an ISO 8601 formatted string
    date = moment(timestamp);
  } else {
    throw new Error("Invalid timestamp format");
  }
  if (options.isUTC) {
    date = date.utc();
  }
  // Format date and time according to the provided format string
  const formattedDate = date.format(format);

  // Return an object with date and time properties
  return {
    date: formattedDate.split(" ")[0],
    time: formattedDate.split(" ")[1],
    datetime: formattedDate,
  };
}

export function convertTimeStringToUTC(timeString: string): Date {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Check if any of the components are NaN or if the hour is out of range
  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    isNaN(seconds) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59 ||
    seconds < 0 ||
    seconds > 59
  ) {
    // If the input time string is invalid, return the current UTC time
    return new Date();
  }

  // Create a new Date object with the UTC hours, minutes, and seconds
  const utcTime = new Date();
  utcTime.setUTCHours(hours, minutes, seconds || 0); // Set seconds to 0 if not provided

  return utcTime;
}

export const expireDate = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export function convertUTCToLocalTime(
  timeString: string,
  offset: number
): string {
  // Split the time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = timeString.split(":").map(Number);

  // Create a new Date object with the UTC hours, minutes, and seconds
  const utcTime = new Date(Date.UTC(2024, 6, 9, hours, minutes, seconds)); // Use Date.UTC to ensure UTC time

  // Calculate the local time based on the offset
  const localTime = new Date(utcTime.getTime() + offset * 3600 * 1000);

  // Format the local time to a readable string (HH:MM:SS)
  const localHours = localTime.getHours().toString().padStart(2, "");
  // const localMinutes = localTime.getMinutes().toString().padStart(2, "0");
  // const localSeconds = localTime.getSeconds().toString().padStart(2, "0");

  return `${localHours}`;
}
export const lastDayOfMonth = () => {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return lastDay;
};

export const getGmtOffsetHours = (testDate = new Date()) => {
  const dateString = testDate.toString();
  const matchResult = dateString.match(/GMT([+-])(\d{2})(\d{2})/);

  // console.log("testDate", testDate);
  // console.log("dateString", dateString);
  // console.log("matchResult", matchResult);

  if (matchResult) {
    const sign = matchResult[1] || "+"; // Either '+' or '-'
    const hours = parseInt(matchResult[2], 10); // The hour part (e.g., '07')
    const minutes = parseInt(matchResult[3], 10); // The minute part (e.g., '30')
    const gmtOffset = hours + minutes / 60;
    const gmtOffsetHours = parseFloat(sign + gmtOffset);

    // console.log("gmtOffsetHours", gmtOffsetHours);
    return gmtOffsetHours;
  } else {
    return null; // or throw an error, depending on your use case
  }
};
