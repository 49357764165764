import jwtAxios from ".";
import { Environment } from "../types/environment";
import { SurveyAnswer } from "../types/survey";
import { getLocalStorage } from "djuno-design";

const QUESTIONS_API_URL = `/survey`;
const SAVE_QUESTIONS_API_URL = `/survey`;

export function getSurveyQuestionsApi() {
  return jwtAxios.get(QUESTIONS_API_URL);
}

export function setSurveyQuestionsApi(answers: SurveyAnswer[]) {
  const env = getLocalStorage<Environment | null>("env", null);
  return jwtAxios.post(SAVE_QUESTIONS_API_URL + "/" + env?.Id, answers);
}
