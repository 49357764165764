import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectSelectedInvoiceForPay,
  handleConfigPayNowModal,
  selectPayByCloudCreditLoading,
  selectPayByDefaultCardLoading,
  selectPayByIntentLoading,
  payNowByCloudCreditAsync,
  payNowByDefaultCardAsync,
  payNowByIntentsync,
  invoicesAsync,
} from "../../../store/billing/billingSlice";
import CardDetailsBox from "./CardDetailsBox";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { CustomToast } from "../../general/Toast";
import { BillingIntentResponse } from "../../../types/billing";
import { selectUser } from "../../../store/auth/authSlice";
import { Button, Modal, Typography, Select, Checkbox } from "djuno-design";

const InvoicePayNowModal = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const invoice = useAppSelector(selectSelectedInvoiceForPay);
  const payByCloudCreditLoading = useAppSelector(selectPayByCloudCreditLoading);
  const payByDefaultCardLoading = useAppSelector(selectPayByDefaultCardLoading);
  const payByIntentLoading = useAppSelector(selectPayByIntentLoading);

  const [showPayByIntentModal, setShowPayByIntentModal] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [defaultCard, setDefaultCard] = useState(false);

  const payOptions = useMemo(() => {
    if (!invoice) return [];
    return invoice.InvoiceActions.map((action) => {
      return {
        label: action.Name,
        value: action.Action,
      };
    });
  }, [invoice]);

  const [selectedPayOption, setSelectedPayOption] = useState<
    string | undefined
  >(payOptions[0]?.value);

  const handleClose = useCallback(() => {
    setSaveCard(false);
    setDefaultCard(false);
    setShowPayByIntentModal(false);
    dispatch(handleConfigPayNowModal(null));
  }, [dispatch]);

  const payCompilited = useCallback(() => {
    handleClose();
    toast.success(
      () =>
        CustomToast(
          "Your invoice has been paid successfully. A confirmation email has been sent to your registered address. Thank you for your payment!"
        ),
      { duration: 4000 }
    );
    dispatch(invoicesAsync());
  }, [dispatch, handleClose]);

  const handlePayByCloudCredit = useCallback(() => {
    if (invoice) {
      dispatch(payNowByCloudCreditAsync({ invoiceId: invoice.Id })).then(
        (action) => {
          if (action.type === "billing/invoice/pay-by-cloud-credit/fulfilled") {
            payCompilited();
          }
        }
      );
    }
  }, [dispatch, invoice, payCompilited]);

  const handlePayByDefaultCard = useCallback(() => {
    if (invoice) {
      dispatch(payNowByDefaultCardAsync({ invoiceId: invoice.Id })).then(
        (action) => {
          if (action.type === "billing/invoice/pay-by-default-card/fulfilled") {
            payCompilited();
          }
        }
      );
    }
  }, [dispatch, invoice, payCompilited]);

  const handleBeforInitPayByIntent = useCallback(async () => {
    if (invoice) {
      return dispatch(
        payNowByIntentsync({
          invoiceId: invoice.Id,
          saveCard,
          defaultCard: saveCard ? defaultCard : false,
        })
      ).then((action) => {
        if (action.type === "billing/invoice/intent/fulfilled") {
          const orderToken = (action.payload as BillingIntentResponse).Token;
          return orderToken;
        } else {
          return undefined;
        }
      });
    } else {
      return undefined;
    }
  }, [defaultCard, dispatch, invoice, saveCard]);

  const handleAfterInitPayByIntent = useCallback(async () => {
    setShowPayByIntentModal(false);
    payCompilited();
  }, [payCompilited]);

  const handleStartPayInvoice = () => {
    if (selectedPayOption) {
      if (selectedPayOption === "Cloud credit") handlePayByCloudCredit();
      if (selectedPayOption === "Card") handlePayByDefaultCard();
      if (selectedPayOption === "Intent") setShowPayByIntentModal(true);
    }
  };

  return (
    <>
      <Modal
        isOpen={invoice !== null}
        onClose={handleClose}
        contentClassName="max-w-lg"
        title="Choose Payment Method"
      >
        <Typography.Text className="!text-sm !mt-5">
          Select how you'd like to pay your invoice. You can use your default
          saved card, add a new card, or apply cloud credit if available. Please
          choose one of the options below to proceed with the payment
        </Typography.Text>
        <div className="flex items-center justify-start mt-5 w-full gap-2">
          <Typography.Text className="!text-sm !whitespace-nowrap">
            Select payment method
          </Typography.Text>
          <Select
            options={payOptions}
            value={selectedPayOption}
            onChange={setSelectedPayOption}
            className="w-full"
            emptyString="Select an option"
          />
          <Button
            disabled={!selectedPayOption}
            onClick={handleStartPayInvoice}
            loading={
              payByCloudCreditLoading ||
              payByDefaultCardLoading ||
              payByIntentLoading
            }
          >
            Pay
          </Button>
        </div>
        {selectedPayOption === "Intent" && (
          <div className="flex flex-col gap-2 mt-5">
            <Checkbox
              label="Save card for next payments"
              value={saveCard}
              onChange={setSaveCard}
            />
            {saveCard && (
              <Checkbox
                label="Set as default"
                value={defaultCard}
                onChange={setDefaultCard}
              />
            )}
          </div>
        )}

        <Modal
          isOpen={showPayByIntentModal}
          onClose={() => {
            setShowPayByIntentModal(false);
          }}
          title="Pay invoice by card"
          contentClassName="max-w-lg"
        >
          <CardDetailsBox
            beforInitFn={handleBeforInitPayByIntent}
            afterInitFn={handleAfterInitPayByIntent}
            initLoading={payByIntentLoading}
            savingLoading={false}
            username={user?.FullName}
          />
        </Modal>
      </Modal>
    </>
  );
};

export default InvoicePayNowModal;
