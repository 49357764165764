import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  Instance,
  InstanceFlavor,
  InstanceImage,
  InstanceSnapshot,
  InstancesState,
  OvhRegion,
  ProductAvalibility,
} from "../../types/instance";
import {
  deleteInstanceApi,
  getInstancesApi,
  getInstancesProductAvalibilityApi,
  getInstancesFlavorsApi,
  getRegionsApi,
  getInstancesImagesApi,
  createInstanceApi,
  createMultipleInstanceApi,
  getInstancesSnapshotsApi,
  deleteInstancesSnapshotApi,
} from "../../apis/instancesAPI";

export const InstanceRefreshStatus = [
  "SHELVING",
  "UNSHELVING",
  "BUILD",
  "RESCUING",
  "UNRESCUING",
  "REBOOT",
  "HARD_REBOOT",
  "REBUILD",
  "RESIZE",
  "VERIFY_RESIZE",
  "SNAPSHOTING",
];

export const InstanceDisableStatus = ["SHUTOFF", "SHELVED_OFFLOADED"];

const initialState: InstancesState = {
  instances: [],
  loading: false,
  actionLoading: false, //for create
  selectedInstance: null, //for rename //TODO
  showRenameModal: false,

  //public data

  flavors: [],
  flavorsLoading: false,

  productAvailibility: undefined, //dummyProductAvailibility
  productAvailibilityLoading: false,

  regions: [], //...dummyRegions
  regionsLoading: false,

  images: [],
  imagesLoading: false,

  snapshots: [],
  snapshotsLoading: false,
  snapshotsActionLoading: false,
};

export const getInstancesAsync = createAsyncThunk<
  { instances: Array<Instance> },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instances",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getInstancesAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getInstancesApi();
      const instances = response.data.Result;
      return fulfillWithValue({ instances });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createInstanceAsync = createAsyncThunk<
  true,
  { data: any },
  IThunkRejectValue
>("instances/create", async ({ data }, { rejectWithValue }) => {
  try {
    await createInstanceApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createMultipleInstanceAsync = createAsyncThunk<
  true,
  { data: any },
  IThunkRejectValue
>("instances/create-multiple", async ({ data }, { rejectWithValue }) => {
  try {
    await createMultipleInstanceApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteInstanceAsync = createAsyncThunk<
  true,
  { id: string },
  IThunkRejectValue
>("instances/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteInstanceApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getInstancesFlavorsAsync = createAsyncThunk<
  { flavors: Array<InstanceFlavor> },
  undefined,
  IThunkRejectValue
>("instances/flavors", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getInstancesFlavorsApi();
    const flavors = response.data.Result;
    return fulfillWithValue({ flavors });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getInstancesProductAvailabilityAsync = createAsyncThunk<
  { avalibility: ProductAvalibility },
  { addonFamily?: string } | undefined,
  IThunkRejectValue
>(
  "instances/productAvailability",
  async (data, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstancesProductAvalibilityApi({
        addonFamily: data?.addonFamily,
      });
      const avalibility = response.data.Result;
      return fulfillWithValue({ avalibility });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getRegionsAsync = createAsyncThunk<
  { regions: OvhRegion[] },
  undefined,
  IThunkRejectValue
>("instances/regions", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getRegionsApi();
    const regions = response.data.Result;
    return fulfillWithValue({ regions });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getInstancesImagesAsync = createAsyncThunk<
  { images: Array<InstanceImage> },
  { region: string },
  IThunkRejectValue
>(
  "instances/images",
  async ({ region }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstancesImagesApi(region);
      const images = response.data.Result;
      return fulfillWithValue({ images });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const getInstancesSnapshotsAsync = createAsyncThunk<
  { snapshots: Array<InstanceSnapshot> },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instances/snapshots",
  async ({ withoutLoading }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstancesSnapshotsApi();
      const snapshots = response.data.Result;
      return fulfillWithValue({ snapshots });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const deleteInstancesSnapshotAsync = createAsyncThunk<
  true,
  { imageId: string },
  IThunkRejectValue
>("instances/snapshot/delete", async ({ imageId }, { rejectWithValue }) => {
  try {
    await deleteInstancesSnapshotApi(imageId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const instancesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    handleShowInstanceRenameModal: (state, action: PayloadAction<Instance>) => {
      state.showRenameModal = true;
      state.selectedInstance = action.payload;
    },
    handleHideInstanceRenameModal: (state) => {
      state.showRenameModal = false;
      state.selectedInstance = null;
    },
    handleClearInstanceImages: (state) => {
      state.images = [];
      state.imagesLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstancesAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getInstancesAsync.fulfilled, (state, action) => {
        const { instances } = action.payload;
        state.instances = instances;
        state.loading = false;
      })
      .addCase(getInstancesAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createInstanceAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createInstanceAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createInstanceAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createMultipleInstanceAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createMultipleInstanceAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createMultipleInstanceAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteInstanceAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteInstanceAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteInstanceAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getInstancesFlavorsAsync.pending, (state) => {
        state.flavorsLoading = true;
      })
      .addCase(getInstancesFlavorsAsync.fulfilled, (state, action) => {
        state.flavorsLoading = false;
        state.flavors = action.payload.flavors;
      })
      .addCase(getInstancesFlavorsAsync.rejected, (state, { payload }) => {
        state.flavorsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getInstancesProductAvailabilityAsync.pending, (state) => {
        state.productAvailibilityLoading = true;
      })
      .addCase(
        getInstancesProductAvailabilityAsync.fulfilled,
        (state, action) => {
          state.productAvailibilityLoading = false;
          state.productAvailibility = action.payload.avalibility;
        }
      )
      .addCase(
        getInstancesProductAvailabilityAsync.rejected,
        (state, { payload }) => {
          state.productAvailibilityLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getRegionsAsync.pending, (state) => {
        state.regionsLoading = true;
      })
      .addCase(getRegionsAsync.fulfilled, (state, action) => {
        state.regionsLoading = false;
        state.regions = action.payload.regions;
      })
      .addCase(getRegionsAsync.rejected, (state, { payload }) => {
        state.regionsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getInstancesImagesAsync.pending, (state) => {
        state.imagesLoading = true;
      })
      .addCase(getInstancesImagesAsync.fulfilled, (state, action) => {
        state.imagesLoading = false;
        state.images = action.payload.images;
      })
      .addCase(getInstancesImagesAsync.rejected, (state, { payload }) => {
        state.imagesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getInstancesSnapshotsAsync.pending, (state) => {
        state.snapshotsLoading = true;
      })
      .addCase(getInstancesSnapshotsAsync.fulfilled, (state, action) => {
        const { snapshots } = action.payload;
        state.snapshots = snapshots;
        state.snapshotsLoading = false;
      })
      .addCase(getInstancesSnapshotsAsync.rejected, (state, { payload }) => {
        state.snapshotsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(deleteInstancesSnapshotAsync.pending, (state) => {
        state.snapshotsActionLoading = true;
      })
      .addCase(deleteInstancesSnapshotAsync.fulfilled, (state) => {
        state.snapshotsActionLoading = false;
      })
      .addCase(deleteInstancesSnapshotAsync.rejected, (state, { payload }) => {
        state.snapshotsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectInstances = (state: RootState) => state.instances.instances;
export const selectInstancesLoading = (state: RootState) =>
  state.instances.loading;

export const selectInstancesActionLoading = (state: RootState) =>
  state.instances.actionLoading;

export const selectDatabaseServiceRenameIsOpen = (state: RootState) =>
  state.instances.showRenameModal;

export const selectSelectedInstance = (state: RootState) =>
  state.instances.selectedInstance;

export const selectInstancesFlavors = (state: RootState) =>
  state.instances.flavors;
export const selectInstancesFlavorsLoading = (state: RootState) =>
  state.instances.flavorsLoading;

export const selectInstancesProductAvailibility = (state: RootState) =>
  state.instances.productAvailibility;
export const selectInstancesProductAvailibilityLoading = (state: RootState) =>
  state.instances.productAvailibilityLoading;

export const selectInstancesRegions = (state: RootState) =>
  state.instances.regions;
export const selectInstancesRegionsLoading = (state: RootState) =>
  state.instances.regionsLoading;

export const selectInstancesImages = (state: RootState) =>
  state.instances.images;
export const selectInstancesImagesLoading = (state: RootState) =>
  state.instances.imagesLoading;

export const selectInstancesSnapshots = (state: RootState) =>
  state.instances.snapshots;
export const selectInstancesSnapshotsLoading = (state: RootState) =>
  state.instances.snapshotsLoading;
export const selectInstancesSnapshotsActionLoading = (state: RootState) =>
  state.instances.snapshotsActionLoading;

export const {
  handleHideInstanceRenameModal,
  handleShowInstanceRenameModal,
  handleClearInstanceImages,
} = instancesSlice.actions;
export default instancesSlice.reducer;
