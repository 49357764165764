import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
// import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import DestinationEditorModal from "./DestinationEditorModal";
import { DeleteModal } from "../../modals/QuestionModal";
import { BlockEventDestination } from "../../../types/block-events";
import {
  deleteDestinationAsync,
  getDestinationsAsync,
  handleSetDestination,
  handleShowDestinationEditor,
  selectDestinations,
  selectDestinationsActionLoading,
  selectDestinationsLoading,
} from "../../../store/block-event/destinationsSlice";
import { useSearch } from "../../../providers/SearchProvider";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { BlockEventDestinationUrl } from "../../../utils/urls";
import BlockEventDestinationsTable from "./BlockEventDestinationsTable";
import { Button, Typography, Dropdown } from "djuno-design";

const BlockEventDesticationTab = () => {
  const [deleteDestination, setDeleteDestination] =
    useState<BlockEventDestination | null>(null);
  const destinations = useAppSelector(selectDestinations);
  const loading = useAppSelector(selectDestinationsLoading);
  const [filteredDestinations, setFilteredDestinations] = useState<
    BlockEventDestination[]
  >([]);
  const actionLoading = useAppSelector(selectDestinationsActionLoading);
  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getDestinationsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUp = destinations.filter((destination) =>
      destination.Name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredDestinations(lookedUp);
  }, [dispatch, searchValue, destinations]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Destinations</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Typography.Text className="!whitespace-nowrap !font-medium"></Typography.Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button
            uiType="primary"
            onClick={() => dispatch(handleShowDestinationEditor({}))}
            className="group"
          >
            Create Destination
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <BlockEventDestinationsTable
          destinations={filteredDestinations}
          searchValue={searchValue}
          loading={loading}
          rowCallback={(destination) => {
            dispatch(handleSetDestination(destination));
            navigate(BlockEventDestinationUrl(destination.DestId));
          }}
          actionsRendered={(destination) => {
            return (
              <div className="h-full w-full inline-flex items-center justify-end gap-1 px-4">
                <div className="w-8 flex justify-center items-center">
                  <Dropdown
                    anchor="bottom end"
                    itemsClassName="!w-40"
                    menu={[
                      {
                        key: "details",
                        label: (
                          <div className="flex items-center gap-1">
                            <DetailsIcon className="w-4" />
                            Get details
                          </div>
                        ),
                        onClick: () => {
                          dispatch(handleSetDestination(destination));
                          navigate(
                            BlockEventDestinationUrl(destination.DestId)
                          );
                        },

                        disabled: loading,
                      },
                      // {
                      //   key: "edit",
                      //   label: (
                      //     <div className="flex items-center gap-1">
                      //       <EditIcon className="w-4" />
                      //       Edit
                      //     </div>
                      //   ),
                      //   onClick: () =>
                      //     dispatch(
                      //       handleShowDestinationEditor({ destination })
                      //     ),
                      // },
                      {
                        type: "divider",
                      },
                      {
                        key: "delete",
                        label: (
                          <div className="flex items-center gap-1">
                            <ArchiveIcon className="w-4" />
                            Delete
                          </div>
                        ),
                        danger: true,
                        onClick: () => {
                          setDeleteDestination(destination);
                        },
                      },
                    ]}
                  >
                    <Button uiSize="small" uiType="icon" className="!px-2">
                      <MoreIcon className="w-4 h-4" />
                    </Button>
                  </Dropdown>
                </div>
                <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
              </div>
            );
          }}
        />
      </div>
      <DestinationEditorModal />

      <DeleteModal
        title="Delete Destination"
        isOpen={deleteDestination !== null}
        onClose={() => setDeleteDestination(null)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteDestination &&
            dispatch(
              deleteDestinationAsync({ destId: deleteDestination.DestId })
            ).then((action) => {
              if (action.type === "block-event/destinations/delete/fulfilled") {
                dispatch(getDestinationsAsync());
              }
              setDeleteDestination(null);
            });
        }}
        loading={actionLoading}
        confirmString={deleteDestination?.Name}
      />
    </>
  );
};

export default BlockEventDesticationTab;
