import {
  DatabaseService,
  DBSEngineType,
  IDBSCertificate,
} from "../../../types/database";
import { ReactComponent as DownloadIcon } from "./../../../assets/icons/arrow-down-tray.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getDBSCertificateAsync,
  selectCertificate,
  selectCertificateLoading,
} from "../../../store/database/serviceSlice";
import { useEffect, useMemo } from "react";
import { Flex, SecureCopy, Typography } from "djuno-design";

export const downloadCertificate = (certificate: IDBSCertificate | null) => {
  if (certificate?.ca) {
    const blob = new Blob([certificate.ca], {
      type: "text/plain;charset=utf-8",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "ca_certificate.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } else {
    console.error("No CA certificate available to download");
  }
};

const DBSCertificate: React.FC<{
  title?: string;
  service: DatabaseService;
  validEngines?: Array<DBSEngineType>;
  disable?: boolean;
}> = ({ title, service, validEngines, disable = false }) => {
  const certificate = useAppSelector(selectCertificate);
  const certificateLoading = useAppSelector(selectCertificateLoading);

  const dispatch = useAppDispatch();

  const isValideService = useMemo(() => {
    return !!(
      validEngines &&
      validEngines.length &&
      validEngines.includes(service.engine)
    );
  }, [service.engine, validEngines]);

  useEffect(() => {
    if (service && isValideService && !disable) {
      dispatch(
        getDBSCertificateAsync({ engine: service.engine, id: service.id })
      );
    }
  }, [dispatch, isValideService, service, disable]);

  if (!isValideService) return null;
  return (
    <div className="p-2">
      <div className="flex flex-col">
        <Typography.Title level={6} className="!text-sm">
          {title}
        </Typography.Title>
        <Flex items="center" className="gap-2">
          <SecureCopy
            className="!flex-1"
            type="copy"
            text={certificate ? certificate.ca : ""}
          />
          <DownloadIcon
            className="w-4 h-4 hover:scale-110 transition-all duration-300 hover:text-primary-400 hover:cursor-pointer dark:text-slate-200 hover:dark:text-primary-400"
            onClick={() => downloadCertificate(certificate)}
          />
        </Flex>
      </div>
    </div>
  );
};

export default DBSCertificate;
