import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import S3Banner from "./S3Banner";
import {
  chartName,
  RpcMetricChart,
} from "../../rpc-endpoints/metrics/RpcEndpointMetricsTab";

import { Flex, Loading, Typography } from "djuno-design";
import {
  getNewS3MetricsAsync,
  selectNewS3Metrics,
  selectNewS3MetricsLoading,
} from "../../../store/s3/s3PublicSlice";
import BucketsCountWidget from "./widgets/BucketsCountWidget";
import ObjectsCountWidget from "./widgets/ObjectsCountWidget";
import {
  getS3MetricsAsync,
  selectS3Metrics,
  selectS3MetricsLoading,
} from "../../../store/s3/overviewSlice";
import ReportedUsageWidget from "./widgets/ReportedUsageWidget";

const S3NewOverviewTab = () => {
  const s3Metrics = useAppSelector(selectNewS3Metrics);
  const s3MetricsLoading = useAppSelector(selectNewS3MetricsLoading);

  const info = useAppSelector(selectS3Metrics);
  const infoLoading = useAppSelector(selectS3MetricsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNewS3MetricsAsync({}));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getS3MetricsAsync());
  }, [dispatch]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    interval = setInterval(() => {
      dispatch(getNewS3MetricsAsync({ withoutLoading: true }));
    }, 10000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-8">
      <S3Banner />

      <div className="mt-10 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-7">
        <BucketsCountWidget bucketCount={info?.buckets} loading={infoLoading} />
        <ObjectsCountWidget objectCount={info?.objects} loading={infoLoading} />
        <ReportedUsageWidget />
      </div>

      {s3MetricsLoading && (
        <Flex items="center" justify="center" className="w-full h-[300px]">
          <Loading borderSize={2} />
        </Flex>
      )}

      {!s3MetricsLoading && s3Metrics && Object.keys(s3Metrics).length > 0 && (
        <Flex direction="col" className="w-full gap-14">
          {Object.keys(s3Metrics).map((key) => {
            return (
              <div className="h-[200px]">
                <Flex justify="center" className="w-full mt-5">
                  <Typography.Text className="!font-semibold !text-xs">
                    {chartName(key)}
                  </Typography.Text>
                </Flex>
                <RpcMetricChart
                  chartName={chartName(key)}
                  data={s3Metrics[key as keyof typeof s3Metrics]}
                />
              </div>
            );
          })}
        </Flex>
      )}
    </div>
  );
};

export default S3NewOverviewTab;
