import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  disableTwoFactorAsync,
  getMeAsync,
  handleShowChangePassEditor,
  handleShowEnable2fa,
  handleShowRecoveryModal,
  selectDisable2FAloading,
  selectProfileUpdateLoading,
  selectUser,
  updateProfileAsync,
} from "../../../store/auth/authSlice";
import PasswordUpdateModal from "./modals/PasswordUpdateModal";
import UserAvatar from "./modals/UserAvatar";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserProfileSchema } from "../../../utils/validations";
import { useEffect, useState } from "react";
import SetupTwoFactorAuthModal from "./modals/SetupTwoFactorAuthModal";
import QuestionModal from "../../modals/QuestionModal";
import { ReactComponent as WarningIcon } from "./../../../assets/icons/warning.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import TwoFactorRecoveryModal from "./modals/TwoFactorRecoveryModal";
import { Helmet } from "react-helmet";
import { Button, Typography, Switcher, Card, Input } from "djuno-design";
import CloudFeatures from "../../general/CloudFeatures";

const SettingProfileTab = () => {
  const authUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const profileUpdateLoading = useAppSelector(selectProfileUpdateLoading);

  const disable2faLoading = useAppSelector(selectDisable2FAloading);
  const [showDisableQuestion, setShowDisableQuestion] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(UserProfileSchema),
  });

  useEffect(() => {
    setValue("name", authUser?.FullName || "");
  }, [authUser, setValue]);

  const onSubmit = (data: any) => {
    if (!profileUpdateLoading) {
      dispatch(
        updateProfileAsync({
          FullName: data.name,
        })
      ).then((action) => {
        if (action.type === "auth/update-profile/fulfilled") {
          reset();
          dispatch(getMeAsync({ withoutLoading: true }));
        }
      });
    }
  };

  const handleChange2faStatus = () => {
    if (authUser) {
      if (authUser.TwoFactorEnabled) {
        setShowDisableQuestion(true);
      } else {
        dispatch(handleShowEnable2fa());
      }
    }
  };

  const handleUpdateTwoFactor = () => {
    dispatch(handleShowEnable2fa());
  };

  const handleGetRecoveryCodes = () => {
    dispatch(
      handleShowRecoveryModal({
        recoveryString: null, // for make request to get new recovery string from recovery modal
        canRegenerateRecovery: true,
        canCloseRecoveryModal: true,
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Profile</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="w-full flex flex-col gap-10">
        <Card title="Profile">
          <div className="flex flex-col gap-4">
            <UserAvatar />
            <div className="flex ">
              <div className="w-32">
                <Typography.Text className="!text-sm !font-medium">
                  Login Email
                </Typography.Text>
              </div>
              <Typography.Text className="!text-sm">
                {authUser?.Email}
              </Typography.Text>
            </div>
            <div className="flex ">
              <div className="w-32">
                <Typography.Text className="!text-sm !font-medium">
                  Full Name
                </Typography.Text>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className=" md:w-[300px]">
                <Input
                  {...register("name")}
                  error={errors.name?.message}
                  placeholder="Full Name..."
                />

                <div className="mt-4 flex">
                  <Button
                    uiType="primary"
                    type="submit"
                    loading={profileUpdateLoading}
                  >
                    Save Changes
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>

        <Card title="Features">
          <CloudFeatures />
        </Card>

        <Card title="Security">
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <div className="w-32">
                <Typography.Text className="!text-sm !font-medium">
                  Password
                </Typography.Text>
              </div>
              <Button onClick={() => dispatch(handleShowChangePassEditor())}>
                Update Password
              </Button>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <Typography.Text className="!text-sm !font-medium !whitespace-nowrap">
                    Two-Factor Authentication
                  </Typography.Text>
                  <div className="flex items-center justify-end gap-1">
                    <Typography.Text className="!text-sm" uiType="secondary">
                      {authUser?.TwoFactorEnabled ? "Enabled" : "Disable"}
                    </Typography.Text>
                    <Switcher
                      loading={disable2faLoading}
                      onChange={handleChange2faStatus}
                      value={authUser?.TwoFactorEnabled}
                    />
                  </div>
                </div>
                <Typography.Text
                  className="!text-xs md:!text-sm md:!w-3/4 mt-2 md:!mt-1"
                  uiType="secondary"
                >
                  Render uses time-based one-time passcode (TOTP) thar are
                  compliant with all major authenticator app and browser
                  extentions including 1password, Authy, and Google
                  Authenticator
                </Typography.Text>
              </div>
            </div>
            {authUser?.TwoFactorEnabled && (
              <div className="flex flex-col border rounded-md border-slate-200 dark:border-dark-2 w-full">
                <div className="flex items-center justify-between p-4 w-full">
                  <div className="flex flex-col w-full">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-0.5">
                        <Typography.Text className="!text-sm !font-medium">
                          Authenticator App
                        </Typography.Text>
                        <Typography.Text
                          className="!text-sm !hidden md:!block"
                          uiType="secondary"
                        >
                          {" "}
                          - Primary method
                        </Typography.Text>
                      </div>
                      <Button onClick={handleUpdateTwoFactor}>
                        Update Method
                      </Button>
                      <EditIcon
                        className="w-5 md:hidden text-slate-800 dark:text-slate-300"
                        onClick={handleUpdateTwoFactor}
                      />
                    </div>

                    <Typography.Text
                      className="!text-xs md:!text-sm md:!w-3/4 !mt-2 md:!mt-1"
                      uiType="secondary"
                    >
                      Use your authenticator app or browser extention to get
                      Two-Factor athentication codes.
                    </Typography.Text>
                  </div>
                </div>
                <div className="flex items-center justify-between p-4">
                  <div className="flex flex-col w-full">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-0.5">
                        <Typography.Text className="!text-sm !font-medium">
                          Backup Codes
                        </Typography.Text>
                      </div>
                      <Button
                        className="!hidden md:!block"
                        onClick={handleGetRecoveryCodes}
                      >
                        Get Codes
                      </Button>
                      <RefreshIcon
                        className="w-5 md:hidden text-slate-800 dark:text-slate-300"
                        onClick={handleGetRecoveryCodes}
                      />
                    </div>

                    <Typography.Text
                      className="!text-xs md:!text-sm md:!w-3/4 !mt-2 md:!mt-1"
                      uiType="secondary"
                    >
                      You can use backup codes to sign in to your account if you
                      don't have access to your authenticator app.
                    </Typography.Text>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Card>

        <PasswordUpdateModal />
        <SetupTwoFactorAuthModal />
        <TwoFactorRecoveryModal />
        <QuestionModal
          isOpen={showDisableQuestion}
          onClose={() => setShowDisableQuestion(false)}
          title={
            <div className="flex gap-1 items-center">
              <WarningIcon className="w-5 aspect-square text-error" />
              <Typography.Text>
                Disable Two-Factor Authentication
              </Typography.Text>
            </div>
          }
          description={
            <div className="flex w-full flex-col gap-3 mt-5">
              <Typography.Text size="sm">
                Two-Factor authentication significantly{" "}
                <span className="text-error">increases the security</span> of
                your account and{" "}
                <span className="text-error">reduces the risk</span> of
                unauthorized logins.
              </Typography.Text>
              <Typography.Text size="sm">
                Are you sure you want to disable it?
              </Typography.Text>
            </div>
          }
          onConfirm={() => {
            dispatch(disableTwoFactorAsync()).then((action) => {
              if (action.type === "auth/disable-2fa/fulfilled") {
                setShowDisableQuestion(false);
                dispatch(getMeAsync({}));
              }
            });
          }}
          confirmButtonType="danger"
          confirmButtonText="Disable"
          contentClassName="!max-w-xl"
          loading={disable2faLoading}
        />
      </div>
    </>
  );
};

export default SettingProfileTab;
