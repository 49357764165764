import { cn, Flex } from "djuno-design";

const Container: React.FC<
  React.PropsWithChildren<{ id?: string; className?: string }>
> = ({ children, id, className }) => {
  return (
    <Flex
      id={id}
      direction="col"
      className={cn(
        "w-full max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1320px] mx-auto h-full px-8",
        className
      )}
    >
      {children}
    </Flex>
  );
};

export const ContainerFluid: React.FC<
  React.PropsWithChildren<{ id?: string; className?: string }>
> = ({ children, id, className }) => {
  return (
    <Flex
      id={id}
      direction="col"
      className={cn("w-full 2xl:max-w-[1440px] h-full mx-auto", className)}
    >
      {children}
    </Flex>
  );
};

export default Container;
