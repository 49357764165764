import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import HighlighterText from "../../general/HighlighterText";
import {
  deleteInstancesSshKeysAsync,
  getInstancesSshKeysAsync,
  handleInstanceDeleteSshKeyHideModal,
  handleInstanceDeleteSshKeyShowModal,
  handleInstancesSshKeyEditor,
  selectInstancesSelectedSshKey,
  selectInstancesSshKeys,
  selectInstancesSshKeysActionLoading,
  selectInstancesSshKeysLoading,
  selectShowInstancesSshKeysModal,
} from "../../../store/instances/instancesSSHkeysSlice";
import { InstanceSshKey } from "../../../types/instance";

import { InstancesSskKeyEditorModal } from "./InstancesSskKeyEditorModal";
import {
  Button,
  Dropdown,
  EmptyState,
  SecureCopy,
  SimpleTable,
} from "djuno-design";
import { useTour } from "../../../providers/TourProvider";

const InstancesSshKeysTab = () => {
  const sshKeys = useAppSelector(selectInstancesSshKeys);
  const sshKey = useAppSelector(selectInstancesSelectedSshKey);
  const [filteredSshKeys, setFilteredSshKeys] = useState<InstanceSshKey[]>([]);
  const loading = useAppSelector(selectInstancesSshKeysLoading);
  const actionLoading = useAppSelector(selectInstancesSshKeysActionLoading);
  const isOpen = useAppSelector(selectShowInstancesSshKeysModal);

  const dispatch = useAppDispatch();

  const { run } = useTour("instances-ssh-keys");

  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getInstancesSshKeysAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpApiKeys = sshKeys.filter((key) =>
      key.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredSshKeys(lookedUpApiKeys);
  }, [dispatch, searchValue, sshKeys]);

  const handleClose = () => {
    dispatch(handleInstanceDeleteSshKeyHideModal());
  };

  return (
    <>
      <div className="flex transition-all duration-500">
        {/* <Text>SSH Keys</Text> */}
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            onClick={() => {
              run();
            }}
          >
            Start Tour
          </Button>
          <Button
            uiType="primary"
            onClick={() =>
              dispatch(handleInstancesSshKeyEditor({ status: true }))
            }
            className="group"
            id="create-ssh-key-button"
          >
            Create New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-6" id="ssh-keys-table">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Label" />
              <SimpleTable.TH lable="Value" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredSshKeys.map((sshKey, index) => (
              <SimpleTable.Row key={index}>
                <SimpleTable.TD className="w-36">
                  <HighlighterText
                    searchValue={searchValue}
                    textToHighlight={sshKey.name}
                  />
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <SecureCopy
                    type="hide"
                    uiSize="small"
                    text={sshKey.publicKey}
                    textClassName="max-w-[300px] md:max-w-[300px] px-2 truncate overflow-hidden"
                  />
                </SimpleTable.TD>
                <SimpleTable.TD className="w-10">
                  <div className="w-8 flex justify-center items-center">
                    <Dropdown
                      anchor="bottom end"
                      itemsClassName="!p-0"
                      menu={[
                        {
                          key: "end",
                          label: (
                            <div className="flex items-center gap-1">
                              <ArchiveIcon className="w-4" />
                              Delete
                            </div>
                          ),
                          danger: true,
                          onClick: (_, close) => {
                            close();
                            dispatch(
                              handleInstanceDeleteSshKeyShowModal(sshKey)
                            );
                          },
                        },
                      ]}
                    >
                      <Button uiType="icon" uiSize="small" className="!px-2">
                        <MoreIcon className="w-4 h-4" />
                      </Button>
                    </Dropdown>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {sshKeys.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={3} className="!border-0">
                  <EmptyState text="You have not created any SSH key yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <InstancesSskKeyEditorModal />
      <DeleteModal
        isOpen={isOpen}
        title="Delete SSH Key"
        onClose={handleClose}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={actionLoading}
        onConfirm={() => {
          sshKey &&
            dispatch(deleteInstancesSshKeysAsync({ id: sshKey.id })).then(
              (action) => {
                if (action.type === "instances/ssh-keys/delete/fulfilled") {
                  dispatch(getInstancesSshKeysAsync());
                  dispatch(handleInstanceDeleteSshKeyHideModal());
                  handleClose();
                }
              }
            );
        }}
        confirmString={sshKey?.name}
      />
    </>
  );
};

export default InstancesSshKeysTab;
