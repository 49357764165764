import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createS3EventAsync,
  selectActionLoading,
  selectLoading,
  selectSelectedS3Event,
} from "../../../../store/s3/eventsSlice";
import { S3KafkaEventSchema } from "../../../../utils/validations";
import { useEffect, useState } from "react";
import { S3EventsUrl } from "../../../../utils/urls";
import { useNavigate } from "react-router-dom";
import useGetS3Events from "../../../../hooks/useGetS3Events";
import {
  Button,
  Input,
  Switcher,
  Textarea,
  Tooltip,
  Typography,
} from "djuno-design";

const KafkaForm = () => {
  const event = useAppSelector(selectSelectedS3Event);
  const loading = useAppSelector(selectActionLoading);
  const eventsLoading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getS3Events = useGetS3Events();

  const [enableSASL, setEnableSASL] = useState<boolean>(false);
  const [enableTLS, setEnableTLS] = useState<boolean>(false);
  const [enableTlsSkip, setEnableTlsSkip] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3KafkaEventSchema),
  });

  useEffect(() => {
    if (event) {
    } else {
    }
  }, [event, setValue]);

  const onSubmit = (data: any) => {
    let keyValues = [
      {
        key: "brokers",
        value: data.brokers,
      },
      {
        key: "topic",
        value: data.topic,
      },
      {
        key: "sasl_username",
        value: data.sasl_username,
      },
      {
        key: "sasl_password",
        value: data.sasl_password,
      },
      {
        key: "sasl_mechanism",
        value: data.sasl_mechanism,
      },
      {
        key: "tls_client_auth",
        value: data.tls_client_auth,
      },
      {
        key: "sasl",
        value: enableSASL ? "on" : "off",
      },
      {
        key: "tls",
        value: enableTLS ? "on" : "off",
      },
      {
        key: "tls_skip_verify",
        value: enableTlsSkip ? "on" : "off",
      },
      {
        key: "client_tls_cert",
        value: data.client_tls_cert,
      },
      {
        key: "client_tls_key",
        value: data.client_tls_key,
      },
      {
        key: "version",
        value: data.version,
      },
      {
        key: "queue_dir",
        value: data.queue_dir,
      },
      {
        key: "comment",
        value: data.comment,
      },
    ];
    if (data.queue_limit !== null) {
      keyValues = [
        ...keyValues,
        {
          key: "queue_limit",
          value: data.queue_limit.toString(),
        },
      ];
    }
    const formData = {
      key_values: keyValues,
    };
    // if (event) {
    // dispatch(
    //   updateS3EventAsync({
    //     id: event.Id,
    //     data: {
    //       label: data.label,
    //     },
    //   })
    // ).then((action) => {
    //   if (action.type === "s3-events/update/fulfilled") {
    //     // handleClearComponent();
    //     dispatch(handleHideEditor());
    //     dispatch(getS3EventsAsync());
    //     reset();
    //   }
    // });
    // } else {
    dispatch(
      createS3EventAsync({
        event: "kafka",
        identifier: data.identifier,
        data: formData,
      })
    ).then((action) => {
      if (action.type === "s3-events/create/fulfilled") {
        getS3Events(() => {
          reset();
          navigate(S3EventsUrl);
        });
      }
    });
    // }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full gap-5">
        <Input
          label="Identifier"
          tooltip={{
            className: "!text-xs",
            content: "Unique descriptive string for this destination",
          }}
          placeholder="Enter Destination Identifier"
          {...register("identifier")}
          error={errors.identifier?.message}
          required
        />

        <Input
          label="Brokers"
          tooltip={{
            className: "!text-xs",
            content: "Comma separated list of Kafka broker addresses",
          }}
          placeholder="Enter Brokers"
          {...register("brokers")}
          error={errors.brokers?.message}
        />

        <Input
          label="Topic"
          tooltip={{
            className: "!text-xs",
            content: "Kafka topic used for bucket notifications",
          }}
          placeholder="Enter Topic"
          {...register("topic")}
          error={errors.topic?.message}
        />

        <Input
          label="SASL Username"
          tooltip={{
            className: "!text-xs",
            content: "Username for SASL/PLAIN or SASL/SCRAM authentication",
          }}
          placeholder="Enter SASL Username"
          {...register("sasl_username")}
          error={errors.sasl_username?.message}
        />

        <Input
          label="SASL Password"
          tooltip={{
            className: "!text-xs",
            content: "Password for SASL/PLAIN or SASL/SCRAM authentication",
          }}
          placeholder="Enter SASL Password"
          {...register("sasl_password")}
          error={errors.sasl_password?.message}
        />

        <Input
          label="SASL Mechanism"
          tooltip={{
            className: "!text-xs",
            content: "SASL authentication mechanism, default 'PLAIN'",
          }}
          {...register("sasl_mechanism")}
          error={errors.sasl_mechanism?.message}
        />

        <Input
          label="TLS Client Auth"
          tooltip={{
            className: "!text-xs",
            content:
              "Client Auth determines the Kafka server's policy for TLS client authorization",
          }}
          placeholder="Enter TLS Client Auth"
          {...register("tls_client_auth")}
          error={errors.tls_client_auth?.message}
        />

        <div className="flex items-center justify-between w-full px-1">
          <div className="flex gap-1 items-center">
            <Typography.Text className="!text-sm">SASL</Typography.Text>
            <Tooltip.Info
              className="!text-sm"
              content="Enable SASL Authentication"
            />
          </div>
          <Switcher value={enableSASL} onChange={setEnableSASL} />
        </div>

        <div className="flex items-center justify-between w-full px-1">
          <div className="flex gap-1 items-center">
            <Typography.Text className="!text-sm">TLS</Typography.Text>
            <Tooltip.Info className="!text-sm" content="Enable TLS" />
          </div>
          <Switcher value={enableTLS} onChange={setEnableTLS} />
        </div>

        <div className="flex items-center justify-between w-full px-1">
          <div className="flex gap-1 items-center">
            <Typography.Text className="!text-sm">
              TLS skip verify
            </Typography.Text>
            <Tooltip.Info
              className="!text-sm"
              content="Trust server TLS without verification"
            />
          </div>
          <Switcher value={enableTlsSkip} onChange={setEnableTlsSkip} />
        </div>

        <Input
          label="client TLS cert"
          tooltip={{
            className: "!text-xs",
            content: "Path to client certificate for mTLS authorization",
          }}
          placeholder="ِEnter TLS Client Cert"
          {...register("client_tls_cert")}
          error={errors.client_tls_cert?.message}
        />

        <Input
          label="client TLS key"
          tooltip={{
            className: "!text-xs",
            content: "Path to client key for mTLS authorization",
          }}
          placeholder="ِEnter TLS Client Key"
          {...register("client_tls_key")}
          error={errors.client_tls_key?.message}
        />

        <Input
          label="Version"
          tooltip={{
            className: "!text-xs",
            content: "Specify the version of the Kafka cluster e.g '2.2.0'",
          }}
          placeholder="ِEnter Kafka Version"
          {...register("version")}
          error={errors.version?.message}
        />

        <Input
          label="Queue Directory"
          tooltip={{
            className: "!text-xs",
            content:
              "Staging directory for undelivered messages e.g. '/home/events'",
          }}
          placeholder="ِEnter Queue Directory"
          {...register("queue_dir")}
          error={errors.queue_dir?.message}
        />

        <Input
          label="Queue Limit"
          tooltip={{
            className: "!text-xs",
            content:
              "Maximum limit for undelivered messages, defaults to '10000'",
          }}
          placeholder="ِEnter Queue Limit"
          {...register("queue_limit")}
          type="number"
          error={errors.queue_limit?.message}
        />

        <Textarea
          label="Comment"
          placeholder="Enter custom notes if any"
          {...register("comment")}
          error={errors.comment?.message}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading || eventsLoading}
          uiType="primary"
          disabled={loading || eventsLoading}
          type="submit"
          className="w-[160px]"
        >
          {event ? "Update Evet Target" : "Save Evet Target"}
        </Button>
      </div>
    </form>
  );
};
export default KafkaForm;
