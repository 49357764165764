import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  selectBucketLifecycleActionLoading,
  selectShowBucketLifecycleEditor,
  handleHideLifecycleEditor,
  createBucketLifecycleAsync,
  getBucketLifecyclesAsync,
  selectSelectedLifecycle,
  updateBucketLifecycleAsync,
} from "../../../../../store/s3/buckets/setting/lifecycleSlice";
import {
  getS3TiersAsync,
  selectS3Tiers,
  selectS3TiersLoading,
} from "../../../../../store/s3/tiersSlice";
import { S3LifecycleSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { useEffect, useState } from "react";
import { ReactComponent as ArchiveIcon } from "./../../../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../../../assets/icons/plus.svg";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import RadioGrouper, {
  RadioGroupeItem,
} from "../../../../general/RadioGrouper";
import { S3LifecycleTag } from "../../../../../types/s3-lifecycle";
import {
  Button,
  Input,
  Modal,
  Select,
  SelectOption,
  Switcher,
  Tooltip,
  Typography,
  Accordion,
} from "djuno-design";

const typeOptions: RadioGroupeItem<"expiry" | "transition">[] = [
  { label: "Expiry", value: "expiry" },
  { label: "Transition", value: "transition" },
];

const versionOptions: SelectOption[] = [
  {
    label: "Current Version",
    value: "current",
  },
  {
    label: "Non-Current Version",
    value: "non-current",
  },
];

const S3LifecycleEditorModal = () => {
  const isOpen = useAppSelector(selectShowBucketLifecycleEditor);
  const loading = useAppSelector(selectBucketLifecycleActionLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const selectedLifecycle = useAppSelector(selectSelectedLifecycle);
  const dispatch = useAppDispatch();

  const tiers = useAppSelector(selectS3Tiers);
  const tiersLoading = useAppSelector(selectS3TiersLoading);

  const [selectedType, setSelectedType] = useState<RadioGroupeItem<string>>(
    typeOptions[0]
  );
  const [selectedVersion, setSelectedVersion] = useState<string>(
    versionOptions[0].value
  );
  const [selectedTier, setSelectedTier] = useState<string>();
  const [enabledDeleteMarker, setEnabledDeleteMarker] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [status, setStatus] = useState<boolean>(true);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(S3LifecycleSchema(selectedType.value)),
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tags",
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isOpen) {
      if (selectedLifecycle) {
        const selectedTypeOption =
          selectedLifecycle.transition.days ||
          selectedLifecycle.transition.noncurrent_transition_days
            ? 1
            : 0;
        setSelectedType(typeOptions[selectedTypeOption]);

        setStatus(selectedLifecycle.status === "Enabled" ? true : false);

        if (!selectedLifecycle.tags) {
          setValue("tags", [{ key: "", value: "" }]);
          console.log("selectedLifecycle", selectedLifecycle);
        } else {
          setValue(
            "tags",
            selectedLifecycle.tags as { key: string; value: string }[]
          );
        }

        setValue("prefix", selectedLifecycle.prefix || "");

        setValue(
          "expiry_days",
          selectedLifecycle.transition.days?.toString() ||
            selectedLifecycle.transition.noncurrent_transition_days?.toString() ||
            selectedLifecycle.expiration.days?.toString() ||
            selectedLifecycle.expiration.noncurrent_expiration_days?.toString() ||
            "0"
        );

        setSelectedTier(
          selectedLifecycle.transition.noncurrent_storage_class ||
            selectedLifecycle.transition.storage_class ||
            ""
        );

        const selectedVersionOption =
          selectedLifecycle.transition.noncurrent_transition_days ||
          selectedLifecycle.expiration.noncurrent_expiration_days
            ? 1
            : 0;
        setSelectedVersion(versionOptions[selectedVersionOption].value);

        setEnabledDeleteMarker(!!selectedLifecycle.expiration.delete_marker);
        setFormSubmitted(false);
      } else {
        setSelectedType(typeOptions[0]);
        setValue("tags", [{ key: "", value: "" }]);
        setSelectedTier(undefined);
        setFormSubmitted(false);
      }
    }
  }, [isOpen, selectedLifecycle, setValue]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getS3TiersAsync());
    }
  }, [dispatch, isOpen]);

  const onSubmit = (data: any) => {
    console.log("submittting");
    setFormSubmitted(true);

    console.log("data", data);

    const tags =
      (data.tags as S3LifecycleTag[])
        .filter(
          (tag) =>
            tag.key !== "" &&
            tag.key !== null &&
            tag.key !== undefined &&
            tag.value !== "" &&
            tag.value !== null &&
            tag.value !== undefined
        )
        .map((tag) => `${tag.key}=${tag.value}`)
        .join("&") || "";
    if (bucketDetails) {
      let firstData = {
        type: selectedType.value,
        prefix: data.prefix || "",
        tags,
        expired_object_delete_marker: enabledDeleteMarker,
      };
      let formData;
      if (selectedType.value === "transition") {
        formData = {
          ...firstData,
          ...(selectedVersion === "current"
            ? {
                transition_days: +data.expiry_days,
                storage_class: selectedTier,
              }
            : {
                noncurrentversion_transition_days: +data.expiry_days,
                noncurrentversion_transition_storage_class: selectedTier,
              }),
        };
      } else {
        formData = {
          ...firstData,
          ...(selectedVersion === "current"
            ? { expiry_days: +data.expiry_days }
            : { noncurrentversion_expiration_days: +data.expiry_days }),
        };
      }

      if (selectedLifecycle) {
        formData = {
          ...formData,
          disable: !status,
        };
        console.log("selectedlifecycle inner submit", selectedLifecycle);
        console.log("formData inner submit", formData);

        dispatch(
          updateBucketLifecycleAsync({
            bucketName: bucketDetails.name,
            id: selectedLifecycle.id,
            data: formData,
          })
        ).then((action) => {
          if (action.type === "bucket/lifecycles/update/fulfilled") {
            reset();
            dispatch(handleHideLifecycleEditor());
            dispatch(getBucketLifecyclesAsync({ name: bucketDetails.name }));
          }
        });
      } else {
        dispatch(
          createBucketLifecycleAsync({
            bucketName: bucketDetails.name,
            data: formData,
          })
        ).then((action) => {
          if (action.type === "bucket/lifecycles/create/fulfilled") {
            reset();
            dispatch(handleHideLifecycleEditor());
            dispatch(getBucketLifecyclesAsync({ name: bucketDetails.name }));
          }
        });
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideLifecycleEditor());
        reset();
      }}
      contentClassName="max-w-lg"
      title={
        selectedLifecycle
          ? "Update Lifecycle Configuration"
          : "Add Lifecycle Rule"
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectedLifecycle && (
          <>
            <div className="mt-5 flex items-center justify-between w-full">
              <div className="flex gap-2 items-center">
                <div className="text-sm font-medium text-slate-800 dark:text-slate-200 mb-1">
                  Status
                </div>
              </div>
              <Switcher onChange={setStatus} value={status} />
            </div>
            <div className="mt-5 flex flex-col w-full gap-5">
              <Input
                className="!disabled"
                label="Id"
                value={selectedLifecycle.id}
                type={"string"}
                disabled={true}
                placeholder=""
              />
            </div>
          </>
        )}

        <div className="mt-5 flex flex-col w-full gap-5">
          <div className="flex gap-5 justify-between items-center">
            <Typography.Text className="!text-sm !font-semibold">
              Type of Lifecycle
            </Typography.Text>
            <div className="flex flex-row gap-5">
              <RadioGrouper
                items={typeOptions}
                selected={selectedType}
                setSelected={setSelectedType}
                disabled={selectedLifecycle !== null}
              />
            </div>
          </div>
          <Select
            className="disabled"
            disabled={!!selectedLifecycle}
            label="Object Version"
            options={versionOptions}
            value={selectedVersion}
            onChange={(v) => v && setSelectedVersion(v)}
          />
          <Input
            label="After"
            hint="days"
            {...register("expiry_days")}
            type="number"
            error={errors.expiry_days?.message}
            placeholder=""
            required
          />

          {selectedType.value === "transition" && (
            <Select
              label="To Tier"
              options={[
                ...tiers.map((tier) => ({
                  label: tier[tier.type].name,
                  value: tier[tier.type].name,
                })),
              ]}
              value={selectedTier}
              onChange={setSelectedTier}
              loading={tiersLoading}
              required
              error={
                formSubmitted && !selectedTier
                  ? "Tier is a required field"
                  : undefined
              }
            />
          )}
          <Accordion
            items={[
              {
                label: "Filters",
                panel: (
                  <div className="">
                    <Input
                      label="Prefix"
                      {...register("prefix")}
                      error={errors.prefix?.message}
                    />
                    <div className="mt-2">
                      <label className="flex items-center gap-1 text-sm font-medium text-slate-800 dark:text-slate-50 whitespace-nowrap">
                        Tags
                      </label>
                      <div className="flex flex-col w-full">
                        {fields.map((_, index) => (
                          <div
                            key={index}
                            className="grid gap-3 grid-cols-12 pb-2"
                          >
                            <div className="col-span-4">
                              <Input
                                label=""
                                {...register(`tags.${index}.key` as const)}
                                error={
                                  errors.tags &&
                                  errors.tags[index] &&
                                  errors.tags[index]?.key &&
                                  errors.tags[index]?.key?.message
                                }
                                placeholder="Tag Key"
                              />
                            </div>
                            <div className="col-span-7">
                              <Input
                                label=""
                                {...register(`tags.${index}.value` as const)}
                                error={
                                  errors.tags &&
                                  errors.tags[index] &&
                                  errors.tags[index]?.value &&
                                  errors.tags[index]?.value?.message
                                }
                                placeholder="Tag Value"
                              />
                            </div>
                            <div className="col-span-1 flex items-start justify-center">
                              <Button
                                uiType="icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (index !== 0) remove(index);
                                }}
                                // onClick: () => remove(index),
                                disabled={false}
                                className="!mt-1.5"
                              >
                                <ArchiveIcon className="w-5 text-slate-700 dark:text-slate-300 hover:text-red-500 hover:dark:text-red-400" />
                              </Button>
                            </div>
                          </div>
                        ))}
                        <div className="flex justify-end m-2">
                          <Button
                            uiType="light"
                            onClick={(e) => {
                              e.preventDefault();
                              append({
                                key: "",
                                value: "",
                              });
                            }}
                          >
                            <PlusIcon className="w-4" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />

          {selectedType.value === "expiry" &&
            selectedVersion === "non-current" && (
              <Accordion
                items={[
                  {
                    label: "Advances",
                    panel: (
                      <div className="flex gap-5 justify-between items-center">
                        <Typography.Text className="!text-sm font-semibold flex items-center gap-2">
                          Expire Delete Marker
                          <Tooltip.Info content="Remove the reference to the object if no versions are left" />
                        </Typography.Text>
                        <div className="">
                          <Switcher
                            value={enabledDeleteMarker}
                            onChange={setEnabledDeleteMarker}
                          />
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            )}
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3LifecycleEditorModal;
