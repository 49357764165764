import { Button, Flex, Typography } from "djuno-design";
import { SettingsBillingUrl } from "../../utils/urls";
import { ReactComponent as NoData } from "./../../assets/icons/exclamation-triangle-icon.svg";
import { Link } from "react-router-dom";

const SuspendAccount = () => {
  return (
    <Flex className="px-4 md:px-6 max-w-[1200px] mx-auto h-[calc(100vh-4rem)] overflow-y-hidden">
      <Flex
        direction="col"
        items="center"
        justify="center"
        className="w-full place-self-center"
      >
        <Flex direction="col" items="center" className="max-w-xl gap-4">
          <NoData className="w-20 text-slate-500 dark:text-slate-300" />
          <Typography.Title level={5} className="text-center !mb-0">
            Account Suspended
          </Typography.Title>
          <Typography.Text className="!text-sm text-center">
            Your account has been temporarily suspended due to an unpaid
            invoice. Please settle your outstanding balance to regain full
            access to the platform.
          </Typography.Text>
          <Link to={SettingsBillingUrl}>
            <Button uiType="light">Go to Billing</Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SuspendAccount;
