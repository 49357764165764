import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getRepositoriesAccountsAsync,
  saveInstallationAsync,
  selectGithubReposLoading,
  selectInstallationId,
  selectIsOpenInstallationModal,
  selectSaveInstallationLoading,
  setInstallationData,
} from "../../store/settings/registeries/repositoriesSlice";
import { useNavigate } from "react-router-dom";
import { SettingsRegisteriesUrl } from "../../utils/urls";
import { AnimatePresence, motion } from "framer-motion";
import { Flex, Loading, Modal, Typography } from "djuno-design";

const InstallationModal = () => {
  const isOpen = useAppSelector(selectIsOpenInstallationModal);
  const installationId = useAppSelector(selectInstallationId);
  const saveLoading = useAppSelector(selectSaveInstallationLoading);
  const githubLoading = useAppSelector(selectGithubReposLoading);

  const dispatch = useAppDispatch();
  const naigate = useNavigate();

  useEffect(() => {
    if (isOpen && installationId) {
      // dispatch(setGithubReposLoading(true));
      dispatch(
        saveInstallationAsync({
          installationId: installationId,
          service: "github",
        })
      ).then(async (action) => {
        if (action.type === "repositories/installation/fulfilled") {
          dispatch(getRepositoriesAccountsAsync());
          dispatch(
            setInstallationData({
              installationId: null,
              installationModal: false,
            })
          );
          naigate(SettingsRegisteriesUrl, { replace: true });
        } else {
          //installation error
        }
      });
    }
  }, [dispatch, installationId, isOpen, naigate]);

  return (
    <Modal title="Install App" isOpen={isOpen} contentClassName="max-w-xl">
      <Flex
        direction="col"
        items="center"
        justify="center"
        className="w-full min-h-[200px]"
      >
        <AnimatePresence>
          {(saveLoading || githubLoading) && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="w-full min-h-full flex flex-col justify-center items-center gap-2"
            >
              <Loading borderSize={2} />
              <Typography.Text className="text-sm">
                Please wait...
              </Typography.Text>
            </motion.div>
          )}
        </AnimatePresence>
      </Flex>
    </Modal>
  );
};

export default InstallationModal;
