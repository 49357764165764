import { Button, Flex, Tooltip, Typography } from "djuno-design";
import Container from "../layouts/Container";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";
import headerimg from "./../../assets/images/headerimg.png";
import Slider from "react-infinite-logo-slider";

import { ReactComponent as IPFSIcon } from "./../../assets/icons/cube.svg";
import { ReactComponent as WorkflowIcon } from "./../../assets/icons/services/workflow.svg";
import { ReactComponent as DatabasesIcon } from "./../../assets/icons/circle-stack.svg";
import { ReactComponent as InstancesIcon } from "./../../assets/icons/driver.svg";
import { ReactComponent as BlockEventsIcon } from "./../../assets/icons/chain.svg";
import { ReactComponent as Web3AuthIcon } from "./../../assets/icons/shield-check.svg";
import { ReactComponent as WalletApiIcon } from "./../../assets/icons/services/crypto-wallet-api.svg";
import { ReactComponent as MarketApiIcon } from "./../../assets/icons/megaphone.svg";
import { ReactComponent as BlockApiIcon } from "./../../assets/icons/squares-2x2.svg";
import { ReactComponent as ArchiveIcon } from "./../../assets/icons/archive-box2.svg";

import EthereumImg from "./../../assets/icons/blockchain/ethereum-eth-logo.png";
import BnbImg from "./../../assets/icons/blockchain/bnb-bnb-logo.png";
import ArbitrumImg from "./../../assets/icons/blockchain/arbitrum-arb-logo.png";
import SolanaImg from "./../../assets/icons/blockchain/solana-sol-logo.png";
import PolygonImg from "./../../assets/icons/blockchain/polygon-matic-logo.png";
import OptimismImg from "./../../assets/icons/blockchain/optimism-ethereum-op-logo.png";
import FantomImg from "./../../assets/icons/blockchain/fantom-ftm-logo.png";
import {
  BlockApiUrl,
  BlockEventNotificationsUrl,
  DatabaseServicesUrl,
  InstancesUrl,
  IpfsFilesUrl,
  MarketApiUrl,
  NftApiUrl,
  RpcEndpointsUrl,
  S3BucketsUrl,
  WalletApiUrl,
  Web3AuthEndpointsUrl,
  WorkflowsUrl,
} from "../../utils/urls";

// const headerimg = "https://webcdn.djuno.io/cdn/headerimg.png";
const imageStyle = {
  height: "100%",
  width: "100%",
  objectFit: "cover",
};

const ParallaxBox: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="mt-32 relative before:absolute before:w-full before:h-full before:inset-0 before:bg-blue-800/50 before:z-10">
      <Parallax bgImage={headerimg} strength={700} style={imageStyle}>
        <div className="relative z-20">{children}</div>
      </Parallax>
    </div>
  );
};

export const CounterParallaxBox = () => {
  return (
    <ParallaxBox>
      <Container className="py-12 lg:py-20 justify-center">
        <div className="grid grid-cols-6 gap-y-16">
          <div className="col-span-6 lg:col-span-3">
            <Flex
              direction="col"
              items={{ default: "start", md: "center" }}
              justify="center"
              className="w-full h-full"
            >
              <Typography.Text
                size="2xl"
                className="font-semibold !text-slate-100"
              >
                Build, run and scale
              </Typography.Text>
              <Typography.Text className="!text-slate-300">
                Confidently with the Djuno low code web3.0 development platform
              </Typography.Text>
            </Flex>
          </div>
          <div className="col-span-6 lg:col-span-3 group">
            <Flex
              direction="col"
              items="center"
              justify="center"
              className="w-full h-full overflow-hidden gap-5"
            >
              <Slider
                width="70px"
                duration={40}
                pauseOnHover={true}
                blurBorders={true}
                blurBorderColor={"#fff"}
              >
                {technologiesLogos.map((item, index) => (
                  <Slider.Slide key={index}>
                    <Link to={item.link}>
                      <Tooltip
                        content={item.title}
                        theme="black"
                        className="!text-xs"
                      >
                        <div className="w-14 h-14 p-2 rounded-md bg-blue-500 shadow-lg text-slate-300 flex justify-center items-center text-xl font-semibold">
                          {item.icon}
                        </div>
                      </Tooltip>
                    </Link>
                  </Slider.Slide>
                ))}
              </Slider>
              <Slider
                width="70px"
                toRight
                duration={40}
                pauseOnHover={true}
                blurBorders={false}
                blurBorderColor={"#fff"}
              >
                {blockchainLogos.map((item, index) => (
                  <Slider.Slide key={index}>
                    <Tooltip
                      content={item.title}
                      className="!text-xs"
                      theme="black"
                    >
                      <div className="w-14 h-14 p-3 rounded-md bg-blue-500 shadow-lg text-slate-300 flex justify-center items-center text-xl font-semibold">
                        <img
                          src={item.imageUrl}
                          alt=""
                          className="w-full h-full"
                        />
                      </div>
                    </Tooltip>
                  </Slider.Slide>
                ))}
              </Slider>
            </Flex>
          </div>
        </div>
      </Container>
    </ParallaxBox>
  );
};

export const ContactUsParallaxBox = () => {
  return (
    <ParallaxBox>
      <Container className="py-12 lg:py-20 justify-center">
        <Flex direction="col" items="center" className="gap-5">
          <Typography.Text size="2xl" className="font-semibold !text-slate-100">
            Djuno is your{" "}
            <Typography.Text size="2xl" underline uiType="transparent">
              strategic partner
            </Typography.Text>{" "}
            on digital transformation, cloud and web3 adoption.
          </Typography.Text>

          <Link to={"/contact-us"}>
            <Button uiType="primary">Contact Us</Button>
          </Link>
        </Flex>
      </Container>
    </ParallaxBox>
  );
};

const technologiesLogos = [
  {
    title: "IPFS",
    icon: <IPFSIcon className="w-full h-full text-slate-300" />,
    link: IpfsFilesUrl,
  },
  {
    title: "rpc",
    icon: "rpc",
    link: RpcEndpointsUrl,
  },
  {
    title: "Workflow",
    icon: <WorkflowIcon className="w-full h-full text-slate-300" />,
    link: WorkflowsUrl,
  },
  {
    title: "Databases",
    icon: <DatabasesIcon className="w-full h-full text-slate-300" />,
    link: DatabaseServicesUrl,
  },
  {
    title: "Instances",
    icon: <InstancesIcon className="w-full h-full text-slate-300" />,
    link: InstancesUrl,
  },
  {
    title: "Object Storage",
    icon: <ArchiveIcon className="w-full h-full text-slate-300" />,
    link: S3BucketsUrl,
  },
  {
    title: "Block Events",
    icon: <BlockEventsIcon className="w-full h-full text-slate-300" />,
    link: BlockEventNotificationsUrl,
  },
  {
    title: "Web3 auth",
    icon: <Web3AuthIcon className="w-full h-full text-slate-300" />,
    link: Web3AuthEndpointsUrl,
  },
  {
    title: "Wallet APIs",
    icon: <WalletApiIcon className="w-full h-full text-slate-300" />,
    link: WalletApiUrl,
  },
  {
    title: "Market APIs",
    icon: <MarketApiIcon className="w-full h-full text-slate-300" />,
    link: MarketApiUrl,
  },
  {
    title: "Block APIs",
    icon: <BlockApiIcon className="w-full h-full" />,
    link: BlockApiUrl,
  },
  {
    title: "NFT",
    icon: "NFT",
    link: NftApiUrl,
  },
];

const blockchainLogos = [
  { title: "Ethereum", imageUrl: EthereumImg },
  { title: "BNB", imageUrl: BnbImg },
  { title: "Solana", imageUrl: SolanaImg },
  { title: "Polygon", imageUrl: PolygonImg },
  { title: "Arbitrum", imageUrl: ArbitrumImg },
  { title: "Optimism", imageUrl: OptimismImg },
  { title: "Fantom", imageUrl: FantomImg },
];

export default ParallaxBox;
