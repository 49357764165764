import jwtAxios, { jwtEnvAxios } from ".";

// block event urls
const GET_BLOCK_EVENTS_API_URL = "/block-events";
const GET_BLOCK_EVENT_API_URL = (blockEventId: string) =>
  `/block-events/${blockEventId}`;
const DELETE_BLOCK_EVENT_API_URL = (quickId: string) =>
  `/block-events/${quickId}`;
const VALIDATE_EXPRESSION_API_URL = `/block-events/validate`;
const BACK_TESTS_EXPRESSION_API_URL = `/block-events/backtest`;
const CREATE_BLOCK_EVENT_API_URL = "/block-events";
const CHANGE_BLOCK_EVENT_STATUS_API_URL = (
  blockEventId: string,
  status: string
) => `/block-events/${blockEventId}/${status}`;
const UPDATE_BLOCK_EVENT_API_URL = (blockEventId: string) =>
  `/block-events/${blockEventId}`;
const GET_BLOCK_EVENT_NETWORKS_API_URL = `/block-event-networks`;
const GET_BLOCK_EVENT_Templates_API_URL = `/block-event-template`;
const GET_BLOCK_EVENT_HISTORY_API_URL = (
  blockEventId: string,
  limit: number = 20,
  offset: number = 0
) => `/block-events/${blockEventId}/events?limit=${limit}&offset=${offset}`;

// destinations urls
const GET_DESTINATIONS_API_URL = "/destinations";
const GET_DESTINATION_API_URL = (destId: string) => `/destinations/${destId}`;
const CREATE_DESTINATIONS_API_URL = "/destinations";
const UPDATE_DESTINATION_API_URL = (destId: string) =>
  `/destinations/${destId}`;
const DELETE_DESTINATION_API_URL = (destId: string) =>
  `/destinations/${destId}`;
const GET_DESTINATIONS_WEBHOOK_TYPES_API_URL = `/enums/destination-method`;
const GET_DESTINATIONS_PAYLOAD_TYPES_API_URL = `/payload-types`;

// block events
export function getBlockEventsApi() {
  return jwtEnvAxios("event").get(GET_BLOCK_EVENTS_API_URL);
}

export function getBlockEventApi(blockEventId: string) {
  return jwtEnvAxios("event").get(GET_BLOCK_EVENT_API_URL(blockEventId));
}

export function deleteBlockEventApi(blockEventId: string) {
  return jwtEnvAxios("event").delete(DELETE_BLOCK_EVENT_API_URL(blockEventId));
}

export function changeBlockEventStatusApi(
  blockEventId: string,
  status: string
) {
  return jwtEnvAxios("event").post(
    CHANGE_BLOCK_EVENT_STATUS_API_URL(blockEventId, status)
  );
}

export function getBlockEventHistoryApi(
  blockEventId: string,
  limit?: number,
  offset?: number
) {
  return jwtEnvAxios("event").get(
    GET_BLOCK_EVENT_HISTORY_API_URL(blockEventId, limit, offset)
  );
}

// creation
export function validateExpressionApi(data: any) {
  return jwtEnvAxios("event").post(VALIDATE_EXPRESSION_API_URL, data);
}

export function getBacktestsExpressionApi(data: any) {
  return jwtEnvAxios("event").post(BACK_TESTS_EXPRESSION_API_URL, data);
}

export function createBlockEventApi(data: any) {
  return jwtEnvAxios("event").post(CREATE_BLOCK_EVENT_API_URL, data);
}

export function updateBlockEventApi(blockEventId: string, data: any) {
  return jwtEnvAxios("event").patch(
    UPDATE_BLOCK_EVENT_API_URL(blockEventId),
    data
  );
}

// networks and templates
export function getBlockEventNetworksApi() {
  return jwtAxios.get(GET_BLOCK_EVENT_NETWORKS_API_URL);
}

export function getBlockEventTemplatesApi() {
  return jwtAxios.get(GET_BLOCK_EVENT_Templates_API_URL);
}

// destinations
export function getDestinationsApi() {
  return jwtEnvAxios("event").get(GET_DESTINATIONS_API_URL);
}

export function getDestinationApi(destId: string) {
  return jwtEnvAxios("event").get(GET_DESTINATION_API_URL(destId));
}

export function createDestinationsApi(data: any) {
  return jwtEnvAxios("event").post(CREATE_DESTINATIONS_API_URL, data);
}

export function updateDestinationsApi(destId: string, data: any) {
  return jwtEnvAxios("event").post(UPDATE_DESTINATION_API_URL(destId), data);
}

export function deleteDestinationApi(destId: string) {
  return jwtEnvAxios("event").delete(DELETE_DESTINATION_API_URL(destId));
}

export function getDestinationsWebhookTypesApi() {
  return jwtAxios.get(GET_DESTINATIONS_WEBHOOK_TYPES_API_URL);
}

export function getDestinationsPayloadTypesApi() {
  return jwtAxios.get(GET_DESTINATIONS_PAYLOAD_TYPES_API_URL);
}
