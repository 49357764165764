export type S3TiersState = {
  tiers: Array<S3Tier>;
  loading: boolean;
  actionLoading: boolean;
  showEditor: boolean;
  selectedTier: null | S3Tier;
};

export const tierTypesNames = ["minio", "gcs", "s3", "azure"] as const;
export type TierTypes = (typeof tierTypesNames)[number];

export type S3Tier<T extends string = TierTypes> = {
  //TODO
  [key in T]: {
    accesskey: string;
    bucket: string;
    endpoint: string;
    name: string;
    objects: string;
    prefix: string;
    secretkey: string;
    usage: string;
    versions: string;
  };
} & {
  status: boolean;
  type: T;
};

export interface TierTypeOption {
  key: TierTypes;
  name: string;
  iconUrl: string;
}

export interface Credentials {
  access_key: string;
  secret_key: string;
}

export interface S3UpdateCredentials {
  type: string;
  name: string;
  credentials: Credentials;
}
