import jwtAxios, { jwtEnvAxios } from ".";
import { Web3AuthEndpointApiData } from "../types/web3-auth";

//endpoints-urls
const GET_WEB3_AUTH_ENDPOINTS_API_URL = `/web3-auth`;
const GET_WEB3_AUTH_ENDPOINT_API_URL = (id: string) => `/web3-auth/${id}`;
const CREATE_WEB3_AUTH_API_URL = `/web3-auth`;
const UPDATE_WEB3_AUTH_API_URL = (id: number) => `/web3-auth/${id}`;
const REFRESH_WEB3_AUTH_ENCRYPTION_API_URL = (id: number) =>
  `/web3-auth/${id}/refresh`;
const DELETE_WEB3_AUTH_API_URL = (id: number) => `/web3-auth/${id}`;

//networks-urls
const GET_WEB3_NETWORKS_API_URL = `/auth-networks`;

//users-url
const GET_WEB3_AUTH_ENDPOINT_USERS_DATA_API_URL = (id: string) =>
  `/web3-auth/${id}/user-datas`;
const CREATE_WEB3_AUTH_ENDPOINT_USERS_DATA_API_URL = (id: string) =>
  `/web3-auth/${id}/user-datas`;
const DELETE_WEB3_AUTH_ENDPOINT_USERS_DATA_API_URL = (
  id: number,
  userDataId: number
) => `/web3-auth/${id}/user-datas/${userDataId}`;

//field-types url
const GET_WEB3_FILED_TYPES_API_URL = `/enums/field-type`;

//api-keys urls
const GET_WEB3_AUTH_ENDPOINT_API_KEYS_API_URL = (endpointId: string) =>
  `/web3-auth/${endpointId}/access-key`;
const DELETE_WEB3_AUTH_ENDPOINT_API_KEY_API_URL = (
  endpointId: number,
  id: number
) => `/web3-auth/${endpointId}/access-key/${id}`;
const CREATE_WEB3_AUTH_ENDPOINT_API_KEY_API_URL = (endpointId: number) =>
  `/web3-auth/${endpointId}/access-key`;
const UPDATE_WEB3_AUTH_ENDPOINT_API_KEY_API_URL = (
  endpointId: number,
  id: number
) => `/web3-auth/${endpointId}/access-key/${id}`;

//endpoints
export function getWeb3AuthEndpointsApi() {
  return jwtEnvAxios().get(GET_WEB3_AUTH_ENDPOINTS_API_URL);
}

export function getWeb3AuthEndpointApi(id: string) {
  return jwtEnvAxios().get(GET_WEB3_AUTH_ENDPOINT_API_URL(id));
}

export function createWeb3AuthEndpointApi(data: Web3AuthEndpointApiData) {
  return jwtEnvAxios().post(CREATE_WEB3_AUTH_API_URL, data);
}

export function updateWeb3AuthEndpointApi(
  id: number,
  data: Web3AuthEndpointApiData
) {
  return jwtEnvAxios().put(UPDATE_WEB3_AUTH_API_URL(id), data);
}

export function refreshWeb3AuthEncryptionKeyApi(id: number) {
  return jwtEnvAxios().put(REFRESH_WEB3_AUTH_ENCRYPTION_API_URL(id));
}

export function deleteWeb3AuthEndpointApi(id: number) {
  return jwtEnvAxios().delete(DELETE_WEB3_AUTH_API_URL(id));
}

//networks
export function getWeb3AuthNetworksApi() {
  return jwtAxios.get(GET_WEB3_NETWORKS_API_URL);
}

//users
export function getWeb3AuthEndpointUsersApi({
  id,
  PageNumber,
  PageSize,
}: {
  id: string;
  PageNumber: number;
  PageSize: number;
}) {
  return jwtEnvAxios().get(
    `/web3-auth/${id}/users?pageNumber=${PageNumber}&pageSize=${PageSize}`
  );
}

//users-data
export function getWeb3AuthEndpointUsersDataApi(id: string) {
  return jwtEnvAxios().get(GET_WEB3_AUTH_ENDPOINT_USERS_DATA_API_URL(id));
}
export function createWeb3AuthEndpointUsersDataApi(id: string, data: any) {
  return jwtEnvAxios().post(
    CREATE_WEB3_AUTH_ENDPOINT_USERS_DATA_API_URL(id),
    data
  );
}
export function deleteWeb3AuthEndpointUsersDataApi(
  id: number,
  userDataId: number
) {
  return jwtEnvAxios().delete(
    DELETE_WEB3_AUTH_ENDPOINT_USERS_DATA_API_URL(id, userDataId)
  );
}
//field-types
export function getWeb3AuthFieldTypesApi() {
  return jwtAxios.get(GET_WEB3_FILED_TYPES_API_URL);
}

//api-keys
export function getWeb3AuthEndpointApiKeysApi(id: string) {
  return jwtEnvAxios().get(GET_WEB3_AUTH_ENDPOINT_API_KEYS_API_URL(id));
}

export function deleteWeb3AuthEndpointApiKeyApi(
  endpointId: number,
  id: number
) {
  return jwtEnvAxios().delete(
    DELETE_WEB3_AUTH_ENDPOINT_API_KEY_API_URL(endpointId, id)
  );
}

export function createWeb3AuthEndpointApiKeyApi(endpointId: number, data: any) {
  return jwtEnvAxios().post(
    CREATE_WEB3_AUTH_ENDPOINT_API_KEY_API_URL(endpointId),
    data
  );
}

export function updateWeb3AuthEndpointApiKeyApi(
  endpointId: number,
  id: number,
  data: any
) {
  return jwtEnvAxios().put(
    UPDATE_WEB3_AUTH_ENDPOINT_API_KEY_API_URL(endpointId, id),
    data
  );
}
