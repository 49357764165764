import AnimatedFeatures from "../../components/website/AnimatedFeaturesBox";
import {
  CounterParallaxBox,
  ContactUsParallaxBox,
} from "../../components/website/ParallaxBox";
import NewsBox from "../../components/website/NewsBox";
import ProductsBox from "../../components/website/ProductsBox";
import TrustedByBox from "../../components/website/TrustedByBox/TrustedByBox";
import UseCasesBox from "../../components/website/UseCasesBox";
import VideosBox from "../../components/website/VideosBox";
import IndexBanner from "../../components/website/IndexBanner";

const IndexPage = () => {
  return (
    <>
      <IndexBanner />
      <AnimatedFeatures />
      <ProductsBox />
      <CounterParallaxBox />
      <UseCasesBox />
      <TrustedByBox />
      <VideosBox />
      <ContactUsParallaxBox />
      <NewsBox />
    </>
  );
};

export default IndexPage;
