import { Node } from "reactflow";
import { INodeResponse } from "../../../../types/workflows";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "./../../../../assets/icons/minus.svg";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ClickAwayListener, Paper, Popper } from "@mui/material";
import { getNodeIconUrl } from "../../../../utils/wfHelper";
import { Button, Input, Tabs, Typography, Accordion } from "djuno-design";

interface IAddNodes {
  nodesData: INodeResponse[];
  nodesDataLoading: boolean;
  node: Node | null;
}

const AddNodes: React.FC<IAddNodes> = ({
  nodesData,
  node,
  nodesDataLoading,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [nodes, setNodes] = useState<{
    [key: string]: INodeResponse[];
  }>({});

  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [categoryExpanded, setCategoryExpanded] = useState<{
    [key: string]: boolean;
  }>({});

  const anchorRef = useRef<HTMLDivElement>(null);
  const s = useRef<HTMLDivElement>(null);

  const scrollTop = () => {
    const curr = s.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  };

  const handleTabChange = useCallback(
    (newValue: number) => {
      setTabValue(newValue);
      let returnData: INodeResponse[] = [];
      switch (newValue) {
        case 0:
          returnData = nodesData;
          break;
        case 1:
          returnData = nodesData.filter(
            (nd) => nd.type.toLowerCase() === "trigger"
          );
          break;
        case 2:
          returnData = nodesData.filter(
            (nd) => nd.type.toLowerCase() === "webhook"
          );
          break;
        case 3:
          returnData = nodesData.filter(
            (nd) => nd.type.toLowerCase() === "action"
          );
          break;
      }
      groupByCategory(returnData);
      scrollTop();
    },
    [nodesData]
  );

  const filterSearch = (value: string) => {
    setSearchValue(value);
    setTimeout(() => {
      if (value) {
        const returnData = nodesData.filter((nd) =>
          nd.name.toLowerCase().includes(value.toLowerCase())
        );
        groupByCategory(returnData, true);
        setTabValue(0);
        scrollTop();
      } else if (value === "") {
        groupByCategory(nodesData);
        scrollTop();
      }
    }, 500);
  };

  const groupByCategory = (
    nodes: INodeResponse[],
    isFilter: boolean = false
  ) => {
    const accordianCategories: { [key: string]: boolean } = {};
    const result: { [key: string]: INodeResponse[] } = nodes.reduce(function (
      r,
      a
    ) {
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      accordianCategories[a.category] = isFilter ? true : false;
      return r;
    },
    Object.create(null));
    setNodes(result);
    setCategoryExpanded(accordianCategories);
  };

  const handleAccordionChange =
    (category: string) => (event: any, isExpanded: boolean) => {
      const accordianCategories = { ...categoryExpanded };
      accordianCategories[category] = isExpanded;
      setCategoryExpanded(accordianCategories);
    };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    node: INodeResponse
  ) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(node));
    event.dataTransfer.effectAllowed = "move";
  };

  // useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current?.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  useEffect(() => {
    if (node) setOpen(false);
  }, [node]);

  useEffect(() => {
    if (tabValue === 0) {
      handleTabChange(0);
    }
  }, [handleTabChange, tabValue]);

  return (
    <div className="relative z-[1050] top-5 left-5 inline-flex" ref={anchorRef}>
      <div className="text-slate-700" onClick={handleToggle}>
        <Button className="!px-1.5 !bg-primary-500">
          {open ? (
            <MinusIcon className="w-5 aspect-square text-white" />
          ) : (
            <PlusIcon className="w-5 aspect-square text-white p-0.5" />
          )}
        </Button>
      </div>
      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        sx={{ zIndex: 1000, minWidth: 350, maxWidth: 400 }}
      >
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Paper
            elevation={3}
            className="bg-white dark:bg-dark-3 border-2 border-white dark:border-dark-2 !rounded-2xl"
          >
            <ClickAwayListener onClickAway={handleClose}>
              <div className="p-3 ">
                <Typography.Text className="!text-sm !font-medium">
                  Add Nodes
                </Typography.Text>
                <Input
                  className="w-full my-2 mb-5"
                  placeholder="Search nodes"
                  value={searchValue}
                  onChange={(e: any) => filterSearch(e.target.value)}
                />

                <Tabs
                  tabType="creamy"
                  onChange={({ index }) => index && handleTabChange(index)}
                  selectedIndex={tabValue}
                  options={[
                    { label: "All" },
                    { label: "Trigger" },
                    { label: "Webhook" },
                    { label: "Action" },
                  ]}
                />
                <div
                  className="scroll max-h-[calc(100vh-380px)] overflow-y-auto mt-3"
                  ref={s}
                >
                  <Accordion
                    loading={nodesDataLoading}
                    items={[
                      ...Object.keys(nodes)
                        .sort()
                        .map((category) => ({
                          label: category,
                          panel: (
                            <>
                              {nodes[category].map((node, index) => (
                                <div
                                  key={node.name}
                                  onDragStart={(event) =>
                                    onDragStart(event, node)
                                  }
                                  draggable
                                >
                                  <div className="cursor-move rounded-lg hover:bg-primary-50 hover:dark:bg-white/10 px-3 py-2">
                                    <div className="flex items-center gap-2">
                                      <div className="w-[36px] h-[36px] aspect-square rounded-full bg-white dark:bg-dark-3">
                                        <img
                                          className="w-full h-full"
                                          src={getNodeIconUrl(node.icon)}
                                          alt={node.name}
                                        />
                                      </div>
                                      <div className="flex flex-col ml-1 my-1">
                                        <Typography.Text className="text-sm">
                                          {node.label}
                                        </Typography.Text>
                                        <Typography.Text
                                          className="!text-xs"
                                          uiType="secondary"
                                        >
                                          {node.description}
                                        </Typography.Text>
                                      </div>
                                    </div>
                                  </div>

                                  {index ===
                                  nodes[category].length - 1 ? null : (
                                    <hr className="border-slate-300 dark:border-slate-600" />
                                  )}
                                </div>
                              ))}
                            </>
                          ),
                        })),
                    ]}
                  />
                </div>
              </div>
            </ClickAwayListener>
          </Paper>
        </Transition>
      </Popper>
    </div>
  );
};

export default AddNodes;
