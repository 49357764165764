import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import { useEffect, useMemo, useRef } from "react";
import {
  getInstanceAsync,
  getInstanceVncAsync,
  selectInstance,
  selectInstanceLoading,
  selectInstanceVnc,
  selectInstanceVncLoading,
} from "./../../../../store/instances/instanceSlice";
import { useNavigate, useParams } from "react-router-dom";
import { InstancesUrl } from "./../../../../utils/urls";
import { Alert, Flex, Loading, Tag, Typography } from "djuno-design";

const InstanceVcnConsoleTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const instance = useAppSelector(selectInstance);
  const instanceLoading = useAppSelector(selectInstanceLoading);
  const vnc = useAppSelector(selectInstanceVnc);
  const vncLoading = useAppSelector(selectInstanceVncLoading);
  const vncRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const isActive = useMemo(() => {
    if (instance) return !["REBUILD", "SHUTOFF"].includes(instance.status);
    return false;
  }, [instance]);

  useEffect(() => {
    if (id && (instance === null || instance.id !== id)) {
      dispatch(getInstanceAsync({ instanceId: id })).then((action) => {
        if (action && action.type === "instance/rejected") {
          navigate(InstancesUrl);
        }
      });
    }
  }, [dispatch, id, instance, navigate]);

  useEffect(() => {
    if (instance && isActive) {
      dispatch(
        getInstanceVncAsync({
          instanceId: instance.id,
        })
      );
    }
  }, [dispatch, instance, isActive]);

  useEffect(() => {
    if (vnc && vncRef && vncRef.current) {
      if (!vncLoading) {
        setTimeout(() => {
          if (vncRef.current)
            vncRef.current.scrollTop = vncRef.current.scrollHeight || 400;
        }, 10);
      }
    }
  }, [vnc, vncLoading]);

  return (
    <div>
      <div className="flex flex-col items-start w-full justify-between gap-2 mb-5">
        <Typography.Text className="!text-sm">
          Tip: if you're unable to type in the window, click on the blue bar
          below
        </Typography.Text>
        <Alert uiType="warning" className="mb-3" showIcon>
          <Typography.Text size="xs">
            To use the VNC console on the {instance?.name} machine, you must
            first log in via SSH and configure a user with a password
          </Typography.Text>
        </Alert>
      </div>

      <div
        className="flex flex-col w-full bg-gray-100 dark:bg-dark-3 relative rounded-tl-xl rounded-tr-xl rounded-xl overflow-hidden h-[400px]"
        ref={vncRef}
      >
        <Flex direction="col" className="flex-1">
          {/* VNC Viewer */}
          {vnc && !vncLoading && !instanceLoading && vnc.url && isActive && (
            <iframe
              className="h-full w-full"
              src={vnc.url}
              seamless
              title="VNC Viewer"
            />
          )}

          {/* Empty VNC message */}
          {((!vnc && !vncLoading && !instance) || !isActive) && (
            <Flex items="center" justify="center" className="h-full px-4">
              <Flex direction="col" items="center" className="gap-1">
                <div className="text-center space-y-1">
                  <Typography.Text
                    size="sm"
                    className="!antialiased !font-sans !font-medium !leading-6"
                  >
                    No VNC to show
                  </Typography.Text>
                </div>
                {!isActive && (
                  <Tag className="" color="warning">
                    <Flex items="center" className="gap-1">
                      Your instance is not active
                    </Flex>
                  </Tag>
                )}
              </Flex>
            </Flex>
          )}

          {/* Loading */}
          {(vncLoading || instanceLoading) && (
            <Flex items="center" justify="center" className="h-full px-4">
              <Loading borderSize={2} />
            </Flex>
          )}
        </Flex>
      </div>
    </div>
  );
};

export default InstanceVcnConsoleTab;
