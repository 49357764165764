import PropTypes from "prop-types";

// material-ui
import { Box, FormControl, OutlinedInput, Popper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DarkCodeEditor } from "./editor/DarkCodeEditor";
import { LightCodeEditor } from "./editor/LightCodeEditor";

// icons
import { ReactComponent as UploadIcon } from "./../../../../assets/icons/arrow-up-tray.svg";
import { ReactComponent as CloseIcon } from "./../../../../assets/icons/close.svg";

// third party
import lodash from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// utils
import {
  convertDateStringToDateObject,
  getFileName,
  getFolderName,
} from "./../../../../utils/wfHelper";

//css
import "./InputParameters.css";
import DateCustomInput from "./DateCustomInput";
import {
  useDjunoDesign,
  Tooltip,
  Button,
  Input,
  Typography,
  Switcher,
} from "djuno-design";

// ==============================|| ARRAY INPUT PARAMETERS ||============================== //

const ArrayInputParameters = ({
  initialValues,
  arrayParams,
  paramsType,
  arrayGroupName,
  errors,
  onArrayInputChange,
  onArrayInputBlur,
  onArrayItemRemove,
  onArrayItemMouseUp,
  onEditVariableDialogOpen,
}) => {
  const {
    theme: { mode },
  } = useDjunoDesign();

  const processUpdateValues = (inputValue, inputName, values, index) => {
    const updateArrayValues = {
      ...values,
      [inputName]: inputValue,
    };
    const updateInitialValues = initialValues;
    updateInitialValues[index] = updateArrayValues;
    return updateInitialValues;
  };

  const onInputChange = (inputValue, inputName, values, index) => {
    const updateInitialValues = processUpdateValues(
      inputValue,
      inputName,
      values,
      index
    );
    onArrayInputChange(updateInitialValues);
  };

  const onInputBlur = (inputValue, inputName, values, index) => {
    const updateInitialValues = processUpdateValues(
      inputValue,
      inputName,
      values,
      index
    );
    onArrayInputBlur(updateInitialValues);
  };

  const onRemoveClick = (index) => {
    const updateInitialValues = initialValues;
    updateInitialValues.splice(index, 1);
    onArrayItemRemove(updateInitialValues);
    onArrayItemMouseUp(false);
  };

  const onMouseUp = (e, inputName, valueIndex) => {
    const cursorPosition = e.target.selectionEnd;
    const textBeforeCursorPosition = e.target.value.substring(
      0,
      cursorPosition
    );
    const textAfterCursorPosition = e.target.value.substring(
      cursorPosition,
      e.target.value.length
    );
    const path = `${paramsType}.${arrayGroupName}[${valueIndex}].${inputName}`;
    const body = {
      textBeforeCursorPosition,
      textAfterCursorPosition,
      path,
      paramsType,
    };
    onArrayItemMouseUp(true, body);
  };

  const handleFolderUpload = (e, values, inputName, index) => {
    if (!e.target.files) return;
    const files = e.target.files;
    const reader = new FileReader();

    function readFile(fileIndex, base64Array) {
      if (fileIndex >= files.length) {
        onInputChange(JSON.stringify(base64Array), inputName, values, index);
        return;
      }
      const file = files[fileIndex];
      reader.onload = (evt) => {
        if (!evt?.target?.result) {
          return;
        }
        const { result } = evt.target;
        const value = result + `,filepath:${file.webkitRelativePath}`;
        base64Array.push(value);
        readFile(fileIndex + 1, lodash.cloneDeep(base64Array));
      };
      reader.readAsDataURL(file);
    }
    readFile(0, []);
  };

  const handleFileUpload = (e, onInputChange, values, inputName, index) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const { name } = file;

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      const value = result + `,filename:${name}`;
      onInputChange(value, inputName, values, index);
    };
    reader.readAsDataURL(file);
  };

  const findMatchingOptions = (options, value) =>
    options.find((option) => option.name === value);

  const getDefaultOptionValue = () => "";

  return (
    <>
      {arrayParams.map((_, index) => {
        const params = arrayParams[index] || [];
        const values = initialValues[index] || {};

        return (
          <Box
            key={index}
            className="p-4 mb-4 rounded-lg relative bg-gray-50/80 dark:bg-dark-2"
          >
            {arrayParams.length > 1 && (
              <CloseIcon
                onClick={() => onRemoveClick(index)}
                className="w-4 h-4 absolute left-2 top-2 cursor-pointer hover:text-red-600 hover:scale-125 transition-all duration-200"
              />
            )}

            {params.map((input, paramIndex) => {
              if (input.type === "file" || input.type === "folder") {
                const inputName = input.name;

                return (
                  <FormControl
                    key={`${inputName}_${paramIndex}`}
                    fullWidth
                    sx={{ mb: 1, mt: 1 }}
                    error={
                      errors && errors.length > 0 && errors[index]
                        ? Boolean(errors[index][inputName])
                        : false
                    }
                  >
                    <div className="flex items-center gap-1 text-xs">
                      <Typography.Text className="!text-xs">
                        {input.label}
                      </Typography.Text>
                      {input.description && (
                        <Tooltip.Info content={input.description} clickable />
                      )}
                    </div>
                    {input.type === "file" && (
                      <span
                        style={{
                          fontWeight: "bold",
                          //   color: theme.palette.grey["800"],
                          marginBottom: "1rem",
                        }}
                      >
                        {values[inputName]
                          ? getFileName(values[inputName])
                          : "Choose a file to upload"}
                      </span>
                    )}

                    {input.type === "folder" && (
                      <span
                        style={{
                          fontWeight: "bold",
                          //   color: theme.palette.grey["800"],
                          marginBottom: "1rem",
                        }}
                      >
                        {values[inputName]
                          ? getFolderName(values[inputName])
                          : "Choose a folder to upload"}
                      </span>
                    )}

                    <Button
                      uiType="light"
                      className="!w-full"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <UploadIcon className="w-4 h-4" />
                    </Button>
                    {input.type === "folder" ? "Upload Folder" : "Upload File"}
                    {input.type === "file" && (
                      <input
                        type="file"
                        hidden
                        onChange={(e) =>
                          handleFileUpload(e, values, inputName, index)
                        }
                      />
                    )}
                    {input.type === "folder" && (
                      <input
                        type="file"
                        // https://github.com/jsx-eslint/eslint-plugin-react/issues/3454
                        // eslint-disable-next-line react/no-unknown-property
                        directory=""
                        // eslint-disable-next-line react/no-unknown-property
                        webkitdirectory=""
                        hidden
                        onChange={(e) =>
                          handleFolderUpload(e, values, inputName, index)
                        }
                      />
                    )}
                  </FormControl>
                );
              }

              if (input.type === "json" || input.type === "code") {
                const inputName = input.name;

                return (
                  <FormControl
                    key={`${inputName}_${paramIndex}`}
                    fullWidth
                    sx={{ mb: 1, mt: 1 }}
                    error={
                      errors && errors.length > 0 && errors[index]
                        ? Boolean(errors[index][inputName])
                        : false
                    }
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        <Typography.Text className="!text-xs">
                          {input.label}
                        </Typography.Text>
                        {input.description && (
                          <Tooltip.Info content={input.description} clickable />
                        )}
                      </div>
                      {(input.type === "string" || input.type === "number") && (
                        <Button
                          uiSize="small"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onEditVariableDialogOpen(input, values, index);
                          }}
                          className="mb-1"
                        >
                          Edit Variables
                        </Button>
                      )}
                    </div>
                    <div className="border h-[200px] max-h-[200px] overflow-x-hidden overflow-y-auto">
                      {mode === "dark" ? (
                        <DarkCodeEditor
                          value={values[inputName] || ""}
                          onValueChange={(code) =>
                            onInputChange(code, inputName, values, index)
                          }
                          placeholder={input.placeholder}
                          type={input.type}
                          onMouseUp={(e) => onMouseUp(e, inputName, index)}
                          onBlur={(e) => {
                            onInputBlur(
                              e.target.value,
                              inputName,
                              values,
                              index
                            );
                            onMouseUp(e, inputName, index);
                          }}
                          style={{
                            fontSize: "0.875rem",
                            minHeight: "200px",
                            width: "100%",
                          }}
                        />
                      ) : (
                        <LightCodeEditor
                          value={values[inputName] || ""}
                          onValueChange={(code) =>
                            onInputChange(code, inputName, values, index)
                          }
                          placeholder={input.placeholder}
                          type={input.type}
                          onMouseUp={(e) => onMouseUp(e, inputName, index)}
                          onBlur={(e) => {
                            onInputBlur(
                              e.target.value,
                              inputName,
                              values,
                              index
                            );
                            onMouseUp(e, inputName, index);
                          }}
                          style={{
                            fontSize: "0.875rem",
                            minHeight: "200px",
                            width: "100%",
                          }}
                        />
                      )}
                    </div>
                  </FormControl>
                );
              }

              if (input.type === "date") {
                const inputName = input.name;

                return (
                  <FormControl
                    key={`${inputName}_${paramIndex}`}
                    fullWidth
                    sx={{ mb: 1, mt: 1 }}
                    error={
                      errors && errors.length > 0 && errors[index]
                        ? Boolean(errors[index][inputName])
                        : false
                    }
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        <Typography.Text className="!text-xs">
                          {input.label}
                        </Typography.Text>
                        {input.description && (
                          <Tooltip.Info content={input.description} clickable />
                        )}
                      </div>
                    </div>
                    <DatePicker
                      customInput={
                        <DateCustomInput isDarkMode={mode === "dark"} />
                      }
                      selected={
                        convertDateStringToDateObject(values[inputName]) || null
                      }
                      showTimeInput
                      isClearable
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      onChange={(date) => {
                        const inputValue = date ? date.toISOString() : null;
                        onInputChange(inputValue, inputName, values, index);
                        onArrayItemMouseUp(false);
                      }}
                    />
                  </FormControl>
                );
              }

              if (
                input.type === "string" ||
                input.type === "password" ||
                input.type === "number"
              ) {
                const inputName = input.name;

                return (
                  <FormControl
                    key={`${inputName}_${paramIndex}`}
                    fullWidth
                    sx={{ mb: 1, mt: 1 }}
                    error={
                      errors && errors.length > 0 && errors[index]
                        ? Boolean(errors[index][inputName])
                        : false
                    }
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        <Typography.Text className="!text-xs">
                          {input.label}
                        </Typography.Text>
                        {input.description && (
                          <Tooltip.Info content={input.description} clickable />
                        )}
                      </div>
                      {(input.type === "string" || input.type === "number") && (
                        <Button
                          uiSize="small"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onEditVariableDialogOpen(input, values, index);
                          }}
                          className="mb-1"
                        >
                          Edit Variables
                        </Button>
                      )}
                    </div>
                    <OutlinedInput
                      id={inputName}
                      type={
                        input.type === "string" || input.type === "number"
                          ? "text"
                          : input.type
                      }
                      value={values[inputName] || ""}
                      placeholder={input.placeholder}
                      name={inputName}
                      onBlur={(e) => {
                        const inputValue = e.target.value;
                        onInputBlur(inputValue, inputName, values, index);
                        onMouseUp(e, inputName, index);
                      }}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        onInputChange(inputValue, inputName, values, index);
                      }}
                      onMouseUp={(e) => onMouseUp(e, inputName, index)}
                      sx={{
                        p: 0,
                        borderRadius: 2,
                        backgroundColor:
                          mode === "dark"
                            ? "rgb(32,36,37)"
                            : "rgb(241,245,249)",
                        border:
                          mode === "dark"
                            ? "solid 2px rgb(32,36,37)"
                            : `solid 2px rgb(241,245,249)`,
                        "&:focus": {
                          borderColor:
                            mode === "dark"
                              ? "rgb(71,85,105)"
                              : "rgb(226,232,240)",
                        },
                        "&:hover": { outline: "0" },
                        color: mode === "dark" ? "white" : "black",
                      }}
                      size="small"
                      // className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-full p-0 dark:bg-dark-2 outline-none disabled:cursor-not-allowed disabled:bg-secondary-200 dark:disabled:bg-gray-700 dark:disabled:text-secondary-400 disabled:text-secondary-500 disabled:border-secondary-300 disabled:dark:border-gray-600"
                    />
                  </FormControl>
                );
              }

              if (input.type === "boolean") {
                const inputName = input.name;

                return (
                  <FormControl
                    key={`${inputName}_${paramIndex}`}
                    fullWidth
                    sx={{ mb: 1, mt: 1 }}
                    error={
                      errors && errors.length > 0 && errors[index]
                        ? Boolean(errors[index][inputName])
                        : false
                    }
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        <Typography.Text className="!text-xs">
                          {input.label}
                        </Typography.Text>
                        {input.description && (
                          <Tooltip.Info content={input.description} clickable />
                        )}
                      </div>
                    </div>
                    {/* <Switch
                      checked={!!values[inputName]}
                      onChange={(event) => {
                        onInputChange(
                          event.target.checked,
                          inputName,
                          values,
                          index
                        );
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    /> */}
                    <Switcher
                      uiSize="small"
                      value={!!values[inputName]}
                      onChange={(checked) => {
                        onInputChange(checked, inputName, values, index);
                      }}
                    />
                  </FormControl>
                );
              }

              if (input.type === "options") {
                const inputName = input.name;
                const availableOptions = input.options || [];

                return (
                  <FormControl
                    key={`${inputName}_${paramIndex}`}
                    fullWidth
                    sx={{ mb: 1, mt: 1 }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        <Typography.Text className="!text-xs">
                          {input.label}
                        </Typography.Text>
                        {input.description && (
                          <Tooltip.Info content={input.description} clickable />
                        )}
                      </div>
                    </div>
                    <Autocomplete
                      id={inputName}
                      freeSolo
                      onOpen={() => onArrayItemMouseUp(false)}
                      options={availableOptions}
                      value={
                        findMatchingOptions(
                          availableOptions,
                          values[inputName]
                        ) || getDefaultOptionValue()
                      }
                      onChange={(e, selection) => {
                        const value = selection ? selection.name : "";
                        onInputBlur(value, inputName, values, index);
                      }}
                      PopperComponent={Popper}
                      renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                          <Input {...params.inputProps} />
                        </div>
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <div className="flex flex-col">
                            <Typography.Text className="!text-sm">
                              {option.label}
                            </Typography.Text>
                            {option.description && (
                              <Typography.Text
                                className="!text-xs"
                                uiType="secondary"
                              >
                                {option.description}
                              </Typography.Text>
                            )}
                          </div>
                        </Box>
                      )}
                      sx={{
                        p: 0,
                        color:
                          mode === "dark" ? "rgb(75,85,99)" : "rgb(51,65,85)",
                      }}
                      size="small"
                      // className="bg-secondary-100 focus:ring-0 text-sm rounded-lg block w-full p-0 dark:bg-dark-2 outline-none disabled:cursor-not-allowed disabled:bg-secondary-200 dark:disabled:bg-gray-700 dark:disabled:text-secondary-400 disabled:text-secondary-500 disabled:border-secondary-300 disabled:dark:border-gray-600"
                    />
                  </FormControl>
                );
              }
              return null;
            })}
          </Box>
        );
      })}
    </>
  );
};

ArrayInputParameters.propTypes = {
  initialValues: PropTypes.array,
  arrayParams: PropTypes.array,
  paramsType: PropTypes.string,
  arrayGroupName: PropTypes.string,
  errors: PropTypes.array,
  onArrayInputChange: PropTypes.func,
  onArrayInputBlur: PropTypes.func,
  onArrayItemRemove: PropTypes.func,
  onArrayItemMouseUp: PropTypes.func,
  onEditVariableDialogOpen: PropTypes.func,
};

export default ArrayInputParameters;
