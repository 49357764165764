import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getInstanceAsync,
  instancesAttachVolumeAsync,
  selectInstanceActionLoading,
} from "../../../store/instances/instanceSlice";
import {
  getInstancesAsync,
  selectInstances,
  selectInstancesLoading,
} from "../../../store/instances/instancesSlice";
import {
  getInstancesVolumesAsync,
  handleInstanceAttachHideModal,
  selectInstancesSelectedVolume,
  selectShowInstanceAttachModal,
} from "../../../store/instances/instancesVolumesSlice";
import { useEffect, useMemo, useState } from "react";
import { Instance } from "../../../types/instance";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceAttachSchema } from "../../../utils/validations";
import { Alert, Button, Modal, Select } from "djuno-design";

const InstanceAttachModal = () => {
  const [selectedInstance, setSelectedInstance] = useState<Instance>();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowInstanceAttachModal);
  const instances = useAppSelector(selectInstances);
  const volume = useAppSelector(selectInstancesSelectedVolume);
  const instancesLoading = useAppSelector(selectInstancesLoading);
  const actionLoading = useAppSelector(selectInstanceActionLoading);

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(InstanceAttachSchema),
    mode: "all",
  });

  useEffect(() => {
    if (instances.length === 0 && isOpen) {
      dispatch(getInstancesAsync({ withoutLoading: true }));
    }
  }, [instances, dispatch, isOpen]);

  const filterInstances = useMemo(() => {
    return instances.filter((instance) => instance.region === volume?.region);
  }, [instances, volume]);

  const onSubmit = (data: any) => {
    if (selectedInstance && volume) {
      const formData = { instanceId: selectedInstance.id };
      dispatch(
        instancesAttachVolumeAsync({
          volumeId: volume?.id,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "instance/volume/attach/fulfilled") {
          dispatch(getInstancesAsync({ withoutLoading: true }));
          dispatch(getInstanceAsync({ instanceId: selectedInstance.id }));
          dispatch(getInstancesVolumesAsync({}));
          dispatch(handleInstanceAttachHideModal());
        }
      });
    }
  };

  const handleClose = () => {
    reset();
    dispatch(handleInstanceAttachHideModal());
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      title="Attach volume to an instance"
    >
      {filterInstances.length > 0 ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-5">
            <Controller
              control={control}
              name="instanceId"
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value?.toString()}
                  label="Instance"
                  options={
                    filterInstances.map((i) => ({
                      label: i.name,
                      value: i.id,
                    })) || []
                  }
                  error={errors.instanceId?.message}
                  required
                  onChange={(value) => {
                    onChange(value);
                    setSelectedInstance(
                      filterInstances.find((i) => i.id === value)
                    );
                  }}
                  loading={instancesLoading}
                />
              )}
            />
          </div>

          <div className="mt-4 flex justify-end gap-2">
            <Button uiType="light" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              uiType="primary"
              type="submit"
              disabled={actionLoading}
              loading={actionLoading}
            >
              Attach
            </Button>
          </div>
        </form>
      ) : (
        <Alert uiType="warning" className="text-xs my-3" showIcon>
          No instances available in your volume’s region.
        </Alert>
      )}
    </Modal>
  );
};

export default InstanceAttachModal;
