import jwtAxios, { jwtEnvAxios } from ".";
import { RpcApiData } from "../types/rpc";

const GET_ALL_RPC_API_URL = `/rpc`;
const GET_RPC_API_URL = (id: number) => `/rpc/${id}`;
const CREATE_RPC_API_URL = `/rpc`;
const DELETE_RPC_API_URL = (id: number) => `/rpc/${id}`;
const GET_ALL_SERVICES_API_URL = `/services/rpc-nodes`;

const GET_RPC_METRICS_API_URL = (id: number) => `/rpc/${id}/metrics`;

const GET_RPC_TOKENS_API_URL = (id: number) => `/rpc/${id}/security-tokens`;
const CREATE_RPC_SECURITY_TOKEN_API_URL = (id: number) =>
  `/rpc/${id}/security-tokens`;
const DELETE_RPC_SECURITY_TOKEN_API_URL = (id: number, tokenId: number) =>
  `/rpc/${id}/security-tokens/${tokenId}`;

const CREATE_RPC_JWT_TOKEN_API_URL = (id: number) => `/rpc/${id}/jwt-token`;

const GET_RPC_WHITELIST_API_URL = (id: number) => `/rpc/${id}/white-list`;
const CREATE_RPC_WHITELIST_API_URL = (id: number) => `/rpc/${id}/white-list`;
const DELETE_RPC_WHITELIST_TOKEN_API_URL = (id: number, tokenId: number) =>
  `/rpc/${id}/white-list/${tokenId}`;

const GET_RPC_DOMAIN_MASKS_API_URL = (id: number) =>
  `/rpc/${id}/security-domain-masks`;
const CREATE_RPC_DOMAIN_MASK_API_URL = (id: number) =>
  `/rpc/${id}/security-domain-masks`;
const DELETE_RPC_DOMAIN_MASK_API_URL = (id: number, tokenId: number) =>
  `/rpc/${id}/security-domain-masks/${tokenId}`;

const CREATE_RPC_SOURCE_IP_API_URL = (id: number) => `/rpc/${id}/source-id`;
const CREATE_RPC_CONTRACT_ADDRESS_API_URL = (id: number) =>
  `/rpc/${id}/contract-address`;

export function getAllRpcApi() {
  return jwtEnvAxios().get(GET_ALL_RPC_API_URL);
}

export function getRpcApi(id: number) {
  return jwtEnvAxios().get(GET_RPC_API_URL(id));
}

export function getRpcMetricsApi(id: number) {
  return jwtEnvAxios().get(GET_RPC_METRICS_API_URL(id));
}

export function createRpcApi(data: RpcApiData) {
  return jwtEnvAxios().post(CREATE_RPC_API_URL, data);
}

export function deleteRpcApi(id: number) {
  return jwtEnvAxios().delete(DELETE_RPC_API_URL(id));
}

export function getServicesApi() {
  return jwtAxios.get(GET_ALL_SERVICES_API_URL);
}

export function getRpcTokensApi(id: number) {
  return jwtEnvAxios().get(GET_RPC_TOKENS_API_URL(id));
}

export function createRpcTokenApi(id: number) {
  return jwtEnvAxios().post(CREATE_RPC_SECURITY_TOKEN_API_URL(id));
}

export function deleteRpcTokenApi(id: number, tokenId: number) {
  return jwtEnvAxios().delete(DELETE_RPC_SECURITY_TOKEN_API_URL(id, tokenId));
}

export function createRpcJwtTokenApi(
  id: number,
  name: string,
  publicKey: string
) {
  return jwtEnvAxios().post(CREATE_RPC_JWT_TOKEN_API_URL(id), {
    name,
    publicKey,
  });
}

export function getRpcWhiteListApi(id: number) {
  return jwtEnvAxios().get(GET_RPC_WHITELIST_API_URL(id));
}

export function createRpcWhiteListApi(id: number, value: string, type: number) {
  return jwtEnvAxios().post(CREATE_RPC_WHITELIST_API_URL(id), [
    { WhiteList: value, WhiteListType: type },
  ]);
}

export function deleteRpcWhiteListApi(id: number, tokenId: number) {
  return jwtEnvAxios().delete(DELETE_RPC_WHITELIST_TOKEN_API_URL(id, tokenId));
}

export function getRpcDomainMasksApi(id: number) {
  return jwtEnvAxios().get(GET_RPC_DOMAIN_MASKS_API_URL(id));
}

export function createRpcDomainMaskApi(id: number, value: string) {
  return jwtEnvAxios().post(CREATE_RPC_DOMAIN_MASK_API_URL(id), {
    Mask: value,
  });
}

export function deleteRpcDomainMaskApi(id: number, tokenId: number) {
  return jwtEnvAxios().delete(DELETE_RPC_DOMAIN_MASK_API_URL(id, tokenId));
}

export function createRpcSourceIpApi(id: number, value: string) {
  return jwtEnvAxios().post(CREATE_RPC_SOURCE_IP_API_URL(id), { value });
}

export function createRpcContractAddressApi(id: number, value: string) {
  return jwtEnvAxios().post(CREATE_RPC_CONTRACT_ADDRESS_API_URL(id), { value });
}
