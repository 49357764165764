import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useRef } from "react";
import {
  handleSetWebAppImageURL,
  handleSetWebAppCredentialId,
  selectWebAppImageCredentialId,
  selectWebAppImageURL,
  selectWebAppIsValidateImage,
  selectWebAppSelectedDeployType,
  selectWebAppValidationLoading,
  selectWebAppValidationResponse,
  validateWebAppImageAsync,
} from "../../../store/web-app/webAppCreateSlice";
import { Link } from "react-router-dom";
import { SettingsRegisteriesUrl } from "../../../utils/urls";
import {
  getCredentialsAsync,
  selectCredentials,
  selectCredentialsLoading,
} from "../../../store/settings/registeries/registeriesSlice";
import { Credential } from "../../../types/registeries";
import { getRegisteryData } from "../../settings/registeries/RegisteriesTab";
import { ImageValidationResponse } from "../../../types/web-app";
import {
  Alert,
  Card,
  Flex,
  Input,
  Select,
  SelectOption,
  Typography,
} from "djuno-design";
import CustomLink from "../../general/CustomLink";

const SelectDeployingSourceStep = () => {
  const selectedDeployType = useAppSelector(selectWebAppSelectedDeployType);
  return (
    <div className="w-full min-h-[calc(100%-6rem)]">
      {selectedDeployType === "git" && <>Connect a repository</>}
      {selectedDeployType === "image" && <DeployingImageCard />}
    </div>
  );
};

const DeployingImageCard = () => {
  const validationResponse = useAppSelector(selectWebAppValidationResponse);
  return (
    <div className="flex my-5 ">
      <Card title="Deploy an image">
        <DeployingImageFrom />
        {validationResponse && (
          <div className="mt-4 lg:hidden text-sm">
            <WebAppImageValidationMessage {...validationResponse} />
          </div>
        )}
      </Card>
      <Flex
        direction="col"
        className="hidden lg:block max-w-[300px] py-8 ml-10 text-sm"
      >
        <Typography.Text size="sm" className="text-base font-medium">
          Registry Credentials
        </Typography.Text>
        <Typography.Text
          size="sm"
          uiType="secondary"
          className="mt-2 leading-5"
        >
          We currently support{" "}
          <Typography.Link className="!text-sm">private images</Typography.Link>{" "}
          from GitHub, GitLab, and Docker Hub registries. We don’t restrict
          public image registry types.
        </Typography.Text>
        {validationResponse && (
          <div className="mt-4">
            <WebAppImageValidationMessage {...validationResponse} />
          </div>
        )}
      </Flex>
    </div>
  );
};

export const DeployingImageFrom = () => {
  const imageURL = useAppSelector(selectWebAppImageURL);
  const selectedCredentialId = useAppSelector(selectWebAppImageCredentialId);

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const validationLoading = useAppSelector(selectWebAppValidationLoading);

  const credentials = useAppSelector(selectCredentials);
  const credentialsLoading = useAppSelector(selectCredentialsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (credentials.length === 0) dispatch(getCredentialsAsync());
  }, [credentials.length, dispatch]);

  const handleChangeImageURL = (event: React.ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value;
    dispatch(handleSetWebAppImageURL(url));
    timeout.current && clearTimeout(timeout.current);
    if (url !== "") {
      timeout.current = setTimeout(
        () => dispatch(validateWebAppImageAsync()),
        2000
      );
    }
  };

  const handleChangeCredential = (option: string | undefined) => {
    dispatch(handleSetWebAppCredentialId(option || null));
    if (imageURL !== "") dispatch(validateWebAppImageAsync());
  };

  return (
    <div className="flex flex-col mt-10 space-y-12 md:space-y-16">
      <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between space-x-0 md:space-x-4 lg:space-x-0 xl:space-x-4">
        <Flex direction="col" className="mb-2 mr-4">
          <Typography.Text className="!text-xs md:!text-sm font-medium">
            Image URL
          </Typography.Text>
          <Typography.Text uiType="secondary" className="!text-xs md:!text-sm ">
            The image URL for your external image.
          </Typography.Text>
        </Flex>
        <div className="w-full md:max-w-md lg:w-full xl:max-w-md">
          <Input
            value={imageURL}
            onChange={handleChangeImageURL}
            placeholder="docker.io/library/nginx:latest"
            loading={validationLoading}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between space-x-0 md:space-x-4 lg:space-x-0 xl:space-x-4">
        <Flex direction="col" className="mr-4 mb-2">
          <Typography.Text
            size="sm"
            className="font-medium !text-xs md:!text-sm"
          >
            Credential (Optional)
          </Typography.Text>
          <Typography.Text
            size="sm"
            uiType="secondary"
            className="!text-xs md:!text-sm"
          >
            Use a credential to access private images. Manage credentials in{" "}
            <CustomLink to={SettingsRegisteriesUrl}>Settings</CustomLink>.
          </Typography.Text>
        </Flex>
        <div className="w-full md:max-w-md lg:w-full xl:max-w-md">
          <Select<any>
            emptyString="No credential"
            clearable
            options={[...credentials.map(credentialSelectOption)]}
            value={selectedCredentialId || undefined}
            onChange={handleChangeCredential}
            loading={credentialsLoading}
            disabled={validationLoading}
          />
        </div>
      </div>
    </div>
  );
};

const credentialSelectOption = (
  credential: Credential
): SelectOption<Credential> => {
  const { Icon } = getRegisteryData(credential.RegisteryType);
  return {
    label: (
      <div className="flex items-center gap-1">
        {Icon}
        {credential.Name}
      </div>
    ),
    value: credential.Id.toString(),
    extraData: credential,
  };
};

export const WebAppImageValidationMessage = ({
  message,
  status,
}: ImageValidationResponse) => {
  return (
    <Alert
      uiType={status === "valid" ? "success" : "error"}
      message={message}
    />
  );
};
export default SelectDeployingSourceStep;
