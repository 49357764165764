import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import { S3Metrics, S3OverveiwState, S3WidgetType } from "../../types/s3";
import { getS3MetricsApi, getS3WidgetApi } from "../../apis/s3API";
import moment from "moment";
import { changeS3ActiveStatus, S3UpdatingMessage } from "./s3PublicSlice";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: S3OverveiwState = {
  startTime: undefined,
  endTime: undefined,
  metrics: null,
  loading: false,
  capacity: null,
  capacityLoading: false,
  network: null,
  networkLoading: false,
  dataUsage: null,
  dataUsageLoading: false,
  objectSize: null,
  objectSizeLoading: false,
  dataReceived: null,
  dataReceivedLoading: false,
  dataSent: null,
  dataSentLoading: false,
  apiRequest: null,
  apiRequestLoading: false,
  apiRequestError: null,
  apiRequestErrorLoading: false,
  internodeData: null,
  internodeDataLoading: false,
  nodeIo: null,
  nodeIoLoading: false,
  nodeMemory: null,
  nodeMemoryLoading: false,
  nodeCPU: null,
  nodeCUPLoading: false,
  drivesFree: null,
  drivesFreeLoading: false,
  driveUsed: null,
  driveUsedLoading: false,
  nodeSyscalls: null,
  nodeSyscallsLoading: false,
  nodeFile: null,
  nodeFileLoading: false,
  lastHealActivity: null,
  lastHealActivityLoading: false,
  lastScanActivity: null,
  lastScanActivityLoading: false,
  uptime: null,
  uptimeLoading: false,
};

export const getS3MetricsAsync = createAsyncThunk<
  { metrics: S3Metrics | null },
  undefined,
  IThunkRejectValue
>("s3-metrics", async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
  try {
    const response = await getS3MetricsApi();

    const { Result, NodeStatus } = response.data;
    const metrics = Result;

    dispatch(changeS3ActiveStatus({ status: NodeStatus === 2 }));

    return fulfillWithValue({ metrics });
  } catch (e) {
    if (isAxiosError(e) && e.response?.data.NodeStatus === 4) {
      dispatch(
        changeS3ActiveStatus({
          status: false,
          message: S3UpdatingMessage,
        })
      );
      return fulfillWithValue({ metrics: null });
    } else {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
});

export const getS3WidgetAsync = createAsyncThunk<
  any,
  {
    n: number | number[];
    key: S3WidgetType;
    startTime?: number;
    endTime?: number;
  },
  IThunkRejectValue
>(
  "s3-widget",
  async (
    { n, key, startTime, endTime },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      let widgetData;
      if (typeof n === "number") {
        const response = await getS3WidgetApi(n, startTime, endTime);
        widgetData = response.data.Result;
      } else {
        const response = await Promise.all([
          ...n.map(async (i) => await getS3WidgetApi(i, startTime, endTime)),
        ]);
        widgetData = response.map((res) => res.data.Result);
      }

      return fulfillWithValue({ key, widgetData });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const overviewSlice = createSlice({
  name: "s3-overview",
  initialState,
  reducers: {
    handleSetTimes: (
      state,
      action: PayloadAction<{ start?: string; end?: string }>
    ) => {
      const { start, end } = action.payload;
      if (start) {
        state.startTime = moment(start).valueOf() / 1000;
      } else {
        state.startTime = undefined;
      }

      if (end) {
        state.endTime = moment(end).valueOf() / 1000;
      } else {
        state.endTime = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getS3MetricsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getS3MetricsAsync.fulfilled, (state, action) => {
        state.metrics = action.payload.metrics;
        state.loading = false;
      })
      .addCase(getS3MetricsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getS3WidgetAsync.pending, (state, action) => {
        switch (action.meta.arg.key) {
          case "capacity":
            state.capacityLoading = true;
            break;
          case "network":
            state.networkLoading = true;
            break;
          case "dataUsage":
            state.dataUsageLoading = true;
            break;
          case "objectSize":
            state.objectSizeLoading = true;
            break;
          case "dataReceived":
            state.dataReceivedLoading = true;
            break;
          case "dataSent":
            state.dataSentLoading = true;
            break;
          case "apiRequest":
            state.apiRequestLoading = true;
            break;
          case "apiRequestError":
            state.apiRequestErrorLoading = true;
            break;
          case "internodeData":
            state.internodeDataLoading = true;
            break;
          case "nodeIo":
            state.nodeIoLoading = true;
            break;
          case "nodeMemory":
            state.nodeMemoryLoading = true;
            break;
          case "nodeCPU":
            state.nodeCUPLoading = true;
            break;
          case "drivesFree":
            state.drivesFreeLoading = true;
            break;
          case "driveUsed":
            state.driveUsedLoading = true;
            break;
          case "nodeSyscalls":
            state.nodeSyscallsLoading = true;
            break;
          case "nodeFile":
            state.nodeFileLoading = true;
            break;
          case "lastHealActivity":
            state.lastHealActivityLoading = true;
            break;
          case "lastScanActivity":
            state.lastScanActivityLoading = true;
            break;
          case "uptime":
            state.uptimeLoading = true;
            break;
        }
      })
      .addCase(getS3WidgetAsync.fulfilled, (state, action) => {
        const widgetData = action.payload.widgetData;
        switch (action.meta.arg.key) {
          case "capacity":
            state.capacity = widgetData;
            state.capacityLoading = false;
            break;
          case "network":
            state.network = { get: widgetData[0], put: widgetData[1] };
            state.networkLoading = false;
            break;
          case "dataUsage":
            state.dataUsage = widgetData;
            state.dataUsageLoading = false;
            break;
          case "objectSize":
            state.objectSize = widgetData;
            state.objectSizeLoading = false;
            break;
          case "dataReceived":
            state.dataReceived = widgetData;
            state.dataReceivedLoading = false;
            break;
          case "dataSent":
            state.dataSent = widgetData;
            state.dataSentLoading = false;
            break;
          case "apiRequest":
            state.apiRequest = widgetData;
            state.apiRequestLoading = false;
            break;
          case "apiRequestError":
            state.apiRequestError = widgetData;
            state.apiRequestErrorLoading = false;
            break;
          case "internodeData":
            state.internodeData = widgetData;
            state.internodeDataLoading = false;
            break;
          case "nodeIo":
            state.nodeIo = widgetData;
            state.nodeIoLoading = false;
            break;
          case "nodeMemory":
            state.nodeMemory = widgetData;
            state.nodeMemoryLoading = false;
            break;
          case "nodeCPU":
            state.nodeCPU = widgetData;
            state.nodeCUPLoading = false;
            break;
          case "drivesFree":
            state.drivesFree = widgetData;
            state.drivesFreeLoading = false;
            break;
          case "driveUsed":
            state.driveUsed = widgetData;
            state.driveUsedLoading = false;
            break;
          case "nodeSyscalls":
            state.nodeSyscalls = widgetData;
            state.nodeSyscallsLoading = false;
            break;
          case "nodeFile":
            state.nodeFile = widgetData;
            state.nodeFileLoading = false;
            break;
          case "lastHealActivity":
            state.lastHealActivity = widgetData;
            state.lastHealActivityLoading = false;
            break;
          case "lastScanActivity":
            state.lastScanActivity = widgetData;
            state.lastScanActivityLoading = false;
            break;
          case "uptime":
            state.uptime = widgetData;
            state.uptimeLoading = false;
            break;
        }
      })
      .addCase(getS3WidgetAsync.rejected, (state, { payload, meta }) => {
        switch (meta.arg.key) {
          case "capacity":
            state.capacityLoading = false;
            break;
          case "network":
            state.networkLoading = false;
            break;
          case "dataUsage":
            state.dataUsageLoading = false;
            break;
          case "objectSize":
            state.objectSizeLoading = false;
            break;
          case "dataReceived":
            state.dataReceivedLoading = false;
            break;
          case "dataSent":
            state.dataSentLoading = false;
            break;
          case "apiRequest":
            state.apiRequestLoading = false;
            break;
          case "apiRequestError":
            state.apiRequestErrorLoading = false;
            break;
          case "internodeData":
            state.internodeDataLoading = false;
            break;
          case "nodeIo":
            state.nodeIoLoading = false;
            break;
          case "nodeMemory":
            state.nodeMemoryLoading = false;
            break;
          case "nodeCPU":
            state.nodeCUPLoading = false;
            break;
          case "drivesFree":
            state.drivesFreeLoading = false;
            break;
          case "driveUsed":
            state.driveUsedLoading = false;
            break;
          case "nodeSyscalls":
            state.nodeSyscallsLoading = false;
            break;
          case "nodeFile":
            state.nodeFileLoading = false;
            break;
          case "lastHealActivity":
            state.lastHealActivityLoading = false;
            break;
          case "lastScanActivity":
            state.lastScanActivityLoading = false;
            break;
          case "uptime":
            state.uptimeLoading = false;
            break;
        }
        // if (payload?.message)
        toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectS3StartTime = (state: RootState) =>
  state.s3Overview.startTime;
export const selectS3EndTime = (state: RootState) => state.s3Overview.endTime;

export const selectS3Metrics = (state: RootState) => state.s3Overview.metrics;
export const selectS3MetricsLoading = (state: RootState) =>
  state.s3Overview.loading;

//capacity
export const selectCapacity = (state: RootState) => state.s3Overview.capacity;
export const selectCapacityLoading = (state: RootState) =>
  state.s3Overview.capacityLoading;

//network
export const selectNetwork = (state: RootState) => state.s3Overview.network;
export const selectNetworkLoading = (state: RootState) =>
  state.s3Overview.networkLoading;

//data usage
export const selectDataUsage = (state: RootState) => state.s3Overview.dataUsage;
export const selectDataUsageLoading = (state: RootState) =>
  state.s3Overview.dataUsageLoading;

//object size
export const selectObjectSize = (state: RootState) =>
  state.s3Overview.objectSize;
export const selectObjectSizeLoading = (state: RootState) =>
  state.s3Overview.objectSizeLoading;

//data received
export const selectDataReceived = (state: RootState) =>
  state.s3Overview.dataReceived;
export const selectDataReceivedLoading = (state: RootState) =>
  state.s3Overview.dataReceivedLoading;

//data sent
export const selectDataSent = (state: RootState) => state.s3Overview.dataSent;
export const selectDataSentLoading = (state: RootState) =>
  state.s3Overview.dataSentLoading;

//api request
export const selectApiRequest = (state: RootState) =>
  state.s3Overview.apiRequest;
export const selectApiRequestLoading = (state: RootState) =>
  state.s3Overview.apiRequestLoading;

//api request error
export const selectApiRequestError = (state: RootState) =>
  state.s3Overview.apiRequestError;
export const selectApiRequestErrorLoading = (state: RootState) =>
  state.s3Overview.apiRequestErrorLoading;

//internode data transfer
export const selectInternodeData = (state: RootState) =>
  state.s3Overview.internodeData;
export const selectInternodeDataLoading = (state: RootState) =>
  state.s3Overview.internodeDataLoading;

//node io
export const selectNodeIo = (state: RootState) => state.s3Overview.nodeIo;
export const selectNodeIoLoading = (state: RootState) =>
  state.s3Overview.nodeIoLoading;

//node memory
export const selectNodeMemory = (state: RootState) =>
  state.s3Overview.nodeMemory;
export const selectNodeMemoryLoading = (state: RootState) =>
  state.s3Overview.nodeMemoryLoading;

//node cpu
export const selectNodeCPU = (state: RootState) => state.s3Overview.nodeCPU;
export const selectNodeCpuLoading = (state: RootState) =>
  state.s3Overview.nodeCUPLoading;

//drives free
export const selectDrivesFree = (state: RootState) =>
  state.s3Overview.drivesFree;
export const selectDrivesFreeLoading = (state: RootState) =>
  state.s3Overview.drivesFreeLoading;

//drive used
export const selectDriveUsed = (state: RootState) => state.s3Overview.driveUsed;
export const selectDriveUsedLoading = (state: RootState) =>
  state.s3Overview.driveUsedLoading;

//node syscalls
export const selectNodeSyscalls = (state: RootState) =>
  state.s3Overview.nodeSyscalls;
export const selectNodeSyscallsLoading = (state: RootState) =>
  state.s3Overview.nodeSyscallsLoading;

//node file
export const selectNodeFile = (state: RootState) => state.s3Overview.nodeFile;
export const selectNodeFileLoading = (state: RootState) =>
  state.s3Overview.nodeFileLoading;

//last heal activity
export const selectLastHealActivity = (state: RootState) =>
  state.s3Overview.lastHealActivity;
export const selectLastHealActivityLoading = (state: RootState) =>
  state.s3Overview.lastHealActivityLoading;

//last Scan Activity
export const selectLastScanActivity = (state: RootState) =>
  state.s3Overview.lastScanActivity;
export const selectLastScanActivityLoading = (state: RootState) =>
  state.s3Overview.lastScanActivityLoading;

//uptime
export const selectUptime = (state: RootState) => state.s3Overview.uptime;
export const selectUptimeLoading = (state: RootState) =>
  state.s3Overview.uptimeLoading;

export const { handleSetTimes } = overviewSlice.actions;
export default overviewSlice.reducer;
