import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { IWorkflowApiKey, WorkflowsApiKeysState } from "../../types/workflows";
import {
  createWorkflowApiKeyApi,
  deleteWorkflowsApiKeyApi,
  getWorkflowsApiKeysApi,
  updateWorkflowApiKeyApi,
} from "../../apis/workflowsAPI";
import { changeWorkflowActiveStatus } from "./workflowsSlice";

const initialState: WorkflowsApiKeysState = {
  apiKeys: [],
  loading: false,
  actionLoading: false,

  showEditorModal: false,
  selectedApiKey: null,
};

export const getWorkflowsApiKeysAsync = createAsyncThunk<
  { apiKeys: Array<IWorkflowApiKey> },
  undefined,
  IThunkRejectValue
>(
  "workflows/api-keys",
  async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getWorkflowsApiKeysApi();
      const { NodeStatus, Result: apiKeys } = response.data;

      dispatch(changeWorkflowActiveStatus(NodeStatus === 2));
      return fulfillWithValue({ apiKeys: apiKeys || [] });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createWorkflowApiKeyAsync = createAsyncThunk<
  boolean,
  string,
  IThunkRejectValue
>("workflows/api-keys/create", async (keyName, { rejectWithValue }) => {
  try {
    await createWorkflowApiKeyApi(keyName);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateWorkflowApiKeyAsync = createAsyncThunk<
  boolean,
  { id: string; keyName: string },
  IThunkRejectValue
>("workflows/api-keys/update", async ({ id, keyName }, { rejectWithValue }) => {
  try {
    await updateWorkflowApiKeyApi(id, keyName);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteWorkflowsApiKeyAsync = createAsyncThunk<
  boolean,
  { id: string },
  IThunkRejectValue
>("workflows/api-keys/delete", async ({ id }, { rejectWithValue }) => {
  try {
    await deleteWorkflowsApiKeyApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {
    handleShowEditorModal: (
      state,
      action: PayloadAction<{ apiKey?: IWorkflowApiKey }>
    ) => {
      state.showEditorModal = true;
      state.selectedApiKey = action.payload.apiKey || null;
    },
    handleHideEditorModal: (state) => {
      state.showEditorModal = false;
      state.selectedApiKey = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflowsApiKeysAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWorkflowsApiKeysAsync.fulfilled, (state, action) => {
        const { apiKeys } = action.payload;
        state.apiKeys = apiKeys;
        state.loading = false;
      })
      .addCase(getWorkflowsApiKeysAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createWorkflowApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createWorkflowApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createWorkflowApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateWorkflowApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateWorkflowApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateWorkflowApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWorkflowsApiKeyAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWorkflowsApiKeyAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteWorkflowsApiKeyAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWorkflowsApiKeys = (state: RootState) =>
  state.workflowsApiKeys.apiKeys;
export const selectWorkflowsApiKeysLoading = (state: RootState) =>
  state.workflowsApiKeys.loading;

export const selectWorkflowsApiKeysActionLoading = (state: RootState) =>
  state.workflowsApiKeys.actionLoading;

export const selectShowEditorModal = (state: RootState) =>
  state.workflowsApiKeys.showEditorModal;
export const selectSelectedWorkflowApiKey = (state: RootState) =>
  state.workflowsApiKeys.selectedApiKey;

export const { handleShowEditorModal, handleHideEditorModal } =
  apiKeysSlice.actions;
export default apiKeysSlice.reducer;
