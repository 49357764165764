import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectS3Events,
  deleteS3EventAsync,
  getS3EventsAsync,
  selectActionLoading,
  selectLoading,
} from "../../../store/s3/eventsSlice";
import S3EventEditorModal from "./forms/S3EventEditorModal";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { S3NotActiveTab } from "../../layouts/NotData";
import { DeleteModal } from "../../modals/QuestionModal";
import { useNavigate } from "react-router-dom";
import { S3EventAddUrl } from "../../../utils/urls";
import { S3Event } from "../../../types/s3-events";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";
import useGetS3Events from "../../../hooks/useGetS3Events";
import {
  Button,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";

const S3EventsTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const events = useAppSelector(selectS3Events);
  const loading = useAppSelector(selectLoading);
  const actionLoading = useAppSelector(selectActionLoading);
  const [deleteEvent, setDeleteEvent] = useState<S3Event | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getS3Events = useGetS3Events();

  useEffect(() => {
    if (events.length === 0) {
      dispatch(getS3EventsAsync());
    }
  }, [events.length, dispatch]);

  const handleDelete = () => {
    if (deleteEvent) {
      dispatch(
        deleteS3EventAsync({
          event: deleteEvent.service,
          identifier: deleteEvent.account_id,
        })
      ).then((action) => {
        if (action.type === "s3-events/delete/fulfilled") {
          getS3Events();
        }
        setDeleteEvent(null);
      });
    }
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            Events
          </Typography.Title>
        </div>
        <div className="flex items-center gap-2">
          <Button
            uiType="primary"
            onClick={() => navigate(S3EventAddUrl)}
            className="group"
          >
            Create Event Target
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="Service" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {events.map((event, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <div className="flex items-center flex-row justify-between space-x-4">
                    <Typography.Text className="!text-xs md:!text-sm">
                      {event.status}
                    </Typography.Text>
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <div className="flex items-center flex-row justify-between space-x-4">
                    <Typography.Text className="!text-xs md:!text-sm">
                      {event.service} : {event.account_id}
                    </Typography.Text>
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-20 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-end items-center">
                      <Dropdown
                        itemsClassName="!w-40"
                        anchor="bottom end"
                        menu={[
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteEvent(event);
                            },
                          },
                        ]}
                      >
                        <Button uiType="icon" uiSize="small" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {events.length === 0 && (
              <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                <SimpleTable.TD colSpan={3} className="!border-0">
                  <EmptyState text="You have not created any Event yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <S3EventEditorModal />
      <DeleteModal
        title="Delete Event"
        isOpen={deleteEvent !== null}
        onClose={() => setDeleteEvent(null)}
        onConfirm={handleDelete}
        loading={actionLoading}
        confirmString={
          events.find((event) => event.account_id === deleteEvent?.account_id)
            ?.account_id
        }
        confirmButtonType="danger"
      />
    </>
  );
};

export default S3EventsTab;
