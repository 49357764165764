import { Link } from "react-router-dom";

import { Divider, Flex, Typography, useDjunoDesign } from "djuno-design";
import { ReactComponent as Logo } from "./../../assets/icons/logo-text.svg";
import { ReactComponent as OvhColorLogo } from "./../../assets/icons/ovh/ovh-color.svg";
import { ReactComponent as OvhWhiteLogo } from "./../../assets/icons/ovh/ovh-white.svg";
import { ReactComponent as ArrowRight } from "./../../assets/icons/arrow-right.svg";
import { AboutUsUrl } from "../../utils/urls";

import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaGithubSquare } from "react-icons/fa";
import { FaDocker } from "react-icons/fa";
import Container from "../layouts/Container";

const WebFooter = () => {
  const blogUrl = process.env.REACT_APP_BLOGS_URL || "";
  const docsUrl = process.env.REACT_APP_DOCS_URL || "";
  const { theme } = useDjunoDesign();

  return (
    <footer className="pt-16 bg-primary-50 dark:bg-primary-700">
      <Container>
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-5 px-6 md:px-4">
            <Flex
              direction="col"
              items={{ default: "center", lg: "start" }}
              className="gap-2"
            >
              <Logo className="w-24 text-slate-800 dark:text-slate-200" />
              <Typography.Text size="sm" className="text-center lg:text-start">
                Djuno is your strategic partner on digital transformation, cloud
                and web3 adoption
              </Typography.Text>
              {theme.mode === "dark" ? (
                <OvhWhiteLogo className="w-32" />
              ) : (
                <OvhColorLogo className="w-20" />
              )}
            </Flex>
          </div>
          <div className="col-span-6 md:col-span-4 lg:col-span-2 px-6 md:px-4 mt-5 lg:mt-0">
            <Flex direction="col" className="w-full">
              <FooterLink to="/#products">Products</FooterLink>
              <FooterLink to={docsUrl + "/en/collections/10816823-pricing"}>
                Pricing
              </FooterLink>
              <FooterLink to="/#use-cases">Use-cases</FooterLink>
              <FooterLink to={blogUrl} target="_blank">
                Blogs
              </FooterLink>
              <FooterLink to={docsUrl} target="_blank">
                Docs
              </FooterLink>
              <FooterLink to={AboutUsUrl}>About us</FooterLink>
            </Flex>
          </div>
          <div className="col-span-6 md:col-span-4 lg:col-span-2 px-6 md:px-4 mt-5 lg:mt-0">
            <Flex direction="col" className="w-full">
              <FooterLink to={blogUrl + "/privacy-notice"} target="_blank">
                Privacy Policy
              </FooterLink>
              <FooterLink to={blogUrl + "/term-of-use"} target="_blank">
                Terms of Use
              </FooterLink>
            </Flex>
          </div>
          <div className="col-span-12 md:col-span-4 lg:col-span-3 px-6 md:px-4 mt-5 lg:mt-0">
            <Flex direction="col" className="w-full gap-1.5">
              <Typography.Text size="sm">Contact Us:</Typography.Text>
              <FooterText>Djuno GmbH</FooterText>
              <FooterText>Chaltenbodenstrasse 6F</FooterText>
              <FooterText>Schindellegi 8834 Schwyz</FooterText>
              <FooterText>Switzerland</FooterText>
              <FooterText>Phone: +41 795663797</FooterText>
              <FooterText>
                E-Mail:{" "}
                <a
                  className="!text-primary-400 hover:!text-primary-500"
                  href={`mailto:${process.env.REACT_APP_SALES_EMAIL}`}
                >
                  {process.env.REACT_APP_SALES_EMAIL}
                </a>
              </FooterText>

              <Flex className="gap-2.5 mt-1">
                <a
                  href="https://twitter.com/realDjuno"
                  target="_blank"
                  rel="noreferrer"
                  className="group"
                >
                  <FaTwitterSquare className="!text-slate-500 group-hover:!text-primary-500 dark:!text-slate-300 dark:group-hover:!text-primary-500 transition-all duration-100" />
                </a>
                <a
                  href="https://www.linkedin.com/company/djuno/"
                  target="_blank"
                  rel="noreferrer"
                  className="group"
                >
                  <FaLinkedin className="!text-slate-500 group-hover:!text-primary-500 dark:!text-slate-300 dark:group-hover:!text-primary-500 transition-all duration-100" />
                </a>
                <a
                  href="https://github.com/Djuno-Ltd"
                  target="_blank"
                  rel="noreferrer"
                  className="group"
                >
                  <FaGithubSquare className="!text-slate-500 group-hover:!text-primary-500 dark:!text-slate-300 dark:group-hover:!text-primary-500 transition-all duration-100" />
                </a>
                <a
                  href="https://hub.docker.com/u/djunoltd"
                  target="_blank"
                  rel="noreferrer"
                  className="group"
                >
                  <FaDocker className="!text-slate-500 group-hover:!text-primary-500 dark:!text-slate-300 dark:group-hover:!text-primary-500 transition-all duration-100" />
                </a>
              </Flex>
            </Flex>
          </div>
        </div>
        <Divider className="!my-0" />
        <Flex items="center" justify="center" className="mb-4">
          <Typography.Text size="sm">
            © 2024{" "}
            <Typography.Text size="sm" className="!text-primary-400">
              Djuno.
            </Typography.Text>{" "}
            All Rights Reserved.
          </Typography.Text>
        </Flex>
      </Container>
    </footer>
  );
};

const FooterLink: React.FC<
  React.PropsWithChildren<{
    to: string;
    target?: React.HTMLAttributeAnchorTarget | undefined;
  }>
> = ({ to, children, target }) => {
  return (
    <Link
      to={to}
      target={target}
      className="w-full group flex items-center gap-2 py-1"
    >
      <ArrowRight className="w-3 h-3 group-hover:translate-x-0.5 transition-all duration-300 dark:text-slate-200" />
      <FooterText>{children}</FooterText>
    </Link>
  );
};

const FooterText: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Typography.Text
      size="sm"
      className="!text-slate-500 group-hover:!text-slate-800 dark:!text-slate-300 dark:group-hover:!text-slate-100 transition-all duration-100"
    >
      {children}
    </Typography.Text>
  );
};
export default WebFooter;
