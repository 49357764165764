import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import { NftApiLog, NftApiOverviewType } from "../../types/nft-api";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { getNftApiRequestLogsApi } from "../../apis/nftApiAPI";

const initialState: NftApiOverviewType = {
  logs: [],
  logsLoading: false,
};

export const getNftApiRequestLogsAysnc = createAsyncThunk<
  {
    logs: Array<NftApiLog>;
  },
  undefined,
  IThunkRejectValue
>("nft-api/request-logs", async (_, { fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await getNftApiRequestLogsApi();
    const logs = response.data.Result;
    return fulfillWithValue({ logs });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

const nftApiOverviewSlice = createSlice({
  name: "nftApiOverview",
  initialState: initialState,
  reducers: {
    clearOverviewApiKeys(state) {
      state.logs = initialState.logs;
      state.logsLoading = initialState.logsLoading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNftApiRequestLogsAysnc.fulfilled, (state, action) => {
        state.logs = action.payload.logs;
        state.logsLoading = false;
      })
      .addCase(getNftApiRequestLogsAysnc.pending, (state) => {
        state.logsLoading = true;
      })
      .addCase(getNftApiRequestLogsAysnc.rejected, (state, { payload }) => {
        state.logsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectNftApiRequestLogsLoading = (state: RootState) =>
  state.nftApiApiOverview.logsLoading;
export const selectNftApiRequestLogs = (state: RootState) =>
  state.nftApiApiOverview.logs;

export const { clearOverviewApiKeys } = nftApiOverviewSlice.actions;

export default nftApiOverviewSlice.reducer;
