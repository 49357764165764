import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Instance,
  InstanceAutoBackupInfo,
  InstanceFlavor,
  InstanceLoginInformation,
  InstanceModel,
  InstanceName,
  InstanceSnapshot,
  InstanceState,
  InstanceVnc,
} from "../../types/instance";
import { IThunkRejectValue, RootState } from "../../types";
import {
  applicationAccessInstanceApi,
  bootInstanceApi,
  getInstanceApi,
  getInstanceSnapshotApi,
  getInstanceSnapshotDetailsApi,
  getInstanceVncApi,
  getInstancesOneFlavorApi,
  instancesAttachVolumeApi,
  reactiveInstanceApi,
  rebootInstanceApi,
  reinstallInstanceImageApi,
  rescueModeInstanceApi,
  stopInstanceApi,
  suspendInstanceApi,
  updateInstanceModelApi,
  updateInstanceNameApi,
  updateInstanceMonthlyBillingApi,
  getInstanceAutoBackupInfoApi,
  enableInstanceAutoBackupApi,
  disableInstanceAutoBackupApi,
  createInstanceManualBackupApi,
  deleteInstanceSnapshotApi,
} from "../../apis/instancesAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import toast from "react-hot-toast";

const initialState: InstanceState = {
  instance: null,
  instanceLoading: false,

  instanceActionloading: false, //attach

  instanceFlavor: null,
  instanceFlavorLoading: false,

  instanceVnc: null,
  instanceVncLoading: false,

  instanceShowStopModal: false,
  instanceShowRebootModal: false,
  instanceShowHotModal: false,
  instanceShowColdModal: false,
  instanceShowSuspendModal: false,
  instanceShowReinstallModal: false,
  instanceShowRescueModal: false,
  instanceShowBootModal: false,
  instanceShowReactiveModal: false,
  instanceShowNameModal: false,

  //backups
  instanceShowManualBackupModal: false,
  instanceAutoBackupInfo: null,
  instanceAutoBackupInfoLoading: false,
  instanceAutoBackupActionLoading: false,
  instanceSnapshots: [],
  instanceSnapshotsloading: false,
  instanceSnapshotsActionloading: false,

  instanceShowVolumeModal: false,
  instanceShowApplicationModal: false,

  loginInformation: null,
  loginInformationLoading: false,

  instanceSnapshot: null, //for making instance from backup
  instanceSnapshotloading: false,
};

export const getInstanceAsync = createAsyncThunk<
  { instance: Instance },
  { instanceId: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instance",
  async (
    { instanceId, withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getInstanceAsync.pending(requestId, {
          instanceId,
          withoutLoading,
        })
      );

      const response = await getInstanceApi(instanceId);
      const instance = response.data.Result;

      return fulfillWithValue({ instance });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstanceOneFlavorAsync = createAsyncThunk<
  { instanceFlavor: InstanceFlavor },
  { flavorId: string },
  IThunkRejectValue
>(
  "instance/flavor",
  async ({ flavorId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstancesOneFlavorApi(flavorId);
      const instanceFlavor = response.data.Result;
      return fulfillWithValue({ instanceFlavor });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstanceVncAsync = createAsyncThunk<
  { vnc: InstanceVnc },
  { instanceId: string },
  IThunkRejectValue
>(
  "instance/vnc",
  async ({ instanceId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceVncApi(instanceId);
      const vnc = response.data.Result;
      return fulfillWithValue({ vnc });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateInstanceNameAsync = createAsyncThunk<
  any,
  { instanceId: string; data: InstanceName },
  IThunkRejectValue
>("instance/update", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await updateInstanceNameApi(instanceId, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

//Rescale
export const updateInstanceModelAsync = createAsyncThunk<
  any,
  { instanceId: string; data: InstanceModel },
  IThunkRejectValue
>("instance/resize", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await updateInstanceModelApi(instanceId, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateInstanceMonthlyBillingAsync = createAsyncThunk<
  any,
  { instanceId: string },
  IThunkRejectValue
>(
  "instance/update-billing-type",
  async ({ instanceId }, { rejectWithValue }) => {
    try {
      const response = await updateInstanceMonthlyBillingApi(instanceId);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const reinstallInstanceImageAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/reinstall", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await reinstallInstanceImageApi(instanceId, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const stopInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/stop", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await stopInstanceApi(instanceId, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const rescueModeInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/rescue", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await rescueModeInstanceApi(instanceId, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const rebootInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/reboot", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await rebootInstanceApi(instanceId, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const suspendInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/shelve", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await suspendInstanceApi(instanceId, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const bootInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/boot", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await bootInstanceApi(instanceId, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const reactiveInstanceAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>("instance/unshelve", async ({ instanceId, data }, { rejectWithValue }) => {
  try {
    const response = await reactiveInstanceApi(instanceId, data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

// --------------------------snapshots & backups------------------------------
export const createInstanceManualBackupAsync = createAsyncThunk<
  any,
  { instanceId: string; data: any },
  IThunkRejectValue
>(
  "instance/snapshot/manual-backup",
  async ({ instanceId, data }, { rejectWithValue }) => {
    try {
      const response = await createInstanceManualBackupApi(instanceId, data);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteInstanceSnapshotAsync = createAsyncThunk<
  true,
  { instanceId: string; imageId: string },
  IThunkRejectValue
>(
  "instance/snapshot/delete",
  async ({ instanceId, imageId }, { rejectWithValue }) => {
    try {
      await deleteInstanceSnapshotApi(instanceId, imageId);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstanceAutoBackupInfoAsync = createAsyncThunk<
  { autoBackupInfo: InstanceAutoBackupInfo },
  { instanceId: string; regionName: string },
  IThunkRejectValue
>(
  "instance/auto-backup",
  async ({ instanceId, regionName }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceAutoBackupInfoApi(
        instanceId,
        regionName
      );
      const autoBackupInfo = response.data.Result;
      return fulfillWithValue({ autoBackupInfo });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const enableInstanceAutoBackupAsync = createAsyncThunk<
  boolean,
  { regionName: string; data: any },
  IThunkRejectValue
>(
  "instance/auto-backup/enable",
  async ({ regionName, data }, { rejectWithValue }) => {
    try {
      await enableInstanceAutoBackupApi(regionName, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const disableInstanceAutoBackupAsync = createAsyncThunk<
  true,
  { regionName: string; backupWorkflowId: string; instanceId: string },
  IThunkRejectValue
>(
  "instance/auto-backup/disable",
  async ({ regionName, backupWorkflowId, instanceId }, { rejectWithValue }) => {
    try {
      await disableInstanceAutoBackupApi(
        regionName,
        backupWorkflowId,
        instanceId
      );
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstanceSnapshotsAsync = createAsyncThunk<
  { snapshots: InstanceSnapshot[] },
  { instanceId: string },
  IThunkRejectValue
>(
  "instances/snapshot",
  async ({ instanceId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceSnapshotApi(instanceId);
      const snapshotsObj = response.data.Result;
      const snapshots: InstanceSnapshot[] = Object.values(snapshotsObj);
      return fulfillWithValue({ snapshots });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstanceSnapshotDtailsAsync = createAsyncThunk<
  { snapshot: InstanceSnapshot },
  { instanceId: string; imageId: string },
  IThunkRejectValue
>(
  "instances/snapshot/details",
  async ({ instanceId, imageId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceSnapshotDetailsApi(instanceId, imageId);
      const snapshot = response.data.Result;
      return fulfillWithValue({ snapshot });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

// --------------------------------------------------------

export const instancesAttachVolumeAsync = createAsyncThunk<
  boolean,
  { volumeId: string; data: any },
  IThunkRejectValue
>("instance/volume/attach", async ({ volumeId, data }, { rejectWithValue }) => {
  try {
    await instancesAttachVolumeApi(volumeId, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const applicationAccessInstanceAsync = createAsyncThunk<
  { loginInformation: InstanceLoginInformation },
  { instanceId: string; data: any },
  IThunkRejectValue
>(
  "instance/applicationAccess",
  async ({ instanceId, data }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await applicationAccessInstanceApi(instanceId, data);
      const loginInformation = response.data.Result;
      return fulfillWithValue({ loginInformation });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const instanceSlice = createSlice({
  name: "instance",
  initialState,
  reducers: {
    handleClearInstance: (state) => {
      state.instance = null;
      //
    },
    handleClearInstanceSnapshot: (state) => {
      state.instanceSnapshot = null;
    },
    handleToggleInstanceRunManualBackupModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowManualBackupModal = action.payload || false;
    },
    handleToggleInstanceStopModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowStopModal = action.payload || false;
    },
    handleToggleInstanceRebootModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowRebootModal = action.payload || false;
    },
    handleToggleInstanceHotModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowHotModal = action.payload || false;
    },
    handleToggleInstanceColdModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowColdModal = action.payload || false;
    },
    handleToggleInstanceSuspendModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowSuspendModal = action.payload || false;
    },
    handleToggleInstanceReinstallModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowReinstallModal = action.payload || false;
    },
    handleToggleInstanceRescueModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowRescueModal = action.payload || false;
    },
    handleToggleInstanceBootModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowBootModal = action.payload || false;
    },
    handleToggleInstanceReactiveModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowReactiveModal = action.payload || false;
    },
    handleToggleInstanceNameModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowNameModal = action.payload || false;
    },
    handleToggleInstanceVolumeModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowVolumeModal = action.payload || false;
    },
    handleToggleInstanceApplicationLoginModal: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.instanceShowApplicationModal = action.payload || false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstanceAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.instanceLoading = true;
      })
      .addCase(getInstanceAsync.fulfilled, (state, action) => {
        const { instance } = action.payload;
        state.instance = instance;
        state.instanceLoading = false;
      })
      .addCase(getInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getInstanceOneFlavorAsync.pending, (state) => {
        state.instanceFlavorLoading = true;
      })
      .addCase(getInstanceOneFlavorAsync.fulfilled, (state, action) => {
        const { instanceFlavor } = action.payload;
        state.instanceFlavor = instanceFlavor;
        state.instanceFlavorLoading = false;
      })
      .addCase(getInstanceOneFlavorAsync.rejected, (state, { payload }) => {
        state.instanceFlavorLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getInstanceVncAsync.pending, (state) => {
        state.instanceVncLoading = true;
      })
      .addCase(getInstanceVncAsync.fulfilled, (state, action) => {
        const { vnc } = action.payload;
        state.instanceVnc = vnc;
        state.instanceVncLoading = false;
      })
      .addCase(getInstanceVncAsync.rejected, (state, { payload }) => {
        state.instanceVncLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(updateInstanceNameAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(updateInstanceNameAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(updateInstanceNameAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(updateInstanceModelAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(updateInstanceModelAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(updateInstanceModelAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateInstanceMonthlyBillingAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(updateInstanceMonthlyBillingAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(
        updateInstanceMonthlyBillingAsync.rejected,
        (state, { payload }) => {
          state.instanceLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(reinstallInstanceImageAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(reinstallInstanceImageAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(reinstallInstanceImageAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(stopInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(stopInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(stopInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(rescueModeInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(rescueModeInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(rescueModeInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(rebootInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(rebootInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(rebootInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(suspendInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(suspendInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(suspendInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(bootInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(bootInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(bootInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(reactiveInstanceAsync.pending, (state) => {
        state.instanceLoading = true;
      })
      .addCase(reactiveInstanceAsync.fulfilled, (state) => {
        state.instanceLoading = false;
      })
      .addCase(reactiveInstanceAsync.rejected, (state, { payload }) => {
        state.instanceLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(instancesAttachVolumeAsync.pending, (state) => {
        state.instanceActionloading = true;
      })
      .addCase(instancesAttachVolumeAsync.fulfilled, (state) => {
        state.instanceActionloading = false;
      })
      .addCase(instancesAttachVolumeAsync.rejected, (state, { payload }) => {
        state.instanceActionloading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(applicationAccessInstanceAsync.pending, (state) => {
        state.loginInformationLoading = true;
      })
      .addCase(applicationAccessInstanceAsync.fulfilled, (state, action) => {
        const { loginInformation } = action.payload;
        state.loginInformation = loginInformation;
        state.loginInformationLoading = false;
      })
      .addCase(
        applicationAccessInstanceAsync.rejected,
        (state, { payload }) => {
          state.loginInformationLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getInstanceSnapshotsAsync.pending, (state) => {
        state.instanceSnapshotsloading = true;
      })
      .addCase(getInstanceSnapshotsAsync.fulfilled, (state, action) => {
        const { snapshots } = action.payload;
        state.instanceSnapshots = snapshots;
        state.instanceSnapshotsloading = false;
      })
      .addCase(getInstanceSnapshotsAsync.rejected, (state, { payload }) => {
        state.instanceSnapshotsloading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createInstanceManualBackupAsync.pending, (state) => {
        state.instanceSnapshotsActionloading = true;
      })
      .addCase(createInstanceManualBackupAsync.fulfilled, (state) => {
        state.instanceSnapshotsActionloading = false;
      })
      .addCase(
        createInstanceManualBackupAsync.rejected,
        (state, { payload }) => {
          state.instanceSnapshotsActionloading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getInstanceSnapshotDtailsAsync.pending, (state) => {
        state.instanceSnapshotloading = true;
      })
      .addCase(getInstanceSnapshotDtailsAsync.fulfilled, (state, action) => {
        const { snapshot } = action.payload;
        state.instanceSnapshot = snapshot;
        state.instanceSnapshotloading = false;
      })
      .addCase(
        getInstanceSnapshotDtailsAsync.rejected,
        (state, { payload }) => {
          state.instanceSnapshotloading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(deleteInstanceSnapshotAsync.pending, (state) => {
        state.instanceSnapshotsActionloading = true;
      })
      .addCase(deleteInstanceSnapshotAsync.fulfilled, (state) => {
        state.instanceSnapshotsActionloading = false;
      })
      .addCase(deleteInstanceSnapshotAsync.rejected, (state, { payload }) => {
        state.instanceSnapshotsActionloading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getInstanceAutoBackupInfoAsync.pending, (state) => {
        state.instanceAutoBackupInfoLoading = true;
      })
      .addCase(getInstanceAutoBackupInfoAsync.fulfilled, (state, action) => {
        state.instanceAutoBackupInfo = action.payload.autoBackupInfo;
        state.instanceAutoBackupInfoLoading = false;
      })
      .addCase(
        getInstanceAutoBackupInfoAsync.rejected,
        (state, { payload }) => {
          state.instanceAutoBackupInfoLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(enableInstanceAutoBackupAsync.pending, (state) => {
        state.instanceAutoBackupActionLoading = true;
      })
      .addCase(enableInstanceAutoBackupAsync.fulfilled, (state) => {
        state.instanceAutoBackupActionLoading = false;
      })
      .addCase(enableInstanceAutoBackupAsync.rejected, (state, { payload }) => {
        state.instanceAutoBackupActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(disableInstanceAutoBackupAsync.pending, (state) => {
        state.instanceAutoBackupActionLoading = true;
      })
      .addCase(disableInstanceAutoBackupAsync.fulfilled, (state) => {
        state.instanceAutoBackupActionLoading = false;
        state.instanceAutoBackupInfo = null;
      })
      .addCase(
        disableInstanceAutoBackupAsync.rejected,
        (state, { payload }) => {
          state.instanceAutoBackupActionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );
  },
});

export const selectInstance = (state: RootState) => state.instance.instance;
export const selectInstanceLoading = (state: RootState) =>
  state.instance.instanceLoading;

export const selectInstanceActionLoading = (state: RootState) =>
  state.instance.instanceActionloading;

export const selectInstanceFlavor = (state: RootState) =>
  state.instance.instanceFlavor;
export const selectInstanceFlavorLoading = (state: RootState) =>
  state.instance.instanceFlavorLoading;

export const selectInstanceVnc = (state: RootState) =>
  state.instance.instanceVnc;
export const selectInstanceVncLoading = (state: RootState) =>
  state.instance.instanceVncLoading;

export const selectShowStopModal = (state: RootState) =>
  state.instance.instanceShowStopModal;
export const selectShowRebootModal = (state: RootState) =>
  state.instance.instanceShowRebootModal;
export const selectShowHotModal = (state: RootState) =>
  state.instance.instanceShowHotModal;
export const selectShowColdModal = (state: RootState) =>
  state.instance.instanceShowColdModal;
export const selectShowSuspendModal = (state: RootState) =>
  state.instance.instanceShowSuspendModal;
export const selectShowReinstallModal = (state: RootState) =>
  state.instance.instanceShowReinstallModal;
export const selectShowRescueModal = (state: RootState) =>
  state.instance.instanceShowRescueModal;
export const selectShowBootModal = (state: RootState) =>
  state.instance.instanceShowBootModal;
export const selectShowReactiveModal = (state: RootState) =>
  state.instance.instanceShowReactiveModal;
export const selectShowNameModal = (state: RootState) =>
  state.instance.instanceShowNameModal;
export const selectShowVolumeModal = (state: RootState) =>
  state.instance.instanceShowVolumeModal;
export const selectShowApplicationLoginModal = (state: RootState) =>
  state.instance.instanceShowApplicationModal;

//backups
export const selectInstanceShowManualBackupModal = (state: RootState) =>
  state.instance.instanceShowManualBackupModal;
export const selectInstanceAutoBackupInfo = (state: RootState) =>
  state.instance.instanceAutoBackupInfo;
export const selectInstanceAutoBackupInfoLoading = (state: RootState) =>
  state.instance.instanceAutoBackupInfoLoading;
export const selectInstanceAutoBackupActionLoading = (state: RootState) =>
  state.instance.instanceAutoBackupActionLoading;
export const selectInstanceSnapshots = (state: RootState) =>
  state.instance.instanceSnapshots;
export const selectInstanceSnapshotsLoading = (state: RootState) =>
  state.instance.instanceSnapshotsloading;
export const selectInstanceSnapshotsActionLoading = (state: RootState) =>
  state.instance.instanceSnapshotsActionloading;

export const selectInstanceLoginInformation = (state: RootState) =>
  state.instance.loginInformation;
export const selectInstanceLoginInformationLoading = (state: RootState) =>
  state.instance.loginInformationLoading;

export const selectInstanceSnapshot = (state: RootState) =>
  state.instance.instanceSnapshot;
export const selectInstanceSnapshotLoading = (state: RootState) =>
  state.instance.instanceSnapshotloading;

export const {
  handleClearInstance,
  handleClearInstanceSnapshot,
  handleToggleInstanceRunManualBackupModal,
  handleToggleInstanceStopModal,
  handleToggleInstanceRebootModal,
  handleToggleInstanceHotModal,
  handleToggleInstanceColdModal,
  handleToggleInstanceSuspendModal,
  handleToggleInstanceReinstallModal,
  handleToggleInstanceRescueModal,
  handleToggleInstanceBootModal,
  handleToggleInstanceReactiveModal,
  handleToggleInstanceNameModal,
  handleToggleInstanceVolumeModal,
  handleToggleInstanceApplicationLoginModal,
} = instanceSlice.actions;
export default instanceSlice.reducer;
