import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  reGenerateRecoveryStringAsync,
  getRecoveryStringAsync,
  handleHideRecoveryModal,
  selectCanRegenerateRecoveryString,
  selectRecoveryLoading,
  selectRecoveryString,
  selectShowRecoveryModal,
  selectCanCloseRecoveryModal,
  handleChangeCanCloseRecoveryModal,
} from "../../../../store/auth/authSlice";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import { ReactComponent as ArrowPathIcon } from "../../../../assets/icons/arrow-path.svg";
import { AnimatePresence, motion } from "framer-motion";
import {
  Button,
  Typography,
  Modal,
  Loading,
  Tooltip,
  copyToClipboard,
} from "djuno-design";

const TwoFactorRecoveryModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowRecoveryModal);
  const recoveryString = useAppSelector(selectRecoveryString);
  const recoveryLoading = useAppSelector(selectRecoveryLoading);
  const canRegenerateRecovery = useAppSelector(
    selectCanRegenerateRecoveryString
  );
  const [showRecoveryCodes, setShowRecoveryCodes] = useState(false);
  const canCloseRecoveryModal = useAppSelector(selectCanCloseRecoveryModal);

  const handleCopy = useCallback(() => {
    const recoveryStr = recoveryString?.join(" , ");
    recoveryStr &&
      copyToClipboard(recoveryStr).then(() => {
        dispatch(handleChangeCanCloseRecoveryModal(true));
      });
  }, [dispatch, recoveryString]);

  //re-generate recovery codes
  const getNewRecoveryString = useCallback(() => {
    dispatch(reGenerateRecoveryStringAsync()).then((action) => {
      if (action.type === "auth/regenerate-recovery/fulfilled") {
        dispatch(handleChangeCanCloseRecoveryModal(false));
      }
    });
  }, [dispatch]);

  //get recovery codes
  useEffect(() => {
    if (isOpen && recoveryString === null) {
      dispatch(getRecoveryStringAsync());
    }
  }, [dispatch, isOpen, recoveryString]);

  const handleCloseModal = useCallback(() => {
    dispatch(handleHideRecoveryModal());
    setShowRecoveryCodes(false);
    dispatch(handleChangeCanCloseRecoveryModal(false));
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      contentClassName="!max-w-lg"
      title="Save your backup code"
      closable={canCloseRecoveryModal}
    >
      <div className="mt-5 flex flex-col gap-4">
        <Typography.Text className="!text-sm">
          You can use backup codes to sign in to your account if you don't have
          access to your authenticato app.{" "}
          <span className="font-medium">Each code can only be used once</span>
        </Typography.Text>
        <Typography.Text className="!text-sm">
          <span className="font-medium">Treat these codes like passwords</span>
          and store them securely in a password manager.
        </Typography.Text>

        <div className="w-full rounded-md border-2 border-secondary-300 bg-secondary-50 dark:border-dark-2 dark:bg-dark-1 h-40 grid grid-cols-2 select-none items-center p-8 gap-1 relative">
          {recoveryLoading && (
            <div className="col-span-2 flex justify-center">
              <Loading borderSize={2} />
            </div>
          )}

          {!recoveryLoading && (
            <>
              <AnimatePresence>
                {!showRecoveryCodes && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="w-full h-full flex justify-center items-center bg-white/10 dark:bg-black/10 backdrop-blur-sm absolute top-0 bottom-0 left-0 right-0"
                  >
                    <Tooltip content="Click to show">
                      <EyeIcon
                        onClick={() => setShowRecoveryCodes(true)}
                        className="w-7 hover:scale-105 transition-all duration-300 cursor-pointer text-secondary-800 dark:text-secondary-300"
                      />
                    </Tooltip>
                  </motion.div>
                )}
              </AnimatePresence>
              {recoveryString?.map((item, i) => (
                <Typography.Text
                  key={i}
                  uiType="secondary"
                  className="!col-span-1 !text-sm !text-center"
                >
                  {item}
                </Typography.Text>
              ))}
            </>
          )}
        </div>

        <div className="flex flex-col md:flex-row md:justify-between gap-3 w-full">
          <Button onClick={handleCopy} className="!w-full">
            <Typography.Text
              uiType="transparent"
              className="w-full text-center flex justify-center"
              size="sm"
            >
              <CopyIcon className="w-4" />
              Copy to clipboard
            </Typography.Text>
          </Button>
          {canRegenerateRecovery && (
            <Button onClick={getNewRecoveryString} className="!w-full">
              <Typography.Text
                uiType="transparent"
                className="w-full text-center flex justify-center"
                size="sm"
              >
                <ArrowPathIcon className="w-4" />
                Generate New Codes
              </Typography.Text>
            </Button>
          )}
        </div>
      </div>

      <div className="mt-10 flex justify-end">
        <Button
          uiType="primary"
          disabled={recoveryLoading || !canCloseRecoveryModal}
          onClick={handleCloseModal}
        >
          I have saved my backup codes
        </Button>
      </div>
    </Modal>
  );
};

export default TwoFactorRecoveryModal;
