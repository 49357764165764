import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as GithubIcon } from "./../../../assets/icons/socials/github.svg";
import { ReactComponent as GitlabIcon } from "./../../../assets/icons/socials/gitlab.svg";
import { ReactComponent as BitbucketIcon } from "./../../../assets/icons/socials/bitbucket.svg";
import { ReactComponent as GithubColorIcon } from "./../../../assets/icons/socials/github-color.svg";
import { ReactComponent as GitlabColorIcon } from "./../../../assets/icons/socials/gitlab-color.svg";
import { ReactComponent as DockerColorIcon } from "./../../../assets/icons/socials/docker-color.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as LinkIcon } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import { Helmet } from "react-helmet";
import {
  getCredentialsAsync,
  openAddCredentialModal,
  removeCredentialAsync,
  selectCredentialRemoveLoading,
  selectCredentials,
  selectCredentialsLoading,
} from "../../../store/settings/registeries/registeriesSlice";
import AddCredentialModal from "./AddCredentialModal";
import QuestionModal from "../../modals/QuestionModal";
import { Credential } from "../../../types/registeries";
import { timeAgo } from "../../../utils/date";
import { useSearchParams } from "react-router-dom";
import {
  getRepositoriesAccountsAsync,
  selectGithubReposData,
  selectGithubReposLoading,
  selectRepoAccounts,
  selectRepoAccountsLoading,
  setGithubReposData,
  setGithubReposLoading,
  setInstallationData,
} from "../../../store/settings/registeries/repositoriesSlice";
import { getGithubRepos } from "../../../apis/repositoriesAPI";
import { selectOnStageEnv } from "../../../store/auth/authSlice";
import {
  Button,
  Typography,
  SimpleTable,
  Card,
  EmptyState,
  Flex,
} from "djuno-design";

const RegisteriesTab = () => {
  const env = useAppSelector(selectOnStageEnv);

  const credentials = useAppSelector(selectCredentials);
  const credentialsLoading = useAppSelector(selectCredentialsLoading);

  const [query] = useSearchParams();
  const installation_id = query.get("installation_id");
  // const setup_action = query.get("setup_action");
  // const source = query.get("source");

  //accounts
  const repoAccounts = useAppSelector(selectRepoAccounts);
  const repoAccountsLoading = useAppSelector(selectRepoAccountsLoading);
  const githubToken = repoAccounts.GithubAccessToken;
  const githubAccount = repoAccounts.GithubAccount;

  //github data
  const githubReposData = useAppSelector(selectGithubReposData);
  const githubReposLoading = useAppSelector(selectGithubReposLoading);
  const githubHtmlUrl = useMemo(() => {
    return githubAccount
      ? githubAccount.html_url
      : githubReposData && githubReposData.repositories.length > 0
      ? githubReposData.repositories[0].owner.html_url
      : "";
  }, [githubAccount, githubReposData]);
  const githubAvatarUrl = useMemo(() => {
    return githubAccount
      ? githubAccount.avatar_url
      : githubReposData && githubReposData.repositories.length > 0
      ? githubReposData.repositories[0].owner.avatar_url
      : "";
  }, [githubAccount, githubReposData]);
  const githubLogin = useMemo(() => {
    return githubAccount
      ? githubAccount.login
      : githubReposData && githubReposData.repositories.length > 0
      ? githubReposData.repositories[0].owner.login
      : "";
  }, [githubAccount, githubReposData]);

  const dispatch = useAppDispatch();

  const [deleteCredential, setDeleteCredential] = useState<Credential>();
  const credentialRemoveLoading = useAppSelector(selectCredentialRemoveLoading);

  //get credentials
  useEffect(() => {
    dispatch(getCredentialsAsync());
  }, [dispatch]);

  const handleInstallGithub = () => {
    window.location.href =
      "https://github.com/apps/djuno-web3-0-cloud/installations/new";
  };

  useEffect(() => {
    if (installation_id) {
      dispatch(
        setInstallationData({
          installationId: installation_id,
          installationModal: true,
        })
      );
    }
  }, [dispatch, installation_id]);

  // get github repos
  useEffect(() => {
    if (!installation_id && !repoAccountsLoading && !githubReposLoading) {
      if (repoAccounts.GithubAccessToken) {
        getGithubRepos(repoAccounts.GithubAccessToken).then((value) => {
          dispatch(setGithubReposLoading(true));
          if (value?.status === 200) {
            dispatch(setGithubReposData(value.data));
          }
        });
      } else {
        dispatch(setGithubReposData(null));
      }
    }
  }, [
    dispatch,
    githubReposLoading,
    installation_id,
    repoAccounts.GithubAccessToken,
    repoAccountsLoading,
  ]);

  useEffect(() => {
    dispatch(getRepositoriesAccountsAsync());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Registeries</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="w-full flex flex-col gap-10">
        <Card
          headerClassName="w-full"
          title={
            <Flex items="center" justify="between" className="w-full gap-0.5">
              <Typography.Title level={5}>
                Container Registery Credentials
              </Typography.Title>
              <Button
                className="whitespace-nowrap"
                onClick={() => dispatch(openAddCredentialModal())}
              >
                Add Credential
              </Button>
            </Flex>
          }
          description={
            <Typography>
              <Typography.Text uiType="secondary" className="!text-sm ">
                Srore registery credentials to pull and deploy private images.
              </Typography.Text>{" "}
              <Typography.Link
                href="https://blogs.djuno.io/privacy-notice"
                target="_blank"
                className="!text-sm"
              >
                Learn more
              </Typography.Link>
            </Typography>
          }
        >
          <SimpleTable
            loading={credentialsLoading}
            containerClassName="min-h-[240px]"
          >
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH lable="Name" />
                <SimpleTable.TH lable="Registery" />
                <SimpleTable.TH lable="Created" />
                <SimpleTable.TH lable="" />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {credentials.map((credential, i) => {
                const registeryData = getRegisteryData(
                  credential.RegisteryType
                );
                return (
                  <SimpleTable.Row key={i}>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {credential.Name}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      {/* <Tooltip
                        clickable
                        className="!bg-gray-200"
                        content={registeryData.Description}
                      > */}
                      <div className="flex items-center gap-1">
                        {registeryData.Icon}
                        <Typography.Text className="!text-xs md:!text-sm">
                          {registeryData.Title}
                        </Typography.Text>
                      </div>
                      {/* </Tooltip> */}
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <Typography.Text className="!text-xs md:!text-sm">
                        {timeAgo(credential.CreatedAt)}
                      </Typography.Text>
                    </SimpleTable.TD>
                    <SimpleTable.TD>
                      <div className="flex w-full justify-end">
                        {env?.IsAccountOwner && (
                          <div
                            onClick={() => setDeleteCredential(credential)}
                            className="p-2 rounded-md dark:border-dark-2 hover:dark:bg-dark-2 hover:bg-slate-200 cursor-pointer"
                          >
                            <ArchiveIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
                          </div>
                        )}
                      </div>
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                );
              })}
              {credentials.length === 0 && (
                <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                  <SimpleTable.TD colSpan={4} className="!border-none">
                    <EmptyState />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </Card>

        <Card title="Code repository">
          <div className="flex flex-col py-5 gap-4">
            <div className="flex items-center">
              <div className="w-32">
                <Typography.Text className="!text-sm">Github</Typography.Text>
              </div>
              {githubAccount === null && githubToken === null && (
                <Button
                  loading={repoAccountsLoading || githubReposLoading}
                  onClick={handleInstallGithub}
                >
                  <GithubIcon className="w-4" />
                  <Typography.Text uiType="transparent" className="!text-sm">
                    Connect Github
                  </Typography.Text>
                </Button>
              )}
              {githubToken !== null && (
                <Typography.Link
                  href={githubHtmlUrl}
                  target="_blank"
                  className="!flex !items-center !gap-1 !text-slate-700 hover:!text-slate-900 dark:!text-slate-300 dark:hover:!text-slate-200"
                >
                  <div className="-mr- w-7 h-7 bg-gray-200 bg-gray-300/30 dark:bg-gray-500/20 rounded-full flex items-center text-center justify-center overflow-hidden">
                    <img
                      alt={githubAccount?.login}
                      className="w-full"
                      src={githubAvatarUrl}
                    />
                  </div>
                  <Typography.Text
                    className="!text-sm !font-semibold"
                    uiType="transparent"
                  >
                    {githubLogin}
                  </Typography.Text>
                  <LinkIcon className="w-4" />
                  <Typography.Text
                    uiType="secondary"
                    className="!text-xs !ml-2"
                  >
                    {githubReposData?.total_count} repo
                    {githubReposData && githubReposData.total_count > 1 && "s"}
                  </Typography.Text>
                </Typography.Link>
              )}
            </div>
            <div className="flex items-center">
              <div className="w-32">
                <Typography.Text className="!text-sm">
                  Bitbucket
                </Typography.Text>
              </div>
              <Button disabled={true}>
                <BitbucketIcon className="w-4" />
                <Typography.Text uiType="transparent" className="!text-sm">
                  Connect Bitbucket
                </Typography.Text>
              </Button>
            </div>
            <div className="flex items-center">
              <div className="w-32">
                <Typography.Text className="!text-sm">Gitlab</Typography.Text>
              </div>
              <Button disabled={true}>
                <GitlabIcon className="w-4" />
                <Typography.Text uiType="transparent" className="!text-sm">
                  Connect Gitlab
                </Typography.Text>
              </Button>
            </div>
          </div>
        </Card>
        <AddCredentialModal />
        <QuestionModal
          title="Remove Credential"
          isOpen={deleteCredential !== undefined}
          onClose={() => setDeleteCredential(undefined)}
          confirmButtonType="danger"
          onConfirm={() => {
            deleteCredential &&
              dispatch(removeCredentialAsync({ id: deleteCredential.Id })).then(
                (action) => {
                  if (
                    action.type === "registeries/credentials/remove/fulfilled"
                  ) {
                    dispatch(getCredentialsAsync());
                    setDeleteCredential(undefined);
                  }
                }
              );
          }}
          loading={credentialRemoveLoading}
        />
      </div>
    </>
  );
};

const GithubDesc = (
  <Typography.Text className="!text-sm">
    You'll need to create a personal access token with the required{" "}
    <Typography.Text className="!inline-block !text-sm" uiType="warning">
      read:packages
    </Typography.Text>{" "}
    permission in{" "}
    <Typography.Link
      target="_blank"
      href="https://github.com/settings/tokens/new?description=&scopes=read%3Apackages"
      className="!text-sm"
    >
      GitHub
    </Typography.Link>{" "}
    <Typography.Text className="!font-semibold !inline-block !text-sm">{`Settings > Developer Settings`}</Typography.Text>
  </Typography.Text>
);

const GitlabDesc = (
  <Typography.Text className="!text-sm">
    You'll need to create a personal access token with the required{" "}
    <Typography.Text className="!text-sm !inline-block" uiType="warning">
      read registery
    </Typography.Text>{" "}
    permission in{" "}
    <Typography.Link
      target="_blank"
      href="https://gitlab.com/-/profile/personal_access_tokens?name=&scopes=read_registry"
      className="!text-sm"
    >
      GitLab
    </Typography.Link>{" "}
    by navigating to{" "}
    <Typography.Text className="!font-semibold !inline-block !text-sm">{`Edit Profile > Access Tokens`}</Typography.Text>
  </Typography.Text>
);

const DockerDesc = (
  <Typography.Text className="!text-sm !gap-1">
    Enter your Docker Hub username and password. or create an access token with{" "}
    <Typography.Text className="!text-sm !inline-block" uiType="warning">
      read
    </Typography.Text>{" "}
    access permission to use in place of your password by visiting{" "}
    <Typography.Link
      target="_blank"
      href="https://hub.docker.com/settings/security?generateToken=true"
      className="!text-sm"
    >
      Docker Hub
    </Typography.Link>{" "}
    <Typography.Text className="!font-semibold !inline-block !text-sm">{`Account Settings> Security`}</Typography.Text>
  </Typography.Text>
);

export const getRegisteryData = (typeValue: number) => {
  let data = {
    Title: "Github",
    Icon: <GithubColorIcon className="w-4" />,
    Description: GithubDesc,
  };
  if (typeValue === 1) {
    data = {
      Title: "Gitlab",
      Icon: <GitlabColorIcon className="w-4" />,
      Description: GitlabDesc,
    };
  } else if (typeValue === 2) {
    data = {
      Title: "Docker",
      Icon: <DockerColorIcon className="w-4" />,
      Description: DockerDesc,
    };
  }
  return data;
};
export default RegisteriesTab;
