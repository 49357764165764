import { cn } from "djuno-design";

const ActionText = ({
  text,
  icon,
  onClick,
  type,
  className,
  loading,
}: {
  text?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  type?: "default" | "simple";
  className?: string;
  loading?: boolean;
}) => {
  return (
    <div
      onClick={() => (onClick ? onClick() : {})}
      className={cn(
        className,
        "text-xs font-semibold p-1 px-1.5 rounded-md flex items-center gap-1 justify-between transition-all duration-300",
        {
          "hover:bg-primary-100/50 hover:text-primary-500 dark:hover:bg-primary-400/20 transition-all duration-150 cursor-pointer":
            onClick,
          "bg-primary-100/30 text-primary-400 dark:bg-primary-100/10 ":
            type === undefined || type === "default",
          "bg-gray-100 text-gray-600 dark:bg-gray-400/10 dark:text-gray-300":
            type === "simple",
          "min-w-[90px] animate-pulse": loading,
        }
      )}
    >
      {text}
      {icon}
    </div>
  );
};

export default ActionText;
