import { jwtEnvAxios } from ".";
import { CreateNftApiApiKeyData } from "../types/nft-api";

const GET_NFT_API_REQUEST_LOGS_URL = `/nft-api/request-logs`;

const GET_NFT_API_API_KEYS_URL = `/nfts/api-settings`;
const DELETE_NFT_API_API_KEYS_URL = (id: number) => `/nfts/api-settings/${id}`;
const CREATE_NFT_API_API_KEYS_URL = `/nfts/api-settings`;
const UPDATE_NFT_API_API_KEYS_URL = (id: number) => `/nfts/api-settings/${id}`;

//overview
export function getNftApiRequestLogsApi() {
  return jwtEnvAxios().get(GET_NFT_API_REQUEST_LOGS_URL);
}

//api keys
export function getNftApiApiKeysApi() {
  return jwtEnvAxios().get(GET_NFT_API_API_KEYS_URL);
}

export function deleteNftApiApiKeyApi(id: number) {
  return jwtEnvAxios().delete(DELETE_NFT_API_API_KEYS_URL(id));
}

export async function createNftApiApiKeyApi(formData: CreateNftApiApiKeyData) {
  return jwtEnvAxios().post(CREATE_NFT_API_API_KEYS_URL, formData);
}

export function updateNftApiApiKeyApi(
  id: number,
  formData: CreateNftApiApiKeyData
) {
  return jwtEnvAxios().put(UPDATE_NFT_API_API_KEYS_URL(id), formData);
}
