import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { DBSUserDetailsSchema } from "../../../utils/validations";
import {
  handleDBSHideUserDetailsModal,
  selectDatabaseServiceUser,
  selectDatabaseServiceUserLoading,
  selectDatabaseUserDetails,
} from "../../../store/database/serviceSlice";
import { useEffect } from "react";
import { Input, Modal, Tag, Typography } from "djuno-design";

const UserDetailsModal = () => {
  const isOpen = useAppSelector(selectDatabaseUserDetails);
  const userLoading = useAppSelector(selectDatabaseServiceUserLoading);
  const user = useAppSelector(selectDatabaseServiceUser);
  const dispatch = useAppDispatch();

  const { register, reset, setValue } = useForm({
    resolver: yupResolver(DBSUserDetailsSchema),
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  //Name
  useEffect(() => {
    if (user) {
      setValue("username", user.username);
    }
  }, [user, setValue]);

  const handleClose = () => {
    reset();
    dispatch(handleDBSHideUserDetailsModal());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-xl "
      title="User information"
    >
      <div className="w-full mt-5">
        <div>
          <Typography.Text className="@font-medium">
            Instance name
          </Typography.Text>
          <Input {...register("username")} disabled />
        </div>
      </div>
      <div className="flex flex-col my-2 gap-5">
        <div className="flex justify-between gap-5">
          <div className="w-1/2 p-2 border border-secondary-300 rounded-lg">
            <Typography.Text className="!text-sm font-medium">
              Keys
            </Typography.Text>

            {user?.keys?.map((k, index) => (
              <Tag key={index} color="processing" className="ml-1">
                {k}
              </Tag>
            ))}
          </div>
          <div className="w-1/2 p-2 border border-secondary-300 rounded-lg">
            <Typography.Text className="!text-sm font-medium">
              Categories
            </Typography.Text>

            {user?.categories?.map((c, index) => (
              <Tag key={index} color="processing" className="ml-1">
                {c}
              </Tag>
            ))}
          </div>
        </div>

        <div className="flex justify-between gap-5">
          <div className="w-1/2 p-2 border border-secondary-300 rounded-lg">
            <Typography.Text className="!text-sm font-medium">
              Commands
            </Typography.Text>

            {user?.commands?.map((c, index) => (
              <Tag key={index} color="processing" className="ml-1">
                {c}
              </Tag>
            ))}
          </div>
          <div className="w-1/2 p-2 border border-secondary-300 rounded-lg ">
            <Typography.Text className="!text-sm font-medium">
              Channels
            </Typography.Text>

            {user?.channels?.map((c, index) => (
              <Tag key={index} color="processing" className="ml-1">
                {c}
              </Tag>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UserDetailsModal;
