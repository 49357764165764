import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getCloudCreditAsync,
  handleShowTopupModal,
  invoicesAsync,
  makeCreditOrderAsync,
  selectCloudCredit,
  selectCloudCreditLoading,
  selectShowTopupModal,
  selectTopupLoading,
} from "../../../store/billing/billingSlice";
import { ReactComponent as TopupIcon } from "./../../../assets/icons/topup.svg";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CustomToast } from "../../general/Toast";
import { currency } from "../../../utils/currency";
import { yupResolver } from "@hookform/resolvers/yup";
import { TopupSchema } from "../../../utils/validations";
import { Button, Modal, Typography, Input, Skeleton, Flex } from "djuno-design";

const TopupModal = () => {
  const isOpen = useAppSelector(selectShowTopupModal);
  const topupLoading = useAppSelector(selectTopupLoading);

  const cloudCredit = useAppSelector(selectCloudCredit);
  const cloudCreditLoading = useAppSelector(selectCloudCreditLoading);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    register,
  } = useForm({
    resolver: yupResolver(TopupSchema),
    mode: "all",
    defaultValues: {
      Value: 10,
    },
  });

  useEffect(() => {
    if (isOpen) {
      setValue("Value", 10);
    }
  }, [isOpen, setValue]);

  const handleClose = () => {
    dispatch(handleShowTopupModal(false));
  };

  const handleSubmitForm = (data: any) => {
    if (!topupLoading) {
      dispatch(makeCreditOrderAsync({ amount: data.Value })).then((action) => {
        if (action.type === "billing/make-credit-order/fulfilled") {
          toast.success(() =>
            CustomToast("Your account has been charged successfully")
          );
          dispatch(invoicesAsync());
          dispatch(getCloudCreditAsync({ withoutLoading: true }));
          handleClose();
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={"Top up "}
      contentClassName="max-w-lg"
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="flex justify-center py-4 text-primary-400">
          <TopupIcon width={100} height={100} />
        </div>
        <Flex direction="col">
          <Typography.Text className="!text-sm">
            Your Credit shows{" "}
            {cloudCreditLoading && (
              <Skeleton style={{ width: 50, height: 24 }} />
            )}
            {!cloudCreditLoading && (
              <span className="font-medium">
                {currency(cloudCredit?.Balance)}
              </span>
            )}{" "}
          </Typography.Text>
          <Typography.Text className="!text-sm !mt-2">
            Top up to get 10% discount on every service you use
          </Typography.Text>
        </Flex>
        <div className="gap-5 mt-5">
          <Input
            label="Amount ($)"
            type="number"
            {...register("Value")}
            step="0.01"
            error={errors.Value?.message}
          />
        </div>
        <div className="flex justify-end mt-5">
          <Button
            uiType="primary"
            type="submit"
            disabled={!isValid}
            loading={topupLoading}
          >
            Top up
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TopupModal;
