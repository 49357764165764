import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getSurveyQuestionsAsync,
  selectSaveSurveyQuestionsLoading,
  selectSurveyQuestions,
  selectSurveyQuestionsLoading,
  setSurveyQuestionsAsync,
} from "../../store/survey/surveySlice";
import { SurveyAnswer, SurveyQuestion } from "../../types/survey";
import { getMeAsync } from "../../store/auth/authSlice";
import { getEnvironmentsAsync } from "../../store/environments/environmentsSlice";
import useConfitti from "../../hooks/useConfitti";
import { Button, cn, Flex, Loading, Typography } from "djuno-design";

const SurveyModal = () => {
  const questions = useAppSelector(selectSurveyQuestions);
  const questionsLoading = useAppSelector(selectSurveyQuestionsLoading);
  const saveLoading = useAppSelector(selectSaveSurveyQuestionsLoading);

  const [onStageQuestion, setOnStageQuestion] = useState<SurveyQuestion | null>(
    questions[0]
  );
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState<Array<SurveyAnswer>>([]);

  const { run } = useConfitti();

  const dispatch = useAppDispatch();

  const selectedOption = useMemo(() => {
    return answers.find((a) => a.id === onStageQuestion?.Id);
  }, [answers, onStageQuestion?.Id]);

  const hasNextGuestion = useMemo(
    () => !!questions[step + 1],
    [questions, step]
  );

  //get questions if there is not any question in the store
  useEffect(() => {
    if (questions.length === 0) dispatch(getSurveyQuestionsAsync());
  }, [dispatch, questions.length]);

  //select question to show when have question list and change step
  useEffect(() => {
    if (questions.length > 0) setOnStageQuestion(questions[step]);
  }, [questions, step]);

  const handleCheckOption = (option: string) => {
    if (onStageQuestion) {
      const answer: SurveyAnswer = {
        id: onStageQuestion.Id,
        option,
      };
      setAnswers((prevAnswers) => {
        let found = false;
        let newAnswers = prevAnswers.map((a) => {
          if (a.id === onStageQuestion.Id) {
            found = true;
            return answer;
          } else {
            return { ...a };
          }
        });
        if (!found) {
          newAnswers = [...prevAnswers, answer];
        }
        return newAnswers;
      });
    }
  };

  const handleGoNextQuestion = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleGoPrevQuestion = () => {
    setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : 0));
  };

  const handleGetStarted = () => {
    if (!questionsLoading && !saveLoading) {
      dispatch(setSurveyQuestionsAsync({ answers })).then((action) => {
        setAnswers([]);
        setOnStageQuestion(questions[0]);
        setStep(0);
        if (action.type === "survey/questions/answer/fulfilled") {
          run();
          dispatch(getMeAsync({}));
          dispatch(getEnvironmentsAsync());
        }
      });
    }
  };
  return (
    <Flex
      items="center"
      justify="center"
      className="w-full h-[calc(100vh-4rem)]"
    >
      <Flex
        direction="col"
        className="min-w-full px-6 md:px-0 md:min-w-[420px]"
      >
        <div className="mt-5">
          {questionsLoading && (
            <Flex items="center" justify="center" className="min-h-[200px]">
              <Loading borderSize={2} />
            </Flex>
          )}
          {!questionsLoading && (
            <>
              <Typography.Text size="sm" className="!font-semibold">
                {onStageQuestion?.Question}
              </Typography.Text>
              <Flex
                direction="col"
                className="border-2 bg-white dark:border-dark-2 dark:bg-dark-1 rounded-lg overflow-hidden mt-3"
              >
                {onStageQuestion?.Options.map((option, index) => (
                  <div
                    onClick={() => handleCheckOption(option)}
                    key={index}
                    className={cn(
                      "flex gap-2 items-center w-full dark:border-dark-2 hover:bg-slate-50 hover:dark:bg-black/20 px-3 py-2.5 dark:text-slate-200 text-sm cursor-pointer",
                      {
                        "border-b-2":
                          onStageQuestion.Options.length !== index + 1,
                      }
                    )}
                  >
                    <div
                      className={cn("w-4 h-4 border rounded-full", {
                        "dark:bg-dark-3  dark:border-dark-2":
                          selectedOption?.option !== option,
                        "bg-primary-300  border-0":
                          selectedOption?.option === option,
                      })}
                    />
                    {option}
                  </div>
                ))}
              </Flex>
            </>
          )}
        </div>

        <div className="mt-4 flex justify-between">
          <Button
            uiType="light"
            disabled={step === 0}
            onClick={handleGoPrevQuestion}
          >
            Back
          </Button>
          <Button
            uiType="primary"
            disabled={!!!selectedOption || questionsLoading}
            onClick={hasNextGuestion ? handleGoNextQuestion : handleGetStarted}
            loading={questionsLoading || saveLoading}
          >
            {hasNextGuestion ? "Next" : "GetStarted"}
          </Button>
        </div>
      </Flex>
    </Flex>
  );
};
export default SurveyModal;
