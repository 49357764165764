import React, { useCallback, useEffect, useMemo } from "react";
import {
  TourProvider as ReactTourProvider,
  StepType,
  useTour as useReactTour,
} from "@reactour/tour";
import { useDjunoDesign } from "djuno-design";

export type TourProviderProps = {
  children: React.ReactNode;
};

function TourProvider({ children }: TourProviderProps) {
  const { theme } = useDjunoDesign();
  const { mode } = theme;
  return (
    <ReactTourProvider
      steps={[]}
      scrollSmooth
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: "10px",
          background: mode === "dark" ? "#202425" : "#fff",
          color: mode === "dark" ? "#eee" : "#202425",
        }),
      }}
    >
      {children}
    </ReactTourProvider>
  );
}

export const useTour = (tour_name: string) => {
  const { setIsOpen, setMeta, setSteps, setCurrentStep } = useReactTour();

  const new_steps: StepType[] = useMemo(() => {
    return getTourSteps(tour_name);
  }, [tour_name]);

  useEffect(() => {
    if (setSteps) setSteps(new_steps);
    if (setMeta) setMeta(tour_name);
  }, [new_steps, setMeta, setSteps, tour_name]);

  const run = useCallback(() => {
    setCurrentStep(0);
    setIsOpen(true);
  }, [setCurrentStep, setIsOpen]);

  return { run };
};

const getTourSteps = (name: string): StepType[] => {
  switch (name) {
    case "sign-up":
      return [];
    case "instances":
      return [
        {
          selector: ".instances-header",
          content:
            "Here you can manage your instances. You can refresh the list or create new instances.",
        },
        {
          selector: ".refresh-button",
          content: "Click here to refresh the list of instances.",
        },
        {
          selector: ".create-instance-button",
          content: "Click here to create a new instance.",
        },
        {
          selector: ".ssh-key",
          content:
            "In the SSH Keys tab, you can manage and create SSH keys for secure instance access, With the option to Create New keys as needed or remove it.",
        },
        {
          selector: ".volumes",
          content:
            "In cloud computing, volumes refer to virtual storage units that can be attached to instances for data storage and management. They provide additional storage capacity beyond what is typically offered by the instance itself. ",
        },
        {
          selector: ".backups",
          content:
            "In the Backups tab, users are informed that backups are automatic copies of the server's disk. ",
        },
        {
          selector: ".instance-table",
          content: "This table shows all your instances and their details.",
        },
      ];
    case "instances-ssh-keys":
      return [
        {
          selector: "#create-ssh-key-button",
          content: "Click here to create a new SSH key.",
        },
        {
          selector: "#ssh-keys-table",
          content: "This table shows all your SSH keys and their details.",
        },
      ];
    case "databases":
      return [
        {
          selector: ".databases-header",
          content:
            "Here you can manage your databases. You can refresh the list or create new databases.",
        },
        {
          selector: ".refresh-button",
          content: "Click here to refresh the list of databases.",
        },
        {
          selector: ".create-database-button",
          content: "Click here to create a new database.",
        },
        // {
        //   selector: ".ssh-key",
        //   content:
        //     "In the SSH Keys tab, you can manage and create SSH keys for secure instance access, With the option to Create New keys as needed or remove it.",
        // },
        // {
        //   selector: ".volumes",
        //   content:
        //     "In cloud computing, volumes refer to virtual storage units that can be attached to instances for data storage and management. They provide additional storage capacity beyond what is typically offered by the instance itself. ",
        // },
        // {
        //   selector: ".backups",
        //   content:
        //     "In the Backups tab, users are informed that backups are automatic copies of the server's disk. ",
        // },
        {
          selector: ".database-table",
          content: "This table shows all your databases and their details.",
        },
      ];
    default:
      return [];
  }
};
export default TourProvider;
