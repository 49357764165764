import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from ".";
import { getServiceTypesAsync, selectServiceTypes } from "../store/publicSlice";

function useServiceType(serviceTypeId: number | string | undefined) {
  const serviceTypes = useAppSelector(selectServiceTypes);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (serviceTypes.length === 0) {
      dispatch(getServiceTypesAsync());
    }
  }, [dispatch, serviceTypes.length]);

  const serviceType = useMemo(() => {
    if (serviceTypeId)
      return serviceTypes.find((st) => st.Id === Number(serviceTypeId));
    return undefined;
  }, [serviceTypeId, serviceTypes]);

  return { serviceType };
}

export default useServiceType;
