import { Flex, Loading, Tabs, Typography } from "djuno-design";
import { useState } from "react";
import ReactPlayer from "react-player";
import Container from "../layouts/Container";
import CloudFeatures from "../general/CloudFeatures";

type VideoOption = { title: string; url: string };
const videoOptions: VideoOption[] = [
  { title: "Build", url: "https://webcdn.djuno.io/cdn/loopvideo.mov" },
  {
    title: "Manage",
    url: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
  },
  { title: "Scale", url: "https://www.youtube.com/watch?v=LXb3EKWsInQ" },
];
const VideosBox = () => {
  const [activeVideo, setActiveVideo] = useState<VideoOption>(videoOptions[0]);
  const [loading, setLoading] = useState(true);

  const handleChangeActiveVideo = (index: number) => {
    setActiveVideo(videoOptions[index]);
    setLoading(true);
  };

  const handleReady = () => {
    setLoading(false);
  };

  return (
    <div className="w-full py-14 my-16">
      <Container>
        <Flex direction="col" items="center" className="gap-10">
          <Typography.Text className="!text-xl md:!text-3xl">
            Try the Djuno development platform for your next project
          </Typography.Text>
          <Flex
            direction="col"
            className="gap-3 w-[320px] md:w-[400px] lg:w-[700px]"
          >
            <CloudFeatures />
            {/* <div className="bg-primary-400 p-1.5 rounded-sm relative pt-[56.25%]">
              {loading && (
                <Flex
                  items="center"
                  justify="center"
                  className="w-full h-full absolute inset-0"
                >
                  <Loading borderSize={2} uiType="cutoff" />
                </Flex>
              )}
              <ReactPlayer
                url={activeVideo.url}
                className="absolute left-0 top-0"
                width="100%"
                height="100%"
                controls
                onReady={handleReady}
                onProgress={handleReady}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </div> */}
            {/* <Flex items="center" justify="center" className="w-full gap-3">
              <Tabs
                listClassName="!w-full"
                tabType="creamy"
                options={videoOptions.map((option) => ({
                  label: option.title,
                }))}
                selectedIndex={videoOptions
                  .map((o) => o.title)
                  .indexOf(activeVideo.title)}
                onChange={({ index }) => {
                  if (index !== undefined) handleChangeActiveVideo(index);
                }}
              />
            </Flex> */}
          </Flex>
        </Flex>
      </Container>
    </div>
  );
};

export default VideosBox;
