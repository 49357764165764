import { RadioGroup } from "@headlessui/react";
import { cn } from "djuno-design";

type RadioGrouperProps<T = number | string> = {
  items: Array<RadioGroupeItem<T>>;
  selected?: RadioGroupeItem<T>;
  setSelected?: (item: RadioGroupeItem<T>) => void;
  dir?: "row" | "col";
  disabled?: boolean;
};

export interface RadioGroupeItem<T = number | string> {
  label: string | React.ReactNode;
  value: T;
}

const RadioGrouper = <T extends string | number>({
  items,
  selected,
  setSelected,
  dir,
  disabled,
}: RadioGrouperProps<T>) => {
  return (
    <RadioGroup value={selected} onChange={setSelected} disabled={disabled}>
      <div
        className={cn("flex ", {
          "flex-col gap-2": dir === "col",
          "flex-row gap-4": dir === "row" || typeof dir === "undefined",
          " opacity-50": disabled,
        })}
      >
        {items.map((item, idx) => (
          <RadioGroup.Option key={idx} value={item} disabled={disabled}>
            {({ checked }) => (
              <div className="flex w-full items-center gap-0.5 cursor-pointer ">
                <div
                  className={cn("w-3.5 h-3.5 rounded-full border ", {
                    " dark:bg-dark-3  dark:border-slate-300": !checked,
                    " bg-primary-300  !border-0": checked,
                    " bg-slate-400": disabled && checked,
                    " cursor-not-allowed": disabled,
                  })}
                />
                <div
                  className={cn("text-sm", {
                    " text-slate-500  dark:text-slate-300": !checked,
                    " text-slate-900 dark:text-slate-100": checked,
                    " cursor-not-allowed ": disabled,
                  })}
                >
                  {item.label}
                </div>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioGrouper;
