import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  selectNftApiActionLoading,
  selectSelectedNftApiApiKey,
  selectShowNftApiEditorModal,
  handleHideNftApiEditorModal,
  createNftApiApiKeyAsync,
  updateNftApiApiKeyAsync,
  getNftApiApiKeysAysnc,
} from "../../../store/nft-api/apiKeysSlice";
import { NftApiApiKeySchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Button, Input, Modal } from "djuno-design";
import { useEffect } from "react";

const ApiKeyEditorModal = () => {
  const isOpen = useAppSelector(selectShowNftApiEditorModal);
  const loading = useAppSelector(selectNftApiActionLoading);
  const apiKey = useAppSelector(selectSelectedNftApiApiKey);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(NftApiApiKeySchema),
  });

  useEffect(() => {
    if (isOpen && apiKey) {
      setValue("label", apiKey.Label);
    }
  }, [isOpen, apiKey, setValue]);

  const onSubmit = (data: any) => {
    if (apiKey) {
      dispatch(
        updateNftApiApiKeyAsync({
          id: apiKey.Id,
          formData: {
            label: data.label,
          },
        })
      ).then((action) => {
        if (action.type === "nft-api/api-keys/update/fulfilled") {
          reset();
          dispatch(handleHideNftApiEditorModal());
          dispatch(getNftApiApiKeysAysnc());
        }
      });
    } else {
      dispatch(
        createNftApiApiKeyAsync({
          label: data.label,
        })
      ).then((action) => {
        if (action.type === "nft-api/api-keys/create/fulfilled") {
          reset();
          dispatch(handleHideNftApiEditorModal());
          dispatch(getNftApiApiKeysAysnc());
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideNftApiEditorModal());
        reset();
      }}
      contentClassName="max-w-lg"
      //   containerClassName="!items-start"
      title={apiKey ? "Update api key" : "Create new api key"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Api key label"
            {...register("label")}
            error={errors.label?.message}
            placeholder=""
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
            className="w-[100px]"
          >
            {apiKey ? "Update" : "Create"}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ApiKeyEditorModal;
