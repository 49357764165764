import { PlansTab } from "../../plans/UniversalPlansBox";

const BlockApiPlansTab = () => {
  const blockApiServiceType =
    Number(process.env.REACT_APP_BLOCK_API_SERVICE_TYPE) || 10;

  return (
    <>
      <PlansTab serviceType={blockApiServiceType} />
    </>
  );
};

export default BlockApiPlansTab;
