import { jwtEnvAxios } from ".";

//countries
export function getCountriesApi() {
  return jwtEnvAxios(undefined, true).get(`/countries`);
}

//service-types
export function getServiceTypesApi() {
  return jwtEnvAxios(undefined, true).get(`/service-types`);
}
