import { Tab as MuiTab, Box, styled } from "@mui/material";
import { cn } from "djuno-design";

interface StyledTabProps {
  label: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const MuiTabItem = styled((props: StyledTabProps) => (
  <MuiTab
    disableRipple
    {...props}
    className={cn(
      "!text-xs !normal-case !mr-2 !text-slate-600 hover:!text-slate-800 dark:!text-slate-400 dark:hover:!text-slate-200"
    )}
  />
))(({ theme }) => ({
  // textTransform: "none",
  // fontWeight: theme.typography.fontWeightRegular,
  // fontSize: theme.typography.pxToRem(13),
  // marginRight: theme.spacing(1),
  // color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#1976d2",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228)",
  },
}));

export function MuiTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
