import { useMemo } from "react";
import { ReactComponent as DocumentIcon } from "./../../assets/icons/document-text.svg";
import { ReactComponent as ImageIcon } from "./../../assets/icons/image.svg";
import { ReactComponent as VideoIcon } from "./../../assets/icons/video.svg";
import { ReactComponent as SoundIcon } from "./../../assets/icons/sound.svg";
import { ReactComponent as FolderIcon } from "./../../assets/icons/folder.svg";
import { ReactComponent as DefaultIcon } from "./../../assets/icons/document.svg";
import { FileTypes } from "../../types";
// import { ReactComponent as PDFIcon } from "./../../assets/icons/pdf.svg";
import { cn } from "djuno-design";

const exts: Record<FileTypes, string[]> = {
  photo: ["jpg", "jpeg", "png", "gif", "webp", "svg", "img"],
  video: ["mp4", "webm", "mkv", "flv", "vob", "avi", "mov", "qt", "wmv", "ts"],
  sound: [
    "mp3",
    "3gp",
    "aa",
    "aac",
    "aax",
    "act",
    "aiff",
    "alac",
    "amr",
    "flac",
    "m4v",
    "ogg",
    "m4a",
  ],
  document: ["txt", "pdf", "md", "doc"],
  folder: ["folder"],
};

export const extToType = (ext: string | null): FileTypes | undefined => {
  if (!ext) return;
  const _ext = ext.toLowerCase();
  const foundType = Object.keys(exts).find((type) =>
    exts[type as keyof typeof exts].includes(_ext)
  );
  if (!foundType) return;
  return foundType as FileTypes;
};

function FileIcon({
  type,
  className,
  disabled,
}: {
  type: FileTypes | undefined;
  className?: string;
  disabled?: boolean;
}) {
  const renderIcon = useMemo(() => {
    switch (type) {
      case "document":
        return (
          <DocumentIcon
            className={cn(
              "w-5 h-5 text-slate-600 dark:text-slate-300",
              className,
              {
                "!text-slate-400 dark:!text-slate-500": disabled,
              }
            )}
          />
        );
      case "photo":
        return (
          <ImageIcon
            className={cn(
              "w-5 h-5 text-slate-600 dark:text-slate-300",
              className,
              {
                "!text-slate-400 dark:!text-slate-500": disabled,
              }
            )}
          />
        );
      case "video":
        return (
          <VideoIcon
            className={cn(
              "w-5 h-5 text-slate-600 dark:text-slate-300",
              className,
              {
                "!text-slate-400 dark:!text-slate-500": disabled,
              }
            )}
          />
        );
      case "sound":
        return (
          <SoundIcon
            className={cn(
              "w-5 h-5 text-slate-600 dark:text-slate-300",
              className,
              {
                "!text-slate-400 dark:!text-slate-500": disabled,
              }
            )}
          />
        );
      case "folder":
        return (
          <FolderIcon
            className={cn(
              "w-5 h-5 text-slate-600 dark:text-slate-300",
              className,
              {
                "!text-slate-400 dark:!text-slate-500": disabled,
              }
            )}
          />
        );
      default:
        return (
          <DefaultIcon
            className={cn(
              "w-5 h-5 text-slate-600 dark:text-slate-300",
              className,
              {
                "!text-slate-400 dark:!text-slate-500": disabled,
              }
            )}
          />
        );
    }
  }, [className, disabled, type]);
  return renderIcon;
}

export default FileIcon;
