import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close.svg";
import {
  InstancesVolumeOverviewUrl,
  InstancesVolumesUrl,
} from "../../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceVolumeEditSchema } from "../../../utils/validations";
import { DBSAddon } from "../../../types/database";
import {
  getInstancesProductAvailabilityAsync,
  getRegionsAsync,
} from "../../../store/instances/instancesSlice";
import {
  getDBSCatalogAsync,
  selectDBSCatalog,
  selectDBSCatalogLoading,
} from "../../../store/database/servicesSlice";
import {
  InstanceVolumeEditApiType,
  InstanceVolumeUpsizeApiType,
} from "../../../types/instance";
import {
  InstanceVolumeCapacityInput,
  InstanceVolumeNameInput,
} from "../../../components/instances/volumes/CreateComponents";
import {
  createInstancesVolumesAsync,
  createInstanceVolumeFromBackupAsync,
  getInstancesVolumeAsync,
  getInstancesVolumesAsync,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
  selectInstancesVolumesActionLoading,
  updateInstancesVolumesAsync,
  upsizeInstancesVolumesAsync,
} from "../../../store/instances/instancesVolumesSlice";
import { Button, Flex, Loading, Typography } from "djuno-design";

const InstanceVolumeEditPage = () => {
  const { id, backupId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const snapshotName = searchParams.get("name") || "";

  const selectedVolume = useAppSelector(selectInstancesSelectedVolume);
  const selectedVolumeLoading = useAppSelector(
    selectInstancesSelectedVolumeLoading
  );

  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);

  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  // states
  const [addons, setAddons] = useState<Array<DBSAddon>>([]);

  const dispatch = useAppDispatch();

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(InstanceVolumeEditSchema),
    defaultValues: {
      capacity: 10,
      volumeName: "",
    },
  });

  const {
    setValue,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
  } = methods;

  // watch form values
  const capacity = watch("capacity");
  // const volumeName = watch("volumeName");

  //get selected volume
  useEffect(() => {
    if (selectedVolume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, selectedVolume]);

  //get first data
  useEffect(() => {
    dispatch(getDBSCatalogAsync());
    dispatch(getInstancesProductAvailabilityAsync({ addonFamily: "volume" }));
    dispatch(getRegionsAsync());
  }, [dispatch, reset]);

  useEffect(() => {
    if (catalog) {
      setAddons(catalog.addons.filter((a) => a.planCode.startsWith("volume.")));
    }
  }, [catalog]);

  useEffect(() => {
    if (selectedVolume) {
      setValue("capacity", selectedVolume.size);
      setValue("volumeName", snapshotName ? snapshotName : selectedVolume.name);
    }
  }, [selectedVolume, setValue, snapshotName]);

  const selectedAddon = useMemo(() => {
    if (selectedVolume) {
      return addons.find(
        (a) => a.planCode === `volume.${selectedVolume.type}.consumption`
      );
    }
  }, [addons, selectedVolume]);

  const estimatedPrice = useMemo(() => {
    if (selectedAddon) {
      const unitPrice = selectedAddon.pricings[0].price;
      return unitPrice * 24 * 30 * capacity;
    }
    return 0;
  }, [capacity, selectedAddon]);

  // const handleSubmitForm = (data: any) => {
  //   if (selectedVolume && !actionLoading) {
  //     const updateApiData: InstanceVolumeEditApiType = {
  //       name: data.volumeName,
  //       bootable: false,
  //     };

  //     const upsizeApiData: InstanceVolumeUpsizeApiType = {
  //       size: data.capacity,
  //     };

  //     if (data.capacity !== selectedVolume.size) {
  //       Promise.all([
  //         dispatch(
  //           updateInstancesVolumesAsync({
  //             id: selectedVolume.id,
  //             data: updateApiData,
  //           })
  //         ),
  //         dispatch(
  //           upsizeInstancesVolumesAsync({
  //             id: selectedVolume.id,
  //             data: upsizeApiData,
  //           })
  //         ),
  //       ]).then((action) => {
  //         if (action[0].type === "instances/volumes/update/fulfilled") {
  //           // navigate(InstancesVolumesUrl);
  //           navigate(InstancesVolumeOverviewUrl(selectedVolume.id));
  //           dispatch(getInstancesVolumesAsync({}));
  //         }
  //       });
  //     } else {
  //       dispatch(
  //         updateInstancesVolumesAsync({
  //           id: selectedVolume.id,
  //           data: updateApiData,
  //         })
  //       ).then((action) => {
  //         if (action.type === "instances/volumes/update/fulfilled") {
  //           navigate(InstancesVolumeOverviewUrl(selectedVolume.id));
  //           dispatch(getInstancesVolumesAsync({}));
  //         }
  //       });
  //     }
  //   }
  // };

  // Define conditions for the specific paths

  // Define conditions for the specific paths
  const isEdit = location.pathname.includes("edit");
  const isSnapshot = location.pathname.includes("volume-snapshots");
  const isBackup = location.pathname.includes("volume-backup");

  const handleSubmitForm = (data: any) => {
    if (isEdit && selectedVolume && !actionLoading) {
      const updateApiData: InstanceVolumeEditApiType = {
        name: data.volumeName,
        bootable: false,
      };

      const upsizeApiData: InstanceVolumeUpsizeApiType = {
        size: data.capacity,
      };

      if (data.capacity !== selectedVolume.size) {
        Promise.all([
          dispatch(
            updateInstancesVolumesAsync({
              id: selectedVolume.id,
              data: updateApiData,
            })
          ),
          dispatch(
            upsizeInstancesVolumesAsync({
              id: selectedVolume.id,
              data: upsizeApiData,
            })
          ),
        ]).then((action) => {
          if (action[0].type === "instances/volumes/update/fulfilled") {
            navigate(InstancesVolumeOverviewUrl(selectedVolume.id));
            dispatch(getInstancesVolumesAsync({}));
          }
        });
      } else {
        dispatch(
          updateInstancesVolumesAsync({
            id: selectedVolume.id,
            data: updateApiData,
          })
        ).then((action) => {
          if (action.type === "instances/volumes/update/fulfilled") {
            navigate(InstancesVolumeOverviewUrl(selectedVolume.id));
            dispatch(getInstancesVolumesAsync({}));
          }
        });
      }
    } else if (isSnapshot && selectedVolume && backupId && !actionLoading) {
      // Snapshot
      const apiData = {
        name: data.volumeName,
        region: selectedVolume.region,
        size: data.capacity,
        type: selectedVolume.type,
        description: null,
        imageId: null,
        snapshotId: backupId,
      };
      dispatch(createInstancesVolumesAsync({ data: apiData })).then(
        (action) => {
          if (action.type === "instances/volumes/create/fulfilled") {
            dispatch(getInstancesVolumesAsync({}));
            navigate(InstancesVolumesUrl);
          }
        }
      );
    } else if (isBackup && selectedVolume && backupId && !actionLoading) {
      // Backup
      dispatch(
        createInstanceVolumeFromBackupAsync({
          volumeId: selectedVolume.id,
          regionName: selectedVolume.region,
          volumeBackupId: backupId,
          data: {
            name: data.volumeName,
          },
        })
      ).then((action) => {
        if (action.type === "instances/volume/craete/from/backup/fulfilled") {
          dispatch(getInstancesVolumesAsync({}));
          navigate(InstancesVolumesUrl);
        }
      });
    }
  };

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <div className="font-medium mr-2 text-standard text-md dark:text-slate-100 flex items-center gap-1">
            {isEdit && "Edit a volume"}
            {isSnapshot && "Create  Volume from a Snapshot"}
            {isBackup && "Create Volume from a Backup"}
          </div>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() =>
              selectedVolume &&
              navigate(InstancesVolumeOverviewUrl(selectedVolume.id))
            }
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      {(catalogLoading || selectedVolumeLoading) && (
        <Flex className="min-h-[300px] w-full flex items-center justify-center">
          <Loading borderSize={2} />
        </Flex>
      )}
      {!catalogLoading && !selectedVolumeLoading && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-10 w-full px-6 flex flex-col lg:flex-row pb-24 gap-8 relative">
            <Flex direction="col" className="gap-1 w-full">
              <Flex items="center" className="gap-1">
                <Typography.Text strong className="!text-sm font-medium">
                  Region:
                </Typography.Text>
                <Typography.Text className="!text-sm">
                  {selectedVolume?.region}
                </Typography.Text>
              </Flex>

              <Flex items="center" className="gap-1">
                <Typography.Text strong className="!text-sm font-medium">
                  Volume type:
                </Typography.Text>
                <Typography.Text className="!text-sm">
                  {selectedVolume?.type}
                </Typography.Text>
              </Flex>

              {/* select capacity */}
              <div className="mt-5">
                <InstanceVolumeCapacityInput
                  selectedAddon={selectedAddon}
                  control={control}
                  errorMessage={errors.capacity?.message}
                  estimatedPrice={estimatedPrice}
                />
              </div>

              {/* select name */}
              <InstanceVolumeNameInput
                control={control}
                errorMessage={errors.volumeName?.message}
              />

              {/* validation */}
              <div className="flex items-center gap-2">
                <Button uiType="primary" loading={actionLoading} type="submit">
                  {isEdit ? "Edit the volume" : "Create the volume"}
                </Button>
              </div>
            </Flex>
          </div>
        </form>
      )}
    </>
  );
};

export default InstanceVolumeEditPage;
