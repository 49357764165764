import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import {
  WebAppCreateState,
  WebAppDeployTypes,
  WebAppOrigin,
  WebAppPlan,
} from "../../types/web-app";
import { Credential } from "../../types/registeries";
import { getValidateWebAppApi } from "../../apis/webAppsAPI";
import { getExtractErrors } from "../../apis";

export const webAppRegions: WebAppOrigin[] = [
  { title: "Oregon (US West)", value: "oregon" },
  { title: "Frankfurt (EU Central)", value: "frankfurt" },
  { title: "Ohio (US East)", value: "ohio" },
  { title: "Singapore (Southeast Asia)", value: "singapore" },
];

export const webAppInstances: WebAppPlan[] = [
  { id: 1, title: "Free", ram: "512 MB", price: "0", cpu: "0.1" },
  { id: 2, title: "Starter", ram: "512 MB", price: "7", cpu: "0.5" },
  { id: 3, title: "Standard", ram: "2 GB", price: "25", cpu: "1" },
  { id: 4, title: "Pro", ram: "4 GB", price: "85", cpu: "2" },
];

const initialState: WebAppCreateState = {
  //------------------step-1-------------//
  deployType: "git",

  //------------------step-2-------------//
  //----git:
  //----image:
  imageURL: "",
  imageCredentialId: null,
  isValidateImage: false,
  imageValidationLoading: false,
  imageValidationResponse: null,

  //----:

  //------------------step-3-------------//
  //notic: some data stored in form object in the WebAppCreatePage component
  hasDisk: false,

  //
  createLoading: false,
};

export const validateWebAppImageAsync = createAsyncThunk<
  any,
  undefined,
  IThunkRejectValue
>("web-apps/validate-image", async (_, { rejectWithValue, getState }) => {
  try {
    const { webAppCreate } = getState() as RootState;
    const { imageURL, imageCredentialId } = webAppCreate;
    const response = await getValidateWebAppApi(
      imageURL,
      imageCredentialId || undefined
    );
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const webAppCreateSlice = createSlice({
  name: "webAppCreate",
  initialState,
  reducers: {
    handleClearWebAppCreationSlice: (state) => {
      state.deployType = initialState.deployType;
      state.imageURL = initialState.imageURL;
      state.imageCredentialId = initialState.imageCredentialId;
      state.imageValidationLoading = initialState.imageValidationLoading;
      state.isValidateImage = initialState.isValidateImage;
      state.imageValidationResponse = initialState.imageValidationResponse;
      state.createLoading = initialState.createLoading;
    },

    //step-1
    handleChangeWebAppDeployType: (
      state,
      action: PayloadAction<WebAppDeployTypes>
    ) => {
      state.deployType = action.payload;
    },
    //step-2
    handleSetWebAppImageURL: (state, action: PayloadAction<string>) => {
      state.imageURL = action.payload;
    },
    handleSetWebAppCredentialId: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.imageCredentialId = action.payload
        ? action.payload.toString()
        : null;
    },

    //step-3
    handleChangeWebAppHasDisk: (state, action: PayloadAction<boolean>) => {
      state.hasDisk = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateWebAppImageAsync.pending, (state) => {
        state.imageValidationLoading = true;
        state.isValidateImage = false;
        state.imageValidationResponse = null;
      })
      .addCase(validateWebAppImageAsync.fulfilled, (state) => {
        state.imageValidationLoading = false;

        //TODO we need to check response
        state.isValidateImage = true;
        state.imageValidationResponse = {
          status: "valid",
          message:
            "This is a public image that can be accessed without credentials.",
        };
      })
      .addCase(validateWebAppImageAsync.rejected, (state, { payload }) => {
        state.imageValidationLoading = false;
        state.isValidateImage = false;
        state.imageValidationResponse = {
          status: "invalid",
          message:
            "No public image found. It looks like you may be using a private image - please add a credential or check the image URL for mistakes.",
        };
      });
  },
});

//step-1
export const selectWebAppSelectedDeployType = (state: RootState) =>
  state.webAppCreate.deployType;

//step-2
export const selectWebAppImageURL = (state: RootState) =>
  state.webAppCreate.imageURL;
export const selectWebAppImageCredentialId = (state: RootState) =>
  state.webAppCreate.imageCredentialId;

export const selectWebAppIsValidateImage = (state: RootState) =>
  state.webAppCreate.isValidateImage;
export const selectWebAppValidationLoading = (state: RootState) =>
  state.webAppCreate.imageValidationLoading;
export const selectWebAppValidationResponse = (state: RootState) =>
  state.webAppCreate.imageValidationResponse;

//step-3
export const selectWebAppHasDisk = (state: RootState) =>
  state.webAppCreate.hasDisk;

export const selectWebAppCreateLoading = (state: RootState) =>
  state.webAppCreate.createLoading;

export const {
  handleClearWebAppCreationSlice,

  //step-1
  handleChangeWebAppDeployType,

  //step-2
  handleSetWebAppCredentialId,
  handleSetWebAppImageURL,

  //step-3
  handleChangeWebAppHasDisk,
  //
} = webAppCreateSlice.actions;
export default webAppCreateSlice.reducer;
