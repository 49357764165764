import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3AnonymousAccessSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { selectBucketDetails } from "../../../../../store/s3/buckets/bucketSlice";
import {
  getBucketAnonymousesAsync,
  handleHideAnonymousModal,
  selectBucketAnonymousesActionLoading,
  selectSelectedAnonymouse,
  selectShowBucketAnonymousModal,
  setBucketAnonymousAccessAsync,
} from "../../../../../store/s3/buckets/setting/anonymousSlice";
import { selectSelectedObject } from "../../../../../store/s3/buckets/objectSlice";
import { useEffect, useState } from "react";
import { Button, Input, Modal, Select, SelectOption } from "djuno-design";

const AccessOptions: SelectOption[] = [
  {
    label: "readonly",
    value: "readonly",
  },
  {
    label: "writeonly",
    value: "writeonly",
  },
  {
    label: "readwrite",
    value: "readwrite",
  },
];
const S3AnonymousAccessModal = () => {
  const isOpen = useAppSelector(selectShowBucketAnonymousModal);
  const loading = useAppSelector(selectBucketAnonymousesActionLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const selectedObject = useAppSelector(selectSelectedObject);
  const selectedAnonymouse = useAppSelector(selectSelectedAnonymouse);

  const [selectedAccess, setSelectedAccess] = useState<string>(
    AccessOptions[0].value
  );
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3AnonymousAccessSchema),
  });

  //edit
  useEffect(() => {
    console.log(selectedAnonymouse);
    if (isOpen) {
      if (selectedAnonymouse) {
        setValue("prefix", selectedAnonymouse.prefix || "/");
        setSelectedAccess(selectedAnonymouse.access);
      } else {
        setValue("prefix", "");
        setSelectedAccess(AccessOptions[0].value);
      }
    }
  }, [isOpen, selectedAnonymouse, setValue]);

  const handleClearComponent = () => {
    reset();
    setSelectedAccess(AccessOptions[0].value);
  };

  const onSubmit = (data: any) => {
    if (bucketDetails) {
      dispatch(
        setBucketAnonymousAccessAsync({
          bucketName: bucketDetails.name,
          data: {
            prefix: data.prefix !== undefined ? data.prefix : "/",
            access: selectedAccess,
          },
        })
      ).then((action) => {
        if (action.type === "bucket/anonymous-access/set/fulfilled") {
          handleClearComponent();
          dispatch(handleHideAnonymousModal());
          dispatch(getBucketAnonymousesAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideAnonymousModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg"
      title="Add Anonymous Access Rule"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-3">
          <Input
            label="Prefix"
            tooltip={{
              content:
                "Enter '/' to apply the rule to all prefixes and objects at the bucket root. Do not include the wildcard asterisk '*' as part of the prefix *unless* it is an explicit part of the prefix name. The Console automatically appends an asterisk to the appropriate sections of the resulting IAM policy.",
            }}
            {...register("prefix")}
            disabled={!!selectedAnonymouse}
            error={errors.prefix?.message}
          />
          <Select
            label="Access"
            options={AccessOptions}
            value={selectedAccess}
            onChange={(v) => v && setSelectedAccess(v)}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(handleHideAnonymousModal());
            }}
            uiType="light"
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3AnonymousAccessModal;
