import { jwtEnvAxios } from ".";
import { CreateBlockApiApiKeyData } from "../types/block-api";

const GET_BLOCK_API_REQUEST_LOGS_URL = `/block-api/request-logs`;

const GET_BLOCK_API_API_KEYS_URL = `/blocks/api-settings`;
const DELETE_BLOCK_API_API_KEYS_URL = (id: number) =>
  `/blocks/api-settings/${id}`;
const CREATE_BLOCK_API_API_KEYS_URL = `/blocks/api-settings`;
const UPDATE_BLOCK_API_API_KEYS_URL = (id: number) =>
  `/blocks/api-settings/${id}`;

//overview
export function getBlockApiRequestLogsApi() {
  return jwtEnvAxios().get(GET_BLOCK_API_REQUEST_LOGS_URL);
}

//api keys
export function getBlockApiApiKeysApi() {
  return jwtEnvAxios().get(GET_BLOCK_API_API_KEYS_URL);
}

export function deleteBlockApiApiKeyApi(id: number) {
  return jwtEnvAxios().delete(DELETE_BLOCK_API_API_KEYS_URL(id));
}

export async function createBlockApiApiKeyApi(
  formData: CreateBlockApiApiKeyData
) {
  return jwtEnvAxios().post(CREATE_BLOCK_API_API_KEYS_URL, formData);
}

export function updateBlockApiApiKeyApi(
  id: number,
  formData: CreateBlockApiApiKeyData
) {
  return jwtEnvAxios().put(UPDATE_BLOCK_API_API_KEYS_URL(id), formData);
}
