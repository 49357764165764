import { PlansTab } from "../../plans/UniversalPlansBox";

const BlockEventPlansTab = () => {
  const blockEventServiceType =
    Number(process.env.REACT_APP_BLOCK_EVENT_SERVICE_TYPE) || 11;
  return (
    <>
      <PlansTab serviceType={blockEventServiceType} />
    </>
  );
};

export default BlockEventPlansTab;
