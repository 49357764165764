import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getBucketDetailsAsync,
  getBucketObjectsAsync,
  selectBucketDetails,
  selectBucketDetailsLoading,
  selectBucketBreadCrumbItems,
  handleShowCreateFolderModal,
  handleCleanUpBucket,
  handleShowRewindModal,
  getBucketVersioningAsync,
  getBucketObjectLockingAsync,
} from "../../store/s3/buckets/bucketSlice";
import { fileParentFinder } from "../../utils/file";
import BucketBreadCrumb from "../../components/s3/buckets/BucketBreadCrumb";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import { uploadBucketFileApi } from "../../apis/s3API";
import BucketNewFolderModal from "../../components/s3/buckets/modals/buckets/BucketNewFolderModal";
import BucketObjectList from "../../components/s3/buckets/BucketObjectList";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { ReactComponent as RefreshIcon } from "./../../assets/icons/arrow-path.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as RewindIcon } from "./../../assets/icons/arrow-uturn-left.svg";
import { ReactComponent as PlusIcon } from "./../../assets/icons/plus.svg";
import { ReactComponent as UploadIcon } from "./../../assets/icons/arrow-up-tray.svg";
import { ReactComponent as FolderPlusIcon } from "./../../assets/icons/folder-plus.svg";
import { S3BucketSettingUrl, S3BucketsUrl } from "../../utils/urls";
import { handleCleanUpObject } from "../../store/s3/buckets/objectSlice";
import S3RewindModal from "../../components/s3/buckets/modals/buckets/S3RewindModal";
import { Helmet } from "react-helmet";
import { NotExist } from "../../components/layouts/NotData";
import { Button, cn, Dropdown, Skeleton, Typography } from "djuno-design";
import useServiceType from "../../hooks/useServiceType";

const S3BucketPage = () => {
  const { bucketName } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);
  const breadCrumb = useAppSelector(selectBucketBreadCrumbItems);
  const url = fileParentFinder(breadCrumb);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { serviceType } = useServiceType(process.env.REACT_APP_S3_SERVICE_TYPE);

  const [isExist, setIsExist] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      dispatch(handleCleanUpBucket());
      dispatch(handleCleanUpObject());
    };
  }, [dispatch]);

  useEffect(() => {
    if (typeof bucketName !== "undefined") {
      dispatch(getBucketDetailsAsync({ name: bucketName })).then((action) => {
        if (action.type === "bucket/fulfilled") {
          dispatch(getBucketVersioningAsync({ name: bucketName }));
        }
      });
    }
  }, [bucketName, dispatch]);

  useEffect(() => {
    if (bucketDetails) {
      dispatch(getBucketObjectsAsync({ name: bucketDetails.name })).then(
        (action) => {
          if (action && action.type === "bucket/objects/rejected") {
            setIsExist(false);
          }
        }
      );
      dispatch(getBucketObjectLockingAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, dispatch]);

  const upload = useCallback(
    async (file: File, url: string) => {
      if (bucketDetails) {
        const _t = toast.loading(`uploading file`);
        // const _url = fileUrlConvertor(url);
        try {
          await uploadBucketFileApi(bucketDetails.name, file, url);
          toast.success(`upload completed`, {
            id: _t,
            className: ToastClasses,
          });
          dispatch(getBucketObjectsAsync({ name: bucketDetails.name }));
        } catch (e) {
          toast.error(`Failed!`, {
            id: _t,
            className: ToastClasses,
          });
        }
        try {
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        } catch (e) {}
      }
    },
    [bucketDetails, dispatch]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      upload(acceptedFiles[0], url);
    },
    [upload, url]
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handleUploadClick = useCallback(() => {
    if (fileInputRef.current) fileInputRef.current.click();
  }, []);

  const handleUploadFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        upload(files[0], url);
      }
    },
    [upload, url]
  );

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""} (
          {bucketDetails ? bucketDetails.name : ""})
        </title>
        <meta
          name="description"
          content="Simple-storage technology refers to a straightforward method of storing data or information in a manner that is easy to implement and access"
        />
      </Helmet>
      <input
        type="file"
        hidden={true}
        ref={fileInputRef}
        multiple={true}
        onChange={handleUploadFiles}
      />
      <div className="min-h-[calc(100vh-9.5rem)] flex flex-col">
        <div className="flex items-center justify-between h-16 border-b dark:border-dark-2">
          <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
            <div className="flex items-center gap-2">
              <RightArrow
                onClick={() => navigate(S3BucketsUrl)}
                className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
              />
              {bucketDetails && !bucketDetailsLoading && (
                <Typography.Title level={5} className="!mb-0">
                  {bucketDetails.name}
                </Typography.Title>
              )}
              {bucketDetailsLoading && (
                <Skeleton
                  shape="rectangle"
                  style={{ width: 200, height: 30 }}
                />
              )}
            </div>
          </div>
          <div className="mr-6 flex items-center gap-2">
            <Button
              uiSize="medium"
              uiType="light"
              onClick={() =>
                bucketName && navigate(S3BucketSettingUrl(bucketName))
              }
              disabled={!isExist}
              className="group"
            >
              <SettingIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>
        {isExist ? (
          <div className="flex flex-col flex-1 p-6">
            <div className="flex transition-all duration-500 items-center justify-between">
              <BucketBreadCrumb bucketDetails={bucketDetails} />
              <div className="hidden ml-auto lg:flex items-center justify-end gap-1 md:gap-2">
                <Button
                  uiType="light"
                  onClick={() =>
                    bucketName && dispatch(handleShowRewindModal())
                  }
                  className="group"
                  tooltip={{ content: "Rewind", className: "!text-xs" }}
                >
                  <RewindIcon className="w-4 h-4 group-hover:scale-110 transition-all duration-500" />
                </Button>
                <Button
                  uiType="light"
                  onClick={() =>
                    bucketName &&
                    dispatch(getBucketDetailsAsync({ name: bucketName }))
                  }
                  className="group"
                  tooltip={{ content: "Refresh", className: "!text-xs" }}
                >
                  <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
                </Button>
                <Button
                  uiType="light"
                  onClick={() => dispatch(handleShowCreateFolderModal())}
                  disabled={bucketDetails === null}
                  tooltip={{
                    content: "Create New Path",
                    className: "!text-xs",
                  }}
                >
                  <FolderPlusIcon className="w-4 h-4 group-hover:scale-110 transition-all duration-500" />
                </Button>
                <Button
                  uiType="primary"
                  onClick={handleUploadClick}
                  disabled={bucketDetails === null}
                >
                  Upload File
                </Button>
              </div>
              <div className="lg:hidden">
                <Dropdown
                  anchor="bottom end"
                  menu={[
                    {
                      key: "UploadFile",
                      label: (
                        <div className="flex items-center gap-2">
                          <UploadIcon className="w-4 h-4" /> Upload File
                        </div>
                      ),
                      onClick: (_, close) => {
                        close();
                        handleUploadClick();
                      },
                      disabled: bucketDetails === null,
                    },
                    {
                      key: "CreateNewPath",
                      label: (
                        <div className="flex items-center gap-2">
                          <FolderPlusIcon className="w-4 h-4" /> Create New Path
                        </div>
                      ),
                      onClick: (_, close) => {
                        close();
                        dispatch(handleShowCreateFolderModal());
                      },
                      disabled: bucketDetails === null,
                    },
                    {
                      key: "Refresh",
                      label: (
                        <div className="flex items-center gap-2">
                          <RefreshIcon className="w-4 h-4" /> Refresh
                        </div>
                      ),
                      onClick: (_, close) => {
                        close();
                        bucketName &&
                          dispatch(getBucketDetailsAsync({ name: bucketName }));
                      },
                    },
                    {
                      key: "Rewind",
                      label: (
                        <div className="flex items-center gap-2">
                          <RewindIcon className="w-4 h-4" /> Rewind
                        </div>
                      ),
                      onClick: (_, close) => {
                        close();
                        bucketName && dispatch(handleShowRewindModal());
                      },
                    },
                  ]}
                >
                  <Button uiType="light">
                    <PlusIcon className="w-4 h-4" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            <div
              {...getRootProps({
                className: cn(
                  "w-full min-h-full flex-1 mt-5 overflow-hidden rounded-lg border-2 bg-white dark:bg-dark-3",
                  {
                    "border-primary-400": isDragActive && bucketDetails,
                    "border-red-500": isDragActive && !bucketDetails,
                    "border-transparent": !isDragActive,
                  }
                ),
              })}
            >
              <input {...getInputProps()} />
              <BucketObjectList />
            </div>
          </div>
        ) : (
          <NotExist
            text={"The specified bucket does not exist"}
            url={() => navigate(S3BucketsUrl)}
          />
        )}
      </div>
      <BucketNewFolderModal />
      <S3RewindModal />
    </>
  );
};
export default S3BucketPage;
