import { useNavigate } from "react-router-dom";
// import { ReactComponent as BellAlertIcon } from "./../../assets/icons/bell-alert.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { WebAppsUrl } from "../../utils/urls";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { WebAppCreateLevels } from "../../types/web-app";
import {
  selectWebAppSelectedDeployType,
  handleClearWebAppCreationSlice,
  selectWebAppIsValidateImage,
  selectWebAppCreateLoading,
  webAppRegions,
  webAppInstances,
  selectWebAppHasDisk,
} from "../../store/web-app/webAppCreateSlice";
import SelectDeployingTypeStep from "../../components/web-apps/create-page-steps/SelectDeployingTypeStep";
import SelectDeployingSourceStep from "../../components/web-apps/create-page-steps/SelectDeployingSourceStep";
import CreateFormStep from "../../components/web-apps/create-page-steps/CreateFormStep";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { WebAppCreateSchema } from "../../utils/validations";
import { Button, Steps, Typography } from "djuno-design";

const WebAppCreatePage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<WebAppCreateLevels>("SelectDeployingType");

  const selectedDeployType = useAppSelector(selectWebAppSelectedDeployType);
  const isValidateImage = useAppSelector(selectWebAppIsValidateImage);
  const hasDisk = useAppSelector(selectWebAppHasDisk);
  const createLoading = useAppSelector(selectWebAppCreateLoading);

  const dispatch = useAppDispatch();

  const form = useForm({
    resolver: yupResolver(WebAppCreateSchema(hasDisk)),
    shouldUnregister: false,
    criteriaMode: "all",
    mode: "all",
  });

  useEffect(() => {
    form.setValue("Name", "");
    form.setValue("Origin", webAppRegions[0].value);
    form.setValue("InstanceType", webAppInstances[0].id);
    form.setValue("ENVs", []);
    form.setValue("SecretFiles", []);
    form.setValue("DiskName", "");
    form.setValue("DiskMountPath", "");
    form.setValue("DiskSize", null);
    form.setValue("HealthCheckPath", "");
    form.setValue("DockerCommand", "");
    form.setValue("PreDeployCommand", "");
    return () => {
      dispatch(handleClearWebAppCreationSlice());
    };
  }, [dispatch, form]);

  const handleCreateWebApp = (data: any) => {
    console.log(data);
  };

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="font-medium">
            Create Web App
          </Typography.Text>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(WebAppsUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      <Steps
        step={step}
        steps={[
          {
            label: "Deploying Type",
            value: "SelectDeployingType",
            callback: () => setStep("SelectDeployingSourceStep"),
          },
          {
            label: "Deploying Source",
            value: "SelectDeployingSourceStep",
            callback: () => setStep("CreateFormStep"),
          },
          { label: "App Data", value: "CreateFormStep" },
        ]}
        className="px-6"
      />
      <form onSubmit={form.handleSubmit(handleCreateWebApp)}>
        <div className="mt-5 w-full px-6">
          <div className="mx-auto flex-1 flex justify-start pb-14 w-full">
            {step === "SelectDeployingType" && <SelectDeployingTypeStep />}
            {step === "SelectDeployingSourceStep" && (
              <SelectDeployingSourceStep />
            )}
            {step === "CreateFormStep" && <CreateFormStep form={form} />}
          </div>
        </div>
        <div className="fixed bottom-0 right-0 left-0 flex items-center w-full h-16 border-t bg-white dark:bg-dark-1 dark:border-dark-2 px-6 md:pl-80">
          {step === "SelectDeployingType" && (
            <div className="flex justify-end w-full">
              <Button
                uiType="primary"
                disabled={!selectedDeployType}
                onClick={() => setStep("SelectDeployingSourceStep")}
                className="group  w-[110px]"
              >
                Continue
                <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
              </Button>
            </div>
          )}
          {step === "SelectDeployingSourceStep" && (
            <div className="flex items-center justify-between w-full">
              <Button
                uiType="light"
                onClick={() => {
                  setStep("SelectDeployingType");
                }}
              >
                back
              </Button>
              <Button
                uiType="primary"
                //TODO
                // disabled: !isValidateImage,
                onClick={() => setStep("CreateFormStep")}
                className="group  w-[110px]"
              >
                Continue
                <ArrowRightIcon className="w-4 h-4 group-hover:scale-110 group-hover:translate-x-1 transition-all duration-300" />
              </Button>
            </div>
          )}
          {step === "CreateFormStep" && (
            <div className="flex items-center justify-between w-full">
              <Button
                uiType="light"
                onClick={() => {
                  setStep("SelectDeployingSourceStep");
                }}
              >
                back
              </Button>
              <Button
                uiType="primary"
                type="submit"
                className="group  w-[110px]"
                loading={createLoading}
              >
                Create Web App
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default WebAppCreatePage;
