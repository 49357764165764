import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  InstancesUrl,
  InstanceOverviewUrl,
  InstanceVncConsoleUrl,
  InstanceNetworksUrl,
  InstanceImagesUrl,
  InstanceRescaleUrl,
  InstanceBackupsUrl,
  InstanceDeleteUrl,
} from "../../utils/urls";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Helmet } from "react-helmet";
import { ReactComponent as BackupIcon } from "./../../assets/icons/arrow-up-on-square.svg";
import { Instance } from "../../types/instance";
import {
  getInstanceAsync,
  handleClearInstance,
  selectInstance,
  selectInstanceLoading,
} from "../../store/instances/instanceSlice";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as GlobeIcon } from "./../../assets/icons/globe-alt.svg";
import { ReactComponent as DockerIcon } from "./../../assets/icons/socials/docker.svg";
import { ReactComponent as SellIcon } from "./../../assets/icons/shell.svg";
import { ReactComponent as DeleteIcon } from "./../../assets/icons/archive-box.svg";
import { ReactComponent as RescaleIcone } from "./../../assets/icons/arrow-path.svg";
import {
  Flex,
  Skeleton,
  TabOption,
  TabOptions,
  Tabs,
  Typography,
} from "djuno-design";
import { InstanceRefreshStatus } from "../../store/instances/instancesSlice";

export const generateInstanceRoutes = (instance: Instance) => {
  const options: TabOptions = [];

  const overviewOption = {
    label: (
      <div className="flex items-center gap-1">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    url: InstanceOverviewUrl(instance.id),
    testId: "1th-tab",
  };
  const networksOption = {
    label: (
      <div className="flex items-center gap-1">
        <GlobeIcon className="w-3" />
        Networks
      </div>
    ),
    url: InstanceNetworksUrl(instance.id),
    testId: "2th-tab",
  };
  const imagesOption = {
    label: (
      <div className="flex items-center gap-1">
        <DockerIcon className="w-3" />
        Images
      </div>
    ),
    url: InstanceImagesUrl(instance.id),
    testId: "3th-tab",
  };
  const rescaleOption = {
    label: (
      <div className="flex items-center gap-1">
        <RescaleIcone className="w-3" />
        Rescale
      </div>
    ),
    url: InstanceRescaleUrl(instance.id),
    testId: "4th-tab",
  };
  const backupsOption = {
    label: (
      <div className="flex items-center gap-1">
        <BackupIcon className="w-3" />
        Backups
      </div>
    ),
    url: InstanceBackupsUrl(instance.id),
    testId: "5th-tab",
  };
  const vncConsoleOption: TabOption = {
    label: (
      <div className="flex items-center gap-1">
        <SellIcon className="w-3" />
        VNC console
      </div>
    ),
    url: InstanceVncConsoleUrl(instance.id),
    testId: "6th-tab",
  };
  const deleteOption: TabOption = {
    label: (
      <div className="flex items-center gap-1">
        <DeleteIcon className="w-3" />
        Delete
      </div>
    ),
    url: InstanceDeleteUrl(instance.id),
    testId: "7th-tab",
  };

  options.push(overviewOption);
  options.push(networksOption);
  options.push(imagesOption);
  options.push(rescaleOption);
  options.push(backupsOption);
  options.push(vncConsoleOption);
  options.push(deleteOption);

  return options;
};

const InstancePage = () => {
  const { instanceId } = useParams();
  const instance = useAppSelector(selectInstance);
  const loading = useAppSelector(selectInstanceLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const InstancePageMenus = useMemo(() => {
    if (instance) {
      return generateInstanceRoutes(instance);
    }
    return [];
  }, [instance]);

  useEffect(() => {
    if (instanceId) {
      dispatch(getInstanceAsync({ instanceId }));
    }

    return () => {
      // clear instance data
      dispatch(handleClearInstance());
    };
  }, [dispatch, instanceId]);

  //refresh the list
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime =
      Number(process.env.REACT_APP_NODES_REFRESH_INTERVAL) || 20000;

    if (instance && InstanceRefreshStatus.includes(instance.status)) {
      interval = setInterval(() => {
        dispatch(
          getInstanceAsync({
            instanceId: instance.id,
            withoutLoading: true,
          })
        );
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, instance]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Database (
          {instance ? instance.name : ""})
        </title>
        <meta
          name="description"
          content="Deploy an option from our range of instances, and harness the flexibility of the cloud to grow in a way that suits your needs."
        />
      </Helmet>
      <Flex items="center" justify="between" className="h-20">
        <Flex items="center" justify="between" className="px-6 flex-1">
          <Flex items="center" className="gap-2">
            <RightArrow
              onClick={() => navigate(InstancesUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {instance && !loading && (
              <Typography.Title level={5} className="!mb-0">
                {instance.name}
              </Typography.Title>
            )}
            {loading && (
              <Skeleton shape="rectangle" style={{ width: 200, height: 30 }} />
            )}
          </Flex>
        </Flex>
      </Flex>

      <div className="px-6">
        <Tabs options={InstancePageMenus} />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default InstancePage;
