import { useNavigate } from "react-router-dom";
import { HomeUrl } from "../utils/urls";
import { Button } from "djuno-design";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen flex flex-auto items-center justify-center text-center px-4 flex-col sm:flex-row bg-primary-50 dark:bg-dark-1">
      <h1 className="text-2xl sm:text-3xl font-extrabold text-slate-500 tracking-tight sm:pr-6 sm:mr-6 sm:border-r sm:border-slate-900/10 sm:dark:border-slate-300/10 dark:text-slate-200">
        500
      </h1>
      <div className="flex flex-col gap-1 justify-start items-start">
        <h2 className="mt-2 text-lg text-slate-600 dark:text-slate-100 sm:mt-0">
          Oops! Unexpected Error
        </h2>
        <div className="mb-1 text-sm text-slate-400 dark:text-slate-300 sm:mt-0">
          Something went wrong
        </div>
        <Button onClick={() => navigate(HomeUrl)}>Go Home</Button>
      </div>
    </div>
  );
};
export default ErrorPage;
