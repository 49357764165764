import Highlighter from "react-highlight-words";
import { BlockEventDestination } from "../../../types/block-events";
import { Typography, SimpleTable, EmptyState } from "djuno-design";

const BlockEventDestinationsTable: React.FC<{
  destinations: BlockEventDestination[];
  loading?: boolean;
  rowCallback?: (destination: BlockEventDestination) => void;
  searchValue?: string;
  actionsRendered?: (destination: BlockEventDestination) => React.ReactNode;
}> = ({ destinations, loading, rowCallback, searchValue, actionsRendered }) => {
  return (
    <SimpleTable loading={loading} containerClassName="min-h-[240px]">
      <SimpleTable.Head>
        <SimpleTable.Row>
          <SimpleTable.TH lable="Name" />
          <SimpleTable.TH lable="URL" />
          <SimpleTable.TH lable="Alerts" />
          <SimpleTable.TH lable="" />
        </SimpleTable.Row>
      </SimpleTable.Head>
      <SimpleTable.Body>
        {destinations.map((destination, index) => (
          <SimpleTable.Row
            key={index}
            onClick={() => {
              rowCallback && rowCallback(destination);
            }}
            className="cursor-pointer"
          >
            <SimpleTable.TD className="w-36">
              <Highlighter
                highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                searchWords={[searchValue || ""]}
                autoEscape={true}
                textToHighlight={destination.Name}
              />
            </SimpleTable.TD>
            <SimpleTable.TD>
              <Typography.Text className="!text-sm">
                {destination.To_url}
              </Typography.Text>
            </SimpleTable.TD>
            <SimpleTable.TD>
              {/* TODO */}
              <Typography.Text className="!text-sm">0</Typography.Text>
            </SimpleTable.TD>
            <SimpleTable.TD className="w-32">
              {actionsRendered && actionsRendered(destination)}
            </SimpleTable.TD>
          </SimpleTable.Row>
        ))}
        {destinations.length === 0 && (
          <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
            <SimpleTable.TD colSpan={4} className="!border-0">
              <EmptyState text="You have not created any destination yet" />
            </SimpleTable.TD>
          </SimpleTable.Row>
        )}
      </SimpleTable.Body>
    </SimpleTable>
  );
};
export default BlockEventDestinationsTable;
