import { useState } from "react";
import { ReactComponent as CloseIcon } from "./../../../assets/icons/close.svg";
import {
  Button,
  Typography,
  getLocalStorage,
  setLocalStorage,
} from "djuno-design";
import useServiceType from "../../../hooks/useServiceType";

const S3Banner = () => {
  const docsUrl = process.env.REACT_APP_DOCS_URL || "";
  const localStorageName = "object-storage-banner";
  const showWeb3Banner = getLocalStorage<boolean>(localStorageName, true);
  const [show, setShow] = useState<boolean>(showWeb3Banner);

  const { serviceType } = useServiceType(process.env.REACT_APP_S3_SERVICE_TYPE);

  const closeBanner = () => {
    setLocalStorage<boolean>(localStorageName, false);
    setShow(false);
  };

  if (!show) return null;
  return (
    <div className="relative w-full bg-gray-50 dark:bg-dark-3 border border-slate-300 dark:border-dark-2 p-6 rounded-lg flex flex-col">
      <CloseIcon
        onClick={closeBanner}
        className="absolute right-3 top-3 text-slate-500 dark:text-slate-300 w-5 hover:scale-110 duration-300 cursor-pointer"
      />
      <div className="flex items-center w-full justify-between">
        <Typography.Text className="!text-sm">
          Get started {serviceType ? `with ${serviceType.ServiceName}` : ""}
        </Typography.Text>
      </div>
      <div className="flex items-center gap-2 mt-5 flex-wrap">
        <a
          href={docsUrl + "/en/articles/10108413-object-storage"}
          target="_blank"
          rel="noreferrer"
        >
          <Button>Get Started</Button>
        </a>
        <a
          href={docsUrl + "/en/articles/10108413-object-storage"}
          target="_blank"
          rel="noreferrer"
        >
          <Button>API refrences</Button>
        </a>
      </div>
    </div>
  );
};

export default S3Banner;
