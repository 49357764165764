import { useState } from "react";
import { useAppDispatch, useAppSelector } from "./../../../../../hooks";
import {
  deleteInstancesVolumesAsync,
  getInstancesVolumesAsync,
  selectInstancesSelectedVolume,
  selectInstancesVolumesActionLoading,
} from "./../../../../../store/instances/instancesVolumesSlice";
import { useNavigate } from "react-router-dom";
import { InstancesVolumesUrl } from "./../../../../../utils/urls";
import { DeleteModal } from "./../../../../modals/QuestionModal";
import { Button, Card, Typography } from "djuno-design";

const VolumeDeleteTab = () => {
  const [deleteVolume, setDeleteVolume] = useState<boolean>(false);
  const volume = useAppSelector(selectInstancesSelectedVolume);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <Card title="Delete">
        <div className="mt-2 grid gap-4 w-full">
          <Typography.Text className="!text-sm">
            Deleting your volume...
          </Typography.Text>
          <div className="">
            {volume && (
              <Button
                onClick={() => setDeleteVolume(true)}
                uiType="dangerLight"
              >
                Delete volume
              </Button>
            )}
          </div>
        </div>
      </Card>
      <DeleteModal
        title={`Delete ${volume?.name}`}
        isOpen={deleteVolume}
        onClose={() => setDeleteVolume(false)}
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={actionLoading}
        onConfirm={() => {
          deleteVolume &&
            volume &&
            dispatch(
              deleteInstancesVolumesAsync({
                id: volume.id,
                region: volume.region,
              })
            ).then((action) => {
              if (action.type === "instances/volumes/delete/fulfilled") {
                dispatch(getInstancesVolumesAsync({}));
                navigate(InstancesVolumesUrl);
              }
              setDeleteVolume(false);
            });
        }}
        confirmString={volume?.name}
      />
    </div>
  );
};

export default VolumeDeleteTab;
