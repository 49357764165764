import { Outlet, useLocation } from "react-router-dom";
import WebHeader from "../website/WebHeader";
import WebFooter from "../website/WebFooter";
import { GlobalLoadingContent } from "./PanelLayout";
import { useEffect, useLayoutEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "djuno-design";
import { selectHasTopMessage } from "../../store/publicSlice";
import { useAppSelector } from "../../hooks";
const WebsiteLayout: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const hasTopMessage = useAppSelector(selectHasTopMessage);

  useEffect(() => {
    if (loading) setTimeout(() => setLoading(false), 2000);
  }, [loading]);

  useLayoutEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      if (window) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div
      className="min-h-screen min-w-full flex flex-col relative bg-white dark:bg-dark-1"
      // style={{ fontFamily: '"Nunito", sans-serif' }}
    >
      <WebHeader />
      <div
        className={cn("flex-1", {
          "pt-0": location.pathname === "/",
          "pt-28": location.pathname !== "/" && hasTopMessage,
          "pt-20": location.pathname !== "/" && !hasTopMessage,
        })}
      >
        <Outlet />
      </div>
      <WebFooter />

      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="w-screen h-screen absolute inset-0 flex items-center justify-center z-[101] bg-white dark:bg-dark-1"
          >
            <GlobalLoadingContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default WebsiteLayout;
