import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EnvAddFromFileSchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createEnvVariableAsync,
  createMultipleEnvVariableAsync,
  getEnvVariablesAysnc,
  handleHideImportFromEnvFileModal,
  selectEnvVariablesActionLoading,
  selectShowImportFromEnvFileModal,
} from "../../../store/settings/env-variables/envVariablesSlice";
import { Button, Flex, Modal, Textarea, Typography } from "djuno-design";

const ImportFromEnvFileModal: React.FC<{ callback?: any }> = ({ callback }) => {
  const isOpen = useAppSelector(selectShowImportFromEnvFileModal);
  const loading = useAppSelector(selectEnvVariablesActionLoading);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EnvAddFromFileSchema),
  });

  const onSubmit = (data: any) => {
    const envs = data.Value as string;
    const envsArr = envs
      .split("\n")
      .filter(Boolean)
      .filter((d) => d.includes("="))
      .map((item) => {
        const [Key, Value] = item.split("=");
        return { Key, Value };
      });

    if (callback) {
      callback(envsArr);
      dispatch(handleHideImportFromEnvFileModal());
    } else {
      dispatch(createMultipleEnvVariableAsync(data)).then((action) => {
        if (action.type === "envVariables/create/multiple/fulfilled") {
          reset();
          dispatch(handleHideImportFromEnvFileModal());
          dispatch(getEnvVariablesAysnc());
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        dispatch(handleHideImportFromEnvFileModal());
      }}
      contentClassName="max-w-lg"
      title="Add from .env"
    >
      <form>
        <Flex direction="col" className="mt-5 w-full gap-5">
          <Typography.Text uiType="secondary" size="sm">
            Paste your .env contents to add multiple environment variables at
            onece
          </Typography.Text>
          <Textarea
            rows={7}
            {...register("Value")}
            error={errors.Value?.message}
            placeholder={`KEY_1=VALUE_1 \nKEY_2=VALUE_2 \nKEY_3=VALUE_3`}
          />
        </Flex>
        <Flex justify="end" className="mt-4 w-full">
          <Button
            loading={loading}
            uiType="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }}
            className="w-[160px]"
          >
            Add Variables
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default ImportFromEnvFileModal;
