import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getBlockApiApiKeysAysnc,
  selectBlockApiApiKeys,
  selectBlockApiLoading,
  handleShowBlockApiEditorModal,
  deleteBlockApiApiKeyAsync,
  selectBlockApiActionLoading,
} from "../../../store/block-api/apiKeysSlice";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import ApiKeyEditorModal from "./ApiKeyEditorModal";
import { DeleteModal } from "../../modals/QuestionModal";
import Highlighter from "react-highlight-words";
import { useSearch } from "../../../providers/SearchProvider";
import { IpfsApiKey } from "../../../types/ipfs-api-key";
import {
  Typography,
  SimpleTable,
  EmptyState,
  Dropdown,
  Button,
  SecureCopy,
} from "djuno-design";

const BlockApiApiKeysTab = () => {
  const [deleteApiKeyId, setDeleteApiKeyId] = useState<number | null>(null);
  const apiKeys = useAppSelector(selectBlockApiApiKeys);
  const [filteredApiKeys, setFilteredApiKeys] = useState<IpfsApiKey[]>([]);
  const loading = useAppSelector(selectBlockApiLoading);
  const actionLoading = useAppSelector(selectBlockApiActionLoading);
  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    // if (apiKeys.length === 0) {
    dispatch(getBlockApiApiKeysAysnc());
    // }
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = apiKeys?.filter((apiKey) =>
      apiKey.Label?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredApiKeys(lookedUpFiles);
  }, [dispatch, searchValue, apiKeys]);

  return (
    <>
      <div className="flex transition-all duration-500">
        <Typography.Text>Access keys</Typography.Text>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            uiType="primary"
            onClick={() => dispatch(handleShowBlockApiEditorModal({}))}
          >
            Create New
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Label" />
              <SimpleTable.TH lable="Value" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredApiKeys.map((apiKey, index) => (
              <SimpleTable.Row key={index}>
                <SimpleTable.TD className="w-36">
                  <Highlighter
                    highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
                    className="whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium"
                    searchWords={[searchValue]}
                    autoEscape={true}
                    textToHighlight={apiKey.Label}
                  />
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <div className="text-sm dark:text-slate-200">
                    <SecureCopy
                      text={apiKey.KeyValue}
                      type="hide"
                      textClassName="!text-sm"
                      uiSize="small"
                    />
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-10">
                  <div className="w-8 flex justify-center items-center">
                    <Dropdown
                      anchor="bottom end"
                      itemsClassName="!w-40"
                      menu={[
                        {
                          key: "1",
                          label: (
                            <div className="flex items-center gap-1">
                              <EditIcon className="w-4" />
                              Edit
                            </div>
                          ),
                          onClick: (_, close) => {
                            close();
                            dispatch(handleShowBlockApiEditorModal({ apiKey }));
                          },
                        },
                        {
                          type: "divider",
                        },
                        {
                          key: "end",
                          label: (
                            <div className="flex items-center gap-1">
                              <ArchiveIcon className="w-4" />
                              Delete
                            </div>
                          ),
                          danger: true,
                          onClick: (_, close) => {
                            close();
                            setDeleteApiKeyId(apiKey.Id);
                          },
                        },
                      ]}
                    >
                      <Button uiSize="small" uiType="icon" className="!px-2">
                        <MoreIcon className="w-4 h-4" />
                      </Button>
                    </Dropdown>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {apiKeys.length === 0 && (
              <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                <SimpleTable.TD colSpan={3} className="!border-0">
                  <EmptyState text="You have not created any API key yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <ApiKeyEditorModal />

      <DeleteModal
        isOpen={deleteApiKeyId !== null}
        title="Delete Access key"
        onClose={() => setDeleteApiKeyId(null)}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteApiKeyId &&
            dispatch(deleteBlockApiApiKeyAsync({ id: deleteApiKeyId })).then(
              (action) => {
                if (action.type === "block-api/api-keys/delete/fulfilled") {
                  dispatch(getBlockApiApiKeysAysnc());
                }
                setDeleteApiKeyId(null);
              }
            );
        }}
        loading={actionLoading}
        // confirmString={apiKeys.find((api) => api.Id === deleteApiKeyId)?.Label}
      />
    </>
  );
};

export default BlockApiApiKeysTab;
