import { useEffect, useState } from "react";
import {
  selectBucketDetails,
  selectBucketDetailsLoading,
  selectBucketObjects,
  selectCheckedObjects,
  selectObjectsLoading,
} from "../../../store/s3/buckets/bucketSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useSearch } from "../../../providers/SearchProvider";
import { EmptyFileList } from "../../layouts/NotData";
import { S3BucketObject } from "../../../types/s3-bucket";
import BucketObject from "./BucketObject";
import S3ObjectDrawer from "./S3ObjectDrawer";
import { selectShowObjectVersions } from "../../../store/s3/buckets/objectSlice";
import S3ObjectVersions from "./S3ObjectVersions";
import S3SelectedObjectsDrawer from "./S3SelectedObjectsDrawer";
import { Flex, Loading, SimpleTable } from "djuno-design";

function BucketObjectList() {
  const objects = useAppSelector(selectBucketObjects);
  const [filteredObjects, setFilteredObjects] = useState<S3BucketObject[]>([]);
  const objectsLoading = useAppSelector(selectObjectsLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);
  const showObjectVersions = useAppSelector(selectShowObjectVersions);
  // const withVersionsStatus = useAppSelector(selectWithVersions);
  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();
  const checkedObjects = useAppSelector(selectCheckedObjects);

  useEffect(() => {
    const lookedUpFiles = objects?.filter((obj) =>
      obj.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredObjects(lookedUpFiles);
  }, [dispatch, searchValue, objects]);

  // const handleChangeShowDeletedObjects = (e: ChangeEvent<HTMLInputElement>) => {
  //   dispatch(handleChangeWithVersions(e.target.checked));
  //   if (bucketDetails) {
  //     dispatch(getBucketObjectsAsync({ name: bucketDetails.name }));
  //   }
  // };

  return (
    <div className="flex flex-col">
      {/* <div className="flex items-start justify-between w-full">
        <div className="w-64"><SearchInput /></div>
        <div className="flex items-start">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              checked={withVersionsStatus}
              className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded ring-0 dark:bg-gray-700 dark:border-gray-600"
              onChange={handleChangeShowDeletedObjects}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-1 text-sm font-medium"
            >
              <Text>Show Deleted Objects</Text>
            </label>
          </div>
        </div>
      </div> */}

      <div className="flex flex-col xl:flex-row">
        <div className="flex-grow transition-all duration-500 w-full xl:w-3/4">
          {!showObjectVersions && (
            <SimpleTable
              loading={objectsLoading || bucketDetailsLoading}
              containerClassName="min-h-[240px]"
            >
              <SimpleTable.Head>
                <SimpleTable.Row>
                  <SimpleTable.TH lable="Name" />
                  <SimpleTable.TH lable="Size" />
                  <SimpleTable.TH lable="Last Modified" />
                </SimpleTable.Row>
              </SimpleTable.Head>
              <SimpleTable.Body>
                {filteredObjects?.map((obj, i) => {
                  return (
                    <BucketObject
                      key={`file-${obj.name}-${i}`}
                      object={obj}
                      bucketDetails={bucketDetails}
                      checked={
                        !!checkedObjects.find((co) => co.name === obj.name)
                      }
                    />
                  );
                })}
                {objects.length === 0 && (
                  <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                    <SimpleTable.TD colSpan={3} className="!border-0">
                      <EmptyFileList />
                    </SimpleTable.TD>
                  </SimpleTable.Row>
                )}
              </SimpleTable.Body>
            </SimpleTable>
          )}
          {showObjectVersions && <S3ObjectVersions />}
        </div>
        <S3ObjectDrawer />
        <S3SelectedObjectsDrawer />
      </div>
    </div>
  );
}

export default BucketObjectList;
