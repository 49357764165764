import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect } from "react";
import { BlockEventDetailSchema } from "../../../utils/validations";
import {
  selectShowBlockEventChangeNameModal,
  selectBlockEventChangeNameLoading,
  selectBlockEvent,
  updateBlockEventNameAsync,
  getBlockEventAsync,
  handleHideBlockEventEditDetailModal,
} from "../../../store/block-event/blockEventsSlice";
import { Modal, Button, Input } from "djuno-design";

const BlockEventChangeNameModal = () => {
  const isOpen = useAppSelector(selectShowBlockEventChangeNameModal);
  const loading = useAppSelector(selectBlockEventChangeNameLoading);

  const dispatch = useAppDispatch();
  const blockEvent = useAppSelector(selectBlockEvent);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(BlockEventDetailSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isOpen) {
      setValue("name", blockEvent?.Name || "");
    }
  }, [blockEvent, isOpen, setValue]);

  const handleClearComponent = () => {
    reset();
  };

  const onSubmit = (data: any) => {
    if (blockEvent) {
      dispatch(
        updateBlockEventNameAsync({
          newName: data.name,
          blockEvent,
        })
      ).then((action) => {
        if (action.type === "block-events/update/name/fulfilled") {
          handleClearComponent();
          dispatch(handleHideBlockEventEditDetailModal());
          dispatch(getBlockEventAsync(blockEvent.BlockEventId));
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideBlockEventEditDetailModal());
        handleClearComponent();
      }}
      contentClassName="max-w-lg overflow-hidden"
      // containerClassName="!items-start"
      title={"Edit details"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Name your block event"
            {...register("name")}
            type="text"
            error={errors.name?.message}
            placeholder=""
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            uiType="primary"
            type="submit"
            className="w-[100px]"
          >
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default BlockEventChangeNameModal;
