import { useEffect, useMemo } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { useAppSelector } from "../hooks";
import {
  selectIsAuthenticate,
  selectOnStageEnv,
  selectUser,
} from "../store/auth/authSlice";
import { useLocation } from "react-router-dom";

const IntercomProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const InterComeAppId = process.env.REACT_APP_INTERCOME_APP_ID || "APP_ID";

  const user = useAppSelector(selectUser);
  const env = useAppSelector(selectOnStageEnv);
  const isAuth = useAppSelector(selectIsAuthenticate);
  const location = useLocation();

  const userData = useMemo(() => {
    if (user && env) {
      return {
        name: user.FullName,
        email: user.Email,
        user_id: user.Id.toString(),
        user_hash: user.HashedUId,
        created_at: user.CreatedAt,
        env_name: env.Name + "-" + env.Id,
      };
    } else {
      return undefined;
    }
  }, [env, user]);

  useEffect(() => {
    if (userData) {
      Intercom({
        app_id: InterComeAppId,
        ...userData,
      });
    } else {
      Intercom({
        app_id: InterComeAppId,
      });
    }
  }, [InterComeAppId, userData, isAuth, env]);

  useEffect(() => {
    if (window.Intercom && typeof window.Intercom === "function") {
      window.Intercom("update");
    }
  }, [location.pathname]);

  return <>{children}</>;
};

export default IntercomProvider;
