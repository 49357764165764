import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  IWorkflowWallet,
  IWorkflowWalletCredentials,
  WorkflowWalletsState,
} from "../../types/workflows";
import {
  createWorkflowWalletApi,
  deleteWorkflowWalletApi,
  getWorkflowWalletApi,
  getWorkflowWalletCredentialsApi,
  getWorkflowWalletsApi,
  updateWorkflowWalletApi,
} from "../../apis/workflowsAPI";
import { changeWorkflowActiveStatus } from "./workflowsSlice";

const initialState: WorkflowWalletsState = {
  wallets: [],
  loading: false,

  actionLoading: false,

  selectedWalletLoading: false,
  selectedWallet: null,
  selectedWalletCredentialsLoading: false,
  selectedWalletCredentials: null,

  walletShowDeleteModal: false,
};

export const getWorkflowWalletsAsync = createAsyncThunk<
  { wallets: Array<IWorkflowWallet> },
  undefined,
  IThunkRejectValue
>(
  "workflows/wallets",
  async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getWorkflowWalletsApi();
      const { NodeStatus, Result: wallets } = response.data;

      dispatch(changeWorkflowActiveStatus(NodeStatus === 2));
      return fulfillWithValue({ wallets: wallets || [] });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWorkflowWalletAsync = createAsyncThunk<
  { wallet: IWorkflowWallet },
  string,
  IThunkRejectValue
>("workflows/wallet", async (id, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getWorkflowWalletApi(id);
    const wallet = response.data.Result;
    return fulfillWithValue({ wallet });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getWorkflowWalletCredentialsAsync = createAsyncThunk<
  { credentials: IWorkflowWalletCredentials },
  string,
  IThunkRejectValue
>(
  "workflows/wallet/credentials",
  async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWorkflowWalletCredentialsApi(id);
      const credentials = response.data.Result;
      return fulfillWithValue({ credentials });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createWorkflowWalletAsync = createAsyncThunk<
  boolean,
  any,
  IThunkRejectValue
>("workflows/wallet/create", async (body, { rejectWithValue }) => {
  try {
    await createWorkflowWalletApi(body);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateWorkflowWalletAsync = createAsyncThunk<
  boolean,
  { id: string; body: any },
  IThunkRejectValue
>("workflows/wallet/update", async ({ id, body }, { rejectWithValue }) => {
  try {
    await updateWorkflowWalletApi(id, body);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteWorkflowWalletAsync = createAsyncThunk<
  boolean,
  string,
  IThunkRejectValue
>("workflows/wallet/delete", async (id, { rejectWithValue }) => {
  try {
    await deleteWorkflowWalletApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const workflowWalletsSlice = createSlice({
  name: "workflow-wallets",
  initialState,
  reducers: {
    handleClearWalletsSlice: (state) => {
      state.selectedWalletCredentials = null;
      state.selectedWallet = null;
    },
    handleWalletDeleteShowModal: (
      state,
      action: PayloadAction<IWorkflowWallet>
    ) => {
      state.walletShowDeleteModal = true;
      state.selectedWallet = action.payload;
    },
    handleInstanceDeleteHideModal: (state) => {
      state.walletShowDeleteModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflowWalletsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getWorkflowWalletsAsync.fulfilled,
        (state, { payload: { wallets } }) => {
          state.wallets = wallets;
          state.loading = false;
        }
      )
      .addCase(getWorkflowWalletsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWorkflowWalletAsync.pending, (state) => {
        state.selectedWalletLoading = true;
      })
      .addCase(
        getWorkflowWalletAsync.fulfilled,
        (state, { payload: { wallet } }) => {
          state.selectedWallet = wallet;
          state.selectedWalletLoading = false;
        }
      )
      .addCase(getWorkflowWalletAsync.rejected, (state, { payload }) => {
        state.selectedWalletLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWorkflowWalletCredentialsAsync.pending, (state) => {
        state.selectedWalletCredentialsLoading = true;
      })
      .addCase(
        getWorkflowWalletCredentialsAsync.fulfilled,
        (state, { payload: { credentials } }) => {
          state.selectedWalletCredentials = credentials;
          state.selectedWalletCredentialsLoading = false;
        }
      )
      .addCase(
        getWorkflowWalletCredentialsAsync.rejected,
        (state, { payload }) => {
          state.selectedWalletCredentialsLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(createWorkflowWalletAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createWorkflowWalletAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createWorkflowWalletAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateWorkflowWalletAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateWorkflowWalletAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateWorkflowWalletAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWorkflowWalletAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWorkflowWalletAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteWorkflowWalletAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWorkflowWallets = (state: RootState) =>
  state.workflowWallets.wallets;
export const selectWorkflowWalletsLoading = (state: RootState) =>
  state.workflowWallets.loading;

export const selectWorkflowSelectedWallet = (state: RootState) =>
  state.workflowWallets.selectedWallet;
export const selectWorkflowSelectedWalletLoading = (state: RootState) =>
  state.workflowWallets.selectedWalletLoading;

export const selectWorkflowSelectedWalletCredentials = (state: RootState) =>
  state.workflowWallets.selectedWalletCredentials;
export const selectWorkflowSelectedWalletCredentialsLoading = (
  state: RootState
) => state.workflowWallets.selectedWalletCredentialsLoading;

export const selectWorkflowWalletActionLoading = (state: RootState) =>
  state.workflowWallets.actionLoading;

export const selectwalletShowDeleteModal = (state: RootState) =>
  state.workflowWallets.walletShowDeleteModal;

// export const selectShowEditorModal = (state: RootState) =>
//   state.workflowsApiKeys.showEditorModal;
// export const selectSelectedWorkflowApiKey = (state: RootState) =>
//   state.workflowsApiKeys.selectedApiKey;

export const {
  handleClearWalletsSlice,
  handleInstanceDeleteHideModal,
  handleWalletDeleteShowModal,
} = workflowWalletsSlice.actions;
export default workflowWalletsSlice.reducer;
