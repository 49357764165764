import { PlansTab } from "../../plans/UniversalPlansBox";

const MarketApiPlansTab = () => {
  const marketApiServiceType =
    Number(process.env.REACT_APP_MARKET_API_SERVICE_TYPE) || 9;
  return (
    <>
      <PlansTab serviceType={marketApiServiceType} />
    </>
  );
};

export default MarketApiPlansTab;
