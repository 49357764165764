import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  handleCloseEDestinationsEditor,
  selectShowBlockEventDestinationsModal,
  selectBlockEventDestinationEditLoading,
  editDestinationsAsync,
} from "../../../store/block-event/blockEventCreateSlice";
import {
  getBlockEventAsync,
  selectBlockEvent,
} from "../../../store/block-event/blockEventsSlice";
import SelectDestinationStep from "./create-page-steps/SelectDestinationStep";
import { Modal, Button } from "djuno-design";

const BlockEventDestinationsModal = () => {
  const blockEvent = useAppSelector(selectBlockEvent);
  const isOpen = useAppSelector(selectShowBlockEventDestinationsModal);
  const loading = useAppSelector(selectBlockEventDestinationEditLoading);
  const dispatch = useAppDispatch();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleCloseEDestinationsEditor());
      }}
      contentClassName="!max-w-xl overflow-hidden"
      // containerClassName="!items-start"
      title={"Edit Destinations"}
    >
      <div className="max-h-[70vh] overflow-y-auto mt-5">
        <SelectDestinationStep withoutHeader />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading}
          uiType="primary"
          onClick={() => {
            blockEvent &&
              dispatch(editDestinationsAsync({ blockEvent })).then((action) => {
                if (action.type === "block-event/destinations/edit/fulfilled") {
                  dispatch(getBlockEventAsync(blockEvent.BlockEventId));
                  dispatch(handleCloseEDestinationsEditor());
                }
              });
          }}
          className="w-[100px]"
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default BlockEventDestinationsModal;
