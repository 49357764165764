import { useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatabaseServiceCreateSchema } from "../../../utils/validations";
import { selectDBSCapabilities } from "../../../store/database/servicesSlice";
import { IDBSBackup } from "../../../types/database";
import { humanizeSize } from "../../../utils/file";
import RadioGrouper, {
  RadioGroupeItem,
} from "../../../components/general/RadioGrouper";
import {
  selectDatabaseService,
  selectDBSBackups,
} from "../../../store/database/serviceSlice";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { formatTimestamp } from "../../../utils/date";

import { Flex, Select, Typography } from "djuno-design";
import { DatabaseServiceTypesInput } from "../../../pages/databases/ServiceCreatePage";

const items: RadioGroupeItem<number>[] = [
  { label: "Newest", value: 1 },
  { label: "Specific date", value: 2 },
  { label: "Backup", value: 3 },
];
interface DatabaseRestorPointProps {
  isRestore?: boolean;
  onBackupSelect?: (backup: IDBSBackup | undefined) => void;
  setRestorePointvalue?: (value: number) => void;
}

const DatabaseRestorPoint: React.FC<DatabaseRestorPointProps> = ({
  isRestore = false,
  onBackupSelect,
  setRestorePointvalue,
}) => {
  const capabilities = useAppSelector(selectDBSCapabilities);

  const selectedService = useAppSelector(selectDatabaseService);

  const backups = useAppSelector(selectDBSBackups);

  const [availableEnginVersions, setAvailableEnginVersions] = useState<
    Array<string>
  >([]);

  //for duplicate fork
  const params = useParams();
  const isDatabaseSelected = Boolean(params.databaseId);

  const location = useLocation();
  const { state } = location;
  const initialSelectedBackup = state?.selectedBackup;
  // const initialSelectedItemValue = state?.selectedValue || 1;
  const initialSelectedItemValue = state?.selectedValue || 3;

  const [selectedItem, setSelectedItem] = useState<RadioGroupeItem<number>>(
    // items.find((item) => item.value === initialSelectedItemValue) || items[0]
    items.find((item) => item.value === initialSelectedItemValue) || items[2]
  );

  const [selectedBackup, setSelectedBackup] = useState<IDBSBackup | undefined>(
    initialSelectedBackup
  );
  const [specificDate, setSpecificDate] = useState(dayjs().toISOString());

  const handleDateChange = (newValue: any) => {
    setSpecificDate(newValue.toISOString());
  };
  const minDate = useMemo(() => {
    return dayjs(
      Math.min(...backups.map((b) => new Date(b.createdAt).getTime()))
    );
  }, [backups]);

  const maxDate = useMemo(() => {
    return dayjs(
      Math.max(...backups.map((b) => new Date(b.createdAt).getTime()))
    );
  }, [backups]);
  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(DatabaseServiceCreateSchema()),
    reValidateMode: "onChange",
    mode: "all",
  });

  // watch form values
  const selectedEngineName = watch("SelectedEngine");

  // all operational engines
  const engines = useMemo(() => {
    if (capabilities && capabilities.engines) {
      return capabilities.engines.filter((e) => e.category === "operational");
    }
    return [];
  }, [capabilities]);

  // full data of selected engine
  const selectedEngine = useMemo(() => {
    if (capabilities && capabilities.engines && selectedEngineName) {
      return capabilities.engines.find((e) => e.name === selectedEngineName);
    }
  }, [capabilities, selectedEngineName]);

  useEffect(() => {
    if (onBackupSelect) {
      onBackupSelect(selectedBackup);
    }
  }, [selectedBackup, onBackupSelect]);

  return (
    <>
      <div className=" w-full  flex flex-col lg:flex-row pb-10 gap-8 relative ">
        <div className="w-full flex flex-col ">
          {/* duplicate fork */}
          <div>
            <Flex direction="col">
              <Typography.Text className="!text-base !font-medium">
                {selectedService && isDatabaseSelected
                  ? isRestore
                    ? ""
                    : "Duplicate your database service (fork)"
                  : "Select your database type"}
              </Typography.Text>
              <Typography.Text uiType="secondary" className="!text-sm mt-1">
                {selectedService && isDatabaseSelected
                  ? isRestore
                    ? "Restoring a backup overwrites the information currently saved on your cluster. This operation may result in a loss of data."
                    : "Your backup fork will be run on a new cluster."
                  : "Select a database type to suit your needs"}
              </Typography.Text>
            </Flex>
            <div className="mt-3">
              {selectedService && isDatabaseSelected ? (
                <>
                  <div className="flex flex-col ">
                    <Typography.Text className="!text-lg !font-medium">
                      Restore point
                    </Typography.Text>
                    <Typography.Text className="!text-sm mt-1 mb-1">
                      Select the restore point from which the service will be
                      duplicated
                    </Typography.Text>
                  </div>
                  {/* <RadioGrouper
                    items={items}
                    selected={selectedItem}
                    setSelected={setSelectedItem}
                    dir="row"
                    disabled={
                      !selectedService.capabilities.backupTime.update
                        ? true
                        : false
                    }
                  /> */}
                  <div className="flex flex-row gap-5 mt-3">
                    {items.map((item) => (
                      <div key={item.value} className="flex items-center ">
                        <RadioGrouper
                          items={[item]}
                          selected={selectedItem}
                          setSelected={(selected) => {
                            // if (item.value === 3) {
                            setSelectedItem(selected);
                            if (setRestorePointvalue) {
                              setRestorePointvalue(selected.value);
                            }
                            // }
                          }}
                          disabled={
                            (selectedService.engine === "mongodb" ||
                              backups.length === 0) &&
                            (item.value === 1 || item.value === 2)
                          }
                        />
                      </div>
                    ))}
                  </div>

                  {selectedItem.value === 2 && (
                    <div className="mt-2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          views={[
                            "year",
                            "month",
                            "day",
                            "hours",
                            "minutes",
                            "seconds",
                          ]}
                          value={dayjs(specificDate)}
                          onChange={handleDateChange}
                          minDate={minDate} // Setting minimum date
                          maxDate={maxDate} // Setting maximum date
                        />
                      </LocalizationProvider>
                    </div>
                  )}

                  {selectedItem.value === 3 && (
                    <div className="mt-2">
                      <Controller
                        name="SelectedBackup"
                        control={control}
                        defaultValue={selectedBackup ? selectedBackup.id : ""}
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Select
                              error={backups.length === 0}
                              label={
                                backups.length === 0
                                  ? "No backups available. Please create a backup first."
                                  : ""
                              }
                              className="mt-2"
                              options={backups.map((backup) => ({
                                label:
                                  formatTimestamp(
                                    backup.createdAt,
                                    "MMM DD hh:mm:ss "
                                  ).datetime +
                                  " " +
                                  humanizeSize(Number(backup.size.value), {
                                    fractionDigits: 2,
                                  }).join("B"),

                                value: backup.id,
                              }))}
                              value={value?.toString() ?? undefined}
                              onChange={(value) => {
                                const backup = backups.find(
                                  (b) => b.id === value
                                );
                                setSelectedBackup(backup);
                                onChange(value);
                              }}
                              emptyString="Select a version"
                            />
                          </>
                        )}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <DatabaseServiceTypesInput
                    engines={engines}
                    control={control}
                    errorMessage={errors.SelectedEngine?.message}
                  />
                  <div className="w-1/2">
                    <Typography.Text className="!text-sm mt-1">
                      Select Version
                    </Typography.Text>
                    <Controller
                      name="SelectedEngineVersion"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          label=""
                          className="mt-2"
                          options={
                            selectedEngine
                              ? [
                                  ...selectedEngine.versions.map((v) => ({
                                    label: v,
                                    value: v,
                                    disabled:
                                      !availableEnginVersions.includes(v),
                                  })),
                                ]
                              : []
                          }
                          value={value?.toString()}
                          onChange={onChange}
                          emptyString="Select a version"
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatabaseRestorPoint;
