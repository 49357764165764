import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  DatabaseServiceCreateUrl,
  DatabaseServiceGeneralInformationUrl,
} from "./../../../utils/urls";
import { DeleteModal } from "./../../../components/modals/QuestionModal";
import { useSearch } from "./../../../providers/SearchProvider";
import {
  DatabaseRefreshStatus,
  deleteDBSAsync,
  getDatabaseServicesAsync,
  handleShowDatabaseRenameModal,
  selectDatabaseServices,
  selectDatabaseServicesActionLoading,
  selectDatabaseServicesLoading,
} from "./../../../store/database/servicesSlice";
import { DBSEngineType, DatabaseService } from "./../../../types/database";
import { formatTimestamp } from "./../../../utils/date";
import { capitalizeFirstLetter } from "./../../../utils";
import DatabaseRenameModal from "./DatabaseRenameModal";

import { useAppDispatch, useAppSelector } from "./../../../hooks";
import { useEffect, useMemo, useState } from "react";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as DetailsIcon } from "./../../../assets/icons/bars-3-bottom-left.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as MongoIcon } from "./../../../assets/icons/db/mongoDB.svg";
import { ReactComponent as CircleStackIcon } from "./../../../assets/icons/circle-stack.svg";
import { ReactComponent as MongoTypeIcon } from "./../../../assets/icons/db/mongodb-logo.svg";
import { ReactComponent as MySQLIcon } from "./../../../assets/icons/db/mysql-logo.svg";
import { ReactComponent as PostgreIcon } from "./../../../assets/icons/db/postgresql.svg";
import { ReactComponent as RedisIcon } from "./../../../assets/icons/db/redis-logo.svg";
import { ReactComponent as CassandraIcon } from "./../../../assets/icons/db/cassandra-logo.svg";
import {
  Button,
  cn,
  Dropdown,
  EmptyState,
  SimpleTable,
  Tag,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";
import useServiceType from "../../../hooks/useServiceType";
import { useTour } from "../../../providers/TourProvider";

const DatabaseServicesTab = () => {
  const { value: searchValue } = useSearch();

  const { serviceType } = useServiceType(
    process.env.REACT_APP_DATABASES_SERVICE_TYPE
  );

  const [deleteService, setDeleteService] = useState<DatabaseService | null>(
    null
  );

  const services = useAppSelector(selectDatabaseServices);
  const [filteredServices, setFilteredServices] = useState<DatabaseService[]>(
    []
  );
  const loading = useAppSelector(selectDatabaseServicesLoading);
  const actionLoading = useAppSelector(selectDatabaseServicesActionLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { run } = useTour("databases");

  useEffect(() => {
    dispatch(getDatabaseServicesAsync({}));
    // dispatch(getDBSCapabilitiesAsync());
    // dispatch(getDBSAvailabilityAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpFiles = services?.filter((service) =>
      service.description.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredServices(lookedUpFiles);
  }, [dispatch, searchValue, services]);

  //refresh the list
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime =
      Number(process.env.REACT_APP_NODES_REFRESH_INTERVAL) || 20000;

    const shouldSetInterval = services.some((service) =>
      DatabaseRefreshStatus.includes(service.status)
    );

    if (shouldSetInterval) {
      interval = setInterval(() => {
        dispatch(getDatabaseServicesAsync({ withoutLoading: true }));
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, services]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="">
        <div className="flex items-center justify-between databases-header">
          <div className="items-center justify-between flex flex-1 transition duration-150">
            <Typography.Text className="font-medium text-lg"></Typography.Text>
          </div>
          <div className="flex items-center gap-2">
            <Button
              onClick={() => {
                run();
              }}
            >
              Start Tour
            </Button>
            <Button
              onClick={() => dispatch(getDatabaseServicesAsync({}))}
              className="refresh-button group"
              tooltip={{ content: "Refresh" }}
            >
              <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
            <Button
              uiType="primary"
              onClick={() => navigate(DatabaseServiceCreateUrl)}
              className="create-database-button group"
            >
              Create a Service
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          </div>
        </div>

        <div className="mt-5 w-full database-table">
          <SimpleTable loading={loading} containerClassName="min-h-[240px]">
            <SimpleTable.Head>
              <SimpleTable.Row>
                <SimpleTable.TH lable="Name" />
                <SimpleTable.TH lable="Engine" />
                <SimpleTable.TH lable="Solution" />
                <SimpleTable.TH lable="Node template" />
                <SimpleTable.TH lable="Usable storage" />
                <SimpleTable.TH lable="Location" />
                <SimpleTable.TH lable="Nodes" />
                <SimpleTable.TH lable="Creation date" />
                <SimpleTable.TH lable="Status" />
                <SimpleTable.TH lable="" />
              </SimpleTable.Row>
            </SimpleTable.Head>
            <SimpleTable.Body>
              {filteredServices.map((service, i) => (
                <SimpleTable.Row
                  key={i}
                  onClick={() =>
                    navigate(DatabaseServiceGeneralInformationUrl(service.id))
                  }
                >
                  <SimpleTable.TD className="cursor-pointer">
                    <div className="flex items-center flex-row justify-between space-x-3">
                      <HighlighterText
                        searchValue={searchValue}
                        textToHighlight={service.description}
                      />
                    </div>
                  </SimpleTable.TD>
                  <SimpleTable.TD className="cursor-pointer">
                    <Typography.Text
                      className="!text-xs md:!text-sm flex items-center whitespace-nowrap gap-0.5"
                      // type="neutral-alert"
                    >
                      {getDBserviceIcon(service.engine)}
                      {`${service.engine} ${service.version}`}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="cursor-pointer">
                    <Typography.Text className="!text-xs md:!text-sm">
                      {service.plan}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="cursor-pointer">
                    <Typography.Text className="!text-xs md:!text-sm"></Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="cursor-pointer">
                    <Typography.Text className="!text-xs md:!text-sm">
                      {`${service.storage.size.value} ${service.storage.size.unit}`}
                    </Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="cursor-pointer">
                    <Typography.Text className="!text-xs md:!text-sm"></Typography.Text>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="cursor-pointer">
                    <div className="flex items-center gap-0.5">
                      {service.nodes.map((node, j) => (
                        <div
                          key={j}
                          className={cn("w-3 aspect-square rounded-full", {
                            "bg-success": node.status === "READY",
                            "bg-secondary-200 dark:bg-secondary-500":
                              node.status !== "READY",
                          })}
                        />
                      ))}
                    </div>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="cursor-pointer">
                    <Typography.Text className="!text-xs md:!text-sm">
                      {formatTimestamp(service.createdAt, "DD/MM/YYYY").date}
                    </Typography.Text>
                  </SimpleTable.TD>
                  <SimpleTable.TD className="cursor-pointer">
                    <Tag
                      className="text-xs"
                      color={
                        service.status === "CREATING"
                          ? "warning"
                          : service.status === "READY"
                          ? "success"
                          : "processing"
                      }
                    >
                      {service.status}
                    </Tag>
                  </SimpleTable.TD>

                  <SimpleTable.TD className="w-32 gap-1">
                    <div className="h-full w-full inline-flex items-center justify-center gap-1">
                      <div className="w-8 flex justify-center items-center">
                        <Dropdown
                          anchor="bottom end"
                          itemsClassName="!w-48"
                          menu={[
                            {
                              key: "1",
                              label: (
                                <div className="flex items-center gap-1">
                                  <DetailsIcon className="w-4" />
                                  General Information
                                </div>
                              ),
                              onClick: (_, close) => {
                                close();
                                navigate(
                                  DatabaseServiceGeneralInformationUrl(
                                    service.id
                                  )
                                );
                              },
                              disabled: loading,
                            },
                            {
                              key: "rename",
                              label: (
                                <div className="flex items-center gap-1">
                                  <EditIcon className="w-4" />
                                  Rename database
                                </div>
                              ),
                              onClick: (_, close) => {
                                close();
                                dispatch(
                                  handleShowDatabaseRenameModal(service)
                                );
                              },
                              disabled: loading || service.status !== "READY",
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "end",
                              label: (
                                <div className="flex items-center gap-1">
                                  <ArchiveIcon className="w-4" />
                                  Delete
                                </div>
                              ),
                              danger: true,
                              onClick: (_, close) => {
                                close();
                                setDeleteService(service);
                              },
                              disabled: loading || service.status !== "READY",
                            },
                          ]}
                        >
                          <Button
                            uiType="icon"
                            uiSize="small"
                            className="!px-2"
                          >
                            <MoreIcon className="w-4 h-4" />
                          </Button>
                        </Dropdown>
                      </div>
                      <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                    </div>
                  </SimpleTable.TD>
                </SimpleTable.Row>
              ))}

              {services.length === 0 && (
                <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                  <SimpleTable.TD colSpan={10} className="!border-0">
                    <EmptyState text="You have not created any service yet" />
                  </SimpleTable.TD>
                </SimpleTable.Row>
              )}
            </SimpleTable.Body>
          </SimpleTable>
        </div>
        <DatabaseRenameModal />
        <DeleteModal
          title="Delete a Database Service"
          isOpen={deleteService !== null}
          onClose={() => setDeleteService(null)}
          description=""
          confirmButtonText="Confirm"
          confirmButtonType="danger"
          confirmButtonClassName="w-full"
          loading={actionLoading}
          onConfirm={() => {
            deleteService &&
              dispatch(
                deleteDBSAsync({
                  clusterId: deleteService.id,
                  engine: deleteService.engine,
                })
              ).then((action) => {
                if (action.type === "db-services/delete/fulfilled") {
                  dispatch(getDatabaseServicesAsync({}));
                  setDeleteService(null);
                }
              });
          }}
          confirmString={deleteService?.description}
        />
      </div>
    </>
  );
};

const getDBserviceIcon = (serviceType: DBSEngineType) => {
  switch (serviceType) {
    case "mongodb":
      return <MongoIcon className="w-5 aspect-square" />;

    case "mysql":
      return <MySQLIcon className="w-5 aspect-square" />;

    case "postgresql":
      return <PostgreIcon className="w-5 aspect-square" />;

    case "redis":
      return <RedisIcon className="w-5 aspect-square" />;

    case "cassandra":
      return <CassandraIcon className="w-5 aspect-square" />;

    case "m3db":
      return <CircleStackIcon className="w-5 aspect-square" />;

    case "m3aggregator":
      return <CircleStackIcon className="w-5 aspect-square" />;

    default:
      return <CircleStackIcon className="w-5 aspect-square" />;
  }
};

export const getDBserviveData = (serviceType: string) => {
  switch (serviceType) {
    case "mongodb":
      return {
        title: "MongoDB",
        Icon: <MongoTypeIcon className="h-16" />,
      };
    case "mysql":
      return {
        title: "MySQL",
        Icon: <MySQLIcon className="h-16" />,
      };
    case "postgresql":
      return {
        title: "PostgreSQL",
        Icon: <PostgreIcon className="h-10" />,
      };
    case "redis":
      return {
        title: "Redis™*",
        Icon: <RedisIcon className="h-10" />,
      };
    case "cassandra":
      return {
        title: "Cassandra",
        Icon: <CassandraIcon className="h-12" />,
      };
    case "m3db":
      return {
        title: "M3DB",
        Icon: <CircleStackIcon className="h-10" />,
      };
    case "m3aggregator":
      return {
        title: "M3 Aggregator",
        Icon: <CircleStackIcon className="h-10" />,
      };
    default:
      return {
        title: capitalizeFirstLetter(serviceType),
        Icon: <CircleStackIcon className="h-10" />,
      };
  }
};
export default DatabaseServicesTab;
