import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createS3EventAsync,
  selectActionLoading,
  selectLoading,
  selectSelectedS3Event,
} from "../../../../store/s3/eventsSlice";
import { S3WeebhookEventSchema } from "../../../../utils/validations";
import { useEffect } from "react";
import { S3EventsUrl } from "../../../../utils/urls";
import { useNavigate } from "react-router-dom";
import useGetS3Events from "../../../../hooks/useGetS3Events";
import { Button, Input, Textarea } from "djuno-design";

const WebhookForm = () => {
  const event = useAppSelector(selectSelectedS3Event);
  const loading = useAppSelector(selectActionLoading);
  const eventsLoading = useAppSelector(selectLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getS3Events = useGetS3Events();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(S3WeebhookEventSchema),
  });

  useEffect(() => {
    if (event) {
    } else {
    }
  }, [event, setValue]);

  const onSubmit = (data: any) => {
    let keyValues = [
      {
        key: "endpoint",
        value: data.endpoint,
      },
      {
        key: "auth_token",
        value: data.auth_token,
      },
      {
        key: "queue_dir",
        value: data.queue_dir,
      },
      {
        key: "comment",
        value: data.comment,
      },
    ];
    if (data.queue_limit !== null) {
      keyValues = [
        ...keyValues,
        {
          key: "queue_limit",
          value: data.queue_limit.toString(),
        },
      ];
    }
    const formData = {
      key_values: keyValues,
    };

    // if (event) {
    //   dispatch(
    //     updateS3EventAsync({
    //       id: event.Id,
    //       data: {
    //         label: data.label,
    //       },
    //     })
    //   ).then((action) => {
    //     if (action.type === "s3-events/update/fulfilled") {
    //       // handleClearComponent();
    //       dispatch(handleHideEditor());
    //       dispatch(getS3EventsAsync());
    //       reset();
    //     }
    //   });
    // } else {
    dispatch(
      createS3EventAsync({
        event: "webhook",
        identifier: data.identifier,
        data: formData,
      })
    ).then((action) => {
      if (action.type === "s3-events/create/fulfilled") {
        getS3Events(() => {
          reset();
          navigate(S3EventsUrl);
        });
      }
    });
    // }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-5 flex flex-col w-full gap-5">
        <Input
          label="Identifier"
          tooltip={{
            className: "!text-xs",
            content: "Unique descriptive string for this destination",
          }}
          placeholder="Enter Destination Identifier"
          {...register("identifier")}
          error={errors.identifier?.message}
          required
        />

        <Input
          label="Endpoint"
          tooltip={{
            className: "!text-xs",
            content:
              "webhook server endpoint e.g. http://localhost:8080/djib/events",
          }}
          placeholder="Enter Endpoint"
          {...register("endpoint")}
          error={errors.endpoint?.message}
          required
        />

        <Input
          label="Auth Token"
          tooltip={{
            className: "!text-xs",
            content: "opaque string or JWT authorization token",
          }}
          placeholder="Enter Auth Token"
          {...register("auth_token")}
          error={errors.auth_token?.message}
          required
        />

        <Input
          label="Queue Directory"
          tooltip={{
            className: "!text-xs",
            content:
              "Staging directory for undelivered messages e.g. '/home/events'",
          }}
          placeholder="ِEnter Queue Directory"
          {...register("queue_dir")}
          error={errors.queue_dir?.message}
        />

        <Input
          label="Queue Limit"
          tooltip={{
            className: "!text-xs",
            content:
              "maximum limit for undelivered messages, defaults to '10000'",
          }}
          placeholder="Enter Queue Limit"
          {...register("queue_limit")}
          error={errors.queue_limit?.message}
        />

        <Textarea
          label="Comment"
          {...register("comment")}
          error={errors.comment?.message}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading || eventsLoading}
          uiType="primary"
          disabled={loading || eventsLoading}
          type="submit"
        >
          {event ? "Update Evet Target" : "Save Evet Target"}
        </Button>
      </div>
    </form>
  );
};
export default WebhookForm;
