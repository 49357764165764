import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import {
  deleteInstanceAsync,
  getInstancesAsync,
  selectInstancesActionLoading,
} from "./../../../../store/instances/instancesSlice";
import {
  selectInstance,
  selectInstanceLoading,
} from "./../../../../store/instances/instanceSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { InstancesUrl } from "./../../../../utils/urls";
import { DeleteModal } from "./../../../modals/QuestionModal";
import { Button, Card, Typography } from "djuno-design";

const InstanceDeleteTab = () => {
  const [deleteInstance, setDeleteInstance] = useState<boolean>(false);

  const instance = useAppSelector(selectInstance);
  const instanceLoading = useAppSelector(selectInstanceLoading);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const actionLoading = useAppSelector(selectInstancesActionLoading);

  const handleDeleteInstance = async () => {
    if (instance) {
      const action = await dispatch(deleteInstanceAsync({ id: instance.id }));
      if (action.type === "instances/delete/fulfilled") {
        dispatch(getInstancesAsync({}));
        navigate(InstancesUrl);
        setDeleteInstance(false);
      }
    }
  };

  return (
    <div>
      <Card title="Delete">
        <div className="mt-2 grid gap-4 w-full">
          <Typography.Text className="!text-sm">
            Deleting your server will stop any existing processes and destroy
            the disk and backups.
          </Typography.Text>
          <Typography.Text className="!text-sm">
            The data won't be retrievable_snapshots of the server remain intact.
          </Typography.Text>

          <Button
            onClick={() => {
              if (instance) setDeleteInstance(true);
            }}
            uiType="danger"
            loading={instanceLoading}
            disabled={instanceLoading || actionLoading}
          >
            Delete instance
          </Button>
        </div>
      </Card>
      <DeleteModal
        isOpen={deleteInstance}
        title="Delete Instance"
        onClose={() => setDeleteInstance(false)}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={actionLoading}
        onConfirm={handleDeleteInstance}
        confirmString={instance?.name}
      />
    </div>
  );
};

export default InstanceDeleteTab;
