import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3BucketEventSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import {
  addBucketEventsAsync,
  getBucketEventsAsync,
  handleHideEventEditor,
  selectBucketDetails,
  selectBucketDetailsLoading,
  selectBucketEventsActionLoading,
  selectShowBucketEventEditor,
} from "../../../../../store/s3/buckets/bucketSlice";
import { useEffect, useState } from "react";
import {
  getS3ArnsAsync,
  selectS3Arns,
  selectS3ArnsLoading,
} from "../../../../../store/s3/eventsSlice";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Select,
  Typography,
} from "djuno-design";

const S3SubscribeEventsModal = () => {
  const isOpen = useAppSelector(selectShowBucketEventEditor);
  const actionLoading = useAppSelector(selectBucketEventsActionLoading);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  const arns = useAppSelector(selectS3Arns);
  const arnsLoading = useAppSelector(selectS3ArnsLoading);

  const dispatch = useAppDispatch();

  const [selectedARN, setSelectedARN] = useState<string>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(S3BucketEventSchema),
  });

  useEffect(() => {
    if (isOpen && arns.length === 0) {
      dispatch(getS3ArnsAsync());
    }
  }, [dispatch, arns.length, isOpen]);

  const handleClearComponent = () => {
    reset();
    setSelectedARN(undefined);
  };

  const onSubmit = (data: any) => {
    console.log(data);
    if (bucketDetails && selectedARN) {
      let events = [];
      if (isPut) events.push("put");
      if (isGet) events.push("get");
      if (isDelete) events.push("delete");
      if (isReplica) events.push("replica");
      if (isIlm) events.push("ilm");
      if (isScanner) events.push("scanner");
      const apiData = {
        configuration: {
          arn: selectedARN,
          events,
          prefix: data.prefix,
          suffix: data.suffix,
        },
        ignoreExisting: true,
      };
      console.log(apiData);
      dispatch(
        addBucketEventsAsync({
          bucketName: bucketDetails.name,
          data: apiData,
        })
      ).then((action) => {
        if (action.type === "bucket/events/add/fulfilled") {
          handleClearComponent();
          dispatch(handleHideEventEditor());
          dispatch(getBucketEventsAsync({ name: bucketDetails.name }));
        }
      });
    }
  };

  const isPut = watch("put");
  const isGet = watch("get");
  const isDelete = watch("delete");
  const isReplica = watch("replica");
  const isIlm = watch("ilm");
  const isScanner = watch("scanner");
  const isAllowToSubmit =
    selectedARN !== undefined &&
    (isPut || isDelete || isGet || isIlm || isReplica || isScanner);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideEventEditor());
        handleClearComponent();
      }}
      contentClassName="!max-w-2xl"
      title="Subscribe To Bucket Events"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-3">
          <Select
            label="ARN"
            options={[...arns.map((arn) => ({ label: arn, value: arn }))]}
            loading={arnsLoading}
            value={selectedARN}
            onChange={setSelectedARN}
            error={selectedARN ? undefined : "ARN is a required field!"}
          />
          <Input
            label="Prefix"
            {...register("prefix")}
            error={errors.prefix?.message}
          />
          <Input
            label="Suffix"
            {...register("suffix")}
            error={errors.suffix?.message}
          />

          <div className="flex flex-col gap-2">
            <Typography.Text className="!text-sm">Select Event</Typography.Text>
            <Controller
              control={control}
              name="put"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id="put"
                  label="PUT - Object uploaded"
                  value={value || undefined}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="get"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id="get"
                  label="GET - Object accessed"
                  value={value || undefined}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="delete"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id="delete"
                  label="DELETE - Object deleted"
                  value={value || undefined}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="replica"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id="replication"
                  label="REPLICA - Object replicated"
                  value={value || undefined}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="ilm"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id="ILM"
                  label="ILM - Object transitioned"
                  value={value || undefined}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="scanner"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id="scanner"
                  label="SCANNER - Object has too many versions / Prefixes has too many sub-folders"
                  value={value || undefined}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(handleHideEventEditor());
            }}
            uiType="light"
          >
            Cancel
          </Button>
          <Button
            loading={actionLoading}
            uiType="primary"
            disabled={bucketDetailsLoading || !isAllowToSubmit}
            type="submit"
          >
            Set
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3SubscribeEventsModal;
