import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getMeAsync,
  resendVerificationEmailAsync,
  selectEmailVerificationLoading,
  selectUser,
} from "../../store/auth/authSlice";
import { ReactComponent as EmailIcon } from "./../../assets/icons/verify-email.svg";
import { Button, Countdown, Divider, Flex, Typography } from "djuno-design";

const EmailNotVerify = () => {
  const dispatch = useAppDispatch();
  const [userLoading, setUserLoading] = useState(false);
  const authUser = useAppSelector(selectUser);
  const loading = useAppSelector(selectEmailVerificationLoading);
  const handleResendVerificationEmail = () => {
    if (!loading && !userLoading)
      dispatch(resendVerificationEmailAsync()).then((action) => {
        setUserLoading(true);
        if (action.type === "auth/resend-verification-email/fulfilled") {
          dispatch(getMeAsync({ withoutLoading: true })).then(() => {
            setUserLoading(false);
          });
        }
      });
  };

  return (
    <Flex className="px-4 md:px-6 max-w-[1200px] mx-auto h-[calc(100vh-4rem)] overflow-y-hidden">
      <Flex
        direction="col"
        items="center"
        justify="center"
        className="w-full place-self-center "
      >
        <Flex direction="col" items="center" className="max-w-xl gap-4">
          <EmailIcon className="w-20 text-slate-500 dark:text-slate-300" />
          <Typography.Title level={5} className="text-center !mb-0">
            Verify your email address
          </Typography.Title>
          <Typography.Text className="!text-sm text-center">
            Please verify your email address by clicking the link we just sent
            to your inbox
          </Typography.Text>

          <Flex direction="col" className="w-full">
            <Divider
              text="Need a new verification email?"
              textClassName="!text-xs"
            />
            <Countdown
              seconds={authUser?.RemainSecondsOfVerification || 0}
              className="flex-col !gap-2"
              timerPosition="end"
              timerRender={({ formatedTime, timeLeft }) => {
                return (
                  <Typography.Text className="!text-xs !h-5">
                    {timeLeft === 0
                      ? ""
                      : `Resend after ${formatedTime} seconds`}
                  </Typography.Text>
                );
              }}
            >
              {({ disabled }) => (
                <Button
                  loading={loading || userLoading}
                  uiType="light"
                  onClick={handleResendVerificationEmail}
                  disabled={disabled}
                >
                  Resend verification email
                </Button>
              )}
            </Countdown>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmailNotVerify;
