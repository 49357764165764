import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddCredentialSchema } from "../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  addCredentialAsync,
  closeAddCredentialModal,
  getCredentialsAsync,
  getRegisteriesTypesAsync,
  selectAddCredentialModalIsOpen,
  selectCredentialAddLoading,
  selectRegisteriesTypes,
  selectRegisteriesTypesLoading,
} from "../../../store/settings/registeries/registeriesSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getRegisteryData } from "./RegisteriesTab";
import {
  Button,
  Typography,
  Modal,
  Input,
  Select,
  SelectOption,
} from "djuno-design";

const AddCredentialModal = () => {
  const isOpen = useAppSelector(selectAddCredentialModalIsOpen);
  const loading = useAppSelector(selectCredentialAddLoading);
  const dispatch = useAppDispatch();

  const registeryTypes = useAppSelector(selectRegisteriesTypes);
  const registeryTypesLoading = useAppSelector(selectRegisteriesTypesLoading);

  const [selectedRegistryId, setSelectedRegistryId] = useState<string>();

  useEffect(() => {
    if (isOpen && registeryTypes.length === 0) {
      dispatch(getRegisteriesTypesAsync());
    }
  }, [dispatch, isOpen, registeryTypes.length]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(AddCredentialSchema),
  });

  const onSubmit = (data: any) => {
    dispatch(addCredentialAsync(data)).then((action) => {
      if (action.type === "registeries/credentials/add/fulfilled") {
        clearModal();
        dispatch(closeAddCredentialModal());
        dispatch(getCredentialsAsync());
      }
    });
  };

  const clearModal = useCallback(() => {
    setSelectedRegistryId(undefined);
    reset();
  }, [reset]);

  const registeryOptions: SelectOption[] = useMemo(() => {
    return [
      ...registeryTypes.map((type) => {
        let data = getRegisteryData(type.Value);
        return {
          label: (
            <div className="flex items-center gap-2">
              {data.Icon}
              <Typography.Text>{type.Text}</Typography.Text>
            </div>
          ),
          value: type.Value?.toString(),
          extraElement: data.Description,
        };
      }),
    ];
  }, [registeryTypes]);

  const selectedRegistry = useMemo(() => {
    return registeryOptions.find((o) => o.value === selectedRegistryId);
  }, [selectedRegistryId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        clearModal();
        dispatch(closeAddCredentialModal());
      }}
      contentClassName="max-w-xl"
      //   containerClassName="!items-start"
      title="Add Credential"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <Input
            label="Name"
            {...register("Name")}
            error={errors.Name?.message}
            placeholder=""
          />
          <div>
            <Select
              label="Registery"
              options={registeryOptions}
              value={selectedRegistryId}
              onChange={(o) => {
                setValue("RegisteryType", o ? +o : -1, {
                  shouldValidate: true,
                });
                setSelectedRegistryId(o);
              }}
              error={errors.RegisteryType?.message}
              loading={registeryTypesLoading}
              clearable
            />
            {selectedRegistry?.extraElement && (
              <div className="mt-3">{selectedRegistry?.extraElement}</div>
            )}
          </div>
          <Input
            label="Username"
            {...register("UserName")}
            error={errors.UserName?.message}
            placeholder=""
          />
          <Input
            label="Personal Access Token or Password"
            {...register("Sec_Key")}
            type="password"
            error={errors.Sec_Key?.message}
            placeholder=""
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
            className="w-[160px]"
          >
            Add Credential
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AddCredentialModal;
