import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import {
  getInstanceAsync,
  selectInstance,
  selectInstanceLoading,
} from "./../../../../store/instances/instanceSlice";
import { getInstancesAsync } from "./../../../../store/instances/instancesSlice";
import { InstancesUrl } from "./../../../../utils/urls";
import { useNavigate, useParams } from "react-router-dom";
import { Flex, SecureCopy, Skeleton, Tag, Typography } from "djuno-design";

const InstanceNetworksTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const instance = useAppSelector(selectInstance);
  const loading = useAppSelector(selectInstanceLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getInstanceAsync({ instanceId: id })).then((action) => {
        if (action && action.type === "instance/rejected") {
          navigate(InstancesUrl);
        }
      });
    }
  }, [dispatch, id, navigate]);

  useEffect(() => {
    if (!instance) {
      dispatch(getInstancesAsync({ withoutLoading: false }));
    }
  }, [dispatch, instance]);

  return (
    <div>
      {instance && (
        <div className="mt-5">
          <div className="mt-2 grid gap-y-4 gap-x-10 md:grid-cols-2 px-2">
            {instance.ipAddresses.map((ip, index) => (
              <Flex direction="col" key={index} className="gap-y-4">
                <div className="mb-4 md:mb-0 w-full">
                  <Typography.Text className="!text-sm">
                    IPv${ip.version}
                  </Typography.Text>
                  {!loading && <SecureCopy type="copy" text={ip.ip} />}
                  {loading && (
                    <Skeleton style={{ width: "100%", height: 36 }} />
                  )}
                </div>

                <div className="mb-4 md:mb-0 w-full">
                  <Typography.Text className="!text-sm">
                    Gateway
                  </Typography.Text>
                  {!loading && (
                    <SecureCopy
                      type="copy"
                      text={ip.gatewayIp || "undefined"}
                    />
                  )}
                  {loading && (
                    <Skeleton style={{ width: "100%", height: 36 }} />
                  )}
                </div>
              </Flex>
            ))}

            {/* <div className="p-2"> //TODO IF HAVE PRIVATE NETWORK
        <div className="flex justify-between flex-col md:flex-row">
          <div className="mb-4 md:mb-0 w-full">
            <Text type="title" className="text-sm mb-2">
              Private networks
            </Text>

            <div className=" flex gap-5 flex-row items-center">
              <Text className="text-sm">-</Text>

              <Button>
                <MoreIcon className="w-4 h-4 dark:text-slate-100 text-slate-800" />
              </Button>
            </div>
          </div>
        </div>
      </div> */}

            <div className="mb-4 md:mb-0 w-full">
              <Typography.Text className="!text-sm">
                Login information
              </Typography.Text>
              {!loading && (
                <SecureCopy
                  type="copy"
                  text={`ssh ${instance?.image?.user}@${instance.ipAddresses[0]?.ip}`}
                />
              )}
              {loading && <Skeleton style={{ width: "100%", height: 36 }} />}
            </div>

            <Flex direction="col" className="mb-4 md:mb-0 w-full">
              <Typography.Text className="!text-sm mb-2">
                SSH Key
              </Typography.Text>
              {!loading && (
                <SecureCopy
                  type="copy"
                  text={instance.sshKey?.name || ""}
                  className="!text-sm"
                />
              )}
              {loading && <Skeleton style={{ width: "100%", height: 36 }} />}
            </Flex>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstanceNetworksTab;
