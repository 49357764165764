import { PlansTab } from "../../plans/UniversalPlansBox";

const Web3AuthPlansTab = () => {
  const web3authServiceType =
    Number(process.env.REACT_APP_WEB3AUTH_SERVICE_TYPE) || 6;
  return (
    <>
      <PlansTab serviceType={web3authServiceType} />
    </>
  );
};

export default Web3AuthPlansTab;
