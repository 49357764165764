import { jwtEnvAxios } from "..";
import { S3Anonymous, S3AnonymousDelete } from "../../types/s3-anonymous";

//anonymous
const GET_ANONYMOUS_ACCESS_API_URL = (bucketName: string) =>
  `/buckets/${bucketName}/access-rules`;
const SET_ANONYMOUS_ACCESS_API_URL = (bucketName: string) =>
  `/buckets/${bucketName}/access-rules`;
const DELETE_ANONYMOUS_ACCESS_API_URL = (bucketName: string) =>
  `/buckets/${bucketName}/access-rules`;

//anonymous
export function getBucketAnonymousApi(name: string) {
  return jwtEnvAxios("s3").get(GET_ANONYMOUS_ACCESS_API_URL(name));
}

export function setS3AnonymousAccessApi(bucketName: string, data: any) {
  return jwtEnvAxios("s3").put(SET_ANONYMOUS_ACCESS_API_URL(bucketName), data);
}

export function deleteS3AnonymousAccessApi(bucketName: string, data: any) {
  return jwtEnvAxios("s3").delete(DELETE_ANONYMOUS_ACCESS_API_URL(bucketName), {
    data,
  });
}
