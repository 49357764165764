import { useAppDispatch, useAppSelector } from "../../../hooks";

import { Button, Flex, Modal, SecureCopy, Typography } from "djuno-design";
import {
  handleToggleDBSUserResetpasswordModal,
  selectDatabaseUserResetpassword,
} from "../../../store/database/serviceSlice";
import { IDBSUser } from "../../../types/database";

const ResetPasswordModal = ({
  resetPassword,
  selectedUser,
}: {
  resetPassword: string;
  selectedUser: IDBSUser;
}) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectDatabaseUserResetpassword);

  const handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    dispatch(handleToggleDBSUserResetpasswordModal(false));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title="Reset password"
    >
      <Flex direction="col" className="my-5 gap-5">
        <Typography.Text className="!text-sm ">
          The password for the <strong>"{selectedUser.username}"</strong> user
          has been reset. Please note it down as it cannot be retrieved later.
        </Typography.Text>
        <SecureCopy type="copy" text={resetPassword} />
      </Flex>

      <Flex className="mt-4 justify-end gap-2">
        <Button uiType="light" onClick={handleClose}>
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
};

export default ResetPasswordModal;
