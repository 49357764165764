import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import HighlighterText from "../../general/HighlighterText";
import { WorkflowNotActiveTab } from "../../layouts/NotData";
import {
  deleteWorkflowWalletAsync,
  getWorkflowWalletsAsync,
  handleInstanceDeleteHideModal,
  handleWalletDeleteShowModal,
  selectwalletShowDeleteModal,
  selectWorkflowSelectedWallet,
  selectWorkflowWalletActionLoading,
  selectWorkflowWallets,
  selectWorkflowWalletsLoading,
} from "../../../store/workflows/walletsSlice";
import { IWorkflowWallet } from "../../../types/workflows";
import { useSearch } from "../../../providers/SearchProvider";
import { timeAgo } from "../../../utils/date";
import { Helmet } from "react-helmet";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil.svg";
import { networks } from "./constant";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import WalletDialog from "./WalletDialog";
import { selectWorkflowStatus } from "../../../store/workflows/workflowsSlice";
import {
  Button,
  Dropdown,
  EmptyState,
  Input,
  SimpleTable,
  Typography,
} from "djuno-design";
import { DeleteModal } from "../../modals/QuestionModal";

const WorkflowsWalletsTab = () => {
  const isOpen = useAppSelector(selectwalletShowDeleteModal);
  const workflowStatus = useAppSelector(selectWorkflowStatus);

  const wallets = useAppSelector(selectWorkflowWallets);
  const walletsLoading = useAppSelector(selectWorkflowWalletsLoading);
  const [filteredWallets, setFilteredWallets] = useState<IWorkflowWallet[]>([]);

  const wallet = useAppSelector(selectWorkflowSelectedWallet);
  const actioloading = useAppSelector(selectWorkflowWalletActionLoading);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogProps, setDialogProps] = useState({});

  const [deleteWallet, setDeleteWallet] = useState<IWorkflowWallet>();

  const dispatch = useAppDispatch();
  const { value: searchValue } = useSearch();

  useEffect(() => {
    dispatch(getWorkflowWalletsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUp = wallets.filter((wallet) =>
      wallet.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredWallets(lookedUp);
  }, [dispatch, searchValue, wallets]);

  const getNetworkItem = useCallback((network: string) => {
    return networks.find((ntw) => ntw.name === network);
  }, []);

  const addNew = useCallback(() => {
    const dialogProp = {
      title: "Add New Wallet",
      type: "ADD",
      cancelButtonName: "Cancel",
      confirmButtonName: "Add",
    };
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const importNew = useCallback(() => {
    const dialogProp = {
      title: "Import Wallet",
      type: "IMPORT",
      cancelButtonName: "Cancel",
      confirmButtonName: "IMPORT",
    };
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const edit = useCallback((id: string) => {
    const dialogProp = {
      title: "Edit Wallet",
      type: "EDIT",
      cancelButtonName: "Cancel",
      confirmButtonName: "Save",
      id,
    };
    setDialogProps(dialogProp);
    setShowDialog(true);
  }, []);

  const onConfirm = useCallback(() => {
    setShowDialog(false);
    dispatch(getWorkflowWalletsAsync());
  }, [dispatch]);

  if (!workflowStatus) {
    return <WorkflowNotActiveTab />;
  }

  const handleClose = () => {
    dispatch(handleInstanceDeleteHideModal());
  };

  const handleDeleteWorkflowWallet = async () => {
    if (deleteWallet) {
      dispatch(deleteWorkflowWalletAsync(deleteWallet._id)).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          dispatch(getWorkflowWalletsAsync());
          dispatch(handleInstanceDeleteHideModal());
        }
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Workflow Wallets </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center justify-between gap-3">
        <Typography.Text className="whitespace-nowrap font-medium"></Typography.Text>
        <div className="flex items-center justify-end gap-1 md:gap-2 ">
          <Button uiType="primary" onClick={addNew} className="group">
            Add New
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
          <Button uiType="light" onClick={importNew} className="group">
            Import Wallet
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable
          loading={walletsLoading}
          className="table-auto"
          containerClassName="min-h-[240px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name" />
              <SimpleTable.TH lable="Address" />
              <SimpleTable.TH lable="Network" />
              <SimpleTable.TH lable="Created" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredWallets.map((wallet, index) => (
              <SimpleTable.Row
                key={index}
                onClick={() => edit(wallet._id)}
                className="cursor-pointer"
              >
                <SimpleTable.TD>
                  <div className="flex items-center">
                    {wallet.address && (
                      <div className="w-[35px] h-[35px] rounded-full bg-white dark:bg-dark-1 mr-3">
                        <Jazzicon
                          diameter={35}
                          seed={jsNumberForAddress(wallet.address)}
                        />
                      </div>
                    )}
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={wallet.name}
                    />
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Input
                    copyable={{ text: wallet.address }}
                    className="!text-xs md:!text-sm"
                    value={`${wallet.address.slice(
                      0,
                      8
                    )}...${wallet.address.slice(-4)}`}
                  />
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text
                    className="!text-xs md:!text-sm px-1 py-0.5 rounded-md"
                    uiType="success"
                    style={{
                      backgroundColor:
                        getNetworkItem(wallet.network)?.color || "#666666",
                    }}
                  >
                    {getNetworkItem(wallet.network)?.label}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {timeAgo(wallet.createdDate)}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-20 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        anchor="bottom end"
                        itemsClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              edit(wallet._id);
                            },
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              dispatch(handleWalletDeleteShowModal(wallet));
                              setDeleteWallet(wallet);
                            },
                          },
                        ]}
                      >
                        <Button uiSize="small" uiType="icon" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {wallets.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={5} className="!border-none">
                  <EmptyState text="No connections created yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
        <WalletDialog
          show={showDialog}
          dialogProps={dialogProps}
          onCancel={() => setShowDialog(false)}
          onConfirm={onConfirm}
        />
      </div>

      <DeleteModal
        isOpen={isOpen}
        title="Delete wallet"
        onClose={handleClose}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        loading={actioloading}
        onConfirm={handleDeleteWorkflowWallet}
        confirmString={wallet?.name}
      />
    </>
  );
};

export default WorkflowsWalletsTab;
