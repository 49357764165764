import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getInstanceAsync,
  handleToggleInstanceReinstallModal,
  reinstallInstanceImageAsync,
  selectInstance,
  selectInstanceLoading,
  selectShowReinstallModal,
} from "../../../../store/instances/instanceSlice";
import { getInstancesAsync } from "../../../../store/instances/instancesSlice";
import { Alert, Button, Flex, Modal, Typography } from "djuno-design";

const InstanceReinstallModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowReinstallModal);
  const loading = useAppSelector(selectInstanceLoading);
  const instance = useAppSelector(selectInstance);

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const onSubmit = (data: any) => {
    if (instance && instance.image) {
      const formData = {
        imageId: instance.image.id,
      };
      dispatch(
        reinstallInstanceImageAsync({
          instanceId: instance.id,
          data: formData,
        })
      ).then((action) => {
        if (action.type === "instance/reinstall/fulfilled") {
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
          dispatch(handleToggleInstanceReinstallModal(false));
        }
      });
    }
  };

  const handleClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
    }
    dispatch(handleToggleInstanceReinstallModal(false));
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      contentClassName="max-w-lg"
      //containerClassName="!items-start"
      title="Re-install an instance"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="col" className="mt-5 gap-2">
          <Typography.Text className="!text-sm">
            Are you sure that you want to re-install the {instance?.name}
            instance?
          </Typography.Text>

          <Alert uiType="warning" className="text-sm" showIcon>
            All of the data stored on it will be deleted.
          </Alert>
        </Flex>

        <Flex justify="end" className="mt-4 gap-2">
          <Button uiType="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            uiType="primary"
            disabled={!isValid}
            type="submit"
            loading={loading}
          >
            Confirm
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default InstanceReinstallModal;
