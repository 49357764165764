import { useNavigate } from "react-router-dom";
import { SignInUrl } from "../../utils/urls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPasswordSchema } from "../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  forgotPasswordAsync,
  selectLoginLoading,
} from "../../store/auth/authSlice";
import toast from "react-hot-toast";
import { ToastClasses } from "../modals/alerts";
import { Button, Flex, Input, Typography } from "djuno-design";
import CustomLink from "../general/CustomLink";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoginLoading);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });
  const onSubmit = (data: any) => {
    if (!loading) {
      dispatch(forgotPasswordAsync({ email: data.email })).then((action) => {
        if (action.type === "auth/forgot-password/fulfilled") {
          toast.success("Check your email inbox", {
            className: ToastClasses,
          });
          setTimeout(() => {
            navigate(SignInUrl);
          }, 4000);
        }
      });
    }
  };

  return (
    <>
      <Typography.Title level={5} className="text-center">
        Password reset
      </Typography.Title>
      <Typography.Text className="mb-7 !text-sm">
        Enter your email address below, and we'll send you a secure link to
        reset your password.
      </Typography.Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="col" className="gap-6">
          <Input
            {...register("email")}
            label="Email address"
            required
            placeholder="your@email.com"
            error={errors.email?.message}
          />
          <Flex direction="col">
            <Button
              uiType="primary"
              type="submit"
              className="!w-full"
              loading={loading}
            >
              <Typography.Text
                uiType="transparent"
                className="w-full text-center"
                size="sm"
              >
                Send reset link
              </Typography.Text>
            </Button>
            <Flex justify="center" className="gap-1 mt-2">
              <CustomLink to={SignInUrl} className="text-sm">
                Go back to sign in
              </CustomLink>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </>
  );
};
export default ForgotPassword;
