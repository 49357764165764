import { Link, Outlet } from "react-router-dom";
import { ReactComponent as LogoTextIcon } from "./../../assets/icons/logo-text.svg";
import { Flex, ThemeChanger } from "djuno-design";

const AuthLayout = () => {
  return (
    <Flex
      items="center"
      justify="center"
      className="w-screen bg-white dark:bg-zinc-900 min-h-screen"
    >
      <div className="fixed top-5 right-10 text-slate-400 dark:text-slate-300">
        <ThemeChanger anchor="bottom end" />
      </div>
      <Flex
        direction="col"
        justify="start"
        className="max-w-lg min-w-[22rem] w-3/5 mx-5"
      >
        <Flex
          direction="col"
          className="p-8 bg-white shadow-[0_4px_30px_0_rgba(0,0,0,0.05)] dark:bg-dark-3  dark:shadow-none rounded-xl"
        >
          <Flex justify="center" className="mb-2">
            <Link to={"/"}>
              <LogoTextIcon className="text-black/60 dark:text-gray-300 h-10" />
            </Link>
          </Flex>
          <Outlet />
        </Flex>
      </Flex>
      {/* <PChatWidget /> */}
    </Flex>
  );
};

export default AuthLayout;
