import { useNavigate, useSearchParams } from "react-router-dom";
// import { ReactComponent as BellAlertIcon } from "./../../assets/icons/bell-alert.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { ReactComponent as CheckIcon } from "./../..//assets/icons/check.svg";
import { InstancesUrl } from "../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceCreateSchema } from "../../utils/validations";
import { DBSAddon } from "../../types/database";
import {
  getInstancesFlavorsAsync,
  getRegionsAsync,
  selectInstancesActionLoading,
  selectInstancesFlavors,
  selectInstancesFlavorsLoading,
  selectInstancesImages,
  selectInstancesRegions,
  selectInstancesRegionsLoading,
  handleClearInstanceImages,
  createInstanceAsync,
  selectInstancesImagesLoading,
  createMultipleInstanceAsync,
  selectInstancesSnapshots,
} from "../../store/instances/instancesSlice";
import {
  getDBSCatalogAsync,
  selectDBSCatalog,
  selectDBSCatalogLoading,
} from "../../store/database/servicesSlice";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  makeBillingPeriodsOptions,
  makeModels,
} from "../../components/instances/create/utils";
import {
  InstanceBillingInput,
  InstanceConfigurationInput,
  InstanceImageInput,
  InstanceModelInput,
  InstanceRegionInput,
  SSHkeyInput,
} from "../../components/instances/create/components";
import { InstanceBillingPeriod } from "../../types/instance";
import { InstancesSskKeyEditorModal } from "../../components/instances/ssh-keys/InstancesSskKeyEditorModal";
import { Alert, Button, cn, Flex, Loading, Typography } from "djuno-design";
import {
  getInstanceSnapshotDtailsAsync,
  handleClearInstanceSnapshot,
  selectInstanceSnapshot,
  selectInstanceSnapshotLoading,
} from "../../store/instances/instanceSlice";

const createSteps = [
  "modelSelection",
  "regionSelection",
  "imageSelection",
  "instanceConfiguration",
  "billingSelection",
];

type CreateStep = (typeof createSteps)[number];

const InstanceCreatePage = () => {
  const [query] = useSearchParams();
  const navigate = useNavigate();

  const flavors = useAppSelector(selectInstancesFlavors);
  const flavorsLoading = useAppSelector(selectInstancesFlavorsLoading);

  const regions = useAppSelector(selectInstancesRegions);
  const regionsLoading = useAppSelector(selectInstancesRegionsLoading);

  const images = useAppSelector(selectInstancesImages);
  const imagesLoading = useAppSelector(selectInstancesImagesLoading);

  const snapshots = useAppSelector(selectInstancesSnapshots);

  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);

  //for creating instance from a snapshot (backup)
  const instanceSnapshot = useAppSelector(selectInstanceSnapshot);
  const instanceSnapshotLoading = useAppSelector(selectInstanceSnapshotLoading);

  const actionLoading = useAppSelector(selectInstancesActionLoading);

  // states
  const [expanded, setExpanded] = useState<CreateStep>("modelSelection");
  const [addons, setAddons] = useState<Array<DBSAddon>>([]);

  const dispatch = useAppDispatch();

  //data for create from backup
  const instanceId = query.get("instanceId");
  const instanceBackupId = query.get("instanceBackupId");
  const returnUrl = query.get("returnUrl");

  const methods = useForm({
    resolver: yupResolver(InstanceCreateSchema),
    mode: "all",
    defaultValues: {
      selectedFlavor: "",
      selectedRegion: "",
      selectedImageGroupId: "",
      selectedImageId: "",
      selectedSshKeyId: "",
      numberOfInstances: 1,
      isFlexible: false,
      instanceName: "",
      postInstallation: "",
      autobackup: null,
      billingPeriod: "hourly",
    },
  });

  const {
    setValue,
    handleSubmit,
    //reset,
    control,
    formState: { errors, isValid },
    watch,
  } = methods;

  // watch form values
  const selectedFlavorName = watch("selectedFlavor");
  const selectedRegionName = watch("selectedRegion");
  const selectedImageGroupId = watch("selectedImageGroupId");
  const selectedImageId = watch("selectedImageId");
  const selectedSshKeyId = watch("selectedSshKeyId");
  const numberOfInstances = watch("numberOfInstances");
  const isFlexible = watch("isFlexible");
  const instanceName = watch("instanceName");
  // const postInstallation = watch("postInstallation");
  const autobackup = watch("autobackup");
  const billingPeriod = watch("billingPeriod");

  //get first data
  useEffect(() => {
    dispatch(getDBSCatalogAsync());
    dispatch(getInstancesFlavorsAsync());
    // dispatch(getInstancesProductAvailabilityAsync());
    dispatch(getRegionsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (instanceBackupId && instanceId) {
      dispatch(
        getInstanceSnapshotDtailsAsync({
          instanceId,
          imageId: instanceBackupId,
        })
      );
    }
    return () => {
      dispatch(handleClearInstanceSnapshot());
    };
  }, [dispatch, instanceBackupId, instanceId]);

  const models = useMemo(() => {
    return makeModels(flavors, undefined);
  }, [flavors]);

  const selectedFlavor = useMemo(() => {
    const filteredFlovors = flavors
      .filter((f) => f.name === selectedFlavorName)
      .filter((f) => {
        if (selectedRegionName !== "") {
          return f.region === selectedRegionName;
        }
        return true;
      });
    return filteredFlovors.length ? filteredFlovors[0] : undefined;
  }, [flavors, selectedFlavorName, selectedRegionName]);

  const selectedFlexFlavor = useMemo(() => {
    const filteredFlovors = flavors
      .filter((f) => f.name === selectedFlavorName + "-flex")
      .filter((f) => {
        if (selectedRegionName !== "") {
          return f.region === selectedRegionName;
        }
        return true;
      });
    return filteredFlovors.length ? filteredFlovors[0] : undefined;
  }, [flavors, selectedFlavorName, selectedRegionName]);

  const selectedImage = useMemo(() => {
    let _selectedImage = images.find((i) => i.id === selectedImageId);
    if (!_selectedImage) {
      _selectedImage = snapshots.find((i) => i.id === selectedImageId);
    }
    return _selectedImage;
  }, [images, selectedImageId, snapshots]);

  //generate billing period options from selected flavor
  const billingPeriodsOptions: InstanceBillingPeriod[] = useMemo(() => {
    const onStageFlavor = isFlexible ? selectedFlexFlavor : selectedFlavor;
    if (onStageFlavor) return makeBillingPeriodsOptions(onStageFlavor);
    return [];
  }, [isFlexible, selectedFlavor, selectedFlexFlavor]);

  //selected billing option
  const selectedBillingPeriod = useMemo(() => {
    return billingPeriodsOptions.find((bp) => bp.value === billingPeriod);
  }, [billingPeriod, billingPeriodsOptions]);

  // console.log({ selectedBillingPeriod, billingPeriodsOptions });

  useEffect(() => {
    if (selectedImage && selectedImage.type === "windows") {
      setValue("selectedSshKeyId", "");
    }
  }, [selectedImage, setValue]);

  useEffect(() => {
    if (instanceSnapshot) {
      setValue("selectedRegion", instanceSnapshot.region);
      setValue("selectedImageGroupId", "nothing");
      setValue("selectedImageId", instanceSnapshot.name);
    }
  }, [instanceSnapshot, setValue]);

  // filter addons by selected engine and period
  useEffect(() => {
    if (catalog) {
      const filteredAddons = catalog.addons.filter(
        (addon) => addon.product === "publiccloud-instance"
      );
      setAddons(filteredAddons);
    }
  }, [catalog, selectedBillingPeriod, selectedFlavorName]);

  const handleEditModelSelection = () => {
    setExpanded("modelSelection");

    setValue("selectedRegion", instanceSnapshot ? instanceSnapshot.region : "");
    setValue("selectedImageGroupId", instanceSnapshot ? "nothing" : "");
    setValue("selectedImageId", instanceSnapshot ? instanceSnapshot.name : "");
    setValue("selectedSshKeyId", "");
    dispatch(handleClearInstanceImages());
    setValue("numberOfInstances", 1);
    setValue("isFlexible", false);
    setValue("instanceName", "");
    setValue("postInstallation", "");
    setValue("autobackup", null);
  };

  const handleEditRegionSelection = () => {
    setExpanded("regionSelection");

    setValue("selectedImageGroupId", instanceSnapshot ? "nothing" : "");
    setValue("selectedImageId", instanceSnapshot ? instanceSnapshot.name : "");
    setValue("selectedSshKeyId", "");
    dispatch(handleClearInstanceImages());
    setValue("numberOfInstances", 1);
    setValue("isFlexible", false);
    setValue("instanceName", "");
    setValue("postInstallation", "");
    setValue("autobackup", null);
  };

  const handleEditImageSelection = () => {
    setExpanded("imageSelection");

    setValue("numberOfInstances", 1);
    setValue("isFlexible", false);
    setValue("instanceName", "");
    setValue("postInstallation", "");
    setValue("autobackup", null);
  };

  const handleEditConfigSelection = () => {
    setExpanded("instanceConfiguration");

    setValue("billingPeriod", "hourly");
  };

  const handleSubmitForm = (data: any) => {
    // console.log(data);
    const flavorId = isFlexible ? selectedFlexFlavor?.id : selectedFlavor?.id;
    if (flavorId && !actionLoading) {
      const number = data.numberOfInstances;

      const apiData = {
        autobackup: data.autobackup,
        flavorId,
        imageId: instanceSnapshot ? instanceSnapshot.id : data.selectedImageId,
        monthlyBilling: billingPeriod === "monthly",
        name: data.instanceName,
        region: data.selectedRegion,
        sshKeyId: data.selectedSshKeyId,
      };

      if (!number || number === 1) {
        dispatch(createInstanceAsync({ data: apiData })).then((action) => {
          if (action.type === "instances/create/fulfilled") {
            navigate(returnUrl || InstancesUrl);
          }
        });
      } else {
        const multipleApiData = { number: data.numberOfInstances, ...apiData };
        dispatch(createMultipleInstanceAsync({ data: multipleApiData })).then(
          (action) => {
            if (action.type === "instances/create-multiple/fulfilled") {
              navigate(returnUrl || InstancesUrl);
            }
          }
        );
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-between h-16 px-6 sticky top-0 z-20 bg-white dark:bg-dark-1 border-b dark:border-dark-2">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="font-medium">
            Create an instance
          </Typography.Text>
        </div>
        <div className="">
          <Button
            uiType="light"
            uiSize="small"
            onClick={() => navigate(returnUrl || InstancesUrl)}
            className="group"
          >
            <CloseIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
      </div>

      {(flavorsLoading || catalogLoading) && (
        <Flex
          items="center"
          justify="center"
          className="h-full w-full min-h-[calc(100vh-128px)]"
        >
          <Loading borderSize={2} />
        </Flex>
      )}
      {!flavorsLoading && !catalogLoading && (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="mt-10 w-full px-6 flex flex-col lg:flex-row pb-24 gap-8 relative">
            <div className="w-full flex flex-col">
              {/* select model */}
              <Accordion
                expanded={expanded === "modelSelection"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="modelSelection"
                  sx={{ my: 0 }}
                >
                  <div className="flex w-full items-center justify-between">
                    {createSteps.indexOf(expanded) > 0 ? (
                      <>
                        <Typography.Text className="!text-base font-medium flex items-center gap-1">
                          <CheckIcon className="w-5 h-5 text-primary-500" />
                          Template chosen: {selectedFlavorName.toUpperCase()}
                        </Typography.Text>
                        <Typography.Link
                          className="!text-sm"
                          onClick={handleEditModelSelection}
                        >
                          edit this step
                        </Typography.Link>
                      </>
                    ) : (
                      <Typography.Text
                        className={cn(
                          "text-base font-medium flex items-center gap-1",
                          {
                            "!text-primary-500": expanded === "modelSelection",
                          }
                        )}
                      >
                        1. Select a model
                      </Typography.Text>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3">
                    <InstanceModelInput
                      models={models}
                      control={control}
                      addons={addons}
                      errorMessage={errors.selectedFlavor?.message}
                    />
                  </div>
                  <Button
                    id="1th-next-button"
                    disabled={selectedFlavorName === ""}
                    onClick={() => setExpanded("regionSelection")}
                  >
                    Next
                  </Button>
                </AccordionDetails>
              </Accordion>

              {/* select region */}
              <Accordion
                expanded={expanded === "regionSelection"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="regionSelection"
                  sx={{ my: 0 }}
                >
                  <div className="flex w-full items-center justify-between">
                    {createSteps.indexOf(expanded) > 1 ? (
                      <>
                        <Typography.Text className="!text-base font-medium flex items-center gap-1">
                          <CheckIcon className="w-5 h-5 text-primary-500" />
                          Region selected: {selectedRegionName.toUpperCase()}
                        </Typography.Text>
                        <Typography.Link
                          className="!text-sm"
                          onClick={handleEditRegionSelection}
                        >
                          edit this step
                        </Typography.Link>
                      </>
                    ) : (
                      <Typography.Text
                        className={cn(
                          "!text-base font-medium flex items-center gap-1",
                          {
                            "!text-primary-500": expanded === "regionSelection",
                          }
                        )}
                      >
                        2. Select a region
                      </Typography.Text>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3">
                    {selectedFlavor && (
                      <InstanceRegionInput
                        regions={regions}
                        flavors={flavors}
                        selectedFlavor={selectedFlavor}
                        control={control}
                        loading={regionsLoading}
                        errorMessage={errors.selectedRegion?.message}
                        handleEditModelSelection={handleEditModelSelection}
                        disabled={!!instanceSnapshot}
                      />
                    )}
                  </div>

                  {selectedRegionName !== "" && selectedFlavor?.quota === 0 && (
                    <Alert uiType="error" className="text-xs mb-3">
                      You no longer have any quota available for this region. To
                      continue,{" "}
                      <Typography.Link className="!text-xs">
                        increase your quota
                      </Typography.Link>
                      .
                    </Alert>
                  )}

                  <Button
                    id="2th-next-button"
                    disabled={
                      selectedRegionName === "" ||
                      (selectedRegionName !== "" && selectedFlavor?.quota === 0)
                    }
                    onClick={() => setExpanded("imageSelection")}
                  >
                    Next
                  </Button>
                </AccordionDetails>
              </Accordion>

              {/* select image */}
              <Accordion
                expanded={expanded === "imageSelection"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="imageSelection"
                  sx={{ my: 0 }}
                >
                  <div className="w-full flex">
                    {createSteps.indexOf(expanded) > 2 ? (
                      <div className="flex flex-col gap-5 w-full">
                        <div className="flex w-full items-center justify-between">
                          <Typography.Text className="!text-base font-medium flex items-center gap-1">
                            <CheckIcon className="w-5 h-5 text-primary-500" />
                            Image has been selected
                          </Typography.Text>
                          <Typography.Link
                            className="!text-sm"
                            onClick={handleEditImageSelection}
                          >
                            edit this step
                          </Typography.Link>
                        </div>
                        <div className="px-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                          <div className="col-span-1 border-2 text-md rounded-xl dark:bg-dark-3 bg-white p-4 shadow transition-all duration-300 h-full border-primary-400 dark:border-primary-400 opacity-60">
                            <div className="flex items-center justify-between">
                              <Typography.Text className="!text-sm">
                                {instanceSnapshot
                                  ? instanceSnapshot.name
                                  : selectedImage?.name}
                              </Typography.Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Typography.Text
                        className={cn(
                          "!text-base font-medium flex items-center gap-1",
                          {
                            "!text-primary-500": expanded === "imageSelection",
                          }
                        )}
                      >
                        3.{" "}
                        {!!instanceSnapshot
                          ? `Image: ${instanceSnapshot.name}`
                          : "Select an image"}
                      </Typography.Text>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3 mb-5">
                    {selectedFlavor && !instanceSnapshot && (
                      <InstanceImageInput
                        selectedRegion={selectedRegionName}
                        selectedFlavor={selectedFlavor}
                        control={control}
                        errorMessage={errors.selectedImageGroupId?.message}
                        versionsErrorMessage={errors.selectedImageId?.message}
                        // handleEditModelSelection={handleEditModelSelection}
                      />
                    )}
                  </div>
                  {selectedImage && (
                    <SSHkeyInput
                      control={control}
                      selectedImage={selectedImage}
                    />
                  )}

                  <Button
                    disabled={
                      !!instanceSnapshot
                        ? false
                        : selectedImageGroupId === "" ||
                          selectedImageId === "" ||
                          (selectedImage && selectedImage.type === "windows"
                            ? false
                            : selectedSshKeyId === "")
                    }
                    onClick={() => setExpanded("instanceConfiguration")}
                    id="3th-next-button"
                  >
                    Next
                  </Button>
                </AccordionDetails>
              </Accordion>

              {/* select configs */}
              <Accordion
                expanded={expanded === "instanceConfiguration"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="instanceConfiguration"
                  sx={{ my: 0 }}
                >
                  <div className="w-full flex">
                    {createSteps.indexOf(expanded) > 3 ? (
                      <div className="flex flex-col gap-5 w-full">
                        <div className="flex w-full items-center justify-between">
                          <Typography.Text className="!text-base font-medium flex items-center gap-1">
                            <CheckIcon className="w-5 h-5 text-primary-500" />
                            Configure your instance
                          </Typography.Text>
                          <Typography.Link
                            className="!text-sm"
                            onClick={handleEditConfigSelection}
                          >
                            edit this step
                          </Typography.Link>
                        </div>

                        <div>
                          <div className="px-5 grid grid-cols-4">
                            <Typography.Text className="text-sm col-span-1">
                              Number of instances:
                            </Typography.Text>
                            <Typography.Text className="text-sm col-span-2">
                              {numberOfInstances}
                            </Typography.Text>
                          </div>
                          <div className="px-5 grid grid-cols-4">
                            <Typography.Text className="text-sm col-span-1">
                              Instance name:
                            </Typography.Text>
                            <Typography.Text className="text-sm col-span-2">
                              {instanceName}
                            </Typography.Text>
                          </div>
                          {autobackup !== null && (
                            <div className="px-5 grid grid-cols-4">
                              <Typography.Text className="text-sm col-span-1">
                                Auto backup:
                              </Typography.Text>
                              <CheckIcon className="w-5 h-5 text-primary-500" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <Typography.Text
                        className={cn(
                          "text-base font-medium flex items-center gap-1",
                          {
                            "!text-primary-500":
                              expanded === "instanceConfiguration",
                          }
                        )}
                      >
                        4. Configure your instance
                      </Typography.Text>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3 mb-5">
                    {selectedFlavor && (
                      <InstanceConfigurationInput
                        selectedRegion={selectedRegionName}
                        selectedFlavor={selectedFlavor}
                        selectedFlexFlavor={selectedFlexFlavor}
                        control={control}
                        numberOfInstancesErrorMessage={
                          errors.numberOfInstances?.message
                        }
                        instanceNameError={errors.instanceName?.message}
                        backupMode={!!instanceSnapshot}
                      />
                    )}
                  </div>

                  <Button
                    disabled={errors.instanceName !== undefined}
                    onClick={() => setExpanded("billingSelection")}
                    id="4th-next-button"
                  >
                    Next
                  </Button>
                </AccordionDetails>
              </Accordion>

              {/* select billing */}
              <Accordion
                expanded={expanded === "billingSelection"}
                sx={{ boxShadow: "none" }}
                className="bg-white dark:bg-dark-3"
              >
                <AccordionSummary
                  expandIcon={null}
                  aria-controls="panel1bh-content"
                  id="billingSelection"
                  sx={{ my: 0 }}
                >
                  <div className="w-full flex">
                    {createSteps.indexOf(expanded) > 4 ? (
                      <div className="flex flex-col gap-5 w-full">
                        <div className="flex w-full items-center justify-between">
                          <Typography.Text className="!text-base font-medium flex items-center gap-1">
                            <CheckIcon className="w-5 h-5 text-primary-500" />
                            Select a billing period
                          </Typography.Text>
                          {/* <A
                              className="text-sm"
                              onClick={handleEditConfigSelection}
                            >
                              edit this step
                            </A> */}
                        </div>

                        <div>
                          {/* <div className="px-5 grid grid-cols-4">
                              <Text className="text-sm col-span-1">
                                Number of instances:
                              </Text>
                              <Text className="text-sm col-span-2">
                                {numberOfInstances}
                              </Text>
                            </div> */}
                        </div>
                      </div>
                    ) : (
                      <Typography.Text
                        className={cn(
                          "!text-base font-medium flex items-center gap-1",
                          {
                            "!text-primary-500":
                              expanded === "billingSelection",
                          }
                        )}
                      >
                        5. Select a billing period
                      </Typography.Text>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mt-3 mb-5">
                    {selectedFlavor && (
                      <InstanceBillingInput
                        billingPeriodsOptions={billingPeriodsOptions}
                        addons={addons}
                        numberOfInstances={numberOfInstances}
                        control={control}
                      />
                    )}
                  </div>

                  <Button
                    type="submit"
                    disabled={!isValid}
                    uiType="primary"
                    loading={actionLoading}
                  >
                    Create an instance
                  </Button>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </form>
      )}
      <InstancesSskKeyEditorModal />
    </>
  );
};

export default InstanceCreatePage;
