import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";

// utils
// import { copyToClipboard } from "./../../../../utils/wfHelper";
import { JsonViewer, useDjunoDesign } from "djuno-design";
import { Typography } from "djuno-design";

const ExpandDataDialog = ({
  show,
  dialogProps,
  onCancel,
  onCopyClick,
  enableClipboard,
}) => {
  const portalElement = document.getElementById("portal");
  const {
    theme: { mode },
  } = useDjunoDesign();

  const component = show ? (
    <Dialog
      open={show}
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        className:
          "!border-0 dark:!bg-dark-3 dark:!border-2 dark:!border-dark-2 !shadow-xl !transition-all !rounded-2xl",
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {dialogProps.title}
        <Typography.Text>{dialogProps.title}</Typography.Text>
      </DialogTitle>
      <DialogContent>
        {!enableClipboard && (
          <JsonViewer
            value={dialogProps.data}
            copyable
            // theme={mode === "dark" ? "ocean" : "rjv-default"}
            // style={{ padding: 10, borderRadius: 10 }}
            // src={dialogProps.data}
            // enableClipboard={(e) => copyToClipboard(e)}
          />
        )}
        {enableClipboard && (
          <JsonViewer
            value={dialogProps.data}
            copyable
            // enableClipboard={(e) => onCopyClick(e, dialogProps.node)} //TODO

            // theme={mode === "dark" ? "ocean" : "rjv-default"}
            // style={{ padding: 10, borderRadius: 10 }}
            // src={dialogProps.data}
          />
        )}
      </DialogContent>
    </Dialog>
  ) : null;

  return createPortal(component, portalElement);
};

ExpandDataDialog.propTypes = {
  show: PropTypes.bool,
  dialogProps: PropTypes.object,
  onCancel: PropTypes.func,
  onCopyClick: PropTypes.func,
  enableClipboard: PropTypes.bool,
};

export default ExpandDataDialog;
