import { jwtEnvAxios } from ".";
import { CreateEnvVariableData } from "../types/env-variables";

const GET_ENV_VARIABLES_URL = `/environment-variables`;
const DELETE_ENV_VARIABLE_URL = (id: number) => `/environment-variables/${id}`;
const CREATE_ENV_VARIABLE_URL = `/environment-variables`;
const CREATE_MULTIPLE_ENV_VARIABLE_URL = `/environment-variables/multiple`;
const UPDATE_ENV_VARIABLE_URL = (id: number) => `/environment-variables/${id}`;

export function getEnvVariablesApi() {
  return jwtEnvAxios().get(GET_ENV_VARIABLES_URL);
}

export function deleteEnvVariableApi(id: number) {
  return jwtEnvAxios().delete(DELETE_ENV_VARIABLE_URL(id));
}

export async function createEnvVariableApi(formData: CreateEnvVariableData) {
  return jwtEnvAxios().post(CREATE_ENV_VARIABLE_URL, formData);
}

export async function createMultipleEnvVariableApi(data: any) {
  return jwtEnvAxios().post(CREATE_MULTIPLE_ENV_VARIABLE_URL, data);
}

export function updateEnvVariableApi(id: number, formData: any) {
  return jwtEnvAxios().put(UPDATE_ENV_VARIABLE_URL(id), formData);
}
