import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect } from "react";
import {
  selectShowCreatedData,
  selectCreatedData,
  handleClearCreatedAccessKey,
} from "../../../store/s3/accessKeysSlice";
import { textJsonDownloader } from "../../../utils/file";
import { Button, Modal, SecureCopy, Typography } from "djuno-design";

const S3AccessKeyShowModal = () => {
  const isOpen = useAppSelector(selectShowCreatedData);
  const accessKey = useAppSelector(selectCreatedData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen, accessKey]);

  const handleDownload = () => {
    if (accessKey) {
      textJsonDownloader(
        accessKey,
        `${accessKey.accessKey}_${new Date()
          .toLocaleDateString("en-CA")
          .replaceAll("-", "")}`
      );
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleClearCreatedAccessKey());
      }}
      contentClassName="max-w-lg"
      title={"Access key"}
    >
      <div className="mt-5 flex flex-col w-full gap-5">
        <div className="items-center flex w-full gap-1">
          <Typography.Text className="!text-sm !w-20">
            Access Key
          </Typography.Text>
          <SecureCopy
            className="flex-1"
            type="copy"
            text={accessKey?.accessKey || ""}
          />
        </div>
        <div className="flex items-center gap-1">
          <Typography.Text className="!text-sm !w-20">
            Secret Key
          </Typography.Text>
          <SecureCopy
            className="flex-1"
            type="copy"
            text={accessKey?.secretKey || ""}
          />
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <Button uiType="primary" onClick={handleDownload}>
          Download JSON
        </Button>
      </div>
    </Modal>
  );
};

export default S3AccessKeyShowModal;
