import { cn } from "djuno-design";
// import { AnchorHTMLAttributes } from "react";
import { Link } from "react-router-dom";

const CustomLink: React.FC<
  React.PropsWithChildren<{ to: string; className?: string }>
> = ({ to, children, className }) => {
  return (
    <Link
      className={cn(
        "cursor-pointer text-sky-500 hover:text-sky-600",
        className
      )}
      to={to}
    >
      {children}
    </Link>
  );
};

// const A: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
//   children,
//   className,
//   ...props
// }) => {
//   return (
//     <a
//       {...props}
//       className={cn(
//         "cursor-pointer text-sky-500 hover:text-sky-600",
//         className
//       )}
//       rel="noreferrer"
//     >
//       {children}
//     </a>
//   );
// };

// export { A };
export default CustomLink;
