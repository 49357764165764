import { FC } from "react";
import { Typography } from "djuno-design";
import headerimg from "./../../assets/images/headerimg.png";
import { ReactComponent as ArrowRightIcon } from "./../../assets/icons/arrow-right.svg";
import { Link } from "react-router-dom";

interface BreadcrumbItem {
  name: string;
  link?: string;
}

interface WebBannerProps {
  title?: string;
  breadcrumb?: BreadcrumbItem[];
}

const WebBanner: FC<WebBannerProps> = ({ title, breadcrumb }) => {
  return (
    <div className="bg-gradient-to-br from-primary-400 via-primary-700 to-primary-800 relative overflow-hidden min-h-[270px]  bg-opacity-70 flex items-center justify-center">
      <img
        src={headerimg}
        alt=""
        className="absolute top-0 left-0 w-full h-full object-cover z-2 opacity-30"
      />

      <div className="relative z-20 text-center flex flex-col items-center w-full">
        <div className="container mx-auto px-4">
          {title && (
            <Typography.Title className="!text-white mb-4">
              {title}
            </Typography.Title>
          )}

          {breadcrumb && breadcrumb.length > 0 && (
            <ol className="inline-flex items-center justify-center space-x-2 text-sm font-normal text-white">
              {breadcrumb.map((item, index) => (
                <li key={index} className="inline-flex items-center">
                  {item.link ? (
                    <Link to={item.link}>
                      <Typography.Text className="!text-white">
                        {item.name}
                      </Typography.Text>
                    </Link>
                  ) : (
                    <span className="!text-white">{item.name}</span>
                  )}
                  {index < breadcrumb.length - 1 && (
                    <ArrowRightIcon className="mx-2 w-4 h-4 text-white" />
                  )}
                </li>
              ))}
            </ol>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebBanner;
