import jwtAxios from ".";
import { EnvironmentCreateData } from "../types/environment";

const GET_ENVIRONMENTS_API_URL = `/environments`;
const GET_ENVIRONMENT_API_URL = (id: number) => `/environments/${id}`;
const CREATE_ENVIRONMENT_API_URL = `/environments`;

export function getEnvironmentsApi() {
  return jwtAxios.get(GET_ENVIRONMENTS_API_URL);
}

export function getEnvironmentApi(id: number) {
  return jwtAxios.get(GET_ENVIRONMENT_API_URL(id));
}

export function createEnvironmentApi(data: EnvironmentCreateData) {
  return jwtAxios.post(CREATE_ENVIRONMENT_API_URL, data);
}
