import { jwtEnvAxios } from ".";
import { InstanceModel, InstanceName } from "../types/instance";

const GET_INSTANCE_API_URL = (instanceId: string) => `/${instanceId}`;
const GET_INSTANCE_ONE_FLAVOR_API_URL = (flavorId: string) =>
  `/flavor/${flavorId}`;
const GET_INSTANCE_VNC_API_URL = (instanceId: string) => `/${instanceId}/vnc`;
const UPDATE_INSTANCE_NAME_API_URL = (instanceId: string) => `/${instanceId}`;
const UPDATE_INSTANCE_MODEL_API_URL = (instanceId: string) =>
  `/${instanceId}/resize`;
const REINSTALL_INSTANCE_IMAGE_API_URL = (instanceId: string) =>
  `/${instanceId}/reinstall`;
const STOP_INSTANCE_API_URL = (instanceId: string) => `/${instanceId}/stop`;
const RESCUE_MODE_INSTANCE_API_URL = (instanceId: string) =>
  `/${instanceId}/rescueMode`;
const REBOOT_INSTANCE_API_URL = (instanceId: string) => `/${instanceId}/reboot`;
const SUSPEND_INSTANCE_API_URL = (instanceId: string) =>
  `/${instanceId}/shelve`;
const BOOT_INSTANCE_API_URL = (instanceId: string) => `/${instanceId}/start`;
const REACTIVE_INSTANCE_API_URL = (instanceId: string) =>
  `/${instanceId}/unshelve`;

const ATTACH_VOLUME_INSTANCE_API_URL = (volumeId: string) =>
  `/volume/${volumeId}/attach`;
const DETACH_VOLUME_INSTANCE_API_URL = (volumeId: string) =>
  `/volume/${volumeId}/detach`;
const GET_INSTANCE_Volume_API_URL = (volumeId: string) => `/${volumeId}`;
const GET_INSTANCES_SNAPSHOTS_API_URL = `/snapshot/all`;

export function getInstancesApi() {
  return jwtEnvAxios("instances").get(``);
}

const GET_INSTANCE_SNAPSHOT_DETAILS = (instanceId: string, imageId: string) =>
  `${instanceId}/snapshot/${imageId}`;

const APPLICATIONACCESS_INSTANCE_API_URL = (instanceId: string) =>
  `/${instanceId}/applicationAccess`;

//volume backup
const GET_INSTANCE_VOLUME_BACKUP_LIST = (
  volumeId: string,
  regionName: string
) => `volume/${volumeId}/region/${regionName}/volumeBackup`;
const DELETE_INSTANCE_VOLUME_BACKUP_LIST = (
  regionName: string,
  volumeId: string,
  volumeBackupId: string
) => `volume/${volumeId}/region/${regionName}/volumeBackup/${volumeBackupId}`;

const RESTORE_INSTANCE_VOLUME_BACKUP_LIST = (
  volumeId: string,
  regionName: string,
  volumeBackupId: string
) =>
  `volume/${volumeId}/region/${regionName}/volumeBackup/${volumeBackupId}/restore`;

//volume snapshot
const GET_INSTANCE_VOLUME_SNAPSHOT_LIST = (volumeId: string) =>
  `volume/${volumeId}/snapshot`;
const DELETE_INSTANCE_VOLUME_SNAPSHOT_LIST = (
  volumeId: string,
  snapshotId: string
) => `volume/${volumeId}/snapshot/${snapshotId}`;

const CREATE_INSTANCE_VOLUME_FROM_BACKUP = (
  volumeId: string,
  regionName: string,
  volumeBackupId: string
) =>
  `volume/${volumeId}/region/${regionName}/volumeBackup/${volumeBackupId}/volume`;

// export function UpdateDBSeApi(
//   id: string,
//   engine: string,
//   data: DBSUpdateApiData
// ) {
//   return jwtEnvAxios("dbs").put(
//     UPDATE_DATABASE_SERVICE_API_URL(id, engine),
//     data
//   );
// }

export function createInstanceApi(data: any) {
  return jwtEnvAxios("instances").post(``, data);
}

export function createMultipleInstanceApi(data: any) {
  return jwtEnvAxios("instances").post(`/bulk`, data);
}

export function deleteInstanceApi(id: string) {
  return jwtEnvAxios("instances").delete(`/${id}`);
}

export function getInstancesFlavorsApi() {
  return jwtEnvAxios("instances", true).get(`/flavors`);
}

export function getInstancesProductAvalibilityApi({
  addonFamily,
}: {
  addonFamily?: string;
}) {
  return jwtEnvAxios("instances", true).get(
    `/productAvailability?ovhSubsidiary=FR${
      addonFamily ? `&addonFamily=${addonFamily}` : ""
    }`
  );
}

export function getRegionsApi() {
  return jwtEnvAxios("instances", true).get(`/regions/detail`);
}

export function getInstancesImagesApi(region: string) {
  return jwtEnvAxios("instances", true).get(
    `/image?region=${region}&flavorType&osType`
  );
}

export function getInstanceApi(instanceId: string) {
  return jwtEnvAxios("instances").get(GET_INSTANCE_API_URL(instanceId));
}

//ssh keys
export function getInstancesSshKeysApi() {
  return jwtEnvAxios("instances").get(`/sshkey`);
}

export function createInstancesSshKeysApi(data: any) {
  return jwtEnvAxios("instances").post(`/sshkey`, data);
}

export function updateInstancesSshKeysApi(id: string, data: any) {
  return jwtEnvAxios("instances").put(`/sshkey/${id}`, data);
}

export function deleteInstancesSshKeysApi(id: string) {
  return jwtEnvAxios("instances").delete(`/sshkey/${id}`);
}

//
export function getInstancesOneFlavorApi(flavorId: string) {
  return jwtEnvAxios("instances", true).get(
    GET_INSTANCE_ONE_FLAVOR_API_URL(flavorId)
  );
}

export function getInstanceVncApi(instanceId: string) {
  return jwtEnvAxios("instances").post(GET_INSTANCE_VNC_API_URL(instanceId));
}

export function updateInstanceNameApi(instanceId: string, data: InstanceName) {
  return jwtEnvAxios("instances").put(
    UPDATE_INSTANCE_NAME_API_URL(instanceId),
    data
  );
}

export function updateInstanceModelApi(
  instanceId: string,
  data: InstanceModel
) {
  return jwtEnvAxios("instances").post(
    UPDATE_INSTANCE_MODEL_API_URL(instanceId),
    data
  );
}

export function updateInstanceMonthlyBillingApi(instanceId: string) {
  return jwtEnvAxios("instances").post(`/${instanceId}/monthlyBilling`);
}

export function reinstallInstanceImageApi(instanceId: string, data: any) {
  return jwtEnvAxios("instances").post(
    REINSTALL_INSTANCE_IMAGE_API_URL(instanceId),
    data
  );
}

export function stopInstanceApi(instanceId: string, data: any) {
  return jwtEnvAxios("instances").post(STOP_INSTANCE_API_URL(instanceId), data);
}
export function rescueModeInstanceApi(instanceId: string, data: any) {
  return jwtEnvAxios("instances").post(
    RESCUE_MODE_INSTANCE_API_URL(instanceId),
    data
  );
}
export function rebootInstanceApi(instanceId: string, data: any) {
  return jwtEnvAxios("instances").post(
    REBOOT_INSTANCE_API_URL(instanceId),
    data
  );
}
export function suspendInstanceApi(instanceId: string, data: any) {
  return jwtEnvAxios("instances").post(
    SUSPEND_INSTANCE_API_URL(instanceId),
    data
  );
}
export function bootInstanceApi(instanceId: string, data: any) {
  return jwtEnvAxios("instances").post(BOOT_INSTANCE_API_URL(instanceId), data);
}

export function applicationAccessInstanceApi(instanceId: string, data: any) {
  return jwtEnvAxios("instances").post(
    APPLICATIONACCESS_INSTANCE_API_URL(instanceId),
    data
  );
}

export function reactiveInstanceApi(instanceId: string, data: any) {
  return jwtEnvAxios("instances").post(
    REACTIVE_INSTANCE_API_URL(instanceId),
    data
  );
}

//volumes
export function getInstancesVolumesApi() {
  return jwtEnvAxios("instances").get(`/volume`);
}

export function getInstancesVolumeApi(id: string) {
  return jwtEnvAxios("instances").get(`/volume/${id}`);
}

export function createInstancesVolumesApi(data: any) {
  return jwtEnvAxios("instances").post(`/volume`, data);
}

export function updateInstancesVolumesApi(id: string, data: any) {
  return jwtEnvAxios("instances").put(`/volume/${id}`, data);
}

export function upsizeInstancesVolumesApi(id: string, data: any) {
  return jwtEnvAxios("instances").post(`/volume/${id}/upsize`, data);
}

export function deleteInstancesVolumesApi(id: string, regionName: string) {
  return jwtEnvAxios("instances").delete(`/volume/${id}/region/${regionName}`);
}

export function instancesAttachVolumeApi(volumeId: string, data: any) {
  return jwtEnvAxios("instances").post(
    ATTACH_VOLUME_INSTANCE_API_URL(volumeId),
    data
  );
}
export function instancesDetachVolumeApi(volumeId: string, data: any) {
  return jwtEnvAxios("instances").post(
    DETACH_VOLUME_INSTANCE_API_URL(volumeId),
    data
  );
}
export function createInstancesVolumeBackupApi(regionName: string, data: any) {
  return jwtEnvAxios("instances").post(
    `/region/${regionName}/volumeBackup`,
    data
  );
}

export function createInstancesVolumeSnapshotApi(volumeId: string, data: any) {
  return jwtEnvAxios("instances").post(`/volume/${volumeId}/snapshot`, data);
}

export function getInstancesVolumesSnapshotApi() {
  return jwtEnvAxios("instances").get(`/volume/snapshot`);
}

export function getInstanceVolumeApi(volumeId: string) {
  return jwtEnvAxios("instances").get(GET_INSTANCE_Volume_API_URL(volumeId));
}

//snapshot
export function getInstanceSnapshotApi(instanceId: string) {
  return jwtEnvAxios("instances").get(`/${instanceId}/snapshot`);
}
export function getInstanceSnapshotDetailsApi(
  instanceId: string,
  imageId: string
) {
  return jwtEnvAxios("instances").get(
    GET_INSTANCE_SNAPSHOT_DETAILS(instanceId, imageId)
  );
}

// --------------------------auto-backup and snapshots-------------------
export function createInstanceManualBackupApi(instanceId: string, data: any) {
  return jwtEnvAxios("instances").post(`/${instanceId}/snapshot`, data);
}

export function deleteInstanceSnapshotApi(instanceId: string, imageId: string) {
  return jwtEnvAxios("instances").delete(`/${instanceId}/snapshot/${imageId}`);
}

export function deleteInstancesSnapshotApi(imageId: string) {
  return jwtEnvAxios("instances").delete(`/snapshot/${imageId}`);
}

export function getInstanceAutoBackupInfoApi(
  instanceId: string,
  regionName: string
) {
  return jwtEnvAxios("instances").get(
    `${instanceId}/region/${regionName}/workflow/backup`
  );
}

export function enableInstanceAutoBackupApi(regionName: string, data: any) {
  return jwtEnvAxios("instances").post(
    `/region/${regionName}/workflow/backup/rotation7`,
    data
  );
}

export function disableInstanceAutoBackupApi(
  regionName: string,
  backupWorkflowId: string,
  instanceId: string
) {
  return jwtEnvAxios("instances").delete(
    `/region/${regionName}/workflow/backup/${backupWorkflowId}/instance/${instanceId}`
  );
}

// -----------------------------------------------------------------

//volume backups
export function getInstanceVolumeBackupListApi(
  volumeId: string,
  regionName: string
) {
  return jwtEnvAxios("instances").get(
    GET_INSTANCE_VOLUME_BACKUP_LIST(volumeId, regionName)
  );
}
export function deleteInstanceVolumeBackupListApi(
  regionName: string,
  volumeId: string,
  volumeBackupId: string
) {
  return jwtEnvAxios("instances").delete(
    DELETE_INSTANCE_VOLUME_BACKUP_LIST(volumeId, regionName, volumeBackupId)
  );
}
export function restoreInstanceVolumeBackupListApi(
  volumeId: string,
  regionName: string,
  volumeBackupId: string,
  data: any
) {
  return jwtEnvAxios("instances").post(
    RESTORE_INSTANCE_VOLUME_BACKUP_LIST(volumeId, regionName, volumeBackupId),
    data
  );
}
//volume snapshots
export function getInstanceVolumeSnapshotListApi(volumeId: string) {
  return jwtEnvAxios("instances").get(
    GET_INSTANCE_VOLUME_SNAPSHOT_LIST(volumeId)
  );
}
export function deleteInstanceVolumeSnapshotListApi(
  volumeId: string,
  snapshotId: string
) {
  return jwtEnvAxios("instances").delete(
    DELETE_INSTANCE_VOLUME_SNAPSHOT_LIST(volumeId, snapshotId)
  );
}

export function getInstancesSnapshotsApi() {
  return jwtEnvAxios("instances").get(GET_INSTANCES_SNAPSHOTS_API_URL);
}

export function createInstanceVolumeFromBackupApi(
  volumeId: string,
  regionName: string,
  volumeBackupId: string,
  data: any
) {
  return jwtEnvAxios("instances").post(
    CREATE_INSTANCE_VOLUME_FROM_BACKUP(volumeId, regionName, volumeBackupId),
    data
  );
}
