import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  InstanceVolume,
  InstanceVolumeBackupApiType,
  InstanceVolumeCreateApiType,
  InstanceVolumeEditApiType,
  InstanceVolumeSnapshot,
  InstanceVolumeSnapshotApiType,
  InstanceVolumeUpsizeApiType,
  InstancesVolumesState,
} from "../../types/instance";
import { IThunkRejectValue, RootState } from "../../types";
import {
  createInstanceVolumeFromBackupApi,
  createInstancesVolumeBackupApi,
  createInstancesVolumeSnapshotApi,
  createInstancesVolumesApi,
  deleteInstancesVolumesApi,
  getInstanceVolumeApi,
  getInstancesVolumeApi,
  getInstancesVolumesApi,
  getInstancesVolumesSnapshotApi,
  instancesDetachVolumeApi,
  updateInstancesVolumesApi,
  upsizeInstancesVolumesApi,
} from "../../apis/instancesAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import toast from "react-hot-toast";

export const InstanceVolumeRefreshStatus = [
  "restoring-backup",
  "backing-up",
  "snapshoting",
  "reserved",
  "attaching",
  "detaching",
];

const initialState: InstancesVolumesState = {
  volumes: [],
  volumesLoading: false,
  volumesActionLoading: false,

  selectedVolume: null,
  selectedVolumeLoading: false,

  instanceShowInstanceAttachModal: false,
  instanceShowInstanceDetachModal: false,
};

export const getInstancesVolumesAsync = createAsyncThunk<
  { volumes: InstanceVolume[] },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instances/volumes",
  async (
    { withoutLoading },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getInstancesVolumesAsync.pending(requestId, {
          withoutLoading,
        })
      );
      const response = await getInstancesVolumesApi();
      const volumes = response.data.Result;
      return fulfillWithValue({ volumes });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getInstancesVolumeAsync = createAsyncThunk<
  { volume: InstanceVolume },
  { id: string; withoutLoading?: boolean },
  IThunkRejectValue
>(
  "instances/volume",
  async (
    { id, withoutLoading },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getInstancesVolumeAsync.pending(requestId, {
          id,
          withoutLoading,
        })
      );
      const response = await getInstancesVolumeApi(id);
      const volume = response.data.Result;
      return fulfillWithValue({ volume });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createInstancesVolumesAsync = createAsyncThunk<
  boolean,
  { data: InstanceVolumeCreateApiType },
  IThunkRejectValue
>("instances/volumes/create", async ({ data }, { rejectWithValue }) => {
  try {
    await createInstancesVolumesApi(data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateInstancesVolumesAsync = createAsyncThunk<
  boolean,
  { id: any; data: InstanceVolumeEditApiType },
  IThunkRejectValue
>("instances/volumes/update", async ({ id, data }, { rejectWithValue }) => {
  try {
    await updateInstancesVolumesApi(id, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const upsizeInstancesVolumesAsync = createAsyncThunk<
  boolean,
  { id: any; data: InstanceVolumeUpsizeApiType },
  IThunkRejectValue
>("instances/volumes/upsize", async ({ id, data }, { rejectWithValue }) => {
  try {
    await upsizeInstancesVolumesApi(id, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createInstancesVolumeBackupAsync = createAsyncThunk<
  boolean,
  { region: string; data: InstanceVolumeBackupApiType },
  IThunkRejectValue
>(
  "instances/volumes/backup/create",
  async ({ region, data }, { rejectWithValue }) => {
    try {
      await createInstancesVolumeBackupApi(region, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createInstancesVolumeSnapshotAsync = createAsyncThunk<
  boolean,
  { volumeId: string; data: InstanceVolumeSnapshotApiType },
  IThunkRejectValue
>(
  "instances/volumes/snapshot/create",
  async ({ volumeId, data }, { rejectWithValue }) => {
    try {
      await createInstancesVolumeSnapshotApi(volumeId, data);
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const deleteInstancesVolumesAsync = createAsyncThunk<
  boolean,
  { id: string; region: string },
  IThunkRejectValue
>("instances/volumes/delete", async ({ id, region }, { rejectWithValue }) => {
  try {
    await deleteInstancesVolumesApi(id, region);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const instancesDetachVolumeAsync = createAsyncThunk<
  any,
  { volumeId: string; data: any },
  IThunkRejectValue
>(
  "instances/volume/detach",
  async ({ volumeId, data }, { rejectWithValue }) => {
    try {
      const response = await instancesDetachVolumeApi(volumeId, data);
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const getInstanceVolumeAsync = createAsyncThunk<
  { volume: InstanceVolume },
  { volumeId: string },
  IThunkRejectValue
>(
  "instances/one/volume",
  async ({ volumeId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getInstanceVolumeApi(volumeId);
      const volume = response.data.Result;
      return fulfillWithValue({ volume });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);
export const createInstanceVolumeFromBackupAsync = createAsyncThunk<
  boolean,
  { volumeId: string; regionName: string; volumeBackupId: string; data: any },
  IThunkRejectValue
>(
  "instances/volume/craete/from/backup",
  async (
    { volumeId, regionName, volumeBackupId, data },
    { rejectWithValue }
  ) => {
    try {
      await createInstanceVolumeFromBackupApi(
        volumeId,
        regionName,
        volumeBackupId,
        data
      );
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const instancesVolumesSlice = createSlice({
  name: "instances-volumes",
  initialState,
  reducers: {
    handleSetSelectedInstanceVolume: (
      state,
      action: PayloadAction<InstanceVolume | null>
    ) => {
      state.selectedVolume = action.payload;
      state.selectedVolumeLoading = false;
    },
    handleInstanceAttachShowModal: (
      state,
      action: PayloadAction<{ selectedVolume: any }>
    ) => {
      state.instanceShowInstanceAttachModal = true;
      state.selectedVolume = action.payload.selectedVolume;
    },
    handleInstanceAttachHideModal: (state) => {
      state.instanceShowInstanceAttachModal = false;
    },
    handleInstanceDetachShowModal: (
      state,
      action: PayloadAction<{ selectedVolume: any }>
    ) => {
      state.instanceShowInstanceDetachModal = true;
      state.selectedVolume = action.payload.selectedVolume;
    },
    handleInstanceDetachHideModal: (state) => {
      state.instanceShowInstanceDetachModal = false;
    },
    handleResetInstanceVolumes: (state) => {
      state.volumes = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstancesVolumesAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.volumesLoading = true;
      })
      .addCase(getInstancesVolumesAsync.fulfilled, (state, action) => {
        state.volumesLoading = false;
        state.volumes = action.payload.volumes;
      })
      .addCase(getInstancesVolumesAsync.rejected, (state, { payload }) => {
        state.volumesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getInstancesVolumeAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.selectedVolumeLoading = true;
      })
      .addCase(getInstancesVolumeAsync.fulfilled, (state, action) => {
        state.selectedVolumeLoading = false;
        state.selectedVolume = action.payload.volume;
      })
      .addCase(getInstancesVolumeAsync.rejected, (state, { payload }) => {
        state.selectedVolumeLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createInstancesVolumesAsync.pending, (state) => {
        state.volumesActionLoading = true;
      })
      .addCase(createInstancesVolumesAsync.fulfilled, (state, action) => {
        state.volumesActionLoading = false;
      })
      .addCase(createInstancesVolumesAsync.rejected, (state, { payload }) => {
        state.volumesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateInstancesVolumesAsync.pending, (state) => {
        state.volumesActionLoading = true;
      })
      .addCase(updateInstancesVolumesAsync.fulfilled, (state, action) => {
        state.volumesActionLoading = false;
      })
      .addCase(updateInstancesVolumesAsync.rejected, (state, { payload }) => {
        state.volumesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(upsizeInstancesVolumesAsync.pending, (state) => {
        state.volumesActionLoading = true;
      })
      .addCase(upsizeInstancesVolumesAsync.fulfilled, (state, action) => {
        state.volumesActionLoading = false;
      })
      .addCase(upsizeInstancesVolumesAsync.rejected, (state, { payload }) => {
        state.volumesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createInstancesVolumeBackupAsync.pending, (state) => {
        state.volumesActionLoading = true;
      })
      .addCase(createInstancesVolumeBackupAsync.fulfilled, (state) => {
        state.volumesActionLoading = false;
      })
      .addCase(
        createInstancesVolumeBackupAsync.rejected,
        (state, { payload }) => {
          state.volumesActionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(createInstancesVolumeSnapshotAsync.pending, (state) => {
        state.volumesActionLoading = true;
      })
      .addCase(createInstancesVolumeSnapshotAsync.fulfilled, (state) => {
        state.volumesActionLoading = false;
      })
      .addCase(
        createInstancesVolumeSnapshotAsync.rejected,
        (state, { payload }) => {
          state.volumesActionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(deleteInstancesVolumesAsync.pending, (state) => {
        state.volumesActionLoading = true;
      })
      .addCase(deleteInstancesVolumesAsync.fulfilled, (state, action) => {
        state.volumesActionLoading = false;
      })
      .addCase(deleteInstancesVolumesAsync.rejected, (state, { payload }) => {
        state.volumesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(instancesDetachVolumeAsync.pending, (state) => {
        state.volumesActionLoading = true;
      })
      .addCase(instancesDetachVolumeAsync.fulfilled, (state) => {
        state.volumesActionLoading = false;
      })
      .addCase(instancesDetachVolumeAsync.rejected, (state, { payload }) => {
        state.volumesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(getInstanceVolumeAsync.pending, (state) => {
        state.selectedVolumeLoading = true;
      })
      .addCase(getInstanceVolumeAsync.fulfilled, (state, action) => {
        state.selectedVolumeLoading = false;
        state.selectedVolume = action.payload.volume;
      })
      .addCase(getInstanceVolumeAsync.rejected, (state, { payload }) => {
        state.selectedVolumeLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
    builder
      .addCase(createInstanceVolumeFromBackupAsync.pending, (state) => {
        state.volumesActionLoading = true;
      })
      .addCase(
        createInstanceVolumeFromBackupAsync.fulfilled,
        (state, action) => {
          state.volumesActionLoading = false;
        }
      )
      .addCase(
        createInstanceVolumeFromBackupAsync.rejected,
        (state, { payload }) => {
          state.volumesActionLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );
  },
});

export const selectInstancesVolumes = (state: RootState) =>
  state.instancesVolumes.volumes;
export const selectInstancesVolumesLoading = (state: RootState) =>
  state.instancesVolumes.volumesLoading;
export const selectInstancesVolumesActionLoading = (state: RootState) =>
  state.instancesVolumes.volumesActionLoading;

export const selectInstancesSelectedVolume = (state: RootState) =>
  state.instancesVolumes.selectedVolume;
export const selectInstancesSelectedVolumeLoading = (state: RootState) =>
  state.instancesVolumes.selectedVolumeLoading;

export const selectShowInstanceAttachModal = (state: RootState) =>
  state.instancesVolumes.instanceShowInstanceAttachModal;
export const selectShowInstanceDetachModal = (state: RootState) =>
  state.instancesVolumes.instanceShowInstanceDetachModal;

export const {
  handleSetSelectedInstanceVolume,
  handleInstanceAttachHideModal,
  handleInstanceAttachShowModal,
  handleInstanceDetachHideModal,
  handleInstanceDetachShowModal,
  handleResetInstanceVolumes,
} = instancesVolumesSlice.actions;
export default instancesVolumesSlice.reducer;
