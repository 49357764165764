import { useNavigate, useSearchParams } from "react-router-dom";
import { HomeUrl, SignInUrl } from "../../utils/urls";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignUpSchema, validatePassword } from "../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getMeAsync,
  registerAsync,
  selectLoginLoading,
  selectUserLoading,
} from "../../store/auth/authSlice";
import { useEffect, useState } from "react";
import { PasswordErrors } from "../../types";
import {
  Button,
  Checkbox,
  cn,
  Flex,
  Input,
  Loading,
  Typography,
  AnimatedFormError,
} from "djuno-design";
import CustomLink from "../general/CustomLink";

const SignUp = () => {
  const blogUrl = process.env.REACT_APP_BLOGS_URL || "";
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoginLoading);
  const userLoading = useAppSelector(selectUserLoading);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const inviteToken = query.get("token");
  const envName = query.get("envName") || "";

  const isInvitedMode =
    inviteToken !== undefined && inviteToken !== null && inviteToken !== "";

  const [password_error, setPasswordErrors] = useState<PasswordErrors>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(SignUpSchema(isInvitedMode)),
  });

  const selectedPassword = watch("password");

  const onSubmit = (data: any) => {
    if (!loading && !userLoading) {
      dispatch(
        registerAsync({
          token: isInvitedMode ? inviteToken : null,
          data: {
            FullName: data.fullName,
            OrganizationName: data.organizationName,
            Email: data.email,
            Password: data.password,
            AgreedWithTerms: data.termsOfService,
          },
        })
      ).then((action) => {
        if (action.type === "auth/register/fulfilled") {
          dispatch(getMeAsync({})).then((action) => {
            if (action.type === "auth/me/fulfilled") {
              navigate(HomeUrl);
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    setValue("organizationName", envName);
  }, [envName, setValue]);

  useEffect(() => {
    setPasswordErrors(validatePassword(selectedPassword));
  }, [selectedPassword]);

  return (
    <>
      <Typography.Title level={5} className="text-center">
        Sign up for an account
      </Typography.Title>
      {!userLoading && (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Flex direction="col" className="gap-4">
            <Input
              {...register("fullName")}
              label="Full name"
              required
              placeholder="Jone Doe"
              error={errors.fullName?.message}
              hint="4 characters minimum"
            />
            <Input
              {...register("organizationName")}
              disabled={isInvitedMode}
              label="Organization name"
              required={!isInvitedMode}
              placeholder={isInvitedMode ? "" : "My Company"}
              error={errors.organizationName?.message}
              hint={
                isInvitedMode
                  ? "You are invited to this organization"
                  : "4 characters minimum"
              }
            />
            {!isInvitedMode && (
              <Input
                {...register("email")}
                label="Email address"
                required
                placeholder="your@email.com"
                error={errors.email?.message}
              />
            )}
            <Input
              label="Password"
              {...register("password")}
              type="password"
              autoComplete="new-password"
              required
              placeholder="●●●●●●"
              error={errors.password?.message}
              hint={
                <Flex justify="end" className="gap-1 flex-wrap text-[10px]">
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.length,
                    })}
                  >
                    8-64 chars
                  </span>
                  |
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.lowercase,
                    })}
                  >
                    1 lowercase
                  </span>
                  |
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.uppercase,
                    })}
                  >
                    1 uppercase
                  </span>
                  |
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.number,
                    })}
                  >
                    1 number
                  </span>{" "}
                  |
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.special_char,
                    })}
                  >
                    1 special char
                  </span>
                </Flex>
              }
              labelClassName="!items-start"
            />
            <Controller
              name="termsOfService"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Flex direction="col">
                  <Flex>
                    <Checkbox
                      value={value}
                      onChange={onChange}
                      id="TermsOfService"
                    />
                    <Typography.Text className="!text-sm">
                      By creating an account, I agree to Djuno Cloud's{" "}
                      <Typography.Link
                        href={blogUrl + "/term-of-use/"}
                        target="_blank"
                        className="!text-sm"
                      >
                        Terms of Service
                      </Typography.Link>{" "}
                      and{" "}
                      <Typography.Link
                        href={blogUrl + "/privacy-notice/"}
                        target="_blank"
                        className="!text-sm"
                      >
                        Privacy Policy
                      </Typography.Link>
                      .
                    </Typography.Text>
                  </Flex>
                  <AnimatedFormError error={errors.termsOfService?.message} />
                </Flex>
              )}
            />

            <Flex direction="col">
              <Button
                uiType="primary"
                type="submit"
                className="!w-full"
                loading={loading}
              >
                <Typography.Text
                  uiType="transparent"
                  className="w-full text-center"
                  size="sm"
                >
                  Create a free account
                </Typography.Text>
              </Button>
              <Flex justify="center" className="gap-1 mt-2">
                <Typography.Text className="!text-sm">
                  Already have an account?
                </Typography.Text>
                <CustomLink to={SignInUrl} className="text-sm">
                  Sign in
                </CustomLink>
              </Flex>
            </Flex>
          </Flex>
        </form>
      )}
      {userLoading && (
        <Flex items="center" justify="center" className="min-h-[200px]">
          <Loading borderSize={2} />
        </Flex>
      )}
    </>
  );
};
export default SignUp;
