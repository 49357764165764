import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  deleteBucketAsync,
  getBucketsAsync,
  handleChangeCheckedBuckets,
  selectActionLoading,
  selectBuckets,
  selectCheckedBuckets,
  selectLoading,
} from "../../../store/s3/buckets/bucketsSlice";
import S3bucketEditorModal from "./modals/buckets/S3bucketEditorModal";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as SettingIcon } from "./../../../assets/icons/setting.svg";
import { ReactComponent as BrowseIcon } from "./../../../assets/icons/arrow-right-circle.svg";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as LifecycleIcon } from "./../../../assets/icons/arrow-path-rounded-square.svg";
import { ReactComponent as RefreshIcon } from "./../../../assets/icons/arrow-path.svg";
import { ReactComponent as BucketIcon } from "./../../../assets/icons/bucket.svg";
import { S3NotActiveTab } from "../../layouts/NotData";
import { DeleteModal } from "../../modals/QuestionModal";
import { humanizeSize } from "../../../utils/file";
import { useNavigate } from "react-router-dom";
import {
  S3BucketCreateUrl,
  S3BucketSettingUrl,
  S3BucketUrl,
} from "../../../utils/urls";
import { dateConvertor } from "../../../utils/date";
import { useSearch } from "../../../providers/SearchProvider";
import { S3Bucket } from "../../../types/s3-bucket";
import S3MultipleBucketEditorModal from "./modals/buckets/S3MultipleBucketEditorModal";
import { handleShowReplicationEditor } from "../../../store/s3/buckets/setting/replicationsSlice";
import S3MultipleLifecycleEditorModal from "./modals/buckets/S3MultipleLifecycleEditorModal";
import { handleShowLifecycleEditor } from "../../../store/s3/buckets/setting/lifecycleSlice";
import { selectS3Status } from "../../../store/s3/s3PublicSlice";
import {
  Button,
  Checkbox,
  Dropdown,
  EmptyState,
  SimpleTable,
  Typography,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";

const S3bucketsTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const buckets = useAppSelector(selectBuckets);
  const [filteredBuckets, setFilteredBuckets] = useState<S3Bucket[]>([]);

  const loading = useAppSelector(selectLoading);
  const actionLoading = useAppSelector(selectActionLoading);
  const [deleteBucketName, setDeleteBucketName] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { value: searchValue } = useSearch();

  const checkedBuckets = useAppSelector(selectCheckedBuckets);

  useEffect(() => {
    dispatch(getBucketsAsync());
  }, [dispatch]);

  useEffect(() => {
    const lookedUpBuckets = buckets.filter((bucket) =>
      bucket.name?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredBuckets(lookedUpBuckets);
  }, [dispatch, searchValue, buckets]);

  const handleDelete = () => {
    deleteBucketName &&
      dispatch(deleteBucketAsync({ name: deleteBucketName })).then((action) => {
        if (action.type === "buckets/delete/fulfilled") {
          dispatch(getBucketsAsync());
        } else {
        }
        setDeleteBucketName(null);
      });
  };

  const handleChangeChecked = (bucket: S3Bucket) => {
    dispatch(handleChangeCheckedBuckets(bucket));
  };

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            Object Storage
          </Typography.Title>
        </div>
        <div className="hidden lg:flex items-center gap-2">
          <Button
            uiType="light"
            onClick={() => dispatch(getBucketsAsync())}
            className="group"
            tooltip={{ content: "Refresh", className: "!text-xs" }}
          >
            <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>

          <Button
            uiType="light"
            onClick={() => dispatch(handleShowLifecycleEditor({}))}
            disabled={checkedBuckets.length < 1}
            className="group"
            tooltip={{ content: "Lifecycle", className: "!text-xs" }}
          >
            <LifecycleIcon className="w-4 h-4" />
          </Button>

          <Button
            uiType="light"
            onClick={() => dispatch(handleShowReplicationEditor({}))}
            disabled={checkedBuckets.length < 1}
            className="group"
            tooltip={{ content: "Set Replication", className: "!text-xs" }}
          >
            <BucketIcon className="w-3" />
          </Button>

          <Button
            uiType="primary"
            onClick={() => navigate(S3BucketCreateUrl)}
            className="group"
          >
            Create Bucket
            <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
          </Button>
        </div>
        <div className="lg:hidden">
          <Dropdown
            anchor="bottom end"
            menu={[
              {
                key: "Rewind",
                label: (
                  <div className="flex items-center gap-2">
                    <PlusIcon className="w-4 h-4" /> Create Bucket
                  </div>
                ),
                onClick: (_, close) => {
                  close();
                  navigate(S3BucketCreateUrl);
                },
              },
              {
                key: "UploadFile",
                label: (
                  <div className="flex items-center gap-2">
                    <RefreshIcon className="w-4 h-4" /> Refresh
                  </div>
                ),
                onClick: (_, close) => {
                  close();
                  dispatch(getBucketsAsync());
                },
              },
              {
                key: "CreateNewPath",
                label: (
                  <div className="flex items-center gap-2">
                    <LifecycleIcon className="w-4 h-4" /> Lifecycle
                  </div>
                ),
                onClick: (_, close) => {
                  close();
                  dispatch(handleShowLifecycleEditor({}));
                },
                disabled: checkedBuckets.length < 1,
              },
              {
                key: "Replication",
                label: (
                  <div className="flex items-center gap-2">
                    <BucketIcon className="w-4 h-4" /> Set Replication
                  </div>
                ),
                onClick: (_, close) => {
                  close();
                  dispatch(handleShowReplicationEditor({}));
                },
                disabled: checkedBuckets.length < 1,
              },
            ]}
          >
            <Button uiType="light">
              <PlusIcon className="w-4 h-4" />
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Name" />
              <SimpleTable.TH lable="Created" />
              <SimpleTable.TH lable="Size" />
              <SimpleTable.TH lable="Objects" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredBuckets.map((bucket, i) => (
              <SimpleTable.Row
                key={i}
                onClick={() => {
                  navigate(S3BucketUrl(bucket.name));
                }}
              >
                <SimpleTable.TD>
                  <div className="flex items-center space-x-1 ml-1">
                    <div onClick={(event) => event.stopPropagation()}>
                      <Checkbox
                        value={checkedBuckets.some(
                          (o) => o.name === bucket.name
                        )}
                        onChange={() => handleChangeChecked(bucket)}
                      />
                    </div>
                    <HighlighterText
                      searchValue={searchValue}
                      textToHighlight={bucket.name}
                    />
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD className="cursor-pointer">
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {dateConvertor(bucket.creation_date)}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="cursor-pointer">
                  <Typography.Text className="!text-xs md:!text-sm whitespace-nowrap">
                    {bucket.size !== 0
                      ? humanizeSize(Number(bucket.size), {
                          fractionDigits: 2,
                        }).join("B")
                      : "0"}
                    {bucket?.details?.quota?.quota
                      ? ` / ${humanizeSize(bucket?.details?.quota?.quota, {
                          fractionDigits: 2,
                        }).join("B")}`
                      : ""}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="cursor-pointer">
                  <Typography.Text className="!text-xs md:!text-sm">
                    {bucket.objects}
                  </Typography.Text>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-20 gap-1">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        anchor="bottom end"
                        itemsClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <BrowseIcon className="w-4" />
                                Browse
                              </div>
                            ),
                            onClick: () => navigate(S3BucketUrl(bucket.name)),
                          },
                          {
                            key: "2",
                            label: (
                              <div className="flex items-center gap-1">
                                <SettingIcon className="w-4" />
                                Setting
                              </div>
                            ),
                            onClick: () =>
                              navigate(S3BucketSettingUrl(bucket.name)),
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: (_, close) => {
                              close();
                              setDeleteBucketName(bucket.name);
                            },
                          },
                        ]}
                      >
                        <Button uiSize="small" uiType="icon" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                    <ArrowRightIcon className="w-4 h-4 dark:text-slate-100 text-slate-800 cursor-pointer" />
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}

            {buckets.length === 0 && (
              <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                <SimpleTable.TD colSpan={5} className="!border-0">
                  <EmptyState text="You have not created any Bucket yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <S3bucketEditorModal />
      <S3MultipleBucketEditorModal />
      <S3MultipleLifecycleEditorModal />
      <DeleteModal
        title="Delete Bucket"
        isOpen={deleteBucketName !== null}
        onClose={() => setDeleteBucketName(null)}
        onConfirm={handleDelete}
        loading={actionLoading}
        confirmString={
          buckets.find((bucket) => bucket.name === deleteBucketName)?.name
        }
        confirmButtonType="danger"
      />
    </>
  );
};

export default S3bucketsTab;
