import { useAppDispatch, useAppSelector } from "./../../../../hooks";
import {
  getInstancesAsync,
  selectInstancesImagesLoading,
} from "./../../../../store/instances/instancesSlice";
import {
  getInstanceAsync,
  reinstallInstanceImageAsync,
  selectInstance,
  selectInstanceLoading,
} from "./../../../../store/instances/instanceSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceImageSchema } from "./../../../../utils/validations";
import { useForm } from "react-hook-form";
import { InstanceImageInput } from "./../../create/components";
import toast from "react-hot-toast";
import { CustomToast } from "./../../../general/Toast";
import { Button } from "djuno-design";

const InstanceImagesTab = () => {
  const instance = useAppSelector(selectInstance);
  const instanceLoading = useAppSelector(selectInstanceLoading);
  const imagesLoading = useAppSelector(selectInstancesImagesLoading);

  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(InstanceImageSchema),
    shouldUnregister: true,
    reValidateMode: "onChange",
  });

  const onSubmitImage = (data: any) => {
    if (instance) {
      dispatch(
        reinstallInstanceImageAsync({
          instanceId: instance.id,
          data: { imageId: data.selectedImageId },
        })
      ).then((action) => {
        if (action.type === "instance/reinstall/fulfilled") {
          toast.success(() =>
            CustomToast("Instance image changed successfully!")
          );
          reset();
          dispatch(getInstancesAsync({ withoutLoading: false }));
          dispatch(getInstanceAsync({ instanceId: instance.id }));
        }
      });
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-4">
        <form onSubmit={handleSubmit(onSubmitImage)} className="w-full">
          <div className="flex flex-col gap-4">
            {instance && (
              <InstanceImageInput
                selectedRegion={instance.region}
                selectedFlavor={instance.flavor}
                selectedImage={instance?.image || undefined}
                control={control}
                errorMessage={errors.selectedImageGroupId?.message}
                versionsErrorMessage={errors.selectedImageId?.message}
              />
            )}
          </div>

          {!imagesLoading && (
            <div className="mt-4 flex gap-5">
              <Button
                uiType="primary"
                type="submit"
                loading={instanceLoading}
                disabled={instance?.status !== "ACTIVE" || instanceLoading}
              >
                Modify image
              </Button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default InstanceImagesTab;
