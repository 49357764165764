import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  InstancesVolumesUrl,
  InstancesVolumeBackupListUrl,
  InstancesVolumeSnapshotListUrl,
  InstancesVolumeOverviewUrl,
  InstancesVolumeDeletetUrl,
} from "../../../utils/urls";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Helmet } from "react-helmet";
import { ReactComponent as RightArrow } from "./../../../assets/icons/arrow-right.svg";
import { ReactComponent as BackupIcon } from "./../../../assets/icons/arrow-up-on-square.svg";
import { ReactComponent as ComputerIcon } from "./../../../assets/icons/computer-desktop.svg";
import { ReactComponent as DeleteIcon } from "./../../../assets/icons/archive-box.svg";
import { InstanceVolume } from "../../../types/instance";
import {
  getInstancesVolumeAsync,
  getInstancesVolumesAsync,
  InstanceVolumeRefreshStatus,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
} from "../../../store/instances/instancesVolumesSlice";
import {
  getInstanceVolumeBackupListAsync,
  getInstanceVolumeSnapshotListAsync,
  // selectSelectedInstancesVolumeSnapshot,
} from "../../../store/instances/instancesVolumeSlice";
import { Skeleton, TabOptions, Tabs, Typography } from "djuno-design";

export const generateInstanceRoutes = (volume: InstanceVolume) => {
  const options: TabOptions = [
    {
      label: (
        <div className="flex items-center gap-1">
          <ComputerIcon className="w-3" />
          Overview
        </div>
      ),
      url: InstancesVolumeOverviewUrl(volume.id),
      testId: "1th-tab",
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <BackupIcon className="w-3" />
          Backups
        </div>
      ),
      url: InstancesVolumeBackupListUrl(volume.id),
      testId: "2th-tab",
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <BackupIcon className="w-3" />
          Snapshots
        </div>
      ),
      url: InstancesVolumeSnapshotListUrl(volume.id),
      testId: "3th-tab",
    },
    {
      label: (
        <div className="flex items-center gap-1">
          <DeleteIcon className="w-3" />
          Delete
        </div>
      ),
      url: InstancesVolumeDeletetUrl(volume.id),
      testId: "4th-tab",
    },
  ];

  // const overviewOption = {
  //   label: (
  //     <div className="flex items-center gap-1">
  //       <ComputerIcon className="w-3" />
  //       Overview
  //     </div>
  //   ),
  //   url: InstancesVolumeOverviewUrl(volume.id),
  //   testId: "1th-tab",
  // };
  // const backupsOption = {
  //   label: (
  //     <div className="flex items-center gap-1">
  //       <BackupIcon className="w-3" />
  //       Backups
  //     </div>
  //   ),
  //   url: InstancesVolumeBackupListUrl(volume.id),
  //   testId: "2th-tab",
  // };
  // const snapshotsOption = {
  //   label: (
  //     <div className="flex items-center gap-1">
  //       <BackupIcon className="w-3" />
  //       Snapshots
  //     </div>
  //   ),
  //   url: InstancesVolumeSnapshotListUrl(volume.id),
  //   testId: "3th-tab",
  // };
  // const deleteOption: TabOption = {
  //   label: (
  //     <div className="flex items-center gap-1">
  //       <DeleteIcon className="w-3" />
  //       Delete
  //     </div>
  //   ),
  //   url: InstancesVolumeDeletetUrl(volume.id),
  //   testId: "4th-tab",
  // };

  // options.push(overviewOption);
  // options.push(backupsOption);
  // options.push(snapshotsOption);
  // options.push(deleteOption);
  return options;
};

const VolumePage = () => {
  const { volumeId } = useParams();
  const volume = useAppSelector(selectInstancesSelectedVolume);
  // const snapshot = useAppSelector(selectSelectedInstancesVolumeSnapshot);
  const loading = useAppSelector(selectInstancesSelectedVolumeLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const VolumePageMenus = useMemo(() => {
    if (volume) {
      return generateInstanceRoutes(volume);
    }
    return [];
  }, [volume]);

  useEffect(() => {
    if (volumeId) {
      dispatch(getInstancesVolumeAsync({ id: volumeId }));
    }
  }, [dispatch, volumeId]);

  //refresh the list
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let intervalTime =
      Number(process.env.REACT_APP_NODES_REFRESH_INTERVAL) || 20000;

    if (volume && InstanceVolumeRefreshStatus.includes(volume.status)) {
      interval = setInterval(() => {
        dispatch(
          getInstancesVolumeAsync({
            id: volume.id,
            withoutLoading: true,
          })
        ).then((action) => {
          if (
            action.payload &&
            action.type === "instances/volume/fulfilled" &&
            "volume" in action.payload &&
            action.payload.volume.status === "available"
          ) {
            if (location.pathname.includes("/volume-backup")) {
              dispatch(
                getInstanceVolumeBackupListAsync({
                  volumeId: volume?.id,
                  regionName: volume?.region,
                  withoutLoading: true,
                })
              );
              dispatch(getInstancesVolumesAsync({ withoutLoading: true }));
            } else if (location.pathname.includes("/volume-snapshots")) {
              dispatch(
                getInstanceVolumeSnapshotListAsync({
                  volumeId: volume?.id,
                  withoutLoading: true,
                })
              );
              dispatch(getInstancesVolumesAsync({ withoutLoading: true }));
            }
          }
        });
      }, intervalTime);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [dispatch, location.pathname, volume]);

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Volume ({volume ? volume.name : ""})
        </title>
        <meta
          name="description"
          content="Deploy an option from our range of instances, and harness the flexibility of the cloud to grow in a way that suits your needs."
        />
      </Helmet>
      <div className="flex items-center justify-between h-16">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <div className="flex items-center gap-2">
            <RightArrow
              onClick={() => navigate(InstancesVolumesUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />

            {!loading && (
              <Typography.Text className="text-lg">
                {volume?.name}
              </Typography.Text>
            )}
            {loading && (
              <Skeleton shape="rectangle" style={{ width: 200, height: 30 }} />
            )}
          </div>
        </div>
      </div>

      <div className="px-6 mt-5">
        <Tabs options={VolumePageMenus} />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default VolumePage;
