import { forwardRef } from "react";

const DateCustomInput = forwardRef<HTMLButtonElement, any>(
  function DateCustomInput(
    {
      isDarkMode,
      value,
      onClick,
    }: { isDarkMode: boolean; value: any; onClick: () => void },
    ref
  ) {
    return (
      <button
        style={{
          backgroundColor: isDarkMode ? "#32353b" : "#f1f5f9",
          paddingTop: 7,
          paddingBottom: 7,
          paddingRight: 15,
          paddingLeft: 15,
          borderRadius: 6,
          width: "100%",
          height: 40,
          border: isDarkMode ? "none" : `1px solid #BDBDBD`,
          cursor: "pointer",
          // fontWeight: "bold",
          textAlign: "start",
          fontSize: 14,
          color: isDarkMode ? "#ffffff" : "#212121",
          opacity: 0.9,
        }}
        type="button"
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    );
  }
);
export default DateCustomInput;
