import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectWebApp,
  selectWebAppLoading,
} from "../../../store/web-app/webAppSlice";
import { Alert, Card, Typography } from "djuno-design";

const WebAppMetricsTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);
  const dispatch = useAppDispatch();
  return (
    <div className="flex flex-col gap-10">
      <Card
        title="Usage"
        description="The historical usage for this service (up to 31 days)."
      >
        <div className="flex flex-col gap-5">
          <Typography.Text>chart section</Typography.Text>
          <Alert>
            <Typography.Text size="sm" className="text-center">
              Total usage: <span className="font-semibold">1.63 hours</span>{" "}
              (month-to-date)
            </Typography.Text>
          </Alert>
          <Alert>
            <Typography.Text size="sm" className="text-center">
              Average usage:{" "}
              <span className="font-semibold">0.09 hours/day</span>
            </Typography.Text>
          </Alert>
          <Alert uiType="info">
            <Typography.Text size="sm">
              You have used 0% of your monthly quota across all of your free
              instances.{" "}
              <Link
                to={`/settings/billing#free-usage`}
                className="text-sm text-primary-300 hover:text-primary-400"
              >
                View usage
              </Link>
            </Typography.Text>
          </Alert>
        </div>
      </Card>
      <BandWidth />
    </div>
  );
};

const BandWidth = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  return (
    <Card title="Bandwidth">
      <Typography.Text size="sm" className="mt-3">
        Service <span className="font-semibold">{webApp?.Name}</span> has used
        zero bandwidth in the last 24 hours.
      </Typography.Text>
    </Card>
  );
};
export default WebAppMetricsTab;
