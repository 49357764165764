import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const AnimatedLinkBox: React.FC<
  React.PropsWithChildren<{
    to: string;
    target?: React.HTMLAttributeAnchorTarget | undefined;
  }>
> = ({ children, to, target }) => {
  return (
    <div className="col-span-12 md:col-span-6">
      <Link
        to={to}
        target={target}
        className="group flex items-center gap-4 h-[40px] bg-white dark:bg-dark-2 hover:bg-sky-100 dark:hover:bg-sky-800 shadow-[0_2px_48px_0_rgba(0,0,0,0.06)] rounded-2xl rounded-tr-none pl-5 transition-all duration-300"
      >
        <FiChevronRight className="w-3 h-3 text-slate-800 dark:text-slate-200 group-hover:translate-x-1 transition-all duration-300" />
        {children}
      </Link>
    </div>
  );
};

export default AnimatedLinkBox;
