import { PublicErrorMessage } from "../../apis";

/* eslint-disable @typescript-eslint/no-explicit-any */
const CustomErrorToast = (message?: string) => {
  return (
    <div
      className="error-toast"
      dangerouslySetInnerHTML={{ __html: message || PublicErrorMessage }}
    />
  );
};

const CustomToast = (message?: string) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: message || PublicErrorMessage }} />
  );
};

export { CustomErrorToast, CustomToast };
