import { humanizeSize } from "../../../../utils/file";
import { Input, Select, SelectOption, Switcher, Tooltip } from "djuno-design";

export const capacityOptions: SelectOption[] = [
  { label: "MB", value: "M" },
  { label: "GB", value: "G" },
  { label: "TB", value: "T" },
];

export const getQuataSize = (capacityValue: number) => {
  const { number, symbol } = humanizeSize(capacityValue);

  let selectedOption: SelectOption | undefined = undefined;
  if (typeof symbol === "undefined" || symbol === "") {
    selectedOption = undefined;
  } else {
    selectedOption = capacityOptions.find((co) => co.value === symbol);
    if (selectedOption) {
    }
  }

  return {
    number,
    selectedOption,
  };
};

const S3QuotaForm = ({
  enabledQuota,
  setQuotaStatus,
  capacityInputProps,
  capacityErrorMessage,
  selectedCapacityOption,
  setSelectedCapacityOption,
}: {
  enabledQuota: boolean;
  setQuotaStatus: (status: boolean) => void;
  capacityInputProps: React.InputHTMLAttributes<HTMLInputElement>;
  capacityErrorMessage: string | undefined;
  selectedCapacityOption: string;
  setSelectedCapacityOption: (v: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between w-full">
        <div className="flex gap-2 items-center">
          <div className="text-sm text-slate-700 dark:text-slate-200">
            Quota
          </div>
          <Tooltip.Info
            className="!text-xs"
            content="Quota limits the amount of data in the bucket."
          />
        </div>
        <Switcher value={enabledQuota} onChange={setQuotaStatus} />
      </div>
      {enabledQuota && (
        <div className="">
          <div className="text-sm text-slate-500 dark:text-slate-200">
            Capacity
          </div>
          <div className="flex gap-2">
            <div className="flex-1">
              <Input {...capacityInputProps} error={capacityErrorMessage} />
            </div>
            <div className="w-20">
              <Select
                options={capacityOptions}
                value={selectedCapacityOption}
                onChange={(o) => o && setSelectedCapacityOption(o)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default S3QuotaForm;
