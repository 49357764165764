import { cn } from "djuno-design";
import Highlighter from "react-highlight-words";

const HighlighterText: React.FC<{
  searchValue: string;
  textToHighlight: string;
  className?: string;
}> = ({ searchValue, textToHighlight, className }) => {
  return (
    <Highlighter
      highlightClassName="bg-primary-100 dark:bg-primary-300 rounded-sm"
      className={cn(
        "whitespace-nowrap max-w-[110px] md:max-w-none text-ellipsis overflow-hidden text-sm font-medium",
        className
      )}
      searchWords={[searchValue]}
      autoEscape={true}
      textToHighlight={textToHighlight}
    />
  );
};

export default HighlighterText;
