import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as UploadIcon } from "./../../../assets/icons/clouds/upload.svg";
import { ReactComponent as FailedIcon } from "./../../../assets/icons/clouds/failed.svg";
import { ReactComponent as SuccessIcon } from "./../../../assets/icons/clouds/success.svg";
import { ReactComponent as TagIcon } from "./../../../assets/icons/tag.svg";
import { ReactComponent as ArrowRightIcon } from "./../../../assets/icons/arrow-right-circle.svg";
import { ReactComponent as CopyIcon } from "./../../../assets/icons/copy.svg";
import {
  // getWebAppEventsAsync,
  selectWebApp,
  selectWebAppEvents,
  selectWebAppEventsLoading,
  selectWebAppLoading,
} from "../../../store/web-app/webAppSlice";
import { formatTimestamp } from "../../../utils/date";
import { Link } from "react-router-dom";
import { WebAppDeployUrl, WebAppPlanUrl } from "../../../utils/urls";
import {
  Button,
  Tag,
  Typography,
  Tooltip,
  copyToClipboard,
  Flex,
  EmptyState,
  Loading,
} from "djuno-design";
import CustomLink from "../../general/CustomLink";
import toast from "react-hot-toast";
import { ToastClasses } from "../../modals/alerts";

const WebAppOverviewTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const events = useAppSelector(selectWebAppEvents);
  const eventsLoading = useAppSelector(selectWebAppEventsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (webApp) {
      // dispatch(getWebAppEventsAsync(webApp.Id.toString()));
    }
  }, [dispatch, webApp]);

  return (
    <>
      <Flex direction="col" className="gap-3 mb-5">
        <Flex items="center" className="gap-2">
          <Typography.Text size="base" className="mr-2">
            {webApp?.Name}
          </Typography.Text>
          <Typography.Text size="sm">
            <Tag>Image</Tag>
          </Typography.Text>
          <Typography.Text size="sm">
            <Tag color="processing">Free</Tag>
          </Typography.Text>
          <Link
            className="cursor-pointer text-sky-500 hover:text-sky-600"
            to={WebAppPlanUrl(webApp?.Id.toString())}
          >
            <Typography.Text
              uiType="transparent"
              size="sm"
              className="font-light flex items-center gap-0.5"
            >
              Upgrade your instance
              <ArrowRightIcon className="w-4 aspect-square" />
            </Typography.Text>
          </Link>
        </Flex>
        <Flex items="center" className="gap-2">
          <Typography.Link
            className="!text-sm"
            href={webApp?.URL || undefined}
            target="_blank"
          >
            {webApp?.URL}
          </Typography.Link>
          <Tooltip content="Copy to clipboard" place="right">
            <CopyIcon
              className="w-4 cursor-pointer text-sky-500 hover:text-sky-600"
              onClick={() =>
                webApp &&
                copyToClipboard(webApp.URL || "").then(() =>
                  toast.success("Data copied to clipboard!", {
                    className: ToastClasses,
                  })
                )
              }
            />
          </Tooltip>
        </Flex>
      </Flex>
      <ul className="mt-2 border dark:border-dark-2 rounded-lg bg-white dark:bg-dark-3">
        {eventsLoading && (
          <Flex items="center" justify="center" className="min-h-[300px]">
            <Loading borderSize={2} />
          </Flex>
        )}
        {!eventsLoading && events.length === 0 && <EmptyState />}
        {!eventsLoading &&
          events.length > 0 &&
          events.map((event, index) => (
            <li
              key={index}
              className="p-4 border-b last:border-b-0 dark:border-dark-2"
            >
              <Flex items="center" justify="between">
                <Flex items="center" className="flex-1 gap-4">
                  <span>
                    {event.type === "deploy" && (
                      <UploadIcon className="w-5 aspect-square text-secondary-400 dark:text-secondary-300" />
                    )}
                    {event.type === "failed" && (
                      <FailedIcon className="w-5 aspect-square text-secondary-400 dark:text-secondary-300" />
                    )}
                    {event.type === "live" && (
                      <SuccessIcon className="w-5 aspect-square text-secondary-400 dark:text-secondary-300" />
                    )}
                  </span>
                  <Flex direction="col" className="gap-2">
                    <Flex items="center" className="gap-3">
                      <Typography.Text
                        size="sm"
                        className="flex items-center gap-1"
                      >
                        <CustomLink
                          to={WebAppDeployUrl(
                            webApp?.Id.toString(),
                            event.deployId
                          )}
                        >
                          Deploy
                        </CustomLink>
                        {event.type === "deploy" && <>started</>}
                        {event.type === "failed" && <>failed</>}
                        {event.type === "live" && <>live</>} for{" "}
                        <span
                          title={`sha256:${event.commitId}`}
                          className="font-mono"
                        >
                          {event.commitId.substring(0, 7)}
                        </span>
                      </Typography.Text>
                      <Typography.Text
                        size="xs"
                        className="flex items-center gap-1 font-mono"
                      >
                        <TagIcon className="w-4" />
                        {event.branchName}
                      </Typography.Text>
                    </Flex>
                    {event.message && (
                      <Flex items="center" className="gap-3">
                        <Typography.Text
                          size="sm"
                          className="flex items-center gap-1 font-light"
                        >
                          {event.message}
                          {". "}
                          {event.type === "failed" && (
                            <>
                              Check your{" "}
                              <Link
                                className="cursor-pointer text-sky-500 hover:text-sky-600"
                                to={WebAppDeployUrl(
                                  webApp?.Id.toString(),
                                  event.deployId
                                )}
                              >
                                deploy logs
                              </Link>{" "}
                              for more information.
                            </>
                          )}
                        </Typography.Text>
                      </Flex>
                    )}
                    <Typography.Text size="xs" uiType="secondary">
                      {
                        formatTimestamp(
                          event.createdAt,
                          "MMMM DD, YYYY [at] hh:mm A"
                        ).datetime
                      }
                    </Typography.Text>
                  </Flex>
                </Flex>
                <Flex items="center">
                  {event.type === "failed" && (
                    <Button disabled={true}>Rollback</Button>
                  )}
                </Flex>
              </Flex>
            </li>
          ))}
      </ul>
    </>
  );
};

export default WebAppOverviewTab;
