import { jwtEnvAxios } from "..";
import { TierTypes } from "../../types/s3-tiers";

const GET_TIERS_API_URL = `admin/tiers`;
const CREATE_TIER_API_URL = `admin/tiers`;
const UPDATE_TIER_CREDENTION_API_URL = `admin/tiers`;

export function getS3TiersApi() {
  return jwtEnvAxios("s3").get(GET_TIERS_API_URL);
}

export function createS3TierApi(type: TierTypes, data: any) {
  return jwtEnvAxios("s3").post(CREATE_TIER_API_URL, { type, [type]: data });
}

export function updateS3TierCredentionApi(data: any) {
  return jwtEnvAxios("s3").put(UPDATE_TIER_CREDENTION_API_URL, { ...data });
}
