import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import {
  DatabaseServicesState,
  DatabaseService,
  DBSUpdateApiData,
  DBSCapabilities,
  DBSAvailability,
  DBSCreateApiData,
  DBSCatalog,
} from "../../types/database";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  getDBSAvailabilityApi,
  getDBSCapabilitiesApi,
  getDatabaseServicesApi,
  UpdateDBSeApi,
  getDBSRolesApi,
  createDBSeApi,
  deleteDBSApi,
  getDBSCatalogApi,
} from "../../apis/databaseAPI";

// import availability from "./availability.json";
// import catalog from "./catalog.json";

export const DatabaseRefreshStatus = ["UPDATING", "CREATING"];

const initialState: DatabaseServicesState = {
  services: [],
  loading: false,
  actionLoading: false, //for create, delete, rename
  selectedService: null, //for rename
  showRenameModal: false,
  // public data
  availability: undefined,
  availabilityLoading: false,
  capabilities: undefined,
  capabilitiesLoading: false,
  roles: [],
  rolesLoading: false,
  catalog: undefined, //catalog as DBSCatalog, //
  catalogLoading: false,
};

export const getDatabaseServicesAsync = createAsyncThunk<
  { services: Array<DatabaseService> },
  { withoutLoading?: boolean },
  IThunkRejectValue
>(
  "db-services",
  async (
    { withoutLoading = false },
    { rejectWithValue, fulfillWithValue, dispatch, requestId }
  ) => {
    try {
      dispatch(
        getDatabaseServicesAsync.pending(requestId, {
          withoutLoading,
        })
      );

      const response = await getDatabaseServicesApi();
      const services = response.data.Result;
      return fulfillWithValue({ services });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateDBSAsync = createAsyncThunk<
  any,
  { id: string; engine: string; data: DBSUpdateApiData },
  IThunkRejectValue
>("db-services/update", async ({ id, engine, data }, { rejectWithValue }) => {
  try {
    const response = await UpdateDBSeApi(id, engine, data);
    return response;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const createDBSAsync = createAsyncThunk<
  true,
  { engine: string; data: DBSCreateApiData },
  IThunkRejectValue
>("db-services/create", async ({ engine, data }, { rejectWithValue }) => {
  try {
    await createDBSeApi(engine, data);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteDBSAsync = createAsyncThunk<
  true,
  { engine: string; clusterId: string },
  IThunkRejectValue
>("db-services/delete", async ({ engine, clusterId }, { rejectWithValue }) => {
  try {
    await deleteDBSApi(engine, clusterId);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getDBSCapabilitiesAsync = createAsyncThunk<
  { capabilities: DBSCapabilities },
  undefined,
  IThunkRejectValue
>(
  "db-services/capabilities",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDBSCapabilitiesApi();
      const capabilities = response.data.Result;
      return fulfillWithValue({ capabilities });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSAvailabilityAsync = createAsyncThunk<
  { availability: Array<DBSAvailability> },
  undefined,
  IThunkRejectValue
>(
  "db-services/avalability",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDBSAvailabilityApi();
      const availability = response.data.Result;
      return fulfillWithValue({ availability });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSRolesAsync = createAsyncThunk<
  { roles: string[] },
  { id: string; engine: string },
  IThunkRejectValue
>(
  "db-services/roles",
  async ({ id, engine }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getDBSRolesApi(id, engine);
      const roles = response.data.Result;
      return fulfillWithValue({ roles });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getDBSCatalogAsync = createAsyncThunk<
  { catalog: DBSCatalog },
  undefined,
  IThunkRejectValue
>("db-services/catalog", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getDBSCatalogApi();
    const catalog = response.data.Result;
    return fulfillWithValue({ catalog });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const servicesSlice = createSlice({
  name: "db-services",
  initialState,
  reducers: {
    handleShowDatabaseRenameModal: (
      state,
      action: PayloadAction<DatabaseService>
    ) => {
      state.showRenameModal = true;
      state.selectedService = action.payload;
    },
    handleHideDatabaseRenameModal: (state) => {
      state.showRenameModal = false;
      state.selectedService = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDatabaseServicesAsync.pending, (state, action) => {
        if (!action.meta.arg.withoutLoading) state.loading = true;
      })
      .addCase(getDatabaseServicesAsync.fulfilled, (state, action) => {
        const { services } = action.payload;
        state.services = services;
        state.loading = false;
      })
      .addCase(getDatabaseServicesAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateDBSAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateDBSAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateDBSAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createDBSAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createDBSAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createDBSAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteDBSAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteDBSAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteDBSAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDBSCapabilitiesAsync.pending, (state) => {
        state.capabilitiesLoading = true;
      })
      .addCase(getDBSCapabilitiesAsync.fulfilled, (state, action) => {
        state.capabilitiesLoading = false;
        state.capabilities = action.payload.capabilities;
      })
      .addCase(getDBSCapabilitiesAsync.rejected, (state, { payload }) => {
        state.capabilitiesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDBSAvailabilityAsync.pending, (state) => {
        state.availabilityLoading = true;
      })
      .addCase(getDBSAvailabilityAsync.fulfilled, (state, action) => {
        state.availabilityLoading = false;
        state.availability = action.payload.availability;
      })
      .addCase(getDBSAvailabilityAsync.rejected, (state, { payload }) => {
        state.availabilityLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDBSCatalogAsync.pending, (state) => {
        state.catalogLoading = true;
      })
      .addCase(getDBSCatalogAsync.fulfilled, (state, action) => {
        state.catalogLoading = false;
        state.catalog = action.payload.catalog;
      })
      .addCase(getDBSCatalogAsync.rejected, (state, { payload }) => {
        state.catalogLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getDBSRolesAsync.pending, (state) => {
        state.rolesLoading = true;
      })
      .addCase(getDBSRolesAsync.fulfilled, (state, action) => {
        state.rolesLoading = false;
        state.roles = action.payload.roles;
      })
      .addCase(getDBSRolesAsync.rejected, (state, { payload }) => {
        state.rolesLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectDatabaseServices = (state: RootState) =>
  state.databaseServices.services;
export const selectDatabaseServicesLoading = (state: RootState) =>
  state.databaseServices.loading;

export const selectDatabaseServicesActionLoading = (state: RootState) =>
  state.databaseServices.actionLoading;

export const selectDatabaseServiceRenameIsOpen = (state: RootState) =>
  state.databaseServices.showRenameModal;
export const selectSelectedDatabaseService = (state: RootState) =>
  state.databaseServices.selectedService;

export const selectDBSCapabilities = (state: RootState) =>
  state.databaseServices.capabilities;
export const selectDBSCapabilitiesLoading = (state: RootState) =>
  state.databaseServices.capabilitiesLoading;

export const selectDBSAvailability = (state: RootState) =>
  state.databaseServices.availability;
export const selectDBSAvailabilityLoading = (state: RootState) =>
  state.databaseServices.availabilityLoading;

export const selectDBSRoles = (state: RootState) =>
  state.databaseServices.roles;
export const selectDBSLoadsLoading = (state: RootState) =>
  state.databaseServices.rolesLoading;

export const selectDBSCatalog = (state: RootState) =>
  state.databaseServices.catalog;
export const selectDBSCatalogLoading = (state: RootState) =>
  state.databaseServices.catalogLoading;

export const { handleShowDatabaseRenameModal, handleHideDatabaseRenameModal } =
  servicesSlice.actions;
export default servicesSlice.reducer;
