import { jwtEnvAxios } from "..";

const GET_LIFECYCLES_API_URL = (bucketName: string) =>
  `/buckets/${bucketName}/lifecycle`;
const DELETE_LIFECYCLES_API_URL = (bucketName: string, id: string) =>
  `/buckets/${bucketName}/lifecycle/${id}`;
const CREATE_LIFECYCLES_API_URL = (bucketName: string) =>
  `/buckets/${bucketName}/lifecycle`;
const UPDATE_LIFECYCLES_API_URL = (bucketName: string, id: string) =>
  `/buckets/${bucketName}/lifecycle/${id}`;
const CREATE_MULTILIFECYCLES_API_URL = `buckets/multi-lifecycle`;

export function getBucketLifecyclesApi(name: string) {
  return jwtEnvAxios("s3").get(GET_LIFECYCLES_API_URL(name));
}

export function deleteBucketLifecyclesApi(name: string, id: string) {
  return jwtEnvAxios("s3").delete(DELETE_LIFECYCLES_API_URL(name, id));
}

export function createBucketLifecycleApi(name: string, data: any) {
  return jwtEnvAxios("s3").post(CREATE_LIFECYCLES_API_URL(name), data);
}

export function updateBucketLifecycleApi(name: string, id: string, data: any) {
  return jwtEnvAxios("s3").put(UPDATE_LIFECYCLES_API_URL(name, id), data);
}

export function createMultiBucketLifecycleApi(data: any) {
  return jwtEnvAxios("s3").post(CREATE_MULTILIFECYCLES_API_URL, data);
}
