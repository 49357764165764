import jwtAxios, { FetchCatchResponse, jwtEnvAxios } from ".";
import {
  IpfsCreateFolderDataType,
  IpfsCreateFolderStrategy,
  IpfsFile,
} from "../types/ipfs-file";
import { textJsonDownloader } from "../utils/file";

const GET_FILES_URL = (url: string) => `/ipfs/drive?path=${url}`;
const GET_FILE_URL = (fileId: number) => `/file/${fileId}`;
const UPLOAD_FILE_URL = `/ipfs/drive/upload`;
const DELETE_FILE_URL = (fileId: number) => `/file/${fileId}`;
const CREATE_FOLDER_URL = `/ipfs/drive/mkdir`;
const DOWNLOAD_FILE_URL = (path: string, is_folder: boolean) =>
  `/ipfs/drive/download?isFolder=${is_folder}&path=${path}`;
const EXPORT_IPFS_FILE_URL = (path: string, is_folder: boolean) =>
  `/ipfs/drive/export?isFolder=${is_folder}&path=${path}`;
const DELETE_IPFS_FILE_URL = (path: string) => `/ipfs/drive?path=${path}`;
const DELETE_IPFS_FOLDER_URL = (path: string) =>
  `ipfs/drive?path=${path}&isFolder=true`;

export const GET_IPFS_FILE_URL = (
  public_cid: string,
  ipfsBaseAddress: string | undefined
) => {
  let baseUrl = ipfsBaseAddress;
  return `${baseUrl}/ipfs/${public_cid}`;
};

export function getFilesApi(url: string) {
  return jwtEnvAxios().get(GET_FILES_URL(url));
}

export function getFileApi(fileId: number) {
  return jwtAxios.get(GET_FILE_URL(fileId));
}

export function uploadFileApi(
  file: File,
  url: string,
  strategy?: IpfsCreateFolderStrategy
) {
  const formData = new FormData();
  formData.append("files", file);
  formData.append("path", url);
  formData.append("strategy", strategy || "None");

  return jwtEnvAxios().post(UPLOAD_FILE_URL, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function deleteFileApi(fileId: number) {
  return jwtEnvAxios().delete(DELETE_FILE_URL(fileId));
}

export function createFolderApi(formData: IpfsCreateFolderDataType) {
  return jwtEnvAxios().post(CREATE_FOLDER_URL, formData);
}

export function downloadFileApi(file: IpfsFile, path: string) {
  // const path = fileUrlConvertor(file.path);
  const filePath = `${path === "/" ? "" : path}/${file.name}`;
  return jwtEnvAxios().get(DOWNLOAD_FILE_URL(filePath, file.is_folder), {
    responseType: "arraybuffer",
  });
}

export async function getExportApi(file: IpfsFile, path: string) {
  // const path = fileUrlConvertor(file.path);
  try {
    const { data } = await jwtEnvAxios().get(
      EXPORT_IPFS_FILE_URL(path, file.is_folder),
      {
        headers: {
          responseType: "blob",
        },
      }
    );
    textJsonDownloader(
      data.Result,
      `${file.name}_${new Date()
        .toLocaleDateString("en-CA")
        .replaceAll("-", "")}`
    );
    return {
      data: data.Result,
      status: true,
      message: data.Message,
    };
  } catch (e) {
    return FetchCatchResponse(e);
  }
}

export function getIpfsMetricsApi() {
  return jwtEnvAxios().get(`/ipfs/drive/metrics`);
}

export function getIpfsUsageApi() {
  return jwtEnvAxios().get(`/ipfs/drive/usage`);
}

export async function deleteIpfsFileApi(path: string) {
  return jwtEnvAxios().delete(DELETE_IPFS_FILE_URL(path));
}
export async function deleteIpfsFolderApi(path: string) {
  return jwtEnvAxios().delete(DELETE_IPFS_FOLDER_URL(path));
}
