import { jwtEnvAxios } from ".";
import {
  S3BucketEncryptionDataType,
  S3BucketSetAccessPolictDataType,
  S3BucketSetQuotaDataType,
  S3BucketSetRetentionDataType,
  S3BucketVersioningDataType,
} from "../types/s3-bucket";
import { encodeUnicode } from "../utils/bucket";

const GET_METRICS_API_URL = `/admin/metrics-info`;
const GET_WIDGET_API_URL = (n: number, start?: number, end?: number) =>
  `/admin/metrics-widget?n=${n}${
    start
      ? `&step=${
          start !== undefined && end !== undefined
            ? `${(end - start) / 60}`
            : "0"
        }&start=${start}`
      : ""
  }${end ? `&end=${end}` : ""}`;
const GET_NEW_METRICS_API_URL = `/s3/metrics`;

//buckets urls
const GET_BUCKETS_API_URL = `/buckets`;
const CREATE_BUCKETS_API_URL = `/buckets`;
const UPDATE_BUCKET_API_URL = (name: string) => `/buckets/${name}`;
const DELETE_BUCKET_API_URL = (name: string) => `/buckets`; ///${name}
const GET_BUCKET_DETAILS_API_URL = (name: string) => `/buckets/${name}`;
const GET_BUCKET_OBJECTS_API_URL = (
  name: string,
  prefix: string,
  with_versions?: boolean
) =>
  `/buckets/${name}/objects?prefix=${encodeUnicode(prefix)}&with_versions=${
    with_versions || false
  }`;
const UPLOAD_FILE_URL = (name: string) => `/buckets/${name}/upload`;
const DELETE_FILE_URL = (name: string) => `/s3/buckets/${name}`;

const GET_BUCKETS_KMS_KEYS_API_URL = `/kms/keys`;
const CREATE_BUCKETS_KMS_KEY_API_URL = `/kms/keys`;
const GET_BUCKET_ENCRYPTION_API_URL = (name: string) =>
  `/buckets/${name}/encryption`;
const SET_BUCKET_ENCRYPTION_API_URL = (name: string) =>
  `/buckets/${name}/encryption`;

const GET_BUCKET_QUOTA_API_URL = (name: string) => `/buckets/${name}/quota`;
const SET_BUCKET_QUOTA_API_URL = (name: string) => `/buckets/${name}/quota`;

const SET_BUCKET_ACCESS_POLICY_API_URL = (name: string) =>
  `/buckets/${name}/set-policy`;

const GET_BUCKET_OBJECT_LOCKING_API_URL = (name: string) =>
  `/buckets/${name}/object-locking`;

const UPDATE_BUCKET_TAGS_API_URL = (name: string) => `/buckets/${name}/tags`;

const GET_BUCKET_RETENTION_API_URL = (name: string) =>
  `/buckets/${name}/retention`;
const SET_BUCKET_RETENTION_API_URL = (name: string) =>
  `/buckets/${name}/retention`;

const GET_BUCKET_VERSIONING_API_URL = (name: string) =>
  `/buckets/${name}/versioning`;
const SET_BUCKET_VERSIONING_API_URL = (name: string) =>
  `/buckets/${name}/versioning`;

const SET_BUCKET_REWIND_API_URL = (name: string) => `/buckets/${name}/rewind`;
const GET_LIST_EXTERNAL_BUCKETS_API_URL = `/list-external-buckets`;

//bucket objects urls
const DOWNLOAD_OBJECT_API_URL = (
  bucketName: string,
  prefix: string,
  versionId: string
) =>
  `/buckets/${bucketName}/object-download?prefix=${encodeUnicode(
    prefix
  )}&version_id=${versionId}`;

const DOWNLOAD_MULTIPLE_OBJECT_API_URL = (bucketName: string) =>
  `/buckets/${bucketName}/download-multiple`;

const GET_OBJECT_METADATA_API_URL = (bucketName: string, prefix: string) =>
  `/buckets/${bucketName}/object-metadata?prefix=${encodeUnicode(prefix)}`;
// const GET_OBJECT_INFO_API_URL = (bucketName: string, prefix: string) =>
//   `/buckets/${bucketName}/object-info?prefix=${encodeUnicode(
//     prefix
//   )}&with_versions=true`;
const SET_OBJECT_LEGAL_HOLD_API_URL = (
  bucketName: string,
  prefix: string,
  versionId: string
) =>
  `/buckets/${bucketName}/object-legalhold?prefix=${encodeUnicode(
    prefix
  )}&version_id=${versionId}`;

const SET_OBJECT_RETENTION_POLICY_API_URL = (
  bucketName: string,
  prefix: string,
  versionId: string
) =>
  `/buckets/${bucketName}/object-retention-policy?prefix=${encodeUnicode(
    prefix
  )}&version_id=${versionId}`;

const SET_OBJECT_TAGS_API_URL = (
  bucketName: string,
  prefix: string,
  versionId: string
) =>
  `/buckets/${bucketName}/object-tags?prefix=${encodeUnicode(
    prefix
  )}&version_id=${versionId}`;

const GET_OBJECT_INSPECT_API_URL = (
  bucketName: string,
  prefix: string,
  encrypt: boolean
) =>
  `/admin/object-inspect?volume=${encodeUnicode(
    bucketName
  )}&file=${encodeUnicode(prefix + "/xl.meta")}&encrypt=${encrypt}`;

const GET_OBJECT_SHARE_API_URL = (
  bucketName: string,
  prefix: string,
  versionId: string,
  expires: string
) =>
  `/buckets/${bucketName}/object-share?prefix=${encodeUnicode(
    prefix
  )}&version_id=${versionId}&expires=${expires}`;

const RESTORE_OBJECT_API_URL = (
  bucketName: string,
  prefix: string,
  versionId: string
) =>
  `/buckets/${bucketName}/object-restore?prefix=${encodeUnicode(
    prefix
  )}&version_id=${versionId}`;

const DELETE_OBJECT_API_URL = (
  bucketName: string,
  prefix: string,
  versionId: string | null,
  recursive: boolean | null,
  all_versions: boolean | null,
  non_current_versions: boolean | null,
  bypass: boolean | null
) =>
  `/buckets/${bucketName}/objects?prefix=${encodeUnicode(prefix)}${
    versionId ? `&version_id=${versionId}` : ``
  }${all_versions ? `&all_versions=${all_versions}` : ``}${
    recursive ? `&recursive=${recursive}` : ``
  }${
    non_current_versions ? `&non_current_versions=${non_current_versions}` : ``
  }${bypass ? `&bypass=${bypass}` : ``}`;

const DELETE_MULTIPLE_OBJECT_API_URL = (
  bucketName: string,
  all_versions: boolean,
  bypass: boolean
) =>
  `/buckets/${bucketName}/delete-multiple?all_versions=${all_versions}&bypass=${bypass}`;

//events
const GET_EVENTS_API_URL = `/event`;
const CREATE_EVENT_API_URL = (event: string, identifier: string) =>
  `/event?event=${event}&identifier=${identifier}`;
const UPDATE_EVENT_API_URL = (id: number) => `/event/${id}`;
const DELETE_EVENT_API_URL = (event: string, identifier: string) =>
  `/event?event=${event}&identifier=${identifier}`;

const GET_S3_ARN_API_URL = `/admin/arns`;

//logs urls
const GET_LOGS_API_URL = `/logs`;

const GET_NETWORKS_API_URL = `/networks`;
const CREATE_NETWORK_API_URL = `/networks`;
const UPDATE_NETWORK_API_URL = (id: number) => `/networks/${id}`;
const DELETE_NETWORK_API_URL = (id: number) => `/networks/${id}`;
const SET_DEFAULT_NETWORK_API_URL = (id: number) =>
  `/networks/${id}/set-default`;

//bucket-events urls
const GET_BUCKET_EVENTS_API_URL = (name: string) => `/buckets/${name}/events`;
const ADD_BUCKET_EVENTS_API_URL = (name: string) => `/buckets/${name}/events`;
const DELETE_BUCKET_EVENTS_API_URL = (name: string, eventName: string) =>
  `/buckets/${name}/events/${eventName}`;

//bucket-policies urls
const GET_BUCKET_POLICIES_API_URL = (name: string) => `/buckets/${name}/policy`;

//bucket-users urls
const GET_BUCKET_USERS_API_URL = (name: string) => `/buckets/${name}/users`;

//overview
export function getS3MetricsApi() {
  return jwtEnvAxios("s3").get(GET_METRICS_API_URL);
}
export function getNewS3MetricsApi() {
  return jwtEnvAxios(undefined).get(GET_NEW_METRICS_API_URL);
}

export function getS3WidgetApi(
  n: number,
  startTime?: number,
  endTime?: number
) {
  return jwtEnvAxios("s3").get(GET_WIDGET_API_URL(n, startTime, endTime));
}

//buckets
export function getBucketsApi() {
  return jwtEnvAxios("s3").get(GET_BUCKETS_API_URL);
}

export function createBucketsApi(data: any) {
  return jwtEnvAxios("s3").post(CREATE_BUCKETS_API_URL, data);
}

export function updateBucketApi(name: string, data: any) {
  return jwtEnvAxios("s3").put(UPDATE_BUCKET_API_URL(name), data);
}

export function deleteBucketApi(name: string) {
  return jwtEnvAxios("s3").delete(DELETE_BUCKET_API_URL(name), {
    data: { name },
  });
}

export function getExternalBucketsApi(data: any) {
  return jwtEnvAxios("s3").post(GET_LIST_EXTERNAL_BUCKETS_API_URL, data);
}

export function getBucketsMaxShareExpAsyncApi() {
  return jwtEnvAxios("s3").get(`/buckets/max-share-exp`);
}

//bucket
export function getBucketDetailsApi(name: string) {
  return jwtEnvAxios("s3").get(GET_BUCKET_DETAILS_API_URL(name));
}

export function getBucketObjectsApi(
  name: string,
  prefix: string,
  with_versions?: boolean
) {
  return jwtEnvAxios("s3").get(
    GET_BUCKET_OBJECTS_API_URL(name, prefix, with_versions)
  );
}

export function uploadBucketFileApi(
  bucketName: string,
  file: File,
  url: string
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("prefix", url + "/" + file.name);

  return jwtEnvAxios("s3").post(UPLOAD_FILE_URL(bucketName), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function deleteBucketFileApi(name: string) {
  return jwtEnvAxios("s3").delete(DELETE_FILE_URL(name));
}

export function getBucketsKmsKeysApi() {
  return jwtEnvAxios("s3").get(GET_BUCKETS_KMS_KEYS_API_URL);
}

export function createBucketsKmsKeyApi(data: any) {
  return jwtEnvAxios("s3").post(CREATE_BUCKETS_KMS_KEY_API_URL, data);
}

export function getBucketEncryptionApi(name: string) {
  return jwtEnvAxios("s3").get(GET_BUCKET_ENCRYPTION_API_URL(name));
}

export function setBucketEncryptionApi(
  name: string,
  data: S3BucketEncryptionDataType
) {
  return jwtEnvAxios("s3").put(SET_BUCKET_ENCRYPTION_API_URL(name), data);
}

export function getBucketQuotaApi(name: string) {
  return jwtEnvAxios("s3").get(GET_BUCKET_QUOTA_API_URL(name));
}

export function setBucketQuotaApi(
  name: string,
  formData: S3BucketSetQuotaDataType
) {
  return jwtEnvAxios("s3").put(SET_BUCKET_QUOTA_API_URL(name), formData);
}

export function setBucketAccessPolicyApi(
  name: string,
  formData: S3BucketSetAccessPolictDataType
) {
  return jwtEnvAxios("s3").put(
    SET_BUCKET_ACCESS_POLICY_API_URL(name),
    formData
  );
}

export function getBucketObjectLockingApi(name: string) {
  return jwtEnvAxios("s3").get(GET_BUCKET_OBJECT_LOCKING_API_URL(name));
}

export function updateBucketTagsApi(
  name: string,
  tags: { [key: string]: string }
) {
  return jwtEnvAxios("s3").put(UPDATE_BUCKET_TAGS_API_URL(name), { tags });
}

export function getBucketRetentionApi(name: string) {
  return jwtEnvAxios("s3").get(GET_BUCKET_RETENTION_API_URL(name));
}

export function setBucketRetentionApi(
  name: string,
  formData: S3BucketSetRetentionDataType
) {
  return jwtEnvAxios("s3").put(SET_BUCKET_RETENTION_API_URL(name), formData);
}

export function getBucketVersioningApi(name: string) {
  return jwtEnvAxios("s3").get(GET_BUCKET_VERSIONING_API_URL(name));
}

export function setBucketVersioningApi(
  name: string,
  formData: S3BucketVersioningDataType
) {
  return jwtEnvAxios("s3").put(SET_BUCKET_VERSIONING_API_URL(name), formData);
}

export function setBucketRewindApi(name: string, date: string) {
  return jwtEnvAxios("s3").post(SET_BUCKET_REWIND_API_URL(name), { date });
}

//bucket-objects
export function getObjectDownloadApi(
  bucketName: string,
  objectPrefix: string,
  versionId: string
) {
  return jwtEnvAxios("s3").get(
    DOWNLOAD_OBJECT_API_URL(bucketName, objectPrefix, versionId),
    {
      responseType: "arraybuffer",
    }
  );
}

export function getObjectDownloadMultipleApi(
  bucketName: string,
  objectNames: string[]
) {
  return jwtEnvAxios("s3").post(
    DOWNLOAD_MULTIPLE_OBJECT_API_URL(bucketName),
    objectNames,
    {
      responseType: "arraybuffer",
    }
  );
}

export function getObjectMetadataApi(bucketName: string, objectPrefix: string) {
  return jwtEnvAxios("s3").get(
    GET_OBJECT_METADATA_API_URL(bucketName, objectPrefix)
  );
}

// export function getObjectInfoApi(bucketName: string, objectPrefix: string) {
//   return jwtEnvAxios("s3").get(
//     GET_BUCKET_OBJECTS_API_URL(bucketName, objectPrefix, true)
//   );
// }

export function setObjectLegalHoldApi(
  bucketName: string,
  objectPrefix: string,
  versionId: string,
  status: string
) {
  return jwtEnvAxios("s3").put(
    SET_OBJECT_LEGAL_HOLD_API_URL(bucketName, objectPrefix, versionId),
    { status }
  );
}

export function setObjectRetentionPolicyApi(
  bucketName: string,
  objectPrefix: string,
  versionId: string,
  data: any
) {
  return jwtEnvAxios("s3").put(
    SET_OBJECT_RETENTION_POLICY_API_URL(bucketName, objectPrefix, versionId),
    data
  );
}

export function setObjectTagsApi(
  bucketName: string,
  objectPrefix: string,
  versionId: string,
  data: any
) {
  return jwtEnvAxios("s3").put(
    SET_OBJECT_TAGS_API_URL(bucketName, objectPrefix, versionId),
    data
  );
}

export function getObjectInspectApi(
  bucketName: string,
  objectPrefix: string,
  encript: boolean
) {
  return jwtEnvAxios("s3").get(
    GET_OBJECT_INSPECT_API_URL(bucketName, objectPrefix, encript),
    {
      responseType: "arraybuffer",
    }
  );
}

export function getObjectShareApi(
  bucketName: string,
  objectPrefix: string,
  versionId: string,
  expires: string
) {
  return jwtEnvAxios("s3").get(
    GET_OBJECT_SHARE_API_URL(bucketName, objectPrefix, versionId, expires)
  );
}

export function restoreObjectApi(
  bucketName: string,
  objectPrefix: string,
  versionId: string
) {
  return jwtEnvAxios("s3").put(
    RESTORE_OBJECT_API_URL(bucketName, objectPrefix, versionId)
  );
}

export function deleteObjectApi(
  bucketName: string,
  objectPrefix: string,
  versionId: string | null,
  recursive: boolean | null,
  all_versions: boolean | null,
  non_current_versions: boolean | null,
  bypass: boolean | null
) {
  return jwtEnvAxios("s3").delete(
    DELETE_OBJECT_API_URL(
      bucketName,
      objectPrefix,
      versionId,
      recursive,
      all_versions,
      non_current_versions,
      bypass
    )
  );
}

export function deleteMultipleObjectApi(
  bucketName: string,
  data: any,
  all_versions: boolean,
  bypass: boolean
) {
  return jwtEnvAxios("s3").post(
    DELETE_MULTIPLE_OBJECT_API_URL(bucketName, all_versions, bypass),
    data
  );
}

//events
export function getS3EventsApi() {
  return jwtEnvAxios("s3").get(GET_EVENTS_API_URL);
}

export function createS3EventApi(event: string, identifier: string, data: any) {
  return jwtEnvAxios("s3").put(CREATE_EVENT_API_URL(event, identifier), data);
}

export function updateS3EventApi(id: number, data: any) {
  return jwtEnvAxios("s3").put(UPDATE_EVENT_API_URL(id), data);
}

export function deleteS3EventApi(event: string, identifier: string) {
  return jwtEnvAxios("s3").delete(DELETE_EVENT_API_URL(event, identifier));
}
export function getS3ArnsApi() {
  return jwtEnvAxios("s3").get(GET_S3_ARN_API_URL);
}

//logs
export function getS3LogsApi() {
  return jwtEnvAxios("s3").get(GET_LOGS_API_URL);
}

//networks
export function getS3NetworksApi() {
  return jwtEnvAxios("s3").get(GET_NETWORKS_API_URL);
}

export function createS3NetworkApi(data: any) {
  return jwtEnvAxios("s3").post(CREATE_NETWORK_API_URL, data);
}

export function updateS3NetworkApi(id: number, data: any) {
  return jwtEnvAxios("s3").put(UPDATE_NETWORK_API_URL(id), data);
}

export function deleteS3NetworkApi(id: number) {
  return jwtEnvAxios("s3").delete(DELETE_NETWORK_API_URL(id));
}

export function setAsDefaultS3NetworkApi(id: number) {
  return jwtEnvAxios("s3").get(SET_DEFAULT_NETWORK_API_URL(id));
}

//bucket-events
export function getBucketEventsApi(name: string) {
  return jwtEnvAxios("s3").get(GET_BUCKET_EVENTS_API_URL(name));
}
export function addBucketEventsApi(bucketName: string, data: any) {
  return jwtEnvAxios("s3").post(ADD_BUCKET_EVENTS_API_URL(bucketName), data);
}
export function deleteBucketEventsApi(
  bucketName: string,
  arn: string,
  data: any
) {
  return jwtEnvAxios("s3").delete(
    DELETE_BUCKET_EVENTS_API_URL(bucketName, arn),
    { data }
  );
}

//bucket-policies
export function getBucketPoliciesApi(name: string) {
  return jwtEnvAxios("s3").get(GET_BUCKET_POLICIES_API_URL(name));
}

//bucket-users
export function getBucketUsersApi(name: string) {
  return jwtEnvAxios("s3").get(GET_BUCKET_USERS_API_URL(name));
}
