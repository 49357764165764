import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import {
  BacktestResult,
  BlockEvent,
  BlockEventChain,
  BlockEventCreateState,
  BlockEventDestination,
  BlockEventNetwork,
} from "../../types/block-events";
import { isAxiosError } from "axios";
import {
  createBlockEventApi,
  getBacktestsExpressionApi,
  updateBlockEventApi,
  validateExpressionApi,
} from "../../apis/blockEventAPI";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: BlockEventCreateState = {
  //step-1
  notificationName: "",
  selectedChain: null,
  selectedNetwork: null,
  selectedTemplate: null,

  //step-2
  expressionValue: "tx_status == 0",
  enableForTestExpression: true,
  expressionHasValidate: false,
  expressionValidateLoading: false,
  activeExpressionTabIndex: 0,
  targetBlockValue: 6282640,
  backtestValue: 1,
  backtestResults: [],
  backtestResultsLoading: false,

  //step-3
  selectedDestinations: [],

  //
  createLoading: false,

  //edit-expression from single page
  showEditExpressionModal: false,
  editExpressionLoading: false,

  //edit-destinations from single page
  showEditDestinationsModal: false,
  editDestinationsLoading: false,
};

export const validateExpressionAsync = createAsyncThunk<
  any,
  undefined,
  IThunkRejectValue
>(
  "block-event/validate-expression",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { blockEventCreate } = getState() as RootState;
      const { expressionValue, selectedNetwork } = blockEventCreate;
      const response = await validateExpressionApi({
        expression: btoa(expressionValue),
        network: selectedNetwork?.NetworkName,
      });
      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createBlockEventAsync = createAsyncThunk<
  any,
  undefined,
  IThunkRejectValue
>("block-events/create", async (_, { rejectWithValue, getState }) => {
  try {
    const { blockEventCreate } = getState() as RootState;
    const {
      notificationName,
      selectedNetwork,
      expressionValue,
      selectedDestinations,
    } = blockEventCreate;

    const response = await createBlockEventApi({
      name: notificationName,
      expression: btoa(expressionValue),
      network: selectedNetwork?.NetworkName,
      destinationIds: selectedDestinations.map((d) => d.DestId),
    });

    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const editExpressionAsync = createAsyncThunk<
  any,
  { blockEvent: BlockEvent },
  IThunkRejectValue
>(
  "block-event/expression/edit",
  async ({ blockEvent }, { rejectWithValue, getState }) => {
    try {
      const { blockEventCreate } = getState() as RootState;
      const { expressionValue } = blockEventCreate;

      const response = await updateBlockEventApi(blockEvent.BlockEventId, {
        name: blockEvent.Name,
        expression: btoa(expressionValue),
        network: blockEvent.Network,
        destinationIds: blockEvent.Destinations.map((d) => d.DestId),
      });

      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const editDestinationsAsync = createAsyncThunk<
  any,
  { blockEvent: BlockEvent },
  IThunkRejectValue
>(
  "block-event/destinations/edit",
  async ({ blockEvent }, { rejectWithValue, getState }) => {
    try {
      const { blockEventCreate } = getState() as RootState;
      const { selectedDestinations } = blockEventCreate;

      const response = await updateBlockEventApi(blockEvent.BlockEventId, {
        name: blockEvent.Name,
        expression: btoa(blockEvent.Expression),
        network: blockEvent.Network,
        destinationIds: selectedDestinations.map((d) => d.DestId),
      });

      return response.data;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getBacktestsExpressionAsync = createAsyncThunk<
  { results: BacktestResult[] },
  undefined,
  IThunkRejectValue
>(
  "block-event/backtests-expression",
  async (_, { rejectWithValue, getState, fulfillWithValue }) => {
    try {
      const { blockEventCreate } = getState() as RootState;
      const {
        expressionValue,
        selectedNetwork,
        targetBlockValue,
        backtestValue,
      } = blockEventCreate;
      const response = await getBacktestsExpressionApi({
        expression: btoa(expressionValue),
        network: selectedNetwork?.NetworkName,
        block_number: targetBlockValue,
        backtest_count: backtestValue,
      });

      const results = response.data.Result.expression_result;

      return fulfillWithValue({ results });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const blockEventCreateSlice = createSlice({
  name: "chainNotifCreate",
  initialState,
  reducers: {
    handleClearBlockEventCreationSlice: (state) => {
      state.notificationName = initialState.notificationName;
      state.selectedChain = initialState.selectedChain;
      state.selectedNetwork = initialState.selectedNetwork;
      state.selectedTemplate = initialState.selectedTemplate;
      state.expressionValue = initialState.expressionValue;
      state.expressionHasValidate = initialState.expressionHasValidate;
      state.expressionValidateLoading = initialState.expressionValidateLoading;
      state.activeExpressionTabIndex = initialState.activeExpressionTabIndex;
      state.targetBlockValue = initialState.targetBlockValue;
      state.backtestValue = initialState.backtestValue;
      state.selectedDestinations = initialState.selectedDestinations;
      state.createLoading = initialState.createLoading;
    },
    //step-1
    handleChangeNotificationName: (state, action) => {
      state.notificationName = action.payload;
    },
    handleChangeSelectedChain: (
      state,
      action: PayloadAction<BlockEventChain | null>
    ) => {
      state.selectedChain = action.payload;
    },
    handleChangeSelectedNetwork: (
      state,
      action: PayloadAction<BlockEventNetwork | null>
    ) => {
      state.selectedNetwork = action.payload;
    },
    handlesetSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload;
    },

    //step-2
    handleChangeExpressionValue: (state, action) => {
      state.expressionValue = action.payload;
      state.expressionHasValidate = false;
      state.expressionValidateLoading = false;
    },
    handleChangeActiveExpressionTabIndex: (state, action) => {
      state.activeExpressionTabIndex = action.payload;
    },
    handleChangeTargetBlockValue: (state, action) => {
      state.targetBlockValue = action.payload;
    },
    handleChangeBacktestValue: (state, action) => {
      state.backtestValue = action.payload;
    },

    //step-3
    handleAddToSelectedDestination: (
      state,
      action: PayloadAction<BlockEventDestination>
    ) => {
      state.selectedDestinations = [
        ...state.selectedDestinations,
        action.payload,
      ];
    },
    handleRemoveToSelectedDestination: (
      state,
      action: PayloadAction<BlockEventDestination>
    ) => {
      state.selectedDestinations = state.selectedDestinations.filter(
        (destination) => destination.Id !== action.payload.Id
      );
    },

    //edit expression
    handleSetupToEditExpression: (
      state,
      action: PayloadAction<{ expressionValue: string }>
    ) => {
      state.expressionHasValidate = false;
      state.expressionValue = action.payload.expressionValue;
      state.showEditExpressionModal = true;
      state.enableForTestExpression = false;
    },
    handleCloseExpressionEditor: (state) => {
      state.expressionHasValidate = false;
      state.expressionValue = initialState.expressionValue;
      state.showEditExpressionModal = false;
      state.enableForTestExpression = true;
    },

    //edit destinations
    handleSetupToEditDestinations: (
      state,
      action: PayloadAction<{ destinations: BlockEventDestination[] }>
    ) => {
      state.selectedDestinations = action.payload.destinations;
      state.showEditDestinationsModal = true;
    },
    handleCloseEDestinationsEditor: (state) => {
      state.selectedDestinations = initialState.selectedDestinations;
      state.showEditDestinationsModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateExpressionAsync.pending, (state) => {
        state.expressionValidateLoading = true;
        state.expressionHasValidate = false;
      })
      .addCase(validateExpressionAsync.fulfilled, (state) => {
        state.expressionValidateLoading = false;
        state.expressionHasValidate = true;
      })
      .addCase(validateExpressionAsync.rejected, (state, { payload }) => {
        state.expressionValidateLoading = false;
        state.expressionHasValidate = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createBlockEventAsync.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createBlockEventAsync.fulfilled, (state) => {
        state.createLoading = false;
      })
      .addCase(createBlockEventAsync.rejected, (state, { payload }) => {
        state.createLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(editExpressionAsync.pending, (state) => {
        state.editExpressionLoading = true;
      })
      .addCase(editExpressionAsync.fulfilled, (state) => {
        state.editExpressionLoading = false;
      })
      .addCase(editExpressionAsync.rejected, (state, { payload }) => {
        state.editExpressionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(editDestinationsAsync.pending, (state) => {
        state.editDestinationsLoading = true;
      })
      .addCase(editDestinationsAsync.fulfilled, (state) => {
        state.editDestinationsLoading = false;
      })
      .addCase(editDestinationsAsync.rejected, (state, { payload }) => {
        state.editDestinationsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getBacktestsExpressionAsync.pending, (state) => {
        state.backtestResultsLoading = true;
      })
      .addCase(getBacktestsExpressionAsync.fulfilled, (state, action) => {
        state.backtestResultsLoading = false;
        state.backtestResults = action.payload.results.reverse();
      })
      .addCase(getBacktestsExpressionAsync.rejected, (state, { payload }) => {
        state.backtestResultsLoading = false;
        state.backtestResults = [];
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

//step-1
export const selectNotificationName = (state: RootState) =>
  state.blockEventCreate.notificationName;

export const selectSelectedChain = (state: RootState) =>
  state.blockEventCreate.selectedChain;

export const selectSelectedNetwork = (state: RootState) =>
  state.blockEventCreate.selectedNetwork;

export const selectSelectedTemplate = (state: RootState) =>
  state.blockEventCreate.selectedTemplate;

//step-2
export const selectExpressionValue = (state: RootState) =>
  state.blockEventCreate.expressionValue;
export const selectEnableForTestExpression = (state: RootState) =>
  state.blockEventCreate.enableForTestExpression;
export const selectExpressionHasValidate = (state: RootState) =>
  state.blockEventCreate.expressionHasValidate;
export const selectExpressionValidateLoading = (state: RootState) =>
  state.blockEventCreate.expressionValidateLoading;

export const selectActiveExpressionTabIndex = (state: RootState) =>
  state.blockEventCreate.activeExpressionTabIndex;

export const selectTargetBlockValue = (state: RootState) =>
  state.blockEventCreate.targetBlockValue;

export const selectBacktestValue = (state: RootState) =>
  state.blockEventCreate.backtestValue;

export const selectBacktestsResults = (state: RootState) =>
  state.blockEventCreate.backtestResults;
export const selectBacktestsResultsLoading = (state: RootState) =>
  state.blockEventCreate.backtestResultsLoading;

//step-3
export const selectSelectedDestinations = (state: RootState) =>
  state.blockEventCreate.selectedDestinations;

export const selectCreateBlockEventLoading = (state: RootState) =>
  state.blockEventCreate.createLoading;

//single-page
export const selectShowBlockEventExpressionModal = (state: RootState) =>
  state.blockEventCreate.showEditExpressionModal;
export const selectBlockEventExpressionEditLoading = (state: RootState) =>
  state.blockEventCreate.editExpressionLoading;

export const selectShowBlockEventDestinationsModal = (state: RootState) =>
  state.blockEventCreate.showEditDestinationsModal;
export const selectBlockEventDestinationEditLoading = (state: RootState) =>
  state.blockEventCreate.editDestinationsLoading;

export const {
  handleClearBlockEventCreationSlice,

  //step-1
  handleChangeNotificationName,
  handleChangeSelectedChain,
  handleChangeSelectedNetwork,
  handlesetSelectedTemplate,

  //step-2
  handleChangeExpressionValue,
  handleChangeActiveExpressionTabIndex,
  handleChangeTargetBlockValue,
  handleChangeBacktestValue,

  //step-3
  handleAddToSelectedDestination,
  handleRemoveToSelectedDestination,

  //
  handleSetupToEditExpression,
  handleCloseExpressionEditor,

  handleSetupToEditDestinations,
  handleCloseEDestinationsEditor,
} = blockEventCreateSlice.actions;
export default blockEventCreateSlice.reducer;
