import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createS3EventAsync,
  selectActionLoading,
  selectLoading,
  selectSelectedS3Event,
} from "../../../../store/s3/eventsSlice";
import { S3PostgresEventSchema } from "../../../../utils/validations";
import { useEffect, useState } from "react";
import RadioGrouper, { RadioGroupeItem } from "../../../general/RadioGrouper";
import { useNavigate } from "react-router-dom";
import { S3EventsUrl } from "../../../../utils/urls";
import useGetS3Events from "../../../../hooks/useGetS3Events";
import {
  Button,
  Input,
  Switcher,
  Textarea,
  Tooltip,
  Typography,
} from "djuno-design";

const formatOptions: RadioGroupeItem[] = [
  { label: "Namespace", value: "namespace" },
  { label: "Access", value: "access" },
];

const PostgresForm = () => {
  const event = useAppSelector(selectSelectedS3Event);
  const loading = useAppSelector(selectActionLoading);
  const eventsLoading = useAppSelector(selectLoading);

  const [useManualString, setUseManualString] = useState<boolean>(false);
  const [selectedFormat, setSelectedFormat] = useState<RadioGroupeItem>(
    formatOptions[0]
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getS3Events = useGetS3Events();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(S3PostgresEventSchema(useManualString)),
  });

  const dbNameInputValue = watch("db_name");
  const hostInputValue = watch("host");
  const portInputValue = watch("port");
  const userInputValue = watch("user");
  const passwordInputValue = watch("password");
  const connection_stringInputValue = watch("connection_string");

  useEffect(() => {
    function parseConnectionString(
      connectionString: string
    ): Record<string, string> {
      const result: Record<string, string> = {
        user: "",
        pass: "",
        host: "",
        port: "",
        dbname: "",
      };

      const keyValuePairs = connectionString.split(/\s+/);

      for (const pair of keyValuePairs) {
        const [key, value] = pair.split("=");

        if (key && value) {
          switch (key.toLowerCase()) {
            case "user":
              result.user = value;
              break;
            case "password":
              result.pass = value;
              break;
            case "host":
              result.host = value;
              break;
            case "port":
              result.port = value;
              break;
            case "dbname":
              result.dbname = value;
              break;
            default:
              // Handle additional key-value pairs if needed
              break;
          }
        }
      }

      return result;
    }

    if (connection_stringInputValue) {
      const parsedDnsString = parseConnectionString(
        connection_stringInputValue
      );
      // console.log("parsedDnsString:", parsedDnsString);
      setValue("db_name", parsedDnsString.dbname);
      setValue("user", parsedDnsString.user);
      setValue("password", parsedDnsString.pass);
      setValue("host", parsedDnsString.host);
      setValue("port", parsedDnsString.port);
    }
  }, [connection_stringInputValue, setValue]);

  useEffect(() => {
    setValue(
      "connection_string",
      `host=${hostInputValue} dbname=${dbNameInputValue} user=${userInputValue} password=${passwordInputValue} port=${portInputValue} sslmode=require`
    );
  }, [
    dbNameInputValue,
    hostInputValue,
    passwordInputValue,
    portInputValue,
    setValue,
    userInputValue,
  ]);

  useEffect(() => {
    if (event) {
    } else {
      setValue("connection_string", "");
      setSelectedFormat(formatOptions[0]);
    }
  }, [event, setValue]);

  const onSubmit = (data: any) => {
    let keyValues = [
      {
        key: "connection_string",
        value: data.connection_string,
      },
      {
        key: "table",
        value: data.table,
      },
      {
        key: "format",
        value: selectedFormat.value,
      },
      {
        key: "queue_dir",
        value: data.queue_dir,
      },
      {
        key: "comment",
        value: data.comment,
      },
    ];
    if (data.queue_limit !== null) {
      keyValues = [
        ...keyValues,
        {
          key: "queue_limit",
          value: data.queue_limit.toString(),
        },
      ];
    }
    const formData = {
      key_values: keyValues,
    };
    // if (event) {
    //   dispatch(
    //     updateS3EventAsync({
    //       id: event.Id,
    //       data: {
    //         label: data.label,
    //       },
    //     })
    //   ).then((action) => {
    //     if (action.type === "s3-access-keys/update/fulfilled") {
    //       // handleClearComponent();
    //       reset();
    //       dispatch(handleHideEditor());
    //       dispatch(getS3EventsAsync());
    //     }
    //   });
    // } else {
    dispatch(
      createS3EventAsync({
        event: "postgres",
        identifier: data.identifier,
        data: formData,
      })
    ).then((action) => {
      if (action.type === "s3-events/create/fulfilled") {
        getS3Events(() => {
          reset();
          navigate(S3EventsUrl);
        });
      }
    });
    // }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col w-full gap-5">
        <Input
          label="Identifier"
          tooltip={{
            className: "!text-xs",
            content: "Unique descriptive string for this destination",
          }}
          placeholder="Enter Destination Identifier"
          {...register("identifier")}
          error={errors.identifier?.message}
          required
        />

        <div className="flex items-center justify-between w-full">
          <Typography.Text className="!text-sm">
            Manually Configure String
          </Typography.Text>
          <Switcher value={useManualString} onChange={setUseManualString} />
        </div>
        {!useManualString && (
          <div className="flex flex-col bg-gray-50 dark:bg-dark-1 dark:border-dark-2 border p-3 rounded-lg gap-3">
            <Input
              label="Host"
              placeholder="Enter Host"
              {...register("host")}
              error={errors.host?.message}
              required
            />
            <Input
              label="DB Name"
              placeholder="Enter DB Name"
              {...register("db_name")}
              error={errors.db_name?.message}
              required
            />
            <Input
              label="Port"
              placeholder="Enter Port"
              {...register("port")}
              error={errors.port?.message}
              required
            />
            <Input
              label="User"
              placeholder="Enter User"
              {...register("user")}
              error={errors.user?.message}
              required
            />
            <Input
              label="Password"
              placeholder="Enter Password"
              {...register("password")}
              error={errors.password?.message}
              required
            />
          </div>
        )}

        <Input
          label="Connection String"
          {...register("connection_string")}
          disabled={!useManualString}
          error={errors.connection_string?.message}
          required={useManualString}
        />

        <Input
          label="Table"
          tooltip={{
            className: "!text-xs",
            content:
              "DB table name to store/update events, table is auto-created",
          }}
          placeholder="Enter Table Name"
          {...register("table")}
          error={errors.table?.message}
        />

        <div className="flex gap-5 justify-between px-1">
          <div className="flex items-center gap-1">
            <div className="text-sm font-medium text-slate-800 dark:text-slate-50">
              Format
            </div>
            <Tooltip.Info
              className="!text-xs"
              content="'namespace' reflects current bucket/object list and 'access' reflects a journal of object operations, defaults to 'namespace'"
            />
          </div>
          <RadioGrouper
            items={formatOptions}
            selected={selectedFormat}
            setSelected={setSelectedFormat}
          />
        </div>

        <Input
          label="Queue Directory"
          tooltip={{
            className: "!text-xs",
            content:
              "Staging directory for undelivered messages e.g. '/home/events'",
          }}
          placeholder="ِEnter Queue Directory"
          {...register("queue_dir")}
          error={errors.queue_dir?.message}
        />

        <Input
          label="Queue Limit"
          tooltip={{
            className: "!text-xs",
            content:
              "maximum limit for undelivered messages, defaults to '10000'",
          }}
          placeholder="ِEnter Queue Limit"
          {...register("queue_limit")}
          error={errors.queue_limit?.message}
        />

        <Textarea
          label="Comment"
          placeholder="Enter custom notes if any"
          {...register("comment")}
          error={errors.comment?.message}
          rows={4}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          loading={loading || eventsLoading}
          uiType="primary"
          disabled={loading || eventsLoading}
          type="submit"
        >
          {event ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  );
};
export default PostgresForm;
