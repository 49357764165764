import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { S3bucketSchema } from "../../../../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { useEffect, useState } from "react";
import {
  selectActionLoading,
  getBucketsAsync,
  selectBuckets,
  // getBucketsAsync,
} from "../../../../../store/s3/buckets/bucketsSlice";
import RadioGrouper, {
  RadioGroupeItem,
} from "../../../../general/RadioGrouper";
import S3QuotaForm, { capacityOptions } from "../../forms/S3QuotaForm";
import { binarySize } from "../../../../../utils/file";
import S3ExclodeForm from "../../forms/S3ExclodeForm";
import S3BucketNameValidation from "../../forms/S3BucketNameValidation";
import { modeOptions, validityOptions } from "./S3RetentionEditorModal";
import { Button, Input, Modal, Select, Switcher, Tooltip } from "djuno-design";

const S3bucketEditorModal = () => {
  // const isOpen = useAppSelector(selectShowEditor);
  const isOpen = false;
  const buckets = useAppSelector(selectBuckets);
  const loading = useAppSelector(selectActionLoading);
  // const bucket = useAppSelector(selectSelectedBucket);

  const [versioning, setVersioning] = useState<boolean>(false);
  const [isExcludeFolders, setIsExcludeFolders] = useState<boolean>(false);
  const [objectLocking, setObjectLocking] = useState<boolean>(false);
  const [quota, setQuota] = useState<boolean>(false);
  const [retention, setRetention] = useState<boolean>(false);
  const [selectedCapacityOption, setSelectedCapacityOption] = useState<string>(
    capacityOptions[0].value
  );
  const [selectedValidityOption, setSelectedValidityOption] = useState<string>(
    validityOptions[0].value
  );
  const [selectedMode, setSelectedMode] = useState<RadioGroupeItem>(
    modeOptions[0]
  );

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm({
    resolver: yupResolver(
      S3bucketSchema(versioning, objectLocking, quota, retention, [
        ...buckets.map((b) => b.name),
      ])
    ),
  });
  const bucketName = watch("name");
  // useEffect(() => {
  //   if (isOpen) {
  //     if (bucket) {
  //       //TODO : specify paraneters after get bucket
  //       setValue("name", "");
  //       // setValue("capacity", 1);
  //       // setValue("validity", 180);
  //       // setVersioning()
  //       // setObjectLocking()
  //       // setQuota()
  //       // setRetention()
  //       // setSelectedCapacityOption()
  //       // setSelectedValidityOption()
  //       // setSelectedMode();
  //     } else {
  //       setValue("name", "");
  //       setValue("capacity", 1);
  //       setValue("validity", 180);
  //       setSelectedCapacityOption(capacityOptions[0]);
  //       setSelectedMode(modeOptions[0]);
  //       setSelectedValidityOption(validityOptions[0]);
  //     }
  //   }
  // }, [isOpen, bucket, setValue]);

  useEffect(() => {
    if (buckets.length === 0) {
      dispatch(getBucketsAsync());
    }
  }, [buckets.length, dispatch]);

  const handleClearComponent = () => {
    setVersioning(false);
    setObjectLocking(false);
    setQuota(false);
    setRetention(false);
    setSelectedCapacityOption(capacityOptions[0].value);
    setSelectedValidityOption(validityOptions[0].value);
    setSelectedMode(modeOptions[0]);
    reset();
    setValue("capacity", 1);
    setValue("validity", 180);
  };

  const onSubmit = (data: any) => {
    // data:
    //capacity -> number or ''
    //validity -> number or '' -used
    //name -used
    //excludePrefixes -used
    //--- others:
    // versioning -used
    // objectLocking -used
    // quota -used
    // selectedCapacityOption -used
    // retention
    // selectedMode
    // selectedValidityOption

    let bucketData: any = {
      name: data.name,
      locking: objectLocking,
      versioning: {
        enabled: versioning,
        excludePrefixes: data.excludePrefixes,
        excludeFolders: isExcludeFolders,
      },
    };

    if (quota) {
      const quotaValue = {
        enabled: quota,
        type: "hard",
        amount:
          data.capacity !== ""
            ? binarySize(data.capacity, selectedCapacityOption)
            : 0,
      };
      bucketData.quota = quotaValue;
    }

    if (retention) {
      const RetentionValue = {
        mode: selectedMode.value,
        unit: selectedValidityOption,
        validity: data.validity,
      };
      bucketData.retention = RetentionValue;
    }

    // if (bucket) {
    //   dispatch(
    //     updateBucketAsync({
    //       name: bucket.name,
    //       data: {
    //         name: data.name,
    //         versioning: {
    //           enabled: versioning,
    //           excludePrefixes: [],
    //           excludeFolders: false,
    //         },
    //         locking: objectLocking,
    //         quota: {
    //           enabled: quota,
    //           type: "hard",
    //           amount:
    //             data.capacity !== ""
    //               ? binarySize(
    //                   data.capacity,
    //                   selectedCapacityOption.value.toString()
    //                 )
    //               : 0,
    //         },
    //       },
    //     })
    //   ).then((action) => {
    //     if (action.type === "buckets/update/fulfilled") {
    //       handleClearComponent();
    //       dispatch(handleHideEditor());
    //       dispatch(getBucketsAsync());
    //     }
    //   });
    // } else {
    //   dispatch(createBucketAsync(bucketData)).then((action) => {
    //     if (action.type === "buckets/create/fulfilled") {
    //       handleClearComponent();
    //       dispatch(handleHideEditor());
    //       dispatch(getBucketsAsync());
    //     }
    //   });
    // }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        // dispatch(handleHideEditor());
        handleClearComponent();
      }}
      contentClassName="max-w-xl"
      // title={bucket ? "Update bucket" : "Create new bucket"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col w-full gap-5">
          <div className="flex flex-col gap-1">
            <Input
              label="Name"
              {...register("name")}
              error={errors.name?.message}
              placeholder=""
            />
            <S3BucketNameValidation name={bucketName} />
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-2 items-center">
              <div className="text-sm text-slate-700 dark:text-slate-200">
                Versioning
              </div>
              <Tooltip.Info
                className="!text-xs"
                content="Versioning allows to keep multiple versions of the same object under the same key."
              />
            </div>
            <Switcher
              disabled={objectLocking}
              value={versioning}
              onChange={setVersioning}
            />
          </div>

          {versioning && !objectLocking && (
            <S3ExclodeForm
              enabledExclodeFolders={isExcludeFolders}
              setExcludeFoldersStatus={setIsExcludeFolders}
              errors={errors}
              register={register}
              control={control}
            />
          )}
          <div className="flex items-center justify-between w-full">
            <div className="flex gap-2 items-center">
              <div className="text-sm text-slate-700 dark:text-slate-200">
                Object Locking
              </div>
              <Tooltip.Info
                className="!text-xs"
                content="Object Locking prevents objects from being deleted. Required to support retention and legal hold. Can only be enabled at bucket creation."
              />
            </div>
            <Switcher
              onChange={(v) => {
                setObjectLocking(v);
                v && setVersioning(v);
              }}
              value={objectLocking}
              disabled={retention}
            />
          </div>

          <S3QuotaForm
            enabledQuota={quota}
            setQuotaStatus={setQuota}
            capacityInputProps={{ ...register("capacity") }}
            capacityErrorMessage={errors.capacity?.message}
            selectedCapacityOption={selectedCapacityOption}
            setSelectedCapacityOption={setSelectedCapacityOption}
          />

          {versioning && (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between w-full">
                <div className="flex gap-2 items-center">
                  <div className="text-sm text-slate-700 dark:text-slate-200">
                    Retention
                  </div>
                  <Tooltip.Info
                    className="!text-xs"
                    content="Retention imposes rules to prevent object deletion for a period of time. Versioning must be enabled in order to set bucket retention policies."
                  />
                </div>
                <Switcher
                  value={retention}
                  onChange={(v) => {
                    setRetention(v);
                    if (v) {
                      setObjectLocking(true);
                    }
                  }}
                />
              </div>
              {retention && (
                <div className="flex flex-col gap-3">
                  <div className="flex gap-5">
                    <div className="text-sm text-slate-500 dark:text-slate-200">
                      Mode
                    </div>
                    <div className="flex-1">
                      <RadioGrouper
                        items={modeOptions}
                        selected={selectedMode}
                        setSelected={setSelectedMode}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-sm text-slate-500 dark:text-slate-200">
                      Validity
                    </div>
                    <div className="flex gap-2">
                      <div className="flex-1">
                        <Input
                          {...register("validity")}
                          error={errors.validity?.message}
                        />
                      </div>
                      <div className="w-20">
                        <Select
                          options={validityOptions}
                          value={selectedValidityOption}
                          onChange={(o) => o && setSelectedValidityOption(o)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            loading={loading}
            uiType="primary"
            disabled={loading}
            type="submit"
          >
            {/* {bucket ? "Update" : "Create"} */}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default S3bucketEditorModal;
