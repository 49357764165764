import { Flex, Typography } from "djuno-design";
import Container from "../layouts/Container";
import { useAnimation, motion } from "framer-motion";

import { FaDiceD20 } from "react-icons/fa";
import { FaToolbox } from "react-icons/fa";
// import { GiArtificialHive } from "react-icons/gi";
import { FaBrain } from "react-icons/fa";
import { SiHiveBlockchain } from "react-icons/si";

const featuresItems = [
  {
    title: "AI optimized infrastructure",
    description:
      "Baked in AI tools for load prediction, network optimisation, cost reduction in managing infrastructure to ensure competitive price",
    icon: <FaBrain className="w-full h-full" />,
  },
  {
    title: "Fully managed services",
    description:
      "One click deploy of services and embedded monitoring with ability to scale automatically",
    icon: <FaToolbox className="w-full h-full" />,
  },
  {
    title: "Blockchain Extensions",
    description:
      "Low code Web3.0 primitives for building decentralised applications across multiple chains",
    icon: <SiHiveBlockchain className="w-full h-full" />,
  },
  {
    title: "Web3.0 workflow studio",
    description:
      "Low code workflow automation application, focusing on integrating both on-chain and off-chain applications ",
    icon: <FaDiceD20 className="w-full h-full" />,
  },
];
const AnimatedFeatures = () => {
  return (
    <Container>
      <div className="grid grid-cols-12 md:gap-8 md:mt-5 lg:-mt-7 xl:-mt-32 relative z-50">
        {featuresItems.map((item, index) => (
          <AnimatedFeatureBox
            key={index}
            order={index}
            icon={item.icon}
            title={item.title}
            paragraph={item.description}
          />
        ))}
      </div>
    </Container>
  );
};

const AnimatedFeatureBox: React.FC<{
  title: string;
  icon: React.ReactNode;
  paragraph: string;
  order: number;
}> = ({ icon, title, paragraph, order = 0 }) => {
  const controls = useAnimation();
  return (
    <motion.div
      animate={controls}
      variants={featuresHomeVariants}
      whileInView="visible"
      custom={order}
      viewport={{ once: true }}
      initial="hidden"
      className="col-span-12 sm:col-span-6 lg:col-span-3 mb-5"
    >
      <Flex
        direction="col"
        items="center"
        className="group gap-4 w-full h-full bg-white dark:bg-dark-2 rounded-3xl rounded-tr-none p-7 px-5 shadow-[0_2px_48px_0_rgba(0,0,0,0.1)]"
      >
        <div className="p-5 bg-sky-50 dark:bg-sky-700 rounded-3xl rounded-tr-none group-hover:-translate-y-2 transition-all duration-300">
          <div className="w-6 h-6 text-blue-500 dark:text-slate-100">
            {icon}
          </div>
        </div>
        <Typography.Text
          size="base"
          className="font-semibold text-center tracking-wide"
        >
          {title}
        </Typography.Text>
        <Typography.Text size="sm" className="text-center tracking-wide">
          {paragraph}
        </Typography.Text>
      </Flex>
    </motion.div>
  );
};

export const featuresHomeVariants = {
  visible: (i: number) => ({
    opacity: 1,
    scale: 1,
    x: 0,
    y: 0,
    rotate: 0,
    transition: {
      delay: i * 0.2,
      duration: 0.5,
    },
  }),
  hidden: { opacity: 0, scale: 1, x: -20, y: 130, rotate: 4 },
};

export default AnimatedFeatures;
