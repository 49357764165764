import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  WebAppDisksUrl,
  WebAppEnvUrl,
  WebAppJobsUrl,
  WebAppLogsUrl,
  WebAppMetricsUrl,
  WebAppPreviewsUrl,
  WebAppScalingUrl,
  WebAppSettingsUrl,
  WebAppSharingUrl,
  WebAppShellUrl,
  WebAppUrl,
  WebAppsUrl,
} from "../../utils/urls";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ReactComponent as RightArrow } from "./../../assets/icons/arrow-right.svg";
import { Helmet } from "react-helmet";
import {
  getWebAppAsync,
  selectWebApp,
  selectWebAppLoading,
} from "../../store/web-app/webAppSlice";
import { NotExist } from "../../components/layouts/NotData";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as DiskIcon } from "./../../assets/icons/driver.svg";
import { ReactComponent as EnviromentIcon } from "./../../assets/icons/globe-alt.svg";
import { ReactComponent as ShellIcon } from "./../../assets/icons/shell.svg";
import { ReactComponent as PreviewIcon } from "./../../assets/icons/eye.svg";
import { ReactComponent as JobIcon } from "./../../assets/icons/job.svg";
import { ReactComponent as ShareIcone } from "./../../assets/icons/share.svg";
import { ReactComponent as MetricsIcon } from "./../../assets/icons/adjustments-vertical.svg";
import { ReactComponent as ScaleIcon } from "./../../assets/icons/scale.svg";
import { ReactComponent as SettingIcon } from "./../../assets/icons/setting.svg";
import { ReactComponent as BeatIcon } from "./../../assets/icons/beat.svg";
import { Skeleton, Typography, Tabs, TabOptions, Flex } from "djuno-design";

const WebAppSinglePage = () => {
  const { webAppId } = useParams();
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isExist, setIsExist] = useState<boolean>(true);

  useEffect(() => {
    if (webApp === null && webAppId) {
      dispatch(getWebAppAsync(webAppId)).then((action) => {
        if (action.type === "web-app/rejected") {
          setIsExist(false);
        }
      });
    }
  }, [dispatch, webAppId, webApp]);

  const Web3EndpointPageMenus: TabOptions = useMemo(
    () => [
      {
        label: (
          <div className="flex items-center gap-1">
            <ComputerIcon className="w-3" />
            Overview
          </div>
        ),
        url: WebAppUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <BeatIcon className="w-3" />
            Logs
          </div>
        ),
        url: WebAppLogsUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <DiskIcon className="w-3" />
            Disks
          </div>
        ),
        url: WebAppDisksUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <EnviromentIcon className="w-3" />
            Environment
          </div>
        ),

        url: WebAppEnvUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <ShellIcon className="w-3" />
            Shell
          </div>
        ),

        url: WebAppShellUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <PreviewIcon className="w-3" />
            Previews
          </div>
        ),
        url: WebAppPreviewsUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <JobIcon className="w-3" />
            Jobs
          </div>
        ),
        url: WebAppJobsUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <ShareIcone className="w-3" />
            Sharing
          </div>
        ),
        url: WebAppSharingUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <MetricsIcon className="w-3" />
            Metrics
          </div>
        ),
        url: WebAppMetricsUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <ScaleIcon className="w-3" />
            Scaling
          </div>
        ),
        url: WebAppScalingUrl(webAppId),
      },
      {
        label: (
          <div className="flex items-center gap-1">
            <SettingIcon className="w-3" />
            Settings
          </div>
        ),
        url: WebAppSettingsUrl(webAppId),
      },
    ],
    [webAppId]
  );
  const pageName = useMemo(
    () => location.pathname.split("/").filter(Boolean)[2] || "",
    [location.pathname]
  );

  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | Web App ({webApp?.Name || ""}){" "}
          {pageName}
        </title>
        <meta
          name="description"
          content="web3-auth refers to authentication mechanisms used within Web3 applications, which are decentralized applications built on blockchain technology. Web3-auth facilitates user authentication and authorization processes within these decentralized applications"
        />
      </Helmet>
      <Flex items="center" justify="between" className="h-20">
        <Flex items="center" justify="between" className="px-6 flex-1">
          <Flex items="center" className="gap-2">
            <RightArrow
              onClick={() => navigate(WebAppsUrl)}
              className="rotate-180 w-5 h-5 hover:scale-110 transition-all duration-500 text-slate-800 dark:text-slate-100 cursor-pointer"
            />
            {webAppLoading ? (
              <Skeleton shape="rectangle" className="!w-48" />
            ) : (
              <Typography.Title level={5} className="!mb-0">
                {webApp?.Name}
              </Typography.Title>
            )}
          </Flex>
        </Flex>
        <div className="mr-6 w-96"></div>
      </Flex>
      {isExist ? (
        <div className="mx-6">
          <Tabs
            options={Web3EndpointPageMenus.map((o) => ({
              label: o.label,
              url: o.url,
            }))}
          />
          <div className="py-6">
            <Outlet />
          </div>
        </div>
      ) : (
        <NotExist url={() => navigate(WebAppsUrl)} />
      )}
    </>
  );
};
export default WebAppSinglePage;
