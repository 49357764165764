import { useAppSelector } from "../../../hooks";
import { WorkflowNotActiveTab } from "../../layouts/NotData";
import { selectWorkflowStatus } from "../../../store/workflows/workflowsSlice";
import { PlansTab } from "../../plans/UniversalPlansBox";

const WorkflowPlansTab = () => {
  const workflowServiceType =
    Number(process.env.REACT_APP_WORKFLOW_SERVICE_TYPE) || 3;
  const workflowStatus = useAppSelector(selectWorkflowStatus);

  if (!workflowStatus) {
    return <WorkflowNotActiveTab />;
  }
  return (
    <>
      <PlansTab serviceType={workflowServiceType} />
    </>
  );
};

export default WorkflowPlansTab;
