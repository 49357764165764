import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ChangePasswordSchema,
  validatePassword,
} from "../../../../utils/validations";
import {
  changePasswordAsync,
  handleHideChangePassEditor,
  selectChangePassLoading,
  selectShowChangePassEditor,
} from "../../../../store/auth/authSlice";
import { useEffect, useState } from "react";
import { PasswordErrors } from "../../../../types";
import toast from "react-hot-toast";
import { CustomToast } from "../../../general/Toast";
import { Button, Modal, Input, cn } from "djuno-design";

const PasswordUpdateModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectShowChangePassEditor);
  const loading = useAppSelector(selectChangePassLoading);
  const [password_error, setPasswordErrors] = useState<PasswordErrors>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
  });
  const selectedPassword = watch("newPassword");

  const onSubmit = (data: any) => {
    dispatch(
      changePasswordAsync({
        password: data.password,
        newPassword: data.newPassword,
        newPasswordConfirmation: data.newPasswordConfirmation,
      })
    ).then((action) => {
      if (action.type === "auth/change-password/fulfilled") {
        toast.success(() => CustomToast("password changed successfully"));
        reset();
        dispatch(handleHideChangePassEditor());
      }
    });
  };

  useEffect(() => {
    setPasswordErrors(validatePassword(selectedPassword));
  }, [selectedPassword]);

  useEffect(() => {
    if (isOpen) {
      setPasswordErrors({
        length: true,
        lowercase: true,
        uppercase: true,
        number: true,
        special_char: true,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        dispatch(handleHideChangePassEditor());
      }}
      contentClassName="max-w-xl"
      //   containerClassName="!items-start"
      title="Change profile password"
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="mt-5 flex flex-col gap-4">
          <Input
            label="Current Password"
            {...register("password")}
            type="password"
            error={errors.password?.message}
            required
            placeholder=""
          />
          <Input
            label="New Password"
            {...register("newPassword")}
            type="password"
            error={errors.newPassword?.message}
            placeholder=""
            required
            hint={
              <div className="flex gap-1 flex-wrap">
                <span
                  className={cn("whitespace-nowrap", {
                    "text-success": !password_error?.length,
                  })}
                >
                  8-64 chars
                </span>
                |
                <span
                  className={cn("whitespace-nowrap", {
                    "text-success": !password_error?.lowercase,
                  })}
                >
                  1 lowercase
                </span>
                |
                <span
                  className={cn("whitespace-nowrap", {
                    "text-success": !password_error?.uppercase,
                  })}
                >
                  1 uppercase
                </span>
                |
                <span
                  className={cn("whitespace-nowrap", {
                    "text-success": !password_error?.number,
                  })}
                >
                  1 number
                </span>{" "}
                |
                <span
                  className={cn("whitespace-nowrap", {
                    "text-success": !password_error?.special_char,
                  })}
                >
                  1 special char
                </span>
              </div>
            }
            labelClassName="!items-start"
          />

          <Input
            label="New Password confirm"
            {...register("newPasswordConfirmation")}
            type="password"
            error={errors.newPasswordConfirmation?.message}
            placeholder=""
            required
          />
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            uiType="primary"
            disabled={loading}
            type="submit"
            loading={loading}
          >
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PasswordUpdateModal;
