import { Typography } from "djuno-design";
import React from "react";
import { FaTwitterSquare, FaLinkedin } from "react-icons/fa";

export interface TeamMemberProps {
  imageSrc: string;
  name: string;
  title: string;
  twitterUrl: string;
  linkedinUrl: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({
  imageSrc,
  name,
  title,
  twitterUrl,
  linkedinUrl,
}) => {
  return (
    <div className="relative bg-white shadow-[0_2px_48px_0_rgba(0,_0,_0,_0.06)] rounded-lg mb-8 mt-12 group dark:bg-dark-2">
      <div className="user-image border-6 absolute left-0 right-0 mx-auto top-[-45px] transition-transform duration-300 ease-in-out w-[90px] h-[90px] border-6 border-white overflow-hidden rounded-[25px] rounded-tr-[10px] group-hover:translate-y-[-10px]">
        <img src={imageSrc} alt={name} />
      </div>
      <div className="text-center overflow-hidden ">
        <div className="flex flex-col bg-primary-400 mt-[70px] mb-8 py-2 group-hover:bg-primary-700 transition-colors duration-300 ease-in-out">
          <Typography.Text className=" !text-white tracking-wide mb-1">
            {name}
          </Typography.Text>
          <Typography.Text className=" !text-white  ">{title}</Typography.Text>
        </div>
        <ul className="text-center mb-4">
          {twitterUrl && (
            <li className="inline-block mx-2">
              <Typography.Link
                href={twitterUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitterSquare className="text-primary-400" />
              </Typography.Link>
            </li>
          )}
          {linkedinUrl && (
            <li className="inline-block mx-2">
              <Typography.Link
                href={linkedinUrl}
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin className="text-primary-400" />
              </Typography.Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TeamMember;
