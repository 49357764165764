import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectEmailVerificationLoading,
  selectIsAuthenticate,
  verificationEmailAsync,
} from "../../store/auth/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { HomeUrl, SignInUrl } from "../../utils/urls";
import { ReactComponent as VerifyIcon } from "./../../assets/icons/verify.svg";
import { ReactComponent as ErrorIcon } from "./../../assets/icons/error-warning.svg";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Flex, Loading, Typography } from "djuno-design";

const EmailVerification = () => {
  const dispatch = useAppDispatch();
  const isAuthenticate = useAppSelector(selectIsAuthenticate);
  const navigate = useNavigate();
  const loading = useAppSelector(selectEmailVerificationLoading);
  const [status, setStatus] = useState<boolean | undefined>(undefined);
  const { token } = useParams();

  const handleVerify = useCallback(() => {
    if (token) {
      setStatus(undefined);
      dispatch(verificationEmailAsync({ token })).then((action) => {
        if (action.type === "auth/verification-email/fulfilled") {
          setStatus(true);
        } else {
          setStatus(false);
        }
      });

      // setTimeout(() => {
      //   setLoading(false);
      //   setStatus(true);
      // }, 4000);
    }
  }, [dispatch, token]);

  useEffect(() => {
    handleVerify();
  }, [handleVerify]);

  const handleContinue = () => {
    if (isAuthenticate) {
      navigate(HomeUrl);
    } else {
      navigate(SignInUrl);
    }
  };

  const handleTryAgain = () => handleVerify();

  return (
    <>
      <Typography.Title level={5} className="text-center">
        Email verification
      </Typography.Title>
      <AnimatePresence>
        <Flex justify="center" items="center" className="w-full min-h-[130px]">
          {loading && <Loading />}
          {!loading && status && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              className="flex flex-col justify-center items-center"
            >
              <VerifyIcon className="w-24 text-green-600" />
              <Typography.Text className="mb-4 text-center !text-sm">
                Your email address has been successfully verified
              </Typography.Text>
              <Button uiType="light" onClick={handleContinue}>
                <Typography.Text
                  uiType="transparent"
                  className="w-full text-center"
                  size="sm"
                >
                  {isAuthenticate ? "Continue" : "Login"}
                </Typography.Text>
              </Button>
            </motion.div>
          )}
          {!loading && !status && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              className="flex flex-col justify-center items-center"
            >
              <ErrorIcon className="w-24 text-red-500" />
              <Typography.Text className="mb-4 text-center">
                There was a problem verifying your email address
              </Typography.Text>
              <Button uiType="light" onClick={handleTryAgain}>
                Try again
              </Button>
            </motion.div>
          )}
        </Flex>
      </AnimatePresence>
    </>
  );
};
export default EmailVerification;
