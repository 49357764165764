import { useAppDispatch, useAppSelector } from "./../../../hooks";
import {
  createFolderAsync,
  fetchFilesAysnc,
  handleHideCreateFolderModal,
  selectBreadCrumbItems,
  selectCreateFolderLoading,
  selectShowCreateFolderModal,
  handleChangeNewFolderNameOrFile,
  selectNewFolderNameOrFile,
  selectShowConflictModal,
  handleHideConflictModal,
  selectConflictModalLoading,
  selectConflictType,
  uploadFileAsync,
} from "../../../store/ipfs/filesSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateIpfsFolderSchema } from "../../../utils/validations";
import { fileParentFinder } from "../../../utils/file";
import { ReactComponent as OverrideIcon } from "./../../../assets/icons/override.svg";
import { ReactComponent as KeepBothIcon } from "./../../../assets/icons/keep_both.svg";
import { IpfsCreateFolderStrategy } from "../../../types/ipfs-file";
import { Button, Modal, Input, Loading, Flex } from "djuno-design";

const NewFolderModal = () => {
  const isOpen = useAppSelector(selectShowCreateFolderModal);
  const loading = useAppSelector(selectCreateFolderLoading);
  const breadCrumbItems = useAppSelector(selectBreadCrumbItems);
  const path = fileParentFinder(breadCrumbItems);
  // const _path = fileUrlConvertor(path);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateIpfsFolderSchema),
  });

  const onSubmit = (data: any) => {
    if (!loading) {
      dispatch(handleChangeNewFolderNameOrFile({ name: data.name.toString() }));
      dispatch(
        createFolderAsync({
          name: data.name,
          strategy: "None",
          path: path,
        })
      ).then((action) => {
        if (action.type === "files/folder/create/fulfilled") {
          dispatch(handleChangeNewFolderNameOrFile({ name: null }));
          reset();
          dispatch(handleHideCreateFolderModal());
          dispatch(fetchFilesAysnc());
        }
      });
    }
  };

  return (
    <Modal
      title="Create new folder"
      contentClassName="max-w-lg"
      isOpen={isOpen}
      onClose={() => {
        reset();
        dispatch(handleHideCreateFolderModal());
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Input
            {...register("name")}
            error={errors.name?.message}
            placeholder="Folder name..."
          />
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            uiType="primary"
            type="submit"
            className="w-[120px]"
            loading={loading}
          >
            Save folder
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export function ConflictModal() {
  const isOpen = useAppSelector(selectShowConflictModal);
  const nameOrFile = useAppSelector(selectNewFolderNameOrFile);
  const conflictType = useAppSelector(selectConflictType);
  const breadCrumbItems = useAppSelector(selectBreadCrumbItems);
  const folderLoading = useAppSelector(selectCreateFolderLoading);
  const conflictLoading = useAppSelector(selectConflictModalLoading);

  const dispatch = useAppDispatch();

  const handleCreateFolder = (strategy: IpfsCreateFolderStrategy) => {
    if (nameOrFile && !folderLoading && !conflictLoading) {
      if (conflictType === "folder") {
        dispatch(
          createFolderAsync({
            name: nameOrFile.toString(),
            strategy: strategy,
            path: fileParentFinder(breadCrumbItems),
          })
        ).then((action) => {
          if (action.type === "files/folder/create/fulfilled") {
            dispatch(handleHideConflictModal());
            dispatch(fetchFilesAysnc());
          }
        });
      } else if (conflictType === "file" && typeof nameOrFile !== "string") {
        dispatch(
          uploadFileAsync({
            file: nameOrFile,
            strategy: strategy,
            path: fileParentFinder(breadCrumbItems),
          })
        ).then((action) => {
          if (action.type === "files/file/upload/fulfilled") {
            dispatch(handleHideConflictModal());
            dispatch(fetchFilesAysnc());
          }
        });
      }
    }
  };

  return (
    <Modal
      title={"The asset already exists"}
      contentClassName="max-w-lg"
      isOpen={isOpen}
      onClose={() => {
        dispatch(handleHideConflictModal());
      }}
    >
      {(folderLoading || conflictLoading) && (
        <Flex items="center" justify="center" className="h-[100px]">
          <Loading uiSize={18} borderSize={2} />
        </Flex>
      )}
      {!folderLoading && (
        <>
          <div className="w-full mt-4 flex flex-col gap-3 mb-3 text-slate-700 dark:text-slate-300 text-sm">
            What do you wish to do?
          </div>

          <div className="w-full mt-2 text-center flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Button
                uiType="light"
                onClick={() => handleCreateFolder("keepBoth")}
              >
                <KeepBothIcon className="w-4 h-4" />
                Keep both
              </Button>
              <Button
                uiType="light"
                onClick={() => handleCreateFolder("override")}
              >
                <OverrideIcon className="w-4 h-4" />
                Override
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

export default NewFolderModal;
