import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  SettingsBillingUrl,
  // SettingsEnvVariablesUrl,
  SettingsProfileUrl,
  SettingsRegisteriesUrl,
  SettingsTeamsUrl,
} from "../../utils/urls";
import SettingProfileTab from "../../components/settings/profile/SettingProfileTab";
import SettingsTeamsTab from "../../components/settings/teams/SettingsTeamsTab";
import SettingBillingTab from "../../components/settings/billing/SettingBillingTab";
import SettingsRegisteriesTab from "../../components/settings/registeries/RegisteriesTab";
// import EnvironmentVariablesTab from "../../components/settings/env-variables/EnvironmentVariablesTab";
import { useAppSelector } from "../../hooks";
import { selectOnStageEnv } from "../../store/auth/authSlice";
import { useEffect, useMemo } from "react";
import { ReactComponent as ProfileIcon } from "./../../assets/icons/user-team.svg";
import { ReactComponent as TeamsIcon } from "./../../assets/icons/teams.svg";
import { ReactComponent as BanknotesIcon } from "./../../assets/icons/bank-notes.svg";
import { ReactComponent as RegisteriesIcon } from "./../../assets/icons/shield-check.svg";
import { Typography, Tabs, TabOptions } from "djuno-design";

export const SettingsPageMenus = ({
  showOnlyOwner,
}: {
  showOnlyOwner?: boolean;
}): TabOptions => [
  {
    label: (
      <div className="flex items-center gap-1">
        <ProfileIcon className="w-3" />
        Profile
      </div>
    ),
    url: SettingsProfileUrl,
    element: <SettingProfileTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <TeamsIcon className="w-3" />
        Teams
      </div>
    ),
    url: SettingsTeamsUrl,
    element: <SettingsTeamsTab />,
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <BanknotesIcon className="w-3" />
        Billing
      </div>
    ),
    url: SettingsBillingUrl,
    element: <SettingBillingTab />,
    isVisible: showOnlyOwner,
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <RegisteriesIcon className="w-3" />
        Registeries
      </div>
    ),
    url: SettingsRegisteriesUrl,
    element: <SettingsRegisteriesTab />,
    isVisible: showOnlyOwner,
  },
  // {
  //   label: "Environment Variables",
  //   url: SettingsEnvVariablesUrl,
  //   element: <EnvironmentVariablesTab />,
  //   setting: { showOnlyOwner: true },
  // },
];

const SettingsPage = () => {
  const onStageEnv = useAppSelector(selectOnStageEnv);
  const location = useLocation();
  const navigate = useNavigate();

  const tabOptions = useMemo(() => {
    return SettingsPageMenus({
      showOnlyOwner: onStageEnv?.IsAccountOwner,
    });
  }, [onStageEnv]);

  const isVisible = useMemo(() => {
    const tabOption = tabOptions.find((m) => m.url === location.pathname);
    return !!(
      tabOption &&
      (tabOption.isVisible === undefined || tabOption.isVisible === true)
    );
  }, [location.pathname, tabOptions]);

  useEffect(() => {
    if (!isVisible) {
      navigate(SettingsProfileUrl);
    }
  }, [navigate, isVisible]);

  // setLocalStorage("login", {
  //   Login: "devs@djuno.io",
  //   FullName: "Moe ",
  //   JwtToken:
  //     "EyJhbGciOiJIUzI1NiIsewrsddfsdfseqrMTcyNzM1MTU3MSwiZXhwIjoxNzI3Mzk0NzcxLCJpYXQiOjE3MjczNTE1NzF9.0JE5_hlkJagtIqnPLf5wBKxGMT36ghmt-7OxUQxdHdg",
  //   TwoFactorEnabled: false,
  // });

  return (
    <>
      <div className="flex items-center justify-between h-16">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Text className="!font-medium !mr-2 !text-standard !text-xl">
            Settings
          </Typography.Text>
        </div>
      </div>
      <div className="px-6">
        <Tabs
          options={tabOptions.map((o) => ({
            label: o.label,
            url: o.url,
            isVisible: o.isVisible,
          }))}
        />
        <div className="py-6">{isVisible && <Outlet />}</div>
      </div>
    </>
  );
};
export default SettingsPage;
