import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import {
  S3BucketObject,
  S3BucketObjectInfo,
  S3ObjectMetadata,
  S3ObjectState,
} from "../../../types/s3-bucket";
import {
  deleteMultipleObjectApi,
  deleteObjectApi,
  getBucketObjectsApi,
  getObjectDownloadApi,
  getObjectDownloadMultipleApi,
  getObjectInspectApi,
  getObjectMetadataApi,
  getObjectShareApi,
  restoreObjectApi,
  setObjectLegalHoldApi,
  setObjectRetentionPolicyApi,
  setObjectTagsApi,
} from "../../../apis/s3API";
import {
  getFileNameAndExt,
  getMainVersionOfObject,
} from "../../../utils/bucket";
import { fullDate } from "../../../utils/date";
import { changeS3ActiveStatus, S3UpdatingMessage } from "../s3PublicSlice";
import { getExtractErrors } from "../../../apis";
import { CustomErrorToast } from "../../../components/general/Toast";

export const s3ImageTypes = ["png", "jpg"];
export const s3TextTypes = ["txt", "svg"];

const initialState: S3ObjectState = {
  showObjectDrawer: false,
  selectedObject: null,
  versions: null,
  versionsLoading: false,
  selectedVersion: null,
  showVersionsModal: false,
  metadata: null,
  metadataLoading: false,
  showObjectPreview: false,
  objectPreview: null,
  objectPreviewLoading: false,
  objectDownloadLoading: false,
  showObjectLegalHoldModal: false,
  legalHoldLoading: false,
  showObjectRetentionPolicyModal: false,
  retentionPolicyLoading: false,
  showObjectTagsModal: false,
  tagsLoading: false,
  showObjectInspectModal: false,
  inspectLoading: false,
  showObjectShareModal: false,
  shareLoading: false,
  shareLink: null,
  restoreLoading: false,
  deleteLoading: false,
};

export const getObjectMetadataAsync = createAsyncThunk<
  { metadata: S3ObjectMetadata },
  { bucketName: string; prefix: string },
  IThunkRejectValue
>(
  "object/metadata",
  async (
    { bucketName, prefix },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await getObjectMetadataApi(bucketName, prefix);
      const { Result, NodeStatus } = response.data;
      const metadata = Result;
      dispatch(changeS3ActiveStatus({ status: NodeStatus === 2 }));

      return fulfillWithValue({ metadata });
    } catch (e) {
      if (isAxiosError(e) && e.response?.data.NodeStatus === 4) {
        dispatch(
          changeS3ActiveStatus({
            status: false,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ metadata: null });
      } else {
        return rejectWithValue({
          message: getExtractErrors(e),
        });
      }
    }
  }
);

export const getObjectVersionsAsync = createAsyncThunk<
  {
    versions: S3BucketObjectInfo[];
  },
  { bucketName: string; prefix: string },
  IThunkRejectValue
>(
  "object/versions",
  async (
    { bucketName, prefix },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    try {
      const response = await getBucketObjectsApi(bucketName, prefix, true);
      const { Result, NodeStatus } = response.data;
      const versions = Result || [];

      dispatch(changeS3ActiveStatus({ status: NodeStatus === 2 }));
      return fulfillWithValue({ versions });
    } catch (e) {
      if (isAxiosError(e) && e.response?.data.NodeStatus === 4) {
        dispatch(
          changeS3ActiveStatus({
            status: false,
            message: S3UpdatingMessage,
          })
        );
        return fulfillWithValue({ versions: [] });
      } else {
        return rejectWithValue({
          message: getExtractErrors(e),
        });
      }
    }
  }
);

export const setObjectLegalHoldAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    prefix: string;
    versionId: string;
    status: string;
  },
  IThunkRejectValue
>(
  "object/legal-hold/set",
  async ({ bucketName, prefix, versionId, status }, { rejectWithValue }) => {
    try {
      await setObjectLegalHoldApi(bucketName, prefix, versionId, status);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getObjectPreviewAsync = createAsyncThunk<
  { preview: string },
  { bucketName: string; prefix: string; verionId: string },
  IThunkRejectValue
>(
  "object/preview",
  async (
    { bucketName, prefix, verionId },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getObjectDownloadApi(bucketName, prefix, verionId);
      let preview = "";
      if (s3ImageTypes.includes(getFileNameAndExt(prefix)?.fileExt || "")) {
        const blob = new Blob([response.data]);
        const base64String = URL.createObjectURL(blob);

        preview = base64String as string;
      }

      if (s3TextTypes.includes(getFileNameAndExt(prefix)?.fileExt || "")) {
        const arrayBuffer = response.data;
        const textDecoder = new TextDecoder("utf-8");
        const decodedText = textDecoder.decode(arrayBuffer);
        preview = decodedText;
      }

      return fulfillWithValue({ preview });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const setObjectRetentionPolicyAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    prefix: string;
    versionId: string;
    data: any;
  },
  IThunkRejectValue
>(
  "object/retention-policy/set",
  async ({ bucketName, prefix, versionId, data }, { rejectWithValue }) => {
    try {
      await setObjectRetentionPolicyApi(bucketName, prefix, versionId, data);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const setObjectTagsAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    prefix: string;
    versionId: string;
    data: any;
  },
  IThunkRejectValue
>(
  "object/tags/set",
  async ({ bucketName, prefix, versionId, data }, { rejectWithValue }) => {
    try {
      await setObjectTagsApi(bucketName, prefix, versionId, data);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getObjectInspectAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    prefix: string;
    encrypt: boolean;
  },
  IThunkRejectValue
>(
  "object/inspect",
  async ({ bucketName, prefix, encrypt }, { rejectWithValue }) => {
    try {
      const response = await getObjectInspectApi(bucketName, prefix, encrypt);

      const blob = new Blob([response.data], {
        type: response.headers["Content-Type"]?.toString(),
      });

      const objUrl = URL.createObjectURL(blob);

      let link = document.createElement("a");
      link.href = objUrl;
      link.download = `inspect-${bucketName}-${prefix}_xl.meta.${
        encrypt ? "enc" : "zip"
      }`;
      link.click();

      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getObjectShareAsync = createAsyncThunk<
  { shareLink: string },
  {
    bucketName: string;
    prefix: string;
    versionId: string;
    expires: string;
  },
  IThunkRejectValue
>(
  "object/share",
  async (
    { bucketName, prefix, versionId, expires },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await getObjectShareApi(
        bucketName,
        prefix,
        versionId,
        expires
      );
      const shareLink = response.data.Result as string;
      return fulfillWithValue({ shareLink });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getObjectDownloadAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    prefix: string;
    versionId: string;
  },
  IThunkRejectValue
>(
  "object/download",
  async ({ bucketName, prefix, versionId }, { rejectWithValue }) => {
    try {
      const response = await getObjectDownloadApi(
        bucketName,
        prefix,
        versionId
      );
      const blob = new Blob([response.data]);
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = prefix;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getObjectDownloadMultipleAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    objects: S3BucketObject[];
  },
  IThunkRejectValue
>(
  "object/download-multiple",
  async ({ bucketName, objects }, { rejectWithValue }) => {
    try {
      const names = objects.map((o) => o.name);
      const response = await getObjectDownloadMultipleApi(bucketName, names);
      const blob = new Blob([response.data], { type: "application/zip" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download =
        fullDate(new Date().toISOString()) + "_files_list.zip";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const restoreObjectAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    prefix: string;
    versionId: string;
  },
  IThunkRejectValue
>(
  "object/restore",
  async ({ bucketName, prefix, versionId }, { rejectWithValue }) => {
    try {
      await restoreObjectApi(bucketName, prefix, versionId);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const deleteObjectAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    prefix: string;
    versionId?: string;
    recursive?: boolean;
    all_versions?: boolean;
    non_current_versions?: boolean;
    bypass?: boolean;
  },
  IThunkRejectValue
>(
  "object/delete",
  async (
    {
      bucketName,
      prefix,
      versionId,
      recursive,
      all_versions,
      non_current_versions,
      bypass,
    },
    { rejectWithValue }
  ) => {
    try {
      await deleteObjectApi(
        bucketName,
        prefix,
        versionId || null,
        recursive || null,
        all_versions || null,
        non_current_versions || null,
        bypass || null
      );
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const deleteMultipleObjectAsync = createAsyncThunk<
  boolean,
  {
    bucketName: string;
    objects: S3BucketObject[] | S3BucketObjectInfo[];
    all_versions: boolean;
    bypass: boolean;
  },
  IThunkRejectValue
>(
  "object/delete-multiple",
  async (
    { bucketName, objects, all_versions, bypass },
    { rejectWithValue }
  ) => {
    try {
      const formData = objects.map((obj) => ({
        path: obj.name,
        versionID: "version_id" in obj ? obj.version_id : "",
        recursive: false,
      }));
      await deleteMultipleObjectApi(
        bucketName,
        formData,
        all_versions,
        all_versions ? bypass : false
      );
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const objectSlice = createSlice({
  name: "s3-object",
  initialState,
  reducers: {
    handleCleanUpObject(state) {
      state.showObjectDrawer = false;
      state.selectedObject = null;
      state.versions = null;
      state.showVersionsModal = false;
      state.selectedVersion = null;
      state.metadata = null;
    },
    handleShowObjectDrawer: (
      state,
      action: { payload: { object: S3BucketObject } }
    ) => {
      state.showObjectDrawer = true;
      state.selectedObject = action.payload.object;
    },
    handleHideObjectDrawer: (state) => {
      state.showObjectDrawer = false;
      state.selectedObject = null;
      state.selectedVersion = null;
      state.showVersionsModal = false;
    },
    handleChangeSelectedVersion: (
      state,
      action: PayloadAction<S3BucketObjectInfo>
    ) => {
      state.selectedVersion = action.payload;
    },
    handleShowObjectPreview: (
      state,
      action: PayloadAction<{
        version?: S3BucketObjectInfo;
        object?: S3BucketObject;
      }>
    ) => {
      if (typeof action.payload.version !== "undefined") {
        state.selectedVersion = action.payload.version;
      }
      if (typeof action.payload.object !== "undefined") {
        state.selectedObject = action.payload.object;
      }
      state.showObjectPreview = true;
    },
    handleHideObjectPreview: (state) => {
      state.objectPreview = null;
      state.showObjectPreview = false;
    },
    handleShowObjectLegalHoldModal: (state) => {
      state.showObjectLegalHoldModal = true;
    },
    handleHideObjectLegalHoldModal: (state) => {
      state.showObjectLegalHoldModal = false;
    },
    handleShowObjectRetentionPolicyModal: (state) => {
      state.showObjectRetentionPolicyModal = true;
    },
    handleHideObjectRetentionPolicyModal: (state) => {
      state.showObjectRetentionPolicyModal = false;
    },
    handleShowObjectTagsModal: (state) => {
      state.showObjectTagsModal = true;
    },
    handleHideObjectTagsModal: (state) => {
      state.showObjectTagsModal = false;
    },
    handleShowObjectInspectModal: (state) => {
      state.showObjectInspectModal = true;
    },
    handleHideObjectInspectModal: (state) => {
      state.showObjectInspectModal = false;
    },
    handleShowObjectShareModal: (
      state,
      action: PayloadAction<{
        version?: S3BucketObjectInfo;
        object?: S3BucketObject;
      }>
    ) => {
      if (typeof action.payload.version !== "undefined") {
        state.selectedVersion = action.payload.version;
      }
      if (typeof action.payload.object !== "undefined") {
        state.selectedObject = action.payload.object;
      }
      state.showObjectShareModal = true;
    },
    handleHideObjectShareModal: (state) => {
      state.shareLink = null;
      state.showObjectShareModal = false;
    },
    handleShowObjectVersionsModal: (state) => {
      state.showVersionsModal = true;
    },
    handleHideObjectVersionsModal: (state) => {
      state.showVersionsModal = false;
      state.selectedObject = getMainVersionOfObject(state.versions) || null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getObjectMetadataAsync.pending, (state) => {
        state.metadataLoading = true;
      })
      .addCase(getObjectMetadataAsync.fulfilled, (state, action) => {
        const { metadata } = action.payload;
        state.metadata = metadata;
        state.metadataLoading = false;
      })
      .addCase(getObjectMetadataAsync.rejected, (state, { payload }) => {
        state.metadataLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getObjectVersionsAsync.pending, (state) => {
        state.versionsLoading = true;
      })
      .addCase(getObjectVersionsAsync.fulfilled, (state, action) => {
        const { versions } = action.payload;
        state.versions = versions;
        const mainVersion = getMainVersionOfObject(versions);
        state.selectedVersion = mainVersion || null;
        state.versionsLoading = false;
      })
      .addCase(getObjectVersionsAsync.rejected, (state, { payload }) => {
        state.versionsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setObjectLegalHoldAsync.pending, (state) => {
        state.legalHoldLoading = true;
      })
      .addCase(setObjectLegalHoldAsync.fulfilled, (state, action) => {
        state.legalHoldLoading = false;
      })
      .addCase(setObjectLegalHoldAsync.rejected, (state, { payload }) => {
        state.legalHoldLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getObjectDownloadAsync.pending, (state) => {
        state.objectDownloadLoading = true;
      })
      .addCase(getObjectDownloadAsync.fulfilled, (state) => {
        state.objectDownloadLoading = false;
      })
      .addCase(getObjectDownloadAsync.rejected, (state, { payload }) => {
        state.objectDownloadLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getObjectDownloadMultipleAsync.pending, (state) => {
        state.objectDownloadLoading = true;
      })
      .addCase(getObjectDownloadMultipleAsync.fulfilled, (state) => {
        state.objectDownloadLoading = false;
      })
      .addCase(
        getObjectDownloadMultipleAsync.rejected,
        (state, { payload }) => {
          state.objectDownloadLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(getObjectPreviewAsync.pending, (state) => {
        state.objectPreviewLoading = true;
      })
      .addCase(getObjectPreviewAsync.fulfilled, (state, action) => {
        state.objectPreview = action.payload.preview;
        state.objectPreviewLoading = false;
      })
      .addCase(getObjectPreviewAsync.rejected, (state, { payload }) => {
        state.objectPreviewLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setObjectRetentionPolicyAsync.pending, (state) => {
        state.retentionPolicyLoading = true;
      })
      .addCase(setObjectRetentionPolicyAsync.fulfilled, (state) => {
        state.retentionPolicyLoading = false;
      })
      .addCase(setObjectRetentionPolicyAsync.rejected, (state, { payload }) => {
        state.retentionPolicyLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(setObjectTagsAsync.pending, (state) => {
        state.tagsLoading = true;
      })
      .addCase(setObjectTagsAsync.fulfilled, (state) => {
        state.tagsLoading = false;
      })
      .addCase(setObjectTagsAsync.rejected, (state, { payload }) => {
        state.tagsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getObjectInspectAsync.pending, (state) => {
        state.inspectLoading = true;
      })
      .addCase(getObjectInspectAsync.fulfilled, (state) => {
        state.inspectLoading = false;
      })
      .addCase(getObjectInspectAsync.rejected, (state, { payload }) => {
        state.inspectLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getObjectShareAsync.pending, (state) => {
        state.shareLoading = true;
      })
      .addCase(getObjectShareAsync.fulfilled, (state, action) => {
        state.shareLink = action.payload.shareLink;
        state.shareLoading = false;
      })
      .addCase(getObjectShareAsync.rejected, (state, { payload }) => {
        state.shareLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(restoreObjectAsync.pending, (state) => {
        state.restoreLoading = true;
      })
      .addCase(restoreObjectAsync.fulfilled, (state) => {
        state.restoreLoading = false;
      })
      .addCase(restoreObjectAsync.rejected, (state, { payload }) => {
        state.restoreLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteObjectAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteObjectAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteObjectAsync.rejected, (state, { payload }) => {
        state.deleteLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteMultipleObjectAsync.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteMultipleObjectAsync.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteMultipleObjectAsync.rejected, (state, { payload }) => {
        state.deleteLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

//objects
export const selectShowObjectDrawer = (state: RootState) =>
  state.s3Object.showObjectDrawer;
export const selectSelectedObject = (state: RootState) =>
  state.s3Object.selectedObject;

//versions
export const selectObjectVersions = (state: RootState) =>
  state.s3Object.versions;
export const selectObjectVersionsLoading = (state: RootState) =>
  state.s3Object.versionsLoading;
export const selectSelectedVersionOfObject = (state: RootState) =>
  state.s3Object.selectedVersion;
export const selectShowObjectVersions = (state: RootState) =>
  state.s3Object.showVersionsModal;

//preview
export const selectShowObjectPreview = (state: RootState) =>
  state.s3Object.showObjectPreview;
export const selectObjectPreview = (state: RootState) =>
  state.s3Object.objectPreview;
export const selectObjectPreviewLoading = (state: RootState) =>
  state.s3Object.objectPreviewLoading;

//download
export const selectObjectDownloadLoading = (state: RootState) =>
  state.s3Object.objectDownloadLoading;

//restore
export const selectObjectRestoreLoading = (state: RootState) =>
  state.s3Object.restoreLoading;

//delete
export const selectObjectDeleteLoading = (state: RootState) =>
  state.s3Object.deleteLoading;

//metadata
export const selectObjectMetadata = (state: RootState) =>
  state.s3Object.metadata;
export const selectObjectMetadataLoading = (state: RootState) =>
  state.s3Object.metadataLoading;

//legal hold
export const selectShowObjectLegalHoldModal = (state: RootState) =>
  state.s3Object.showObjectLegalHoldModal;
export const selectObjectLegalHoldLoading = (state: RootState) =>
  state.s3Object.legalHoldLoading;

//retention policy
export const selectShowObjectRetentionPolicyModal = (state: RootState) =>
  state.s3Object.showObjectRetentionPolicyModal;
export const selectObjectRetentionPolicyLoading = (state: RootState) =>
  state.s3Object.retentionPolicyLoading;

//tags
export const selectShowObjectTagsModal = (state: RootState) =>
  state.s3Object.showObjectTagsModal;
export const selectObjectTagsLoading = (state: RootState) =>
  state.s3Object.tagsLoading;

//inspect
export const selectShowObjectInspectModal = (state: RootState) =>
  state.s3Object.showObjectInspectModal;
export const selectObjectInspectLoading = (state: RootState) =>
  state.s3Object.inspectLoading;

//share
export const selectShowObjectShareModal = (state: RootState) =>
  state.s3Object.showObjectShareModal;
export const selectObjectShareLoading = (state: RootState) =>
  state.s3Object.shareLoading;
export const selectObjectShareLink = (state: RootState) =>
  state.s3Object.shareLink;

export const {
  handleCleanUpObject,
  handleShowObjectDrawer,
  handleHideObjectDrawer,
  handleChangeSelectedVersion,
  handleShowObjectPreview,
  handleHideObjectPreview,
  handleShowObjectLegalHoldModal,
  handleHideObjectLegalHoldModal,
  handleShowObjectRetentionPolicyModal,
  handleHideObjectRetentionPolicyModal,
  handleShowObjectTagsModal,
  handleHideObjectTagsModal,
  handleShowObjectInspectModal,
  handleHideObjectInspectModal,
  handleShowObjectShareModal,
  handleHideObjectShareModal,
  handleShowObjectVersionsModal,
  handleHideObjectVersionsModal,
} = objectSlice.actions;
export default objectSlice.reducer;
