import { useAppSelector } from "../../../hooks";
import { IpfsNotActiveTab } from "../../layouts/NotData";
import { selectIpfsStatus } from "../../../store/ipfs/ipfsPublicSlice";
import { PlansTab } from "../../plans/UniversalPlansBox";

const IpfsPlansTab = () => {
  const ipfsServiceType = Number(process.env.REACT_APP_IPFS_SERVICE_TYPE) || 12;
  const ipfsStatus = useAppSelector(selectIpfsStatus);

  if (!ipfsStatus) {
    return <IpfsNotActiveTab />;
  }
  return (
    <>
      <PlansTab serviceType={ipfsServiceType} />
    </>
  );
};

export default IpfsPlansTab;
