import jwtAxios, { jwtEnvAxios } from ".";
import { InviteUserData } from "../types/team";

const GET_TEAMS_API_URL = `/teams`;
const INVITE_USER_API_URL = `/teams`;
const DELETE_TEAM_USER_API_URL = (id: number) => `/teams/${id}`;
const CHECK_INVITATION_API_URL = (env: string, token: string) =>
  `/environments/${env}/teams/${token}/validate`;
const ACCEPT_TEAM_INVITATION_API_URL = (env: string, token: string) =>
  `/environments/${env}/teams/${token}/accept-invitation`;

export function getTeamsApi() {
  return jwtEnvAxios().get(GET_TEAMS_API_URL);
}

export function inviteUserApi(data: InviteUserData[]) {
  return jwtEnvAxios().post(INVITE_USER_API_URL, data);
}

export function deleteTeamUserApi(id: number) {
  return jwtEnvAxios().delete(DELETE_TEAM_USER_API_URL(id));
}

export function checkInvitationApi(env: string, token: string) {
  return jwtAxios.get(CHECK_INVITATION_API_URL(env, token));
}

export function acceptTeamInvitationApi(env: string, token: string) {
  return jwtAxios.get(ACCEPT_TEAM_INVITATION_API_URL(env, token));
}
