import { useCallback, useRef } from "react";
import { useAppDispatch } from ".";
import {
  getS3EventsAsync,
  handleChangeEventActionLoading,
} from "../store/s3/eventsSlice";

function useGetS3Events() {
  let timeoutId = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();

  const checkAndDispatch = useCallback(
    (callback?: () => void) => {
      dispatch(getS3EventsAsync()).then((action) => {
        if (action.type === "s3-events/fulfilled") {
          dispatch(handleChangeEventActionLoading(false));
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
          callback && callback();
        } else {
          timeoutId.current = setTimeout(
            () => checkAndDispatch(callback),
            2000
          );
        }
      });
    },
    [dispatch]
  );

  return checkAndDispatch;
}

export default useGetS3Events;
