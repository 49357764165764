import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
  Chip,
} from "@mui/material";

// third-party
import * as Yup from "yup";
import lodash from "lodash";
import { ethers } from "ethers";

// project imports
import InputParameters from "./../workflows/inputs/InputParameters";
import CredentialInput from "./../workflows/inputs/CredentialInput";
import EditVariableDialog from "./../workflows/dialog/EditVariableDialog";

// Icons
import { ReactComponent as ExpandMoreIcon } from "./../../../assets/icons/arrow-down.svg";
import { ReactComponent as CheckIcon } from "./../../../assets/icons/check.svg";
import { ReactComponent as IconArrowUpRightCircle } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import { ReactComponent as IconX } from "./../../../assets/icons/close.svg";

// Const
import {
  contract_details,
  networks,
  networkExplorers,
} from "./../wallets/constant";

// utils
import {
  handleCredentialParams,
  initializeNodeData,
} from "./../../../utils/wfHelper";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createWorkflowContractAsync,
  // deleteWorkflowContractAsync,
  getWorkflowContractAsync,
  // handleClearContractsSlice,
  selectWorkflowContractsActionLoading,
  selectWorkflowSelectedContract,
  selectWorkflowSelectedContractLoading,
  updateWorkflowContractAsync,
} from "../../../store/workflows/contractsSlice";
import toast from "react-hot-toast";
import { ToastClasses } from "../../modals/alerts";
import { getWorkflowContractAbiApi } from "../../../apis/workflowsAPI";

import { IconBtnWrapper } from "../../general/Wrappers";
import {
  Typography,
  Tooltip,
  Input,
  Button,
  Flex,
  Alert,
  Modal,
} from "djuno-design";
import { handleClearWorkflow } from "../../../store/workflows/workflowSlice";

const ContractDialog = ({ show, dialogProps, onCancel, onConfirm }) => {
  const dispatch = useAppDispatch();

  const selectedContract = useAppSelector(selectWorkflowSelectedContract);
  const selectedContractLoading = useAppSelector(
    selectWorkflowSelectedContractLoading
  );

  const actionLoading = useAppSelector(selectWorkflowContractsActionLoading);

  const [contractDetails, setContractDetails] = useState(contract_details);
  const [contractData, setContractData] = useState({});
  const [contractParams, setContractParams] = useState([]);
  const [contractValues, setContractValues] = useState({});
  const [contractValidation, setContractValidation] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [invalidABI, setInvalidABI] = useState("");
  const [isReadyToAdd, setIsReadyToAdd] = useState(false);
  const [isEditVariableDialogOpen, setEditVariableDialog] = useState(false);
  const [editVariableDialogProps, setEditVariableDialogProps] = useState({});
  const contractParamsType = ["networks", "credentials", "contractInfo"];

  // console.log({ expanded });
  // console.log({ contractData });
  // console.log(contractParams);
  // console.log({ contractValues });

  const handleAccordionChange = (expanded) => (event, isExpanded) => {
    setExpanded(isExpanded ? expanded : false);
  };

  const reset = () => {
    setContractData({});
    setContractParams([]);
    setContractValues({});
    setContractValidation({});
    setInvalidAddress(false);
    setInvalidABI("");
    setIsReadyToAdd(false);
    setContractDetails(contract_details);
    setExpanded(false);
  };

  const checkIsReadyToAdd = () => {
    for (let i = 0; i < contractParamsType.length; i += 1) {
      const paramType = contractParamsType[i];
      if (!contractData[paramType] || !contractData[paramType].submit) {
        setIsReadyToAdd(false);
        return;
      }
    }
    setIsReadyToAdd(true);
  };

  const onEditVariableDialogOpen = (input, values, arrayItemBody) => {
    const dialogProps = {
      input,
      values,
      arrayItemBody,
      cancelButtonName: "Cancel",
      confirmButtonName: "Save",
      hideVariables: true,
    };

    setEditVariableDialogProps(dialogProps);
    setEditVariableDialog(true);
  };

  const addNewContract = async () => {
    const createNewContractBody = {
      network: contractData.networks.network,
      name: contractData.contractInfo.name,
      abi: contractData.contractInfo.abi,
      address: contractData.contractInfo.address,
      providerCredential: JSON.stringify(contractData.credentials),
    };
    dispatch(createWorkflowContractAsync(createNewContractBody)).then(
      (action) => {
        if (action.type === "workflows/contract/create/fulfilled") {
          toast.success("New contract added", { className: ToastClasses });
          handleClear();
          onConfirm();
        }
        // else {
        //   onCancel();
        // }
      }
    );
  };

  const saveContract = async () => {
    const saveContractBody = {
      network: contractData.networks.network,
      name: contractData.contractInfo.name,
      abi: contractData.contractInfo.abi,
      address: contractData.contractInfo.address,
      providerCredential: JSON.stringify(contractData.credentials),
    };
    dispatch(
      updateWorkflowContractAsync({
        id: dialogProps.id,
        body: saveContractBody,
      })
    ).then((action) => {
      if (action.type === "workflows/contract/update/fulfilled") {
        toast.success("Contract saved", { className: ToastClasses });
        handleClear();
        onConfirm();
      }
      // else {
      //   onCancel();
      // }
    });
  };

  const fetchABI = async (formValues, paramsType) => {
    const selectedNetwork = networks.find(
      (network) => network.name === contractData.networks.network
    );
    if (!selectedNetwork) return;

    const body = {
      ...contractData,
      networks: {
        ...contractData.networks,
        uri: selectedNetwork.uri || "",
      },
    };
    const resp = await getWorkflowContractAbiApi(body);
    if (!resp.data) {
      const updateContractData = {
        ...contractData,
        [paramsType]: { ...formValues, submit: null },
      };
      setContractData(updateContractData);
      setInvalidABI("Unable to fetch ABI");
      return;
    } else {
      const { status, result: abi } = resp.data.Result;
      if (status === "0") {
        setInvalidABI("Unable to fetch ABI");
        return;
      }
      setInvalidABI("");
      return abi === "Invalid API Key" ? undefined : abi;
    }
  };

  const valueChanged = (formValues, paramsType) => {
    const updateContractData = {
      ...contractData,
      [paramsType]: formValues,
    };

    const index = contractParamsType.indexOf(paramsType);
    if (index >= 0 && index !== contractParamsType.length - 1) {
      for (let i = index + 1; i < contractParamsType.length; i += 1) {
        const paramType = contractParamsType[i];
        if (updateContractData[paramType])
          updateContractData[paramType].submit = null;
      }
    }

    setContractData(updateContractData);
  };

  const paramsChanged = (formParams, paramsType) => {
    // Because formParams options can be changed due to show hide options,
    // To avoid that, replace with original details options

    const credentialMethodParam = formParams.find(
      (param) => param.name === "credentialMethod"
    );
    const credentialMethodParamIndex = formParams.findIndex(
      (param) => param.name === "credentialMethod"
    );

    if (credentialMethodParam !== undefined) {
      const originalParam = contractDetails[paramsType].find(
        (param) => param.name === "credentialMethod"
      );
      if (originalParam !== undefined) {
        formParams[credentialMethodParamIndex]["options"] =
          originalParam.options;
      }
    }

    const updateContractDetails = {
      ...contractDetails,
      [paramsType]: formParams,
    };
    setContractDetails(updateContractDetails);
  };

  const onSubmit = async (formValues, paramsType) => {
    if (formValues.address) {
      if (ethers.isAddress(formValues.address)) {
        setInvalidAddress(false);
        const abi = await fetchABI(formValues, paramsType);
        if (abi) {
          const updateFormValues = {
            submit: true,
            ...formValues,
          };
          updateFormValues.abi = abi;
          const updateContractData = {
            ...contractData,
            [paramsType]: updateFormValues,
          };
          setContractData(updateContractData);
        } else {
          const updateContractData = {
            ...contractData,
            [paramsType]: { ...formValues, submit: null },
          };
          setContractData(updateContractData);
        }
      } else {
        setInvalidAddress(true);
        const updateContractData = {
          ...contractData,
          [paramsType]: { ...formValues, submit: null },
        };
        setContractData(updateContractData);
      }
    } else {
      const updateContractData = {
        ...contractData,
        [paramsType]: formValues,
      };
      setContractData(updateContractData);
    }

    const index = contractParamsType.indexOf(paramsType);
    if (index >= 0 && index !== contractParamsType.length - 1) {
      setExpanded(contractParamsType[index + 1]);
    }
  };

  const showHideOptions = (displayType, options) => {
    let returnOptions = options;
    const toBeDeleteOptions = [];

    for (let i = 0; i < returnOptions.length; i += 1) {
      const option = returnOptions[i];
      const displayOptions = option[displayType];

      if (displayOptions) {
        Object.keys(displayOptions).forEach((path) => {
          const comparisonValue = displayOptions[path];
          const groundValue = lodash.get(contractData, path, "");
          if (Array.isArray(comparisonValue)) {
            if (
              displayType === "show" &&
              !comparisonValue.includes(groundValue)
            ) {
              toBeDeleteOptions.push(option);
            }
            if (
              displayType === "hide" &&
              comparisonValue.includes(groundValue)
            ) {
              toBeDeleteOptions.push(option);
            }
          }
        });
      }
    }

    for (let i = 0; i < toBeDeleteOptions.length; i += 1) {
      returnOptions = returnOptions.filter(
        (opt) => JSON.stringify(opt) !== JSON.stringify(toBeDeleteOptions[i])
      );
    }

    return returnOptions;
  };

  const displayOptions = (params) => {
    let clonedParams = params;

    for (let i = 0; i < clonedParams.length; i += 1) {
      const input = clonedParams[i];
      if (input.type === "options") {
        input.options = showHideOptions("show", input.options);
        input.options = showHideOptions("hide", input.options);
      }
    }

    return clonedParams;
  };

  const setYupValidation = (params) => {
    const validationSchema = {};
    for (let i = 0; i < params.length; i += 1) {
      const input = params[i];
      if (input.type === "string" && !input.optional) {
        validationSchema[input.name] = Yup.string().required(
          `${input.label} is required. Type: ${input.type}`
        );
      } else if (input.type === "number" && !input.optional) {
        validationSchema[input.name] = Yup.number().required(
          `${input.label} is required. Type: ${input.type}`
        );
      } else if (
        (input.type === "options" || input.type === "asyncOptions") &&
        !input.optional
      ) {
        validationSchema[input.name] = Yup.string().required(
          `${input.label} is required. Type: ${input.type}`
        );
      }
    }
    return validationSchema;
  };

  const initializeFormValuesAndParams = (paramsType) => {
    const initialValues = {};
    let contractParams = displayOptions(
      lodash.cloneDeep(contractDetails[paramsType] || [])
    );
    contractParams = handleCredentialParams(
      contractParams,
      paramsType,
      contractDetails[paramsType],
      contractData
    );
    for (let i = 0; i < contractParams.length; i += 1) {
      const input = contractParams[i];

      // Load from contractData values
      if (
        paramsType in contractData &&
        input.name in contractData[paramsType]
      ) {
        initialValues[input.name] = contractData[paramsType][input.name];

        // Check if option value is still available from the list of options
        if (input.type === "options") {
          const optionVal = input.options.find(
            (option) => option.name === initialValues[input.name]
          );
          if (!optionVal) delete initialValues[input.name];
        }
      } else {
        // Load from contractParams default values
        initialValues[input.name] = input.default || "";
      }
    }

    initialValues.submit = null;

    setContractValues(initialValues);
    setContractValidation(setYupValidation(contractParams));
    setContractParams(contractParams);
  };

  const transformContractResponse = (
    contractResponseData,
    _contractDetails
  ) => {
    const contractData = {
      networks: {},
      credentials: {},
      contractInfo: {},
    };

    if (contractResponseData) {
      contractData.networks = {
        network: contractResponseData.network,
        submit: true,
      };
      contractData.contractInfo = { ...contractResponseData, submit: true };
      if (contractResponseData.providerCredential) {
        try {
          contractData.credentials = JSON.parse(
            contractResponseData.providerCredential
          );
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      contractData.networks = initializeNodeData(_contractDetails.networks);
      contractData.credentials = initializeNodeData(
        _contractDetails.credentials
      );
      contractData.contractInfo = initializeNodeData(
        _contractDetails.contractInfo
      );
    }
    return contractData;
  };

  // Get Contract Details from API
  useEffect(() => {
    if (selectedContract) {
      const contractResponseData = selectedContract;
      setContractData(transformContractResponse(contractResponseData));
      setExpanded("networks");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContract]);

  // Initialization
  useEffect(() => {
    if (show && dialogProps.type === "ADD") {
      reset();
      setContractData(transformContractResponse(null, contractDetails));
      setExpanded("networks");
    } else if (show && dialogProps.type === "EDIT" && dialogProps.id) {
      reset();
      dispatch(getWorkflowContractAsync(dialogProps.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, dialogProps, dispatch]);

  // Initialize Parameters Initial Values & Validation
  useEffect(() => {
    if (contractDetails && contractData && expanded) {
      initializeFormValuesAndParams(expanded);
      checkIsReadyToAdd();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractDetails, contractData, expanded]);

  const handleClear = () => {
    dispatch(handleClearWorkflow());
    reset();
  };
  const handleClose = () => {
    handleClear();
    onCancel();
  };

  return (
    <Modal
      title={dialogProps.title}
      isOpen={show}
      onClose={handleClose}
      contentClassName="max-w-lg"
    >
      {dialogProps.type === "ADD" && (
        <Alert uiType="warning" showIcon className="mt-5">
          <Typography.Text className="!text-xs">
            You can only add contract which has been publicly verified
          </Typography.Text>
        </Alert>
      )}

      {contractData &&
        contractData.contractInfo &&
        contractData.contractInfo.address &&
        dialogProps.type === "EDIT" && (
          <Flex direction="col" className="mt-5 px-4">
            <Typography.Text className="!text-sm">Address</Typography.Text>
            <Flex items="center" className="gap-1 text-xs w-full">
              <Input
                containerClassName="flex-1"
                value={contractData.contractInfo.address}
                copyable
              />
              <Tooltip content="Open in Block Explorer">
                <IconBtnWrapper>
                  <IconArrowUpRightCircle
                    onClick={() =>
                      window.open(
                        `${
                          networkExplorers[contractData.networks.network]
                        }/address/${contractData.contractInfo.address}`,
                        "_blank"
                      )
                    }
                    className="w-4 h-4"
                  />
                </IconBtnWrapper>
              </Tooltip>
            </Flex>
          </Flex>
        )}

      {/* networks */}
      <Box>
        <Accordion
          expanded={expanded === "networks"}
          onChange={handleAccordionChange("networks")}
          sx={{ boxShadow: "none" }}
          className="bg-white dark:bg-[#161a1d]"
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
            }
            aria-controls="networks-content"
            id="networks-header"
          >
            <div className="flex items-center justify-between">
              <Typography.Text className="!text-sm !font-semibold">
                Networks
              </Typography.Text>
              {contractData &&
                contractData.networks &&
                contractData.networks.submit && (
                  <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                    <CheckIcon className="p-1 text-white" />
                  </div>
                )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <InputParameters
              paramsType="networks"
              params={contractParams}
              initialValues={contractValues}
              nodeParamsValidation={contractValidation}
              valueChanged={valueChanged}
              onSubmit={onSubmit}
              setVariableSelectorState={() => null}
              onEditVariableDialogOpen={onEditVariableDialogOpen}
            />
          </AccordionDetails>
        </Accordion>
        <Divider />
      </Box>

      {/* credentials */}
      <Box>
        <Accordion
          expanded={expanded === "credentials"}
          onChange={handleAccordionChange("credentials")}
          sx={{ boxShadow: "none" }}
          className="bg-white dark:bg-[#161a1d]"
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
            }
            aria-controls="credentials-content"
            id="credentials-header"
          >
            <div className="flex items-center justify-between">
              <Typography.Text className="!text-sm !font-semibold">
                Credentials
              </Typography.Text>
              {contractData &&
                contractData.credentials &&
                contractData.credentials.submit && (
                  <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                    <CheckIcon className="p-1 text-white" />
                  </div>
                )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <CredentialInput
              paramsType="credentials"
              initialParams={contractParams}
              initialValues={contractValues}
              initialValidation={contractValidation}
              valueChanged={valueChanged}
              paramsChanged={paramsChanged}
              onSubmit={onSubmit}
            />
          </AccordionDetails>
        </Accordion>
        <Divider />
      </Box>

      {/* contractInfo */}
      <Box>
        <Accordion
          expanded={expanded === "contractInfo"}
          onChange={handleAccordionChange("contractInfo")}
          sx={{ boxShadow: "none" }}
          className="bg-white dark:bg-[#161a1d]"
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon className="w-[15px] h-[15px] text-slate-700 dark:text-slate-300" />
            }
            aria-controls="contractInfo-content"
            id="contractInfo-header"
          >
            <div className="flex items-center justify-between">
              <Typography.Text className="!text-sm !font-semibold">
                Contract Details
              </Typography.Text>
              {contractData &&
                contractData.contractInfo &&
                contractData.contractInfo.submit && (
                  <div className="w-[18px] h-[18px] rounded-full bg-success flex justify-center items-center ml-1">
                    <CheckIcon className="p-1 text-white" />
                  </div>
                )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <InputParameters
              paramsType="contractInfo"
              params={contractParams}
              initialValues={contractValues}
              nodeParamsValidation={contractValidation}
              valueChanged={valueChanged}
              onSubmit={onSubmit}
              setVariableSelectorState={() => null}
              onEditVariableDialogOpen={onEditVariableDialogOpen}
            />
            <div className="flex w-full">
              {invalidAddress && (
                <Chip
                  sx={{ mt: 2, mb: 1 }}
                  icon={<IconX className="w-3 h-3 text-white" />}
                  label="Invalid Contract Address"
                  color="error"
                />
              )}
              {invalidABI && (
                <Chip
                  sx={{ mt: 2, mb: 1, ml: invalidAddress ? 2 : 0 }}
                  icon={<IconX className="w-3 h-3 text-white" />}
                  label={invalidABI}
                  color="error"
                />
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Divider />
      </Box>

      <EditVariableDialog
        key={JSON.stringify(editVariableDialogProps)}
        show={isEditVariableDialogOpen}
        dialogProps={editVariableDialogProps}
        onCancel={() => setEditVariableDialog(false)}
        onConfirm={(updateValues) => {
          valueChanged(updateValues, expanded);
          setEditVariableDialog(false);
        }}
      />

      <div className="mt-4 flex justify-end gap-2">
        <Button onClick={handleClose}>{dialogProps.cancelButtonName}</Button>
        <Button
          uiType="primary"
          disabled={!isReadyToAdd || selectedContractLoading}
          onClick={() => {
            dialogProps.type === "ADD" ? addNewContract() : saveContract();
          }}
          loading={actionLoading || selectedContractLoading}
        >
          {dialogProps.confirmButtonName}
        </Button>
      </div>
    </Modal>
  );
};

ContractDialog.propTypes = {
  show: PropTypes.bool,
  dialogProps: PropTypes.object,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ContractDialog;
