import { useNavigate, Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { useAppDispatch } from "./hooks";
import { syncState } from "./store/auth/authSlice";
import { ToastClasses } from "./components/modals/alerts";
import { DDProvider } from "djuno-design";
import TourProvider from "./providers/TourProvider";
import IntercomProvider from "./providers/IntercomProvider";

function App() {
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  useEffect(() => {
    dispatch(syncState());
  }, [dispatch]);

  return (
    <IntercomProvider>
      <DDProvider navigator={(url) => url && navigator(url)}>
        <TourProvider>
          <Outlet />
          <Toaster
            toastOptions={{
              className: ToastClasses,
            }}
          />
        </TourProvider>
      </DDProvider>
    </IntercomProvider>
  );
}

export default App;
