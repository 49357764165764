import { useCallback } from "react";
import { useAppDispatch } from ".";
import { handleSetConfettiStatus } from "../store/auth/authSlice";

function useConfitti() {
  const dispatch = useAppDispatch();

  const run = useCallback(() => {
    dispatch(handleSetConfettiStatus(true));
    setTimeout(() => {
      dispatch(handleSetConfettiStatus(false));
    }, 2600);
  }, [dispatch]);

  return { run } as const;
}

export default useConfitti;
