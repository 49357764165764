import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import { MarketApiLog, MarketApiOverviewType } from "../../types/market-api";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { getMarketApiRequestLogsApi } from "../../apis/marketApiAPI";

const initialState: MarketApiOverviewType = {
  logs: [],
  logsLoading: false,
};

export const getMarketApiRequestLogsAysnc = createAsyncThunk<
  {
    logs: Array<MarketApiLog>;
  },
  undefined,
  IThunkRejectValue
>(
  "market-api/request-logs",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getMarketApiRequestLogsApi();
      const logs = response.data.Result;
      return fulfillWithValue({ logs });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const marketApiOverviewSlice = createSlice({
  name: "marketApiOverview",
  initialState: initialState,
  reducers: {
    clearOverviewApiKeys(state) {
      state.logs = initialState.logs;
      state.logsLoading = initialState.logsLoading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarketApiRequestLogsAysnc.fulfilled, (state, action) => {
        state.logs = action.payload.logs;
        state.logsLoading = false;
      })
      .addCase(getMarketApiRequestLogsAysnc.pending, (state) => {
        state.logsLoading = true;
      })
      .addCase(getMarketApiRequestLogsAysnc.rejected, (state, { payload }) => {
        state.logsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectMarketApiRequestLogsLoading = (state: RootState) =>
  state.marketApiApiOverview.logsLoading;
export const selectMarketApiRequestLogs = (state: RootState) =>
  state.marketApiApiOverview.logs;

export const { clearOverviewApiKeys } = marketApiOverviewSlice.actions;

export default marketApiOverviewSlice.reducer;
