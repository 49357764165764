import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  handleAddToSelectedDestination,
  handleRemoveToSelectedDestination,
  selectSelectedDestinations,
} from "../../../../store/block-event/blockEventCreateSlice";
import {
  getDestinationsAsync,
  handleShowDestinationEditor,
  selectDestinations,
  selectDestinationsLoading,
} from "../../../../store/block-event/destinationsSlice";
import DestinationEditorModal from "../../destinations/DestinationEditorModal";
import { ReactComponent as PlusIcon } from "./../../../../assets/icons/plus.svg";
import {
  Button,
  Accordion,
  Typography,
  Switcher,
  Skeleton,
} from "djuno-design";

const SelectDestinationStep: React.FC<{ withoutHeader?: boolean }> = ({
  withoutHeader,
}) => {
  const destinations = useAppSelector(selectDestinations);
  const destinationsLoading = useAppSelector(selectDestinationsLoading);

  const selectedDestinations = useAppSelector(selectSelectedDestinations);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDestinationsAsync());
  }, [dispatch]);

  const DestinationsAccordion = () => {
    return (
      <>
        {" "}
        {destinationsLoading ? (
          <Skeleton />
        ) : (
          <Accordion
            items={[
              ...destinations.map((destination) => ({
                label: (
                  <div className="flex items-center justify-between w-full pr-5">
                    <Typography.Text>{destination.Name}</Typography.Text>
                    <Switcher
                      value={
                        selectedDestinations.find(
                          (sd) => sd.Id === destination.Id
                        ) !== undefined
                      }
                      onChange={(status) => {
                        if (status) {
                          dispatch(handleAddToSelectedDestination(destination));
                        } else {
                          dispatch(
                            handleRemoveToSelectedDestination(destination)
                          );
                        }
                      }}
                    />
                  </div>
                ),
                panel: (
                  <div>
                    <Typography.Text className="!text-sm">
                      {destination.To_url}
                    </Typography.Text>
                  </div>
                ),
              })),
            ]}
          />
        )}
      </>
    );
  };
  if (withoutHeader) return <DestinationsAccordion />;
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between gap-1 md:gap-2 w-full">
        <Typography.Text className="!font-medium">
          Select Destination
        </Typography.Text>
        <Button
          uiType="primary"
          onClick={() => dispatch(handleShowDestinationEditor({}))}
        >
          Create Destination
          <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
        </Button>
      </div>
      <div className="mt-5">
        <DestinationsAccordion />
      </div>
      <DestinationEditorModal />
    </div>
  );
};

export default SelectDestinationStep;
