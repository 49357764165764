import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  IWorkflowContract,
  WorkflowContractsState,
} from "../../types/workflows";
import {
  createWorkflowContractApi,
  deleteWorkflowContractApi,
  getWorkflowContractApi,
  getWorkflowContractsApi,
  updateWorkflowContractApi,
} from "../../apis/workflowsAPI";
import { changeWorkflowActiveStatus } from "./workflowsSlice";

const initialState: WorkflowContractsState = {
  contracts: [],
  loading: false,

  actionLoading: false,

  selectedContractLoading: false,
  selectedContract: null,
  // selectedWalletCredentialsLoading: false,
  // selectedWalletCredentials: null,
};

export const getWorkflowContractsAsync = createAsyncThunk<
  { contracts: Array<IWorkflowContract> },
  undefined,
  IThunkRejectValue
>(
  "workflows/contracts",
  async (_, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const response = await getWorkflowContractsApi();
      const { NodeStatus, Result: contracts } = response.data;

      dispatch(changeWorkflowActiveStatus(NodeStatus === 2));
      return fulfillWithValue({ contracts: contracts || [] });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWorkflowContractAsync = createAsyncThunk<
  { contract: IWorkflowContract },
  string,
  IThunkRejectValue
>("workflows/contract", async (id, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getWorkflowContractApi(id);
    const contract = response.data.Result;
    return fulfillWithValue({ contract });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

// export const getWorkflowWalletCredentialsAsync = createAsyncThunk<
//   { credentials: IWorkflowWalletCredentials },
//   string,
//   IThunkRejectValue
// >(
//   "workflows/wallet/credentials",
//   async (id, { rejectWithValue, fulfillWithValue }) => {
//     try {
//       const response = await getWorkflowWalletCredentialsApi(id);
//       const credentials = response.data.Result;
//       return fulfillWithValue({ credentials });
//     } catch (e) {
//       return rejectWithValue({ message: getExtractErrors(e) });
//     }
//   }
// );

export const createWorkflowContractAsync = createAsyncThunk<
  boolean,
  any,
  IThunkRejectValue
>("workflows/contract/create", async (body, { rejectWithValue }) => {
  try {
    await createWorkflowContractApi(body);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateWorkflowContractAsync = createAsyncThunk<
  boolean,
  { id: string; body: any },
  IThunkRejectValue
>("workflows/contract/update", async ({ id, body }, { rejectWithValue }) => {
  try {
    await updateWorkflowContractApi(id, body);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteWorkflowContractAsync = createAsyncThunk<
  boolean,
  string,
  IThunkRejectValue
>("workflows/contract/delete", async (id, { rejectWithValue }) => {
  try {
    await deleteWorkflowContractApi(id);
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const workflowContractsSlice = createSlice({
  name: "workflow-contracts",
  initialState,
  reducers: {
    handleClearContractsSlice: (state) => {
      // state.selectedWalletCredentials = null;
      // state.selectedWallet = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflowContractsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getWorkflowContractsAsync.fulfilled,
        (state, { payload: { contracts } }) => {
          state.contracts = contracts;
          state.loading = false;
        }
      )
      .addCase(getWorkflowContractsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWorkflowContractAsync.pending, (state) => {
        state.selectedContractLoading = true;
      })
      .addCase(
        getWorkflowContractAsync.fulfilled,
        (state, { payload: { contract } }) => {
          state.selectedContract = contract;
          state.selectedContractLoading = false;
        }
      )
      .addCase(getWorkflowContractAsync.rejected, (state, { payload }) => {
        state.selectedContractLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    // builder
    //   .addCase(getWorkflowWalletCredentialsAsync.pending, (state) => {
    //     state.selectedWalletCredentialsLoading = true;
    //   })
    //   .addCase(
    //     getWorkflowWalletCredentialsAsync.fulfilled,
    //     (state, { payload: { credentials } }) => {
    //       state.selectedWalletCredentials = credentials;
    //       state.selectedWalletCredentialsLoading = false;
    //     }
    //   )
    //   .addCase(
    //     getWorkflowWalletCredentialsAsync.rejected,
    //     (state, { payload }) => {
    //       state.selectedWalletCredentialsLoading = false;
    //       if (payload?.message)
    // toast.error(() => CustomErrorToast(payload?.message));
    //     }
    //   );

    builder
      .addCase(createWorkflowContractAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(createWorkflowContractAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(createWorkflowContractAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateWorkflowContractAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(updateWorkflowContractAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(updateWorkflowContractAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWorkflowContractAsync.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(deleteWorkflowContractAsync.fulfilled, (state) => {
        state.actionLoading = false;
      })
      .addCase(deleteWorkflowContractAsync.rejected, (state, { payload }) => {
        state.actionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWorkflowContracts = (state: RootState) =>
  state.workflowContracts.contracts;
export const selectWorkflowContractsLoading = (state: RootState) =>
  state.workflowContracts.loading;

export const selectWorkflowSelectedContract = (state: RootState) =>
  state.workflowContracts.selectedContract;
export const selectWorkflowSelectedContractLoading = (state: RootState) =>
  state.workflowContracts.selectedContractLoading;

// export const selectWorkflowSelectedWalletCredentials = (state: RootState) =>
//   state.workflowWallets.selectedWalletCredentials;
// export const selectWorkflowSelectedWalletCredentialsLoading = (
//   state: RootState
// ) => state.workflowWallets.selectedWalletCredentialsLoading;

export const selectWorkflowContractsActionLoading = (state: RootState) =>
  state.workflowContracts.actionLoading;

export const { handleClearContractsSlice } = workflowContractsSlice.actions;
export default workflowContractsSlice.reducer;
