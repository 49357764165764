import { jwtEnvAxios } from ".";
import { CreateMarketApiApiKeyData } from "../types/market-api";

const GET_MARKET_API_REQUEST_LOGS_URL = `/market-api/request-logs`;

const GET_MARKET_API_API_KEYS_URL = `/market-insight/api-settings`;
const DELETE_MARKET_API_API_KEYS_URL = (id: number) =>
  `/market-insight/api-settings/${id}`;
const CREATE_MARKET_API_API_KEYS_URL = `/market-insight/api-settings`;
const UPDATE_MARKET_API_API_KEYS_URL = (id: number) =>
  `/market-insight/api-settings/${id}`;

//overview
export function getMarketApiRequestLogsApi() {
  return jwtEnvAxios().get(GET_MARKET_API_REQUEST_LOGS_URL);
}

//api keys
export function getMarketApiApiKeysApi() {
  return jwtEnvAxios().get(GET_MARKET_API_API_KEYS_URL);
}

export function deleteMarketApiApiKeyApi(id: number) {
  return jwtEnvAxios().delete(DELETE_MARKET_API_API_KEYS_URL(id));
}

export async function createMarketApiApiKeyApi(
  formData: CreateMarketApiApiKeyData
) {
  return jwtEnvAxios().post(CREATE_MARKET_API_API_KEYS_URL, formData);
}

export function updateMarketApiApiKeyApi(
  id: number,
  formData: CreateMarketApiApiKeyData
) {
  return jwtEnvAxios().put(UPDATE_MARKET_API_API_KEYS_URL(id), formData);
}
