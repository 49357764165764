import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectEnvironmentCreateIsOpen,
  selectEnvironmentCreateLoading,
  closeEnvCreateModal,
  getEnvironmentsAsync,
  createEnvironmentAsync,
} from "../../store/environments/environmentsSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateEnvSchema } from "../../utils/validations";
import { Button, Input, Modal } from "djuno-design";

const EnvironmentCreateModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectEnvironmentCreateIsOpen);
  const loading = useAppSelector(selectEnvironmentCreateLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateEnvSchema),
  });

  const onSubmit = (data: any) => {
    if (!loading) {
      dispatch(
        createEnvironmentAsync({
          Name: data.name,
        })
      ).then((action) => {
        if (action.type === "environments/create/fulfilled") {
          reset();
          dispatch(closeEnvCreateModal());
          dispatch(getEnvironmentsAsync());
        }
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        dispatch(closeEnvCreateModal());
      }}
      contentClassName="max-w-lg"
      title="Create new environment"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <Input
            {...register("name")}
            error={errors.name?.message}
            placeholder="Environment name..."
          />
        </div>

        <div className="mt-4 flex justify-end">
          <Button
            uiType="primary"
            disabled={loading}
            type="submit"
            loading={loading}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EnvironmentCreateModal;
