import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import toast from "react-hot-toast";
import { BlockApiLog, BlockApiOverviewType } from "../../types/block-api";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import { getBlockApiRequestLogsApi } from "../../apis/blockApiAPI";

const initialState: BlockApiOverviewType = {
  logs: [],
  logsLoading: false,
};

export const getBlockApiRequestLogsAysnc = createAsyncThunk<
  {
    logs: Array<BlockApiLog>;
  },
  undefined,
  IThunkRejectValue
>(
  "block-api/request-logs",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getBlockApiRequestLogsApi();
      const logs = response.data.Result;
      return fulfillWithValue({ logs });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

const blockApiOverviewSlice = createSlice({
  name: "blockApiOverview",
  initialState: initialState,
  reducers: {
    clearOverviewApiKeys(state) {
      state.logs = initialState.logs;
      state.logsLoading = initialState.logsLoading;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlockApiRequestLogsAysnc.fulfilled, (state, action) => {
        state.logs = action.payload.logs;
        state.logsLoading = false;
      })
      .addCase(getBlockApiRequestLogsAysnc.pending, (state) => {
        state.logsLoading = true;
      })
      .addCase(getBlockApiRequestLogsAysnc.rejected, (state, { payload }) => {
        state.logsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectBlockApiRequestLogsLoading = (state: RootState) =>
  state.blockApiApiOverview.logsLoading;
export const selectBlockApiRequestLogs = (state: RootState) =>
  state.blockApiApiOverview.logs;

export const { clearOverviewApiKeys } = blockApiOverviewSlice.actions;

export default blockApiOverviewSlice.reducer;
