import { jwtEnvAxios } from ".";
import { CreateIpfsGatewayData } from "../types/ipfs-gateway";

const GET_IPFS_GET_WAYS_URL = `/ipfs/white-lists`;
const DELETE_IPFS_GET_WAY_URL = (id: number) => `/ipfs/white-lists/${id}`;
const CREATE_IPFS_GET_WAY_URL = `/ipfs/white-lists`;
const UPDATE_IPFS_GET_WAY_URL = (id: number) => `/ipfs/white-lists/${id}`;

export function getIpfsGatewaysApi() {
  return jwtEnvAxios().get(GET_IPFS_GET_WAYS_URL);
}

export function deleteIpfsGatewayApi(id: number) {
  return jwtEnvAxios().delete(DELETE_IPFS_GET_WAY_URL(id));
}

export async function createIpfsGatewayApi(
  formData: Array<CreateIpfsGatewayData>
) {
  return jwtEnvAxios().post(CREATE_IPFS_GET_WAY_URL, formData);
}

export function updateIpfsGatewayApi(
  id: number,
  formData: CreateIpfsGatewayData
) {
  return jwtEnvAxios().put(UPDATE_IPFS_GET_WAY_URL(id), formData);
}
