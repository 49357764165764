import {
  S3MetricsServer,
  S3MetricsWidget,
  S3MetricsWidgetTarget,
} from "../types/s3";
import { S3BucketObjectInfo } from "../types/s3-bucket";
import { formatTimestamp } from "./date";

export const objectIsFolder = (name: string) => {
  return name.endsWith("/");
};

export const getFolderName = (path: string): string | null => {
  const parts = path.split("/");
  parts.pop();
  const folderName = parts.pop();
  return folderName || null;
};
export const getBucketName = (path: string): string | null => {
  const parts = path.split("/");
  parts.pop();
  const bucketName = parts.pop();
  return bucketName || null;
};

export const getFileNameAndExt = (
  path?: string
): { fileName: string; fileExt: string; fullName: string } | null => {
  if (path) {
    const parts = path.split("/");
    const fileNameWithExt = parts.pop(); // Get the last part, which is the file name with extension

    if (fileNameWithExt) {
      const fileNameArray = fileNameWithExt.split(".");
      const fileExt = fileNameArray[fileNameArray.length - 1];
      fileNameArray.pop();
      return {
        fileName: fileNameArray.join("."),
        fileExt,
        fullName: fileNameWithExt,
      };
    } else {
      return null; // Return null if there is no file name with extension
    }
  }
  return null;
};

export const encodeUnicode = (str: string) => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      // return String.fromCharCode("0x" + p1);
      return String.fromCharCode(parseInt(p1, 16));
    })
  );
};

export const decodeUnicode = (str: string) => {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

export const getMainVersionOfObject = (
  versions: S3BucketObjectInfo[] | null
): S3BucketObjectInfo | undefined => {
  if (versions === null) return undefined;
  if (versions.length === 1) {
    return versions[0];
  } else {
    const v = versions.filter((v) => v.is_latest);
    return v[0];
  }
};

export const getObjectTotalVersionsSize = (
  versions: S3BucketObjectInfo[] | null
): number => {
  if (versions === null) return 0;
  const total_size = versions
    .map((i) => i.size)
    .filter((i) => typeof i === "number")
    .reduce(
      (accumulator: number, currentValue: number | undefined | "-") =>
        currentValue && currentValue !== "-" ? accumulator + currentValue : 0,
      0
    );
  return total_size;
};

export const getServersCountCalculator = (servers: S3MetricsServer[]) => {
  let onlineServersCount = 0;
  let offlineServersCount = 0;
  let onlineDrivesCount = 0;
  let offlineDrivesCount = 0;

  servers.forEach((server) => {
    server.state === "online" ? onlineServersCount++ : offlineServersCount++;
    server.drives.forEach((drive) => {
      drive.state === "ok" ? onlineDrivesCount++ : offlineDrivesCount++;
    });
  });

  return {
    onlineServersCount,
    offlineServersCount,
    onlineDrivesCount,
    offlineDrivesCount,
  };
};

export const getLastValueOfWidgetTarget = (
  targets?: S3MetricsWidgetTarget[] | null,
  legendFormat?: string
) => {
  let lastValue = [0, "0"];

  let target;
  if (legendFormat) {
    target = targets?.find((t) => t.legendFormat === legendFormat);
  } else {
    target = targets ? targets[0] : undefined;
  }

  if (target?.result) {
    if (target?.result[0].values) {
      const values = target?.result[0].values;
      lastValue = values[values.length - 1];
    }
  }
  return lastValue;
};

export const combineGraphLineData = (
  data: S3MetricsWidget | null,
  dateFormat: string = "MM/DD/YYYY HH:mm"
) => {
  const combinedData: Record<string, any>[] = [];
  if (
    data !== null &&
    data !== undefined &&
    data.targets &&
    data.targets.length > 0
  ) {
    data.targets.forEach((target, i) => {
      target.result?.forEach((res) => {
        res.values.forEach(([timestamp, value]) => {
          const time = formatTimestamp(timestamp, dateFormat).datetime;
          // Find existing entry or create a new one
          let entry = combinedData.find((item) => item.name === time);

          if (!entry) {
            entry = {
              name: time,
              timestamp,
            };
            combinedData.push(entry);
          }
          // Add data for the current line/server
          entry[replaceRegendFormat(target.legendFormat, res.metric)] =
            Number(value);
        });
      });
    });
  }
  // Iterate over the values array of each line data
  return combinedData;
};

export const combineObjectSizeBarData = (data: S3MetricsWidget | null) => {
  const combinedData: Record<string, any>[] = [];
  if (
    data !== null &&
    data !== undefined &&
    data.targets &&
    data.targets.length > 0
  ) {
    data.targets[0].result?.forEach((res) => {
      const values = res.values;
      const nameKey = res.metric?.range || "";

      const lastValue = values[values.length - 1] || [0, "0"];

      // Find existing entry or create a new one
      let entry = combinedData.find((item) => item?.nameKey === nameKey);

      if (!entry) {
        const [name, index] = createObjectSizeChartName(nameKey);
        entry = {
          name,
          nameKey,
          value: Number(lastValue[1]),
        };
        combinedData[index as number] = entry;
      }
    });
  }
  // Iterate over the values array of each line data
  return combinedData;
};

export function replaceRegendFormat(
  inputString?: string,
  variables?: Record<string, string | undefined>
): string {
  if (!inputString || !variables) {
    return inputString || ""; // Return an empty string if either inputString or variables is undefined
  }

  const regex = /{{(.*?)}}/g;

  const replacedString = inputString.replace(regex, (match, p1) => {
    const variableNames: string[] = p1
      .split(",")
      .map((varName: string) => varName.trim());
    const variableValues: (string | undefined)[] = variableNames.map(
      (varName) => variables[varName]
    );
    const areAllVariablesDefined = variableValues.every(
      (value) => value !== undefined
    );

    return areAllVariablesDefined ? variableValues.join(", ") : match;
  });

  return replacedString;
}

export const getGraphLineColor = (index: number) => {
  const colors = [
    "rgb(196, 212, 233)",
    "rgb(220, 209, 238)",
    "rgb(209, 238, 231)",
    "rgb(238, 222, 209)",
    "rgb(170, 243, 143)",
    "rgb(249, 230, 197)",
    "rgb(200, 59, 81)",
    "rgb(244, 206, 206)",
    "rgb(214, 214, 214)",
    "rgb(99, 59, 84)",
    "rgb(81, 139, 84)",
    "rgb(161, 251, 84)",
    "rgb(32, 107, 84)",
    "rgb(245, 251, 84)",
    "rgb(124, 27, 84)",
    "rgb(81, 139, 84)",
    "rgb(81, 139, 84)",
    "rgb(160, 43, 84)",
    "rgb(124, 27, 84)",
    "rgb(160, 43, 84)",
    "rgb(160, 43, 84)",
    "rgb(89, 91, 84)",
    "rgb(160, 43, 84)",
    "rgb(99, 59, 84)",
    "rgb(99, 59, 84)",
    "rgb(81, 139, 84)",
    "rgb(161, 251, 84)",
    "rgb(32, 107, 84)",
    "rgb(160, 43, 84)",
    "rgb(124, 27, 84)",
    "rgb(53, 139, 84)",
    "rgb(81, 139, 84)",
    "rgb(81, 139, 84)",
    "rgb(160, 43, 84)",
    "rgb(124, 27, 84)",
    "rgb(160, 43, 84)",
    "rgb(160, 43, 84)",
    "rgb(160, 43, 84)",
    "rgb(89, 91, 84)",
    "rgb(99, 59, 84)",
    "rgb(99, 59, 84)",
    "rgb(122, 139, 84)",
    "rgb(160, 43, 84)",
    "rgb(99, 59, 84)",
    "rgb(81, 139, 84)",
    "rgb(81, 139, 84)",
    "rgb(32, 107, 84)",
    "rgb(245, 251, 84)",
    "rgb(124, 27, 84)",
  ];
  const colorsLen = colors.length;

  if (index <= colorsLen - 1) {
    return colors[index];
  } else {
    return colors[index - colorsLen];
  }
};

const createObjectSizeChartName = (nameKey: string) => {
  switch (nameKey) {
    case "BETWEEN_1024_B_AND_1_MB":
      return ["Between 1024B and 1MB", 1];
    case "BETWEEN_10_MB_AND_64_MB":
      return ["Between 10MB and 64MB", 3];
    case "BETWEEN_128_MB_AND_512_MB":
      return ["Between 128MB and 512MB", 5];
    case "BETWEEN_1_MB_AND_10_MB":
      return ["Between 1MB and 10MB", 2];
    case "BETWEEN_64_MB_AND_128_MB":
      return ["Between 64MB and 128MB", 4];
    case "GREATER_THAN_512_MB":
      return ["Greater than 512MB", 6];
    case "LESS_THAN_1024_B":
      return ["Less than 1024B", 0];
    default:
      return ["...", 100];
  }
};
