import { Outlet } from "react-router-dom";
import {
  WalletApiApiKeysUrl,
  WalletApiOverviewUrl,
  WalletApiPlansUrl,
} from "../../utils/urls";
import { Helmet } from "react-helmet";
import WalletApiApiKeysTab from "../../components/wallet-api/api_keys/WalletApiApiKeysTab";
import WalletApiOverviewTab from "../../components/wallet-api/oweview/WalletApiOverviewTab";
import WalletApiPlansTab from "../../components/wallet-api/plans/WalletApiPlansTab";
import { ReactComponent as ComputerIcon } from "./../../assets/icons/computer-desktop.svg";
import { ReactComponent as KeyIcon } from "./../../assets/icons/key.svg";
import { ReactComponent as ListBulletIcon } from "./../../assets/icons/list-bullet.svg";
import { Tabs, Typography, TabOptions } from "djuno-design";
import useServiceType from "../../hooks/useServiceType";

export const WalletApiPageMenus: TabOptions = [
  {
    label: (
      <div className="flex items-center gap-1">
        <ComputerIcon className="w-3" />
        Overview
      </div>
    ),
    url: WalletApiOverviewUrl,
    element: <WalletApiOverviewTab />,
    testId: "1th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <KeyIcon className="w-3" />
        Access Keys
      </div>
    ),
    url: WalletApiApiKeysUrl,
    element: <WalletApiApiKeysTab />,
    testId: "2th-tab",
  },
  {
    label: (
      <div className="flex items-center gap-1">
        <ListBulletIcon className="w-3" />
        Plans
      </div>
    ),
    url: WalletApiPlansUrl,
    element: <WalletApiPlansTab />,
    testId: "3th-tab",
  },
];

const WalletApiPage = () => {
  const { serviceType } = useServiceType(
    process.env.REACT_APP_WALLET_API_SERVICE_TYPE
  );
  return (
    <>
      <Helmet>
        <title>
          {process.env.REACT_APP_NAME} | {serviceType?.ServiceName || ""}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex items-center justify-between h-20">
        <div className="px-6 items-center justify-between flex flex-1 transition duration-150">
          <Typography.Title level={5} className="!mb-0">
            {serviceType?.ServiceName}
          </Typography.Title>
        </div>
        {/* <div className="mr-6 w-96"></div> */}
      </div>
      <div className="mx-6">
        <Tabs
          options={WalletApiPageMenus.map((o) => ({
            label: o.label,
            url: o.url,
            testId: o.testId,
          }))}
        />
        <div className="py-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default WalletApiPage;
