import { useNavigate } from "react-router-dom";
import WebBanner from "../../components/website/WebBanner";
import Container from "../../components/layouts/Container";
import { NotExist } from "../../components/layouts/NotData";

const WebNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <WebBanner title="Page Not Found (404)" />

      <Container>
        <div className="grid grid-cols-12 gap-8 mt-32 mb-32">
          <div className="col-span-12 ">
            <div className="text-center mt-6">
              <div className="bg-primary-50 shadow-lg rounded-lg p-8 dark:bg-dark-2">
                <NotExist
                  url={() => navigate("/")}
                  text="The page you are looking for doesn't exist or other error occurred. "
                  buttonText="Go to Home Page"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default WebNotFound;
