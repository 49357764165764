import { useEffect } from "react";
import {
  getBucketPoliciesAsync,
  getBucketUsersAsync,
  selectBucketDetails,
  selectBucketDetailsLoading,
  // selectBucketDetailsLoading,
  selectBucketPolicies,
  selectBucketPoliciesLoading,
  selectBucketUsers,
  selectBucketUsersLoading,
} from "../../../../store/s3/buckets/bucketSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { S3NotActiveTab } from "../../../layouts/NotData";
import { selectS3Status } from "../../../../store/s3/s3PublicSlice";
import { Accordion, TabOption, Tabs } from "djuno-design";

const S3AccessLogsPoliciesTab = () => {
  const bucketPolicies = useAppSelector(selectBucketPolicies);
  const loading = useAppSelector(selectBucketPoliciesLoading);
  return (
    <Accordion
      items={bucketPolicies.map((bp) => ({
        label: bp.name,
        panel: <div className="text-sm">{bp.policy}</div>,
      }))}
      loading={loading}
    />
  );
};

const S3AccessLogsUsersTab = () => {
  const bucketUsers = useAppSelector(selectBucketUsers);
  const loading = useAppSelector(selectBucketUsersLoading);
  return (
    <Accordion
      items={bucketUsers.map((bu) => ({
        label: bu,
      }))}
      loading={loading}
    />
  );
};

const tabOptions: TabOption[] = [
  {
    label: "Policies",
    element: <S3AccessLogsPoliciesTab />,
  },
  {
    label: "Users",
    element: <S3AccessLogsUsersTab />,
  },
];
const S3BucketAccessLogsTab = () => {
  const s3Status = useAppSelector(selectS3Status);
  const bucketDetails = useAppSelector(selectBucketDetails);
  const bucketDetailsLoading = useAppSelector(selectBucketDetailsLoading);

  // const bucketPolicies = useAppSelector(selectBucketPolicies);
  // const bucketUsers = useAppSelector(selectBucketUsers);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketPoliciesAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, bucketDetailsLoading, dispatch]);

  useEffect(() => {
    if (bucketDetails && !bucketDetailsLoading) {
      dispatch(getBucketUsersAsync({ name: bucketDetails.name }));
    }
  }, [bucketDetails, bucketDetailsLoading, dispatch]);

  if (!s3Status) {
    return <S3NotActiveTab />;
  }
  return (
    <>
      <div className="w-full">
        <div className="flex flex-col">
          <div className="text-slate-800 dark:text-slate-100">
            <Tabs
              listClassName="max-w-xs mb-6"
              options={tabOptions}
              tabType="creamy"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default S3BucketAccessLogsTab;
