import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IThunkRejectValue, RootState } from "../../types";
import { isAxiosError } from "axios";
import toast from "react-hot-toast";
import { ToastClasses } from "../../components/modals/alerts";
import {
  Environment,
  EnvironmentCreateData,
  EnvironmentState,
} from "../../types/environment";
import {
  getEnvironmentsApi,
  getEnvironmentApi,
  createEnvironmentApi,
} from "../../apis/environmentAPI";
import { getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";

const initialState: EnvironmentState = {
  environments: [],
  loading: false,
  selectModalIsOpen: false,
  selectModalIsClosable: true,
  createModalIsOpen: false,
  createLoading: false,
};

export const getEnvironmentsAsync = createAsyncThunk<
  { environments: Environment[] },
  undefined,
  IThunkRejectValue
>("environments", async (_, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await getEnvironmentsApi();
    const environments = response.data.Result;

    return fulfillWithValue({ environments });
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const getEnvironmentAsync = createAsyncThunk<
  { environment: Environment },
  { id: number },
  IThunkRejectValue
>(
  "environments/getOne",
  async ({ id }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getEnvironmentApi(id);
      const environment = response.data.Result;

      return fulfillWithValue({ environment });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createEnvironmentAsync = createAsyncThunk<
  any,
  EnvironmentCreateData,
  IThunkRejectValue
>("environments/create", async (data, { rejectWithValue }) => {
  try {
    const response = await createEnvironmentApi(data);
    return response.data;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const environmentsSlice = createSlice({
  name: "environment",
  initialState,
  reducers: {
    openEnvSelectionModal: (
      state,
      action: PayloadAction<{ closable?: boolean } | undefined>
    ) => {
      state.selectModalIsOpen = true;
      state.selectModalIsClosable =
        typeof action.payload !== "undefined"
          ? typeof action.payload.closable !== "undefined"
            ? action.payload.closable
            : true
          : true;
    },
    closeEnvSelectionModal: (state) => {
      state.selectModalIsOpen = false;
    },
    openEnvCreateModal: (state) => {
      state.createModalIsOpen = true;
    },
    closeEnvCreateModal: (state) => {
      state.createModalIsOpen = false;
    },
    handleSetEnvironments: (
      state,
      action: PayloadAction<{ environments: Array<Environment> }>
    ) => {
      state.environments = action.payload.environments;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnvironmentsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEnvironmentsAsync.fulfilled, (state, action) => {
        const { environments } = action.payload;
        state.environments = environments;
        state.loading = false;
      })
      .addCase(getEnvironmentsAsync.rejected, (state, { payload }) => {
        state.loading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createEnvironmentAsync.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(createEnvironmentAsync.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(createEnvironmentAsync.rejected, (state, { payload }) => {
        state.createLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectEnvironments = (state: RootState) =>
  state.environments.environments;
export const selectEnvironmentsLoading = (state: RootState) =>
  state.environments.loading;

export const selectEnvironmentCreateLoading = (state: RootState) =>
  state.environments.createLoading;

export const selectEnvironmentCreateIsOpen = (state: RootState) =>
  state.environments.createModalIsOpen;

export const selectEnvironmentSelectIsOpen = (state: RootState) =>
  state.environments.selectModalIsOpen;
export const selectEnvironmentSelectIsClosable = (state: RootState) =>
  state.environments.selectModalIsClosable;

export const {
  openEnvSelectionModal,
  closeEnvSelectionModal,
  openEnvCreateModal,
  closeEnvCreateModal,
  handleSetEnvironments,
} = environmentsSlice.actions;
export default environmentsSlice.reducer;
