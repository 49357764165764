import { jwtEnvAxios } from "..";
import { S3UpdateReplication } from "../../types/s3-replication";

const GET_REPLICATIONS_API_URL = (bucketName: string) =>
  `/buckets/${bucketName}/replication`;
const GET_ONE_REPLICATIONS_API_URL = (bucketName: string, id: string) =>
  `/buckets/${bucketName}/replication/${id}`;
const DELETE_REPLICATIONS_API_URL = (bucketName: string, id: string) =>
  `/buckets/${bucketName}/replication/${id}`;
const UPDATE_REPLICATIONS_API_URL = (bucketName: string, id: string) =>
  `/buckets/${bucketName}/replication/${id}`;
const CREATE_REPLICATIONS_API_URL = `/buckets-replication`;

export function getBucketReplicatiosApi(name: string) {
  return jwtEnvAxios("s3").get(GET_REPLICATIONS_API_URL(name));
}

export function getOneBucketReplicatiosApi(name: string, id: string) {
  return jwtEnvAxios("s3").get(GET_ONE_REPLICATIONS_API_URL(name, id));
}

export function deleteBucketReplicationsApi(name: string, id: string) {
  return jwtEnvAxios("s3").delete(DELETE_REPLICATIONS_API_URL(name, id));
}

export function createBucketReplicationsApi(data: any) {
  return jwtEnvAxios("s3").post(CREATE_REPLICATIONS_API_URL, data);
}

export function updateBucketReplicationsApi(
  name: string,
  data: S3UpdateReplication,
  id: string
) {
  return jwtEnvAxios("s3").put(UPDATE_REPLICATIONS_API_URL(name, id), data);
}
