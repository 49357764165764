import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as EditIcon } from "./../../../assets/icons/pencil-square.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import Web3ApiKeyEditorModal from "./Web3ApiKeyEditorModal";
import { DeleteModal } from "../../modals/QuestionModal";
import { useSearch } from "../../../providers/SearchProvider";
import {
  deleteWeb3AuthEndpointApiKeyAsync,
  getWeb3AuthEndpointApiKeysAsync,
  handleShowApiKeyEditor,
  selectWeb3AuthEndpoint,
  selectWeb3AuthEndpointActionLoading,
  selectWeb3AuthEndpointApiKeys,
  selectWeb3AuthEndpointApiKeysLoading,
  selectWeb3AuthEndpointLoading,
} from "../../../store/web3-auth/web3AuthEndpointSlice";
import { Web3AuthApiKey } from "../../../types/web3-auth";
import {
  Button,
  SecureCopy,
  SimpleTable,
  Dropdown,
  EmptyState,
} from "djuno-design";
import HighlighterText from "../../general/HighlighterText";

const Web3AuthEndpointApiKeysTab = () => {
  const endpoint = useAppSelector(selectWeb3AuthEndpoint);
  const endpointLoading = useAppSelector(selectWeb3AuthEndpointLoading);
  const [deleteApiKeyId, setDeleteApiKeyId] = useState<number | null>(null);
  const apiKeys = useAppSelector(selectWeb3AuthEndpointApiKeys);
  const [filteredApiKeys, setFilteredApiKeys] = useState<Web3AuthApiKey[]>([]);
  const loading = useAppSelector(selectWeb3AuthEndpointApiKeysLoading);
  const actionLoading = useAppSelector(selectWeb3AuthEndpointActionLoading);
  const dispatch = useAppDispatch();

  const { value: searchValue } = useSearch();

  useEffect(() => {
    if (endpoint) {
      dispatch(getWeb3AuthEndpointApiKeysAsync(endpoint.Id.toString()));
    }
  }, [dispatch, endpoint]);

  useEffect(() => {
    const lookedUpFiles = apiKeys?.filter((apiKey) =>
      apiKey.Label?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredApiKeys(lookedUpFiles);
  }, [dispatch, searchValue, apiKeys]);

  return (
    <>
      <div className="flex transition-all duration-500">
        <div className="text-slate-800 dark:text-slate-100">Access keys</div>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          <Button
            uiType="primary"
            onClick={() => dispatch(handleShowApiKeyEditor({}))}
          >
            Create New
          </Button>
        </div>
      </div>
      <div className="mt-6">
        <SimpleTable
          loading={loading || endpointLoading}
          containerClassName="min-h-[240px]"
        >
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Label" />
              <SimpleTable.TH lable="Value" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {filteredApiKeys.map((apiKey, index) => (
              <SimpleTable.Row key={index}>
                <SimpleTable.TD className="w-36">
                  <HighlighterText
                    searchValue={searchValue}
                    textToHighlight={apiKey.Label}
                  />
                </SimpleTable.TD>
                <SimpleTable.TD>
                  <SecureCopy
                    text={apiKey.KeyValue}
                    type="hide"
                    textClassName="!text-sm"
                    uiSize="small"
                  />
                </SimpleTable.TD>
                <SimpleTable.TD className="w-10">
                  <div className="h-full w-full inline-flex items-center justify-end gap-1 px-4">
                    <div className="w-8 flex justify-center items-center">
                      <Dropdown
                        anchor="bottom end"
                        itemsClassName="!w-40"
                        menu={[
                          {
                            key: "1",
                            label: (
                              <div className="flex items-center gap-1">
                                <EditIcon className="w-4" />
                                Edit
                              </div>
                            ),
                            onClick: (_, close) => {
                              close();
                              dispatch(handleShowApiKeyEditor({ apiKey }));
                            },
                          },
                          {
                            type: "divider",
                          },
                          {
                            key: "end",
                            label: (
                              <div className="flex items-center gap-1">
                                <ArchiveIcon className="w-4" />
                                Delete
                              </div>
                            ),
                            danger: true,
                            onClick: () => {
                              setDeleteApiKeyId(apiKey.Id);
                            },
                          },
                        ]}
                      >
                        <Button uiSize="small" uiType="icon" className="!px-2">
                          <MoreIcon className="w-4 h-4" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {apiKeys.length === 0 && (
              <SimpleTable.Row withoutHoverStyle className="h-[200px]">
                <SimpleTable.TD colSpan={3} className="!border-0">
                  <EmptyState />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <Web3ApiKeyEditorModal />

      <DeleteModal
        title="Delete Access key"
        isOpen={deleteApiKeyId !== null}
        onClose={() => setDeleteApiKeyId(null)}
        description=""
        confirmButtonText="Confirm"
        confirmButtonType="danger"
        confirmButtonClassName="w-full"
        onConfirm={() => {
          deleteApiKeyId &&
            endpoint &&
            dispatch(
              deleteWeb3AuthEndpointApiKeyAsync({
                endpointId: endpoint.Id,
                id: deleteApiKeyId,
              })
            ).then((action) => {
              if (
                action.type === "web3-auth/endpoint/api-keys/delete/fulfilled"
              ) {
                dispatch(
                  getWeb3AuthEndpointApiKeysAsync(endpoint.Id.toString())
                );
              }
              setDeleteApiKeyId(null);
            });
        }}
        loading={actionLoading}
        // confirmString={apiKeys.find((api) => api.Id === deleteApiKeyId)?.Label}
      />
    </>
  );
};

export default Web3AuthEndpointApiKeysTab;
