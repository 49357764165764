import { EventDestinationNames } from "../types/s3-events";
import { TierTypes } from "../types/s3-tiers";

//auth
export const SignUpUrl = `/auth/register`;
export const SignInUrl = `/auth/login`;
export const ForgotPasswordUrl = `/auth/forgot-password`;
export const ResetPasswordUrl = (t: string) => `/auth/reset-password/${t}`;

//endpoints
export const RpcEndpointsUrl = `/rpc-endpoints/endpoints`;
export const RpcEndpointCreateUrl = `/rpc-endpoints/create`;
export const RpcEndpontGetStartedUrl = (id: number) => `/rpc-endpoints/${id}`;
export const RpcEndpontMetricsUrl = (id: number) =>
  `/rpc-endpoints/${id}/metrics`;
export const RpcEndpontSecurityUrl = (id: number) =>
  `/rpc-endpoints/${id}/security`;
export const RpcEndpointsPlansUrl = `/rpc-endpoints/plans`;

//web apps
export const WebAppsUrl = `/web-apps`;
export const WebAppCreateUrl = `/web-apps/create`;
export const WebAppUrl = (id?: string) => `/web-apps/${id}`;
export const WebAppLogsUrl = (id?: string) => `/web-apps/${id}/logs`;
export const WebAppDisksUrl = (id?: string) => `/web-apps/${id}/disks`;
export const WebAppEnvUrl = (id?: string) => `/web-apps/${id}/env`;
export const WebAppShellUrl = (id?: string) => `/web-apps/${id}/shell`;
export const WebAppPreviewsUrl = (id?: string) => `/web-apps/${id}/previews`;
export const WebAppJobsUrl = (id?: string) => `/web-apps/${id}/jobs`;
export const WebAppSharingUrl = (id?: string) => `/web-apps/${id}/sharing`;
export const WebAppMetricsUrl = (id?: string) => `/web-apps/${id}/metrics`;
export const WebAppScalingUrl = (id?: string) => `/web-apps/${id}/scaling`;
export const WebAppSettingsUrl = (id?: string) => `/web-apps/${id}/settings`;
export const WebAppPlanUrl = (id?: string) => `/web-apps/${id}/plan`;
export const WebAppDeployUrl = (id?: string, deployId?: string) =>
  `/web-apps/${id}/deploys/${deployId}`;

//workflows
export const WorkflowsUrl = `/workflow-studio/workflows`;
export const WorkflowUrl = (id?: string) =>
  `/workflow-studio/canvas${id ? `/${id}` : ""}`;
export const WorkflowContractsUrl = `/workflow-studio/contracts`;
export const WorkflowWalletsUrl = `/workflow-studio/wallets`;
export const WorkflowApiKeysUrl = `/workflow-studio/api-keys`;
export const WorkflowPlanesUrl = `/workflow-studio/planes`;

//web3 auth
export const Web3AuthEndpointsUrl = `/web3-auth/endpoints`;
export const Web3EndpoindUsersUrl = (id?: string) => `/web3-auth/${id}`;
export const Web3EndpoindSettingUrl = (id?: string) =>
  `/web3-auth/${id}/setting`;
export const Web3EndpoindApiKeysUrl = (id?: string) =>
  `/web3-auth/${id}/api-keys`;
export const Web3AuthPlansUrl = `/web3-auth/plans`;

//wallet api
export const WalletApiUrl = `/wallet-api`;
export const WalletApiOverviewUrl = `/wallet-api/overview`;
export const WalletApiApiKeysUrl = `/wallet-api/api-keys`;
export const WalletApiPlansUrl = `/wallet-api/plans`;

//nft api
export const NftApiUrl = `/nft-api`;
export const NftApiOverviewUrl = `/nft-api/overview`;
export const NftApiApiKeysUrl = `/nft-api/api-keys`;
export const NftApiPlansUrl = `/nft-api/plans`;

//market api
export const MarketApiUrl = `/market-api`;
export const MarketApiOverviewUrl = `/market-api/overview`;
export const MarketApiApiKeysUrl = `/market-api/api-keys`;
export const MarketApiPlansUrl = `/market-api/plans`;

//block api
export const BlockApiUrl = `/block-api`;
export const BlockApiOverviewUrl = `/block-api/overview`;
export const BlockApiApiKeysUrl = `/block-api/api-keys`;
export const BlockApiPlansUrl = `/block-api/plans`;

//block event
export const BlockEventNotificationsUrl = `/block-events`;
export const BlockEventDestinationsUrl = `/block-events/destinations`;
export const BlockEventDestinationUrl = (destId: string) =>
  `/block-events/destinations/${destId}`;
export const BlockEventCreateUrl = `/block-events/create`;
export const BlockEventUrl = (blockEventId: string) =>
  `/block-events/${blockEventId}/details`;
export const BlockEventPlansUrl = `/block-events/plans`;

//ipfs
export const IpfsOverviewUrl = `/ipfs/overview`;
export const IpfsFilesUrl = `/ipfs/files`;
export const IpfsGatewaysUrl = `/ipfs/gateways`;
export const IpfsApiKeysUrl = `/ipfs/api-keys`;
export const IpfsSettingsUrl = `/ipfs/settings`;
export const IpfsPlansUrl = `/ipfs/plans`;

//s3
export const S3OverviewUrl = `/s3`;
export const S3BucketsUrl = `/s3/buckets`;
export const S3AccessKeysUrl = `/s3/access-keys`;
export const S3EventsUrl = `/s3/events`;
export const S3LogsUrl = `/s3/logs`;
export const S3SettingsUrl = `/s3/settings`;
export const S3BucketUrl = (name: string) => `/s3/buckets/${name}`;
export const S3BucketSettingUrl = (name: string) =>
  `/s3/buckets/${name}/settings`;
export const S3BucketCreateUrl = `/s3/bucket/create`;
export const S3EventAddUrl = `/s3/event/add`;
export const S3EventAddFormUrl = (destination: EventDestinationNames) =>
  `/s3/event/add/${destination}`;
export const S3TiersUrl = `/s3/tiers`;
export const S3TierAddUrl = `/s3/tier/add`;
export const S3TierAddFormUrl = (type: TierTypes) => `/s3/tier/add/${type}`;
export const S3PlansUrl = `/s3/plans`;

//database
export const DatabaseServicesUrl = `/database/services`;
export const DatabaseServicesPlansUrl = `/database/plans`;
export const DatabaseServiceCreateUrl = `/database/create`;
export const DatabaseServiceGeneralInformationUrl = (id: string) =>
  `/database/${id}`;
export const DatabaseUsersUrl = (id: string) => `/database/${id}/users`;
export const DatabaseDatabasesUrl = (id: string) => `/database/${id}/databases`;
export const DatabaseUsersCreateUrl = (id: string) =>
  `/database/${id}/users/create`;
export const DatabaseUsersEditUrl = (id: string, userId: string) =>
  `/database/${id}/users/${userId}`;
export const DatabaseAuthorisedIPsUrl = (id: string) =>
  `/database/${id}/allowed-ips`;
export const DatabasePoolsUrl = (id: string) => `/database/${id}/pools`;
export const DatabaseLogsUrl = (id: string) => `/database/${id}/logs`;
export const DatabaseMetricsUrl = (id: string) => `/database/${id}/metrics`;
export const DatabaseQueryStatisticsUrl = (id: string) =>
  `/database/${id}/queryStatistics`;
export const DatabaseCurrentQueriesUrl = (id: string) =>
  `/database/${id}/current-queries`;
export const DatabaseServiceIntegrationsUrl = (id: string) =>
  `/database/${id}/service-integrations`;
export const DatabaseServiceAdvancedConfigurationsUrl = (id: string) =>
  `/database/${id}/advanced-configurations`;
export const DatabaseUpgradeNodeUrl = (id: string) =>
  `/database/${id}/upgrade-node`;
export const DatabaseUpgradePlanUrl = (id: string) =>
  `/database/${id}/upgrade-plan`;
export const DatabaseUpgradeStorageUrl = (id: string) =>
  `/database/${id}/upgrade-storage`;
export const DatabaseBackupsUrl = (id: string) => `/database/${id}/backup`;
export const DatabaseBackupsDuplicateUrl = (id: string) =>
  `/database/${id}/backup/fork`;
export const DatabaseServiceNamespacesUrl = (id: string) =>
  `/database/${id}/namespace`;
export const DatabaseServiceNamespacesCreateUrl = (id: string) =>
  `/database/${id}/namespace/create`;

//vms
export const VMsUrl = `/vms`;
//instances
export const InstancesUrl = `/instances`;
export const InstancesCreateUrl = `/instances/create`;
export const InstanceOverviewUrl = (id: string) => `/instances/${id}`;
export const InstanceVncConsoleUrl = (id: string) => `/instances/${id}/vnc`;
export const InstanceNetworksUrl = (id: string) => `/instances/${id}/networks`;
export const InstanceImagesUrl = (id: string) => `/instances/${id}/images`;
export const InstanceRescaleUrl = (id: string) => `/instances/${id}/rescale`;
export const InstanceBackupsUrl = (id: string) => `/instances/${id}/backups`;
export const InstanceDeleteUrl = (id: string) => `/instances/${id}/delete`;
export const InstancesSshKeysUrl = `/instances/ssh-keys`;
export const InstanceAutomaticBackupUrl = (id: string) =>
  `/instances/${id}/workflow/new`;
export const InstancesPlansUrl = `/instances/plans`;
export const InstancesVolumesUrl = `/instances/volumes`;
export const InstancesBackupsUrl = `/instances/backups`;
export const InstancesCreateVolumeUrl = `/instances/volumes/create`;
export const InstancesEditVolumeUrl = (id: string) =>
  `/instances/volumes/${id}/edit`;
export const InstancesVolumeBackupUrl = (id: string) =>
  `/instances/volumes/${id}/backup`;
export const InstancesVolumeOverviewUrl = (id: string) =>
  `/instances/volumes/${id}`;
export const InstancesVolumeBackupListUrl = (id: string) =>
  `/instances/volumes/${id}/volume-backup/list`;
export const InstancesVolumeSnapshotListUrl = (id: string) =>
  `/instances/volumes/${id}/volume-snapshots`;
export const InstancesVolumeDeletetUrl = (id: string) =>
  `/instances/volumes/${id}/delete`;
export const InstancesCreateVolumeFromBackupUrl = (
  id: string,
  backupId: string
) => `/instances/volumes/${id}/volume-backup/list/${backupId}/create`;
export const InstancesCreateVolumeFromSnapshotUrl = (
  id: string,
  snapshotId: string,
  snapshotName: string
) =>
  `/instances/volumes/${id}/volume-snapshots/${snapshotId}/create?name=${snapshotName}`;

//settings
export const SettingsProfileUrl = `/settings/profile`;
export const SettingsTeamsUrl = `/settings/teams`;
export const SettingsInvitedTeamsUrl = (env: string, token: string) =>
  `/settings/teams/invited/${env}/${token}`;
export const SettingsBillingUrl = `/settings/billing`;
export const SettingsRegisteriesUrl = `/settings/registeries`;
export const SettingsEnvVariablesUrl = `/settings/environment-variables`;

//
export const HomeUrl = RpcEndpointsUrl;
export const AboutUsUrl = "/about-us";
export const ContactUsUrl = "/contact-us";
