import { FileBreadCrumbItem } from "../types/ipfs-file";

export const fileParentFinder = (
  breadCrumbItems: FileBreadCrumbItem[],
  prefixMode: boolean = false
) => {
  let url: string = "";
  if (breadCrumbItems.length === 1) {
    url = prefixMode ? "" : "/";
  } else {
    for (let i = 1; i < breadCrumbItems.length; i++) {
      url =
        url +
        (prefixMode
          ? `${breadCrumbItems[i]?.title}/`
          : `/${breadCrumbItems[i]?.title}`);
    }
  }
  return url;
};

export const fileUrlConvertor = (url: string) => {
  return url.replaceAll("/", "%2F");
};

const lookup = (baseValue: number) => [
  { value: 1, binaryValue: 1, symbol: "" },
  { value: 1e3, binaryValue: baseValue, symbol: "K" },
  { value: 1e6, binaryValue: Math.pow(baseValue, 2), symbol: "M" },
  { value: 1e9, binaryValue: Math.pow(baseValue, 3), symbol: "G" },
  { value: 1e12, binaryValue: Math.pow(baseValue, 4), symbol: "T" },
  { value: 1e15, binaryValue: Math.pow(baseValue, 5), symbol: "P" },
  { value: 1e18, binaryValue: Math.pow(baseValue, 6), symbol: "E" },
];

export function humanizeSize(
  num: number,
  option?: { fractionDigits?: number; binaryBaseValue?: number }
) {
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const binaryBaseValue = option?.binaryBaseValue;
  const item = lookup(binaryBaseValue || 1024)
    .slice()
    .reverse()
    .find((item) => num >= item.binaryValue);
  const _number = item
    ? (num / item.binaryValue).toFixed(option?.fractionDigits).replace(rx, "$1")
    : 0;
  return {
    number: _number,
    symbol: item?.symbol,
    join: (unit: string) =>
      _number
        ? `${_number}${item?.symbol ? ` ${item?.symbol}` : ""}${unit}`
        : `${_number}`,
  };
}

export const binarySize = (
  num: number,
  symbol: string,
  binaryBaseValue: number = 1024
): number => {
  const item = lookup(binaryBaseValue)
    .slice()
    .reverse()
    .find((item) => symbol === item.symbol);
  const _number = item ? num * item.binaryValue : 0;

  return _number;
};

export const biteSize = (
  binaryNum: number,
  symbol: string,
  binaryBaseValue: number
): number => {
  const item = lookup(binaryBaseValue)
    .slice()
    .reverse()
    .find((item) => symbol === item.symbol);
  const _number = item ? binaryNum / item.binaryValue : 0;

  return _number;
};

// export const findBreadcrunbIndex = (
//   items: FileBreadCrumbItem[],
//   id: string
// ) => {
//   let index;
//   for (let i = 0; i < items.length; i++) {
//     const node = items[i];
//     if (node.id === id) {
//       index = i;
//       break;
//     }
//   }
//   return index;
// };

export const textJsonDownloader = (data: any, fileName: string) => {
  const url = window.URL.createObjectURL(
    new Blob([JSON.stringify(data)], {
      type: "application/x-ndjson",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}_export.json`);
  link.click();
  link.remove();
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result as string;
      resolve(base64Data);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export function convertBlobToFile(blob: Blob, filename: string): File {
  // Create a new File object with the Blob data and filename
  const file = new File([blob], filename, {
    type: blob.type,
    lastModified: new Date().getTime(),
  });

  return file;
}
