import { MouseEvent, useMemo, useRef, useState } from "react";
import { ReactComponent as CopyToClipboardIcon } from "./../../../assets/icons/copy.svg";
import { ReactComponent as ArrowDownCircleIcon } from "./../../../assets/icons/arrow-down-circle.svg";
import { ReactComponent as ArrowTopRightOnSquareIcon } from "./../../../assets/icons/arrow-top-right-on-square.svg";
import { ReactComponent as ArchiveBoxIcon } from "./../../../assets/icons/archive-box.svg";
import FileIcon, { extToType } from "./../../general/FileIcon";
import { humanizeSize } from "../../../utils/file";
import { IpfsFile } from "../../../types/ipfs-file";
import { useSearch } from "../../../providers/SearchProvider";
import { FileTypes } from "../../../types";
import { Button, Flex, SimpleTable, Typography } from "djuno-design";
import HighlighterText from "../../general/HighlighterText";

export type FileProps = {
  name: string;
  size: number;
  file: IpfsFile;
  downloadLoading?: boolean;
  exportLoading?: boolean;
  deleteLoading?: boolean;
  onOpenFile?: (
    file: IpfsFile,
    e: MouseEvent<HTMLDivElement>,
    type: FileTypes | undefined
  ) => void;
  onDownload?: (file: IpfsFile) => Promise<any>;
  onExport?: (file: IpfsFile) => Promise<any>;
  onCopy?: (file: IpfsFile) => Promise<any>;
  onDelete?: (file: IpfsFile) => Promise<any>;
};

function File({
  name,
  size,
  file,
  downloadLoading,
  exportLoading,
  deleteLoading,
  onOpenFile,
  onDownload,
  onExport,
  onCopy,
  onDelete,
}: FileProps) {
  const ref = useRef(null);

  const { value: searchValue } = useSearch();

  const [exporStatus, setExportStatus] = useState("Export");
  const [downloadStatus, setDownloadStatus] = useState("Download");
  const [copyStatus, setCopyStatus] = useState("Copy");
  const [deleteStatus, setDeleteStatus] = useState("Delete");

  const file_ext = useMemo(() => {
    return (file.extension?.split(".")[1] ||
      file.name.split(".")[1] ||
      file.content_type?.split("/")[1] ||
      "folder") as FileTypes;
  }, [file.content_type, file.extension, file.name]);

  const fileType = useMemo(() => {
    return extToType(file_ext);
  }, [file_ext]);

  const fileSize = useMemo(() => {
    if (size !== 0) {
      return humanizeSize(Number(size), { fractionDigits: 2 }).join("B");
    }
    return "";
  }, [size]);

  return (
    <SimpleTable.Row
      ref={ref}
      onClick={(e) => (onOpenFile ? onOpenFile(file, e, fileType) : null)}
    >
      <SimpleTable.TD>
        <Flex items="center" className="gap-1">
          <FileIcon type={fileType} />
          <HighlighterText searchValue={searchValue} textToHighlight={name} />
        </Flex>
      </SimpleTable.TD>
      <SimpleTable.TD>
        <Typography.Text className="!text-xs md:!text-sm">
          {fileSize}
        </Typography.Text>
      </SimpleTable.TD>
      <SimpleTable.TD className="w-20 gap-1">
        <div className="flex gap-2">
          <Button
            uiType="icon"
            uiSize="small"
            className="!px-1"
            onClick={(e: any) => {
              e.stopPropagation();
              if (onCopy) {
                setCopyStatus("Copying...");
                onCopy(file).then(() => {
                  setCopyStatus("Copyed");
                  setTimeout(() => setCopyStatus("Copy"), 2000);
                });
              }
            }}
            disabled={file.is_folder || file.public_cid === null}
            tooltip={{ content: copyStatus, className: "!text-xs" }}
          >
            <CopyToClipboardIcon className="w-4 h-4 md:w-5 md:h-5" />
          </Button>

          <Button
            uiType="icon"
            uiSize="small"
            className="!px-1"
            onClick={(e: any) => {
              e.stopPropagation();
              if (onExport && !exportLoading) {
                setExportStatus("Exporting...");
                onExport(file).then(() => {
                  setExportStatus("Exported");
                  setTimeout(() => setExportStatus("Export"), 2000);
                });
              }
            }}
            tooltip={{ content: exporStatus, className: "!text-xs" }}
            // loading={exportLoading}
          >
            <ArrowTopRightOnSquareIcon className="w-4 h-4 md:w-5 md:h-5" />
          </Button>

          <Button
            uiType="icon"
            uiSize="small"
            className="!px-1"
            onClick={(e: any) => {
              e.stopPropagation();
              if (onDownload && !downloadLoading) {
                setDownloadStatus("Downloading...");
                onDownload(file).then(() => {
                  setDownloadStatus("Downloaded");
                  setTimeout(() => setDownloadStatus("Download"), 2000);
                });
              }
            }}
            tooltip={{ content: downloadStatus, className: "!text-xs" }}
            // loading={downloadLoading}
          >
            <ArrowDownCircleIcon className="w-4 h-4 md:w-5 md:h-5" />
          </Button>
          <Button
            uiType="icon"
            uiSize="small"
            className="!px-1"
            onClick={(e: any) => {
              e.stopPropagation();
              if (onDelete) {
                setDeleteStatus("Deleting...");
                onDelete(file).then(() => {
                  setDeleteStatus("Deleted");
                  setTimeout(() => setDeleteStatus("Delete"), 2000);
                });
              }
            }}
            tooltip={{ content: deleteStatus, className: "!text-xs" }}
            // loading={deleteLoading}
          >
            <ArchiveBoxIcon className="w-4 h-4 md:w-5 md:h-5" />
          </Button>
        </div>
      </SimpleTable.TD>
    </SimpleTable.Row>
  );
}

export default File;
