import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as RefreshIcon } from "./../../../../../assets/icons/arrow-path.svg";
import { ReactComponent as ArchiveIcon } from "./../../../../../assets/icons/archive-box.svg";
import { ReactComponent as EditIcon } from "./../../../../../assets/icons/pencil-square.svg";
import { useAppDispatch, useAppSelector } from "./../../../../../hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InstanceVolumeEditSchema } from "./../../../../../utils/validations";
import { DBSAddon } from "./../../../../../types/database";
import {
  getDBSCatalogAsync,
  selectDBSCatalog,
  selectDBSCatalogLoading,
} from "./../../../../../store/database/servicesSlice";
import {
  deleteInstancesVolumesAsync,
  getInstancesVolumeAsync,
  getInstancesVolumesAsync,
  handleSetSelectedInstanceVolume,
  selectInstancesSelectedVolume,
  selectInstancesSelectedVolumeLoading,
  selectInstancesVolumesActionLoading,
} from "./../../../../../store/instances/instancesVolumesSlice";
import { DeleteModal } from "./../../../../modals/QuestionModal";
import {
  InstancesEditVolumeUrl,
  InstancesVolumesUrl,
} from "./../../../../../utils/urls";
import { getDBSPriceAmout } from "./../../../../../pages/databases/ServiceCreatePage";
import {
  Button,
  Card,
  Dropdown,
  Flex,
  Loading,
  Tag,
  Typography,
} from "djuno-design";

const VolumeOverviewTab = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedVolume = useAppSelector(selectInstancesSelectedVolume);
  const selectedVolumeLoading = useAppSelector(
    selectInstancesSelectedVolumeLoading
  );

  const catalog = useAppSelector(selectDBSCatalog);
  const catalogLoading = useAppSelector(selectDBSCatalogLoading);
  const actionLoading = useAppSelector(selectInstancesVolumesActionLoading);

  const [deleteVolume, setDeleteVolume] = useState<boolean>(false);
  const [addons, setAddons] = useState<Array<DBSAddon>>([]);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(InstanceVolumeEditSchema),
    defaultValues: {
      capacity: 10,
      volumeName: "",
    },
  });

  const { setValue, reset, control, formState, watch } = methods;

  // watch form values
  const capacity = watch("capacity");
  // const volumeName = watch("volumeName");

  //get selected volume
  useEffect(() => {
    if (selectedVolume === null && id !== undefined) {
      dispatch(getInstancesVolumeAsync({ id }));
    }
  }, [dispatch, id, selectedVolume]);

  useEffect(() => {
    if (id) {
      dispatch(getInstancesVolumeAsync({ id: id })).then((action) => {
        if (action && action.type === "instances/volume/rejected") {
          navigate(InstancesVolumesUrl);
        }
      });
    }
  }, [dispatch, id, navigate]);

  //get first data
  useEffect(() => {
    if (!catalog) {
      dispatch(getDBSCatalogAsync());
    }
    // dispatch(getRegionsAsync());
  }, [catalog, dispatch]);

  useEffect(() => {
    if (catalog) {
      setAddons(catalog.addons.filter((a) => a.planCode.startsWith("volume.")));
    }
  }, [catalog]);

  useEffect(() => {
    if (selectedVolume) {
      setValue("capacity", selectedVolume.size);
      setValue("volumeName", selectedVolume.name);
    }
  }, [selectedVolume, setValue]);

  const selectedAddon = useMemo(() => {
    if (selectedVolume) {
      return addons.find(
        (a) => a.planCode === `volume.${selectedVolume.type}.consumption`
      );
    }
  }, [addons, selectedVolume]);

  const estimatedPrice = useMemo(() => {
    if (selectedAddon) {
      const unitPrice = selectedAddon.pricings[0].price;
      return unitPrice * 24 * 30 * capacity;
    }
    return 0;
  }, [capacity, selectedAddon]);

  const OverviewCardSetting = () => {
    if (!selectedVolume) return null;
    return (
      <div className="flex items-center gap-2">
        <Button
          uiSize="medium"
          onClick={() =>
            dispatch(getInstancesVolumeAsync({ id: selectedVolume.id }))
          }
          className="group"
          tooltip={{ content: "Refresh" }}
        >
          <RefreshIcon className="w-4 h-4 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
        </Button>
        <Dropdown
          anchor="bottom end"
          itemsClassName="!w-50 max-h-48 overflow-y-auto"
          menu={[
            {
              key: "1",
              label: (
                <div className="flex items-center gap-1">
                  <EditIcon className="w-4" />
                  Edit volume
                </div>
              ),
              onClick: (_, close) => {
                close();
                dispatch(handleSetSelectedInstanceVolume(selectedVolume));
                navigate(InstancesEditVolumeUrl(selectedVolume.id));
              },
            },

            { type: "divider" as const },
            {
              key: "end",
              label: (
                <div className="flex items-center gap-1">
                  {" "}
                  <ArchiveIcon className="w-4" />
                  Delete
                </div>
              ),
              danger: true,
              onClick: (_, close) => {
                close();
                setDeleteVolume(true);
              },
              disabled: selectedVolumeLoading,
            },
          ]}
        >
          <Button>Actions</Button>
        </Dropdown>
      </div>
    );
  };

  return (
    <>
      {selectedVolume && !selectedVolumeLoading && (
        <>
          <div className="mt-5">
            <Card title="Information" setting={<OverviewCardSetting />}>
              <div className="mt-2 grid gap-7 gap-x-10 md:grid-cols-2">
                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Status:
                  </Typography.Text>
                  <Tag
                    className="text-xs !inline-block"
                    color={
                      selectedVolume.status === "available" ||
                      selectedVolume.status === "in-use"
                        ? "success"
                        : selectedVolume.status === "error" ||
                          selectedVolume.status === "error_backing-up" ||
                          selectedVolume.status === "error_deleting" ||
                          selectedVolume.status === "error_extending" ||
                          selectedVolume.status === "error_restoring"
                        ? "error"
                        : "processing"
                    }
                  >
                    {selectedVolume.status}
                  </Tag>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Region:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {selectedVolume.region}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Volume size:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {selectedVolume.size}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Estimated amount:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {`€${getDBSPriceAmout(estimatedPrice)} ex. VAT/month`}
                  </Typography.Text>
                </Flex>

                <Flex items="center" className="gap-1">
                  <Typography.Text strong className="!text-sm">
                    Volume type:
                  </Typography.Text>
                  <Typography.Text className="!text-sm">
                    {selectedVolume.type}
                  </Typography.Text>
                </Flex>
              </div>
            </Card>
          </div>

          <DeleteModal
            title={`Delete ${selectedVolume?.name}`}
            isOpen={deleteVolume}
            onClose={() => setDeleteVolume(false)}
            description=""
            confirmButtonText="Confirm"
            confirmButtonType="danger"
            confirmButtonClassName="w-full"
            loading={actionLoading}
            onConfirm={() => {
              deleteVolume &&
                selectedVolume &&
                dispatch(
                  deleteInstancesVolumesAsync({
                    id: selectedVolume.id,
                    region: selectedVolume.region,
                  })
                ).then((action) => {
                  if (action.type === "instances/volumes/delete/fulfilled") {
                    dispatch(getInstancesVolumesAsync({}));
                    navigate(InstancesVolumesUrl);
                  }
                  setDeleteVolume(false);
                });
            }}
            confirmString={selectedVolume.name}
          />
        </>
      )}
      {(selectedVolumeLoading || catalogLoading) && (
        <Flex items="center" justify="center" className="!min-h-[300px] w-full">
          <Loading borderSize={2} />
        </Flex>
      )}
    </>
  );
};

export default VolumeOverviewTab;
