import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ReactComponent as PlusIcon } from "./../../../assets/icons/plus.svg";
import { ReactComponent as UserTeamIcon } from "./../../../assets/icons/user-team.svg";
import InviteUserModal from "./InviteUserModal";
import {
  selectTeams,
  selectTeamsLoading,
  getTeamsAsync,
  openInviteModal,
  selectActionLoading,
  deleteTeamUserAsync,
  inviteUserAsync,
} from "../../../store/settings/teams/teamsSlice";
import { ReactComponent as MoreIcon } from "./../../../assets/icons/more.svg";
import { ReactComponent as ArchiveIcon } from "./../../../assets/icons/archive-box.svg";
import { ReactComponent as ResendIcon } from "./../../../assets/icons/arrow-path.svg";
import { DeleteModal } from "../../modals/QuestionModal";
import { selectOnStageEnv } from "../../../store/auth/authSlice";
import { Team } from "../../../types/team";
import { Helmet } from "react-helmet";
import {
  Button,
  Countdown,
  SimpleTable,
  EmptyState,
  Dropdown,
  Tag,
  Typography,
  Flex,
} from "djuno-design";

const SettingsTeamsTab = () => {
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const teams = useAppSelector(selectTeams);
  const loading = useAppSelector(selectTeamsLoading);
  const actionLoading = useAppSelector(selectActionLoading);
  const selectedEnv = useAppSelector(selectOnStageEnv);

  useEffect(() => {
    dispatch(getTeamsAsync({}));
  }, [dispatch, teams.length]);

  const inviteUserHandler = useCallback(
    (team: Team) => {
      dispatch(inviteUserAsync({ Email: team.Email })).then((action) => {
        if (action.type === "teams/invite/fulfilled") {
          dispatch(getTeamsAsync({ withoutLoading: true }));
        }
      });
    },
    [dispatch]
  );

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} | Teams</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="flex transition-all duration-500 items-center">
        <div className="flex items-center gap-2">
          <Typography.Text>Teams</Typography.Text>
          <Tag bordered={false} className="!px-2">
            <Typography.Text className="!text-sm" uiType="secondary">
              {selectedEnv?.Name}
            </Typography.Text>
          </Tag>
        </div>
        <div className="ml-auto flex items-center justify-end gap-1 md:gap-2">
          {selectedEnv?.IsAccountOwner && (
            <Button
              uiType="primary"
              onClick={() => dispatch(openInviteModal())}
              className="group"
            >
              Invite user
              <PlusIcon className="w-3 h-3 group-hover:rotate-90 group-hover:scale-110 transition-all duration-500" />
            </Button>
          )}
        </div>
      </div>
      <div className="mt-5 w-full">
        <SimpleTable loading={loading} containerClassName="min-h-[240px]">
          <SimpleTable.Head>
            <SimpleTable.Row>
              <SimpleTable.TH lable="Email address" />
              {/* <SimpleTable.TH lable="Account created" /> */}
              <SimpleTable.TH lable="Status" />
              <SimpleTable.TH lable="" />
            </SimpleTable.Row>
          </SimpleTable.Head>
          <SimpleTable.Body>
            {teams.map((team, i) => (
              <SimpleTable.Row key={i}>
                <SimpleTable.TD>
                  <Flex items="center" className="gap-3">
                    <div className="-mr- w-6 h-6 bg-gray-200 bg-gray-300/30 dark:bg-gray-500/20 rounded-full ring-1 ring-gray-400 dark:ring-gray-600 flex items-center text-center justify-center">
                      <UserTeamIcon className="w-5 h-5 text-slate-400 dark:text-slate-400" />
                    </div>
                    <Typography.Text className="!text-xs md:!text-sm">
                      {team.Email}
                    </Typography.Text>
                  </Flex>
                </SimpleTable.TD>
                {/* <SimpleTable.TD>
                  <Text className="text-xs md:text-sm">
                    {timeAgo(team.CreatedAt)}
                  </Text>
                </SimpleTable.TD> */}
                <SimpleTable.TD>
                  <div className="flex">
                    <Tag
                      className="!text-xs"
                      color={team.Status === 1 ? "success" : "warning"}
                    >
                      {team.Status === 1 ? "Accepted" : "Pending"}
                    </Tag>
                  </div>
                </SimpleTable.TD>
                <SimpleTable.TD className="w-10 ">
                  <div className="w-8 flex justify-center items-center">
                    {selectedEnv?.IsAccountOwner && (
                      <Countdown
                        seconds={
                          team.Status === 1
                            ? 0
                            : team.RemainSecondsOfVerification || 0
                        }
                        timerPosition="end"
                        timerRender={() => {
                          return null;
                        }}
                      >
                        {({ disabled, formatedTime, timeLeft }) => (
                          <Dropdown
                            anchor="bottom end"
                            itemsClassName="!w-40"
                            menu={[
                              {
                                key: "1",
                                label: (
                                  <div className="flex items-center gap-1 ">
                                    <ResendIcon className="w-4" />
                                    Resend
                                    {timeLeft > 0 && (
                                      <Typography.Text
                                        uiType="secondary"
                                        className="!text-xs"
                                      >
                                        {formatedTime}
                                      </Typography.Text>
                                    )}
                                  </div>
                                ),
                                disabled: team.Status === 1 || disabled,
                                onClick: () => {
                                  inviteUserHandler(team);
                                },
                              },
                              { type: "divider" },
                              {
                                key: "end",
                                label: (
                                  <div className="flex items-center gap-1">
                                    <ArchiveIcon className="w-4" />
                                    Delete
                                  </div>
                                ),
                                danger: true,
                                onClick: (_i, close) => {
                                  close();
                                  setDeleteUserId(team.Id);
                                },
                              },
                            ]}
                          >
                            <Button
                              uiSize="small"
                              uiType="icon"
                              className="!px-2"
                            >
                              <MoreIcon className="w-4 h-4" />
                            </Button>
                          </Dropdown>
                        )}
                      </Countdown>
                    )}
                  </div>
                </SimpleTable.TD>
              </SimpleTable.Row>
            ))}
            {teams.length === 0 && (
              <SimpleTable.Row withoutHoverStyle={true} className="h-[200px]">
                <SimpleTable.TD colSpan={4} className="!border-0">
                  <EmptyState text="You have not inveted any user yet" />
                </SimpleTable.TD>
              </SimpleTable.Row>
            )}
          </SimpleTable.Body>
        </SimpleTable>
      </div>
      <InviteUserModal />

      <DeleteModal
        title="Delete User"
        isOpen={deleteUserId !== null}
        onClose={() => setDeleteUserId(null)}
        onConfirm={() => {
          deleteUserId &&
            dispatch(deleteTeamUserAsync({ id: deleteUserId })).then(
              (action) => {
                if (action.type === "teams/user/delete/fulfilled") {
                  dispatch(getTeamsAsync({}));
                } else {
                }
                setDeleteUserId(null);
              }
            );
        }}
        loading={actionLoading}
        confirmString={teams.find((team) => team.Id === deleteUserId)?.Email}
        confirmButtonType="danger"
      />
    </>
  );
};

export default SettingsTeamsTab;
