import { Link, useNavigate } from "react-router-dom";
import { SignInUrl } from "../../utils/urls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResetPasswordSchema, validatePassword } from "../../utils/validations";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  resetPasswordAsync,
  selectLoginLoading,
} from "../../store/auth/authSlice";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ToastClasses } from "../modals/alerts";
import { useEffect, useState } from "react";
import { PasswordErrors } from "../../types";
import { Button, cn, Flex, Input, Typography } from "djuno-design";
import CustomLink from "../general/CustomLink";

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoginLoading);
  const { token } = useParams();
  const navigate = useNavigate();
  const [password_error, setPasswordErrors] = useState<PasswordErrors>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const selectedPassword = watch("password");

  const onSubmit = (data: any) => {
    if (token && !loading) {
      dispatch(
        resetPasswordAsync({
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
          token,
        })
      ).then((action) => {
        if (action.type === "auth/reset-password/fulfilled") {
          toast.success("Your password was changed. Please sign in", {
            className: ToastClasses,
            duration: 5000,
          });
          setTimeout(() => navigate(SignInUrl), 4000);
        }
      });
    }
  };

  useEffect(() => {
    setPasswordErrors(validatePassword(selectedPassword));
  }, [selectedPassword]);

  return (
    <>
      <Typography.Title level={5} className="text-center">
        Reset your account
      </Typography.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="col" className="gap-1">
          <Flex direction="col" className="gap-5">
            <Input
              label="Password"
              {...register("password")}
              type="password"
              required
              placeholder="●●●●●●"
              error={errors.password?.message}
              hint={
                <Flex justify="end" className="gap-1 flex-wrap text-[10px]">
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.length,
                    })}
                  >
                    8-64 chars
                  </span>
                  |
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.lowercase,
                    })}
                  >
                    1 lowercase
                  </span>
                  |
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.uppercase,
                    })}
                  >
                    1 uppercase
                  </span>
                  |
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.number,
                    })}
                  >
                    1 number
                  </span>{" "}
                  |
                  <span
                    className={cn("whitespace-nowrap", {
                      "text-success": !password_error?.special_char,
                    })}
                  >
                    1 special char
                  </span>
                </Flex>
              }
              labelClassName="!items-start"
            />

            <Input
              label="Password confirm"
              {...register("passwordConfirmation")}
              type="password"
              required
              placeholder="●●●●●●"
              error={errors.passwordConfirmation?.message}
            />
          </Flex>

          <Flex direction="col" className="mt-4">
            <Button
              uiType="primary"
              type="submit"
              className="!w-full"
              loading={loading}
            >
              <Typography.Text
                uiType="transparent"
                className="w-full text-center"
                size="sm"
              >
                Reset password
              </Typography.Text>
            </Button>
            <Flex justify="center" className="gap-1 mt-2">
              <CustomLink to={SignInUrl} className="text-sm">
                Go back to sign in
              </CustomLink>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </>
  );
};
export default ResetPassword;
