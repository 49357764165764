import { PlansTab } from "../../plans/UniversalPlansBox";

const WalletApiPlansTab = () => {
  const walletApiServiceType =
    Number(process.env.REACT_APP_WALLET_API_SERVICE_TYPE) || 7;

  return (
    <>
      <PlansTab serviceType={walletApiServiceType} />
    </>
  );
};

export default WalletApiPlansTab;
