import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { IThunkRejectValue, RootState } from "../../types";
import { getExtractErrorCode, getExtractErrors } from "../../apis";
import { CustomErrorToast } from "../../components/general/Toast";
import {
  WebApp,
  WebAppDisk,
  WebAppEnvironment,
  WebAppEvent,
  WebAppJob,
  WebAppLogs,
  WebAppPreview,
  WebAppSecretFile,
  WebAppShared,
  WebAppState,
} from "../../types/web-app";
import {
  createWebAppSharingApi,
  deleteWebAppDiskApi,
  deleteWebAppSharingApi,
  getWebAppApi,
  getWebAppDiskApi,
  getWebAppEnvironmentsApi,
  getWebAppEventsApi,
  getWebAppLogsApi,
  getWebAppManualScalingApi,
  getWebAppSecretFilesApi,
  getWebAppSharedApi,
  saveWebAppDiskApi,
  saveWebAppEnvironmentsApi,
  saveWebAppSecretFilesApi,
  updateWebAppDockerCommandApi,
  updateWebAppHealthCheckPathApi,
  updateWebAppManualScalingApi,
  updateWebAppNameApi,
  updateWebAppPlanApi,
  updateWebAppPreDeployCommandApi,
} from "../../apis/webAppsAPI";
import { dummyWebApps } from "./webAppsSlice";

const dummyEvents: WebAppEvent[] = [
  {
    type: "deploy",
    deployId: "dep-cnmrr4ocmk4c73ajculg",
    commitId:
      "eeabfa5cd6a2091bf35eb9eae6ae48aab8231fd760f5a61cd0129df454333b1d",
    branchName: "latest",
    createdAt: "2024-02-28T18:03:39.753584",
  },
  {
    type: "failed",
    deployId: "dep-cnmrr4ocmk4c73ajculg",
    commitId:
      "eeabfa5cd6a2091bf35eb9eae6ae48aab8231fd760f5a61cd0129df454333b1d", //"05aa73005987caaed48ea8213696b0df761ccd600d2c53fc0a1a97a180301d71",
    branchName: "latest",
    createdAt: "2024-02-28T18:03:40.753584",
    message: "Exited with status 1 while running your code",
  },
  {
    type: "deploy",
    deployId: "dep-cnmrr4ocmk4c73ajculg",
    commitId:
      "eeabfa5cd6a2091bf35eb9eae6ae48aab8231fd760f5a61cd0129df454333b1d",
    branchName: "latest",
    createdAt: "2024-02-28T18:03:41.753584",
  },
  {
    type: "live",
    deployId: "dep-cnmrr4ocmk4c73ajculg",
    commitId:
      "eeabfa5cd6a2091bf35eb9eae6ae48aab8231fd760f5a61cd0129df454333b1d",
    branchName: "latest",
    createdAt: "2024-02-28T18:03:41.753584",
  },
];

const dummyLogs = {
  logs: [
    {
      id: "co0p3520si5c73fjicig",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:13.064140228Z",
      text: "\u001B[0;34m\u001B[1m==\u003e \u001B[0m\u001B[1mStarting service...\u001B[0m",
      __typename: "LogWithLabels",
    },
    {
      id: "631dd110-20f3-44c1-9375-abe8f28dcf6d",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.38588724Z",
      text: "/docker-entrypoint.sh: /docker-entrypoint.d/ is not empty, will attempt to perform configuration",
      __typename: "LogWithLabels",
    },
    {
      id: "bd779e01-774a-40b1-854c-9b78735f1aec",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.390566158Z",
      text: "/docker-entrypoint.sh: Looking for shell scripts in /docker-entrypoint.d/",
      __typename: "LogWithLabels",
    },
    {
      id: "8cc47aff-0b01-4d65-b608-e901299d9d1f",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-cnmrs0acn0vc738fqgmg",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-78fb8cb8c-8bvqg",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "error",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T09:05:19.443609998Z",
      text: '2024/03/25 09:05:19 [error] 29#29: *3 open() "/usr/share/nginx/html/favicon.ico" failed (2: No such file or directory), client: ::1, server: localhost, request: "GET /favicon.ico HTTP/1.1", host: "mysql-ffln.onrender.com", referrer: "https://mysql-ffln.onrender.com/"',
      __typename: "LogWithLabels",
    },
    {
      id: "4531a0c1-5b69-4e04-b70a-0fc401a0e545",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.401337554Z",
      text: "/docker-entrypoint.sh: Launching /docker-entrypoint.d/10-listen-on-ipv6-by-default.sh",
      __typename: "LogWithLabels",
    },
    {
      id: "2b0a5358-b342-4b82-99ae-e211447cd275",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.500096246Z",
      text: "10-listen-on-ipv6-by-default.sh: info: Getting the checksum of /etc/nginx/conf.d/default.conf",
      __typename: "LogWithLabels",
    },
    {
      id: "f7a3d33f-18e7-4bc8-9d1c-8f7ffe34b9a4",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.585226012Z",
      text: "10-listen-on-ipv6-by-default.sh: info: Enabled listen on IPv6 in /etc/nginx/conf.d/default.conf",
      __typename: "LogWithLabels",
    },
    {
      id: "e713934e-2510-450a-bc58-44ac1362e38b",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.585366325Z",
      text: "/docker-entrypoint.sh: Sourcing /docker-entrypoint.d/15-local-resolvers.envsh",
      __typename: "LogWithLabels",
    },
    {
      id: "eedaa6ab-5088-482e-9059-24da64c8266a",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.585847055Z",
      text: "/docker-entrypoint.sh: Launching /docker-entrypoint.d/20-envsubst-on-templates.sh",
      __typename: "LogWithLabels",
    },
    {
      id: "8ef8aaaa-1f89-4bb1-9995-aca4c1fe9a54",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.591976933Z",
      text: "/docker-entrypoint.sh: Launching /docker-entrypoint.d/30-tune-worker-processes.sh",
      __typename: "LogWithLabels",
    },
    {
      id: "bc3ed7f6-d244-4dbe-92c3-a094c7fc3b8c",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.593185419Z",
      text: "/docker-entrypoint.sh: Configuration complete; ready for start up",
      __typename: "LogWithLabels",
    },
    {
      id: "70a985bd-3fd5-42b6-89a3-c767f00f9a66",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.627377216Z",
      text: '2024/03/25 14:52:40 [notice] 1#1: using the "epoll" event method',
      __typename: "LogWithLabels",
    },
    {
      id: "a9712a24-b9f2-474e-80f0-823a017f95c4",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.627388506Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: nginx/1.25.4",
      __typename: "LogWithLabels",
    },
    {
      id: "bd3777a3-58f1-4673-8db2-7721865cb067",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.627392376Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: built by gcc 12.2.0 (Debian 12.2.0-14) ",
      __typename: "LogWithLabels",
    },
    {
      id: "24572489-0762-435c-9ed5-004420db3ece",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.627395756Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: OS: Linux 5.15.0-1056-aws",
      __typename: "LogWithLabels",
    },
    {
      id: "b6291ac9-eccf-4b2d-aeca-e27a292d46e0",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.627399006Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: getrlimit(RLIMIT_NOFILE): 1048576:1048576",
      __typename: "LogWithLabels",
    },
    {
      id: "7ccc40aa-dd55-4b37-8d72-b1f948549c9f",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.629596982Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: start worker processes",
      __typename: "LogWithLabels",
    },
    {
      id: "b3a458cd-98d7-4055-a808-e62619c3a5bb",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.629608282Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: start worker process 29",
      __typename: "LogWithLabels",
    },
    {
      id: "1231f130-7493-472a-980e-4e4ac653ff1e",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.629612312Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: start worker process 30",
      __typename: "LogWithLabels",
    },
    {
      id: "240bffd8-f21f-4828-9d62-8ee4151491bb",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.629615552Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: start worker process 31",
      __typename: "LogWithLabels",
    },
    {
      id: "a8b2553e-5412-4de8-a6de-6565f8e150a1",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.629618703Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: start worker process 32",
      __typename: "LogWithLabels",
    },
    {
      id: "96d8d56f-45d2-42c4-8ac7-82a5e40ab753",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.629620483Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: start worker process 33",
      __typename: "LogWithLabels",
    },
    {
      id: "15569c3a-4b2e-41c0-a80e-f5b6cba0555f",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.629622073Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: start worker process 34",
      __typename: "LogWithLabels",
    },
    {
      id: "dda47494-b710-48bc-8f9b-b49a94a5d7d3",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.629623673Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: start worker process 35",
      __typename: "LogWithLabels",
    },
    {
      id: "5b0aaed4-c57c-4085-b937-b8b1b2d1492d",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "notice",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:40.629625223Z",
      text: "2024/03/25 14:52:40 [notice] 1#1: start worker process 36",
      __typename: "LogWithLabels",
    },
    {
      id: "co0p3520si5c73fjici0",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:41.40337375Z",
      text: "\u001B[0;34m\u001B[1m==\u003e \u001B[0m\u001B[1mDetected service running on port 80\u001B[0m",
      __typename: "LogWithLabels",
    },
    {
      id: "co0p3520si5c73fjichg",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:41.42114418Z",
      text: "\u001B[0;34m\u001B[1m==\u003e \u001B[0m\u001B[1mDocs on specifying a port: https://render.com/docs/web-services#port-detection\u001B[0m",
      __typename: "LogWithLabels",
    },
    {
      id: "co0p3520si5c73fjich0",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:44.197292426Z",
      text: "\u001B[0;32m\u001B[1m==\u003e \u001B[0m\u001B[1mYour service is live 🎉\u001B[0m",
      __typename: "LogWithLabels",
    },
    {
      id: "d62eb7af-8dda-428b-ae7a-037598edda99",
      labels: [
        {
          label: "SERVICE",
          value: "srv-cnmrr4gcmk4c73ajcuj0",
          __typename: "LogLabel",
        },
        {
          label: "DEPLOY",
          value: "dep-co0ov76d3nmc73cl0io0",
          __typename: "LogLabel",
        },
        {
          label: "INSTANCE",
          value: "srv-cnmrr4gcmk4c73ajcuj0-hibernate-5b7d5bcc48-j6dx9",
          __typename: "LogLabel",
        },
        {
          label: "LEVEL",
          value: "info",
          __typename: "LogLabel",
        },
        {
          label: "LOG_TYPE",
          value: "app",
          __typename: "LogLabel",
        },
      ],
      timestamp: "2024-03-25T14:52:44.820539881Z",
      text: '::1 - - [25/Mar/2024:14:52:44 +0000] "GET / HTTP/1.1" 200 615 "-" "Go-http-client/2.0" "35.247.39.23, 172.71.150.166, 10.204.151.142"',
      __typename: "LogWithLabels",
    },
  ],
  nextEndTime: "2024-03-25T14:52:13.064140228Z",
  nextStartTime: "2024-03-25T11:00:36.087Z",
  hasMore: false,
  __typename: "LogResult",
};

const dummyDisk: WebAppDisk = {
  DiskName: "test",
  DiskMountPath: "/usr/data/lib",
  DiskSize: 11,
};

const dummyEnvironments: WebAppEnvironment[] = [
  { Key: "key_1", Value: "abc1234" },
];

const dummySecretFiles: WebAppSecretFile[] = [
  { Filename: "key_1", FileContents: "abc1234" },
];

const dummyPreviews: WebAppPreview[] = [
  {
    By: "moe@djuno.io",
    CommitId:
      "eeabfa5cd6a2091bf35eb9eae6ae48aab8231fd760f5a61cd0129df454333b1d",
    CreatedAt: "2024-03-25T14:52:13.064140228Z",
    Link: "https://djuno.io/test-54/preview",
    Type: "image",
  },
  {
    By: "moe@djuno.io",
    CommitId:
      "eeabfa5cd6a2091bf35eb9eae6ae48aab8231fd760f5a61cd0129df454333b1d",
    CreatedAt: "2024-03-25T14:52:13.064140228Z",
    Link: "https://djuno.io/test-54/preview",
    Type: "image",
  },
];

const dummyJobs: WebAppJob[] = [
  {
    Name: "job-1234",
    StartCommand: "echo hi",
    CreatedAt: "2024-03-25T14:52:13.064140228Z",
    Plan: "plan-cra-002",
    Status: "Pending",
  },
];

const dummySharing: WebAppShared[] = [
  {
    Id: 1,
    Email: "moe@djuno.io",
    Status: 0,
  },
  {
    Id: 2,
    Email: "ansar@djuno.io",
    Status: 0,
  },
];

const initialState: WebAppState = {
  webApp: null,
  webAppLoading: false,

  //overview
  events: [...dummyEvents.reverse()],
  eventsLoading: false,

  //disks
  disk: undefined,
  diskLoading: false,
  diskActionLoading: false,

  //logs
  logs: {
    hasMore: false,
    logs: [],
    nextEndTime: null,
    nextStartTime: null,
  },
  logsLoading: false,

  //environments
  environments: [],
  environmentsLoading: false,
  environmentsActionLoading: false,

  //secret-files
  secretFiles: [],
  secretFilesLoading: false,
  secretFilesActionLoading: false,

  //previews
  previews: [],
  previewsLoading: false,

  //jobs
  jobs: [],
  jobsLoading: false,

  //sharing
  shared: [],
  sharedLoading: false,
  sharingLoading: false, //for invite
  deleteShared: null,

  //scaling
  scaling: 1,
  manualScaling: true,
  scalingLoading: false,
  scalingUpdateLoading: false,

  //settings
  updateNameLoading: false,
  updateDockerCommandLoading: false,
  updatePreDeployCommandLoading: false,
  showUpdateImageModal: false,
  updateHealthCheckPathLoading: false,

  //plan
  planUpdateLoading: false,
};

export const getWebAppAsync = createAsyncThunk<
  { webApp: WebApp },
  string,
  IThunkRejectValue
>(
  "web-app",
  async (webAppId: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppApi(webAppId);
      const webApp = response.data.Result;

      return fulfillWithValue({ webApp });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
        // code: getExtractErrorCode(e),
      });
    }
  }
);

export const getWebAppEventsAsync = createAsyncThunk<
  { events: WebAppEvent[] },
  string,
  IThunkRejectValue
>(
  "web-app/events",
  async (webAppId: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppEventsApi(webAppId);
      const events = response.data.Result;
      return fulfillWithValue({ events });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppLogsAsync = createAsyncThunk<
  { logs: WebAppLogs },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/logs",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppLogsApi(webAppId);
      const logs = response.data.Result;
      return fulfillWithValue({ logs });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppDiskAsync = createAsyncThunk<
  { disk: WebAppDisk },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/disk",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppDiskApi(webAppId);
      const disk = response.data;
      return fulfillWithValue({ disk });
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const saveWebAppDiskAsync = createAsyncThunk<
  boolean,
  { webAppId: string; data: any },
  IThunkRejectValue
>("web-app/disk/save", async ({ webAppId, data }, { rejectWithValue }) => {
  try {
    await saveWebAppDiskApi(webAppId, data);
    return true;
  } catch (e) {
    return rejectWithValue({
      message: getExtractErrors(e),
    });
  }
});

export const deleteWebAppDiskAsync = createAsyncThunk<
  boolean,
  { webAppId: string },
  IThunkRejectValue
>("web-app/disk/dalete", async ({ webAppId }, { rejectWithValue }) => {
  try {
    await deleteWebAppDiskApi(webAppId);
    return true;
  } catch (e) {
    return rejectWithValue({
      message: getExtractErrors(e),
    });
  }
});

export const getWebAppEnvironmentsAsync = createAsyncThunk<
  { environments: WebAppEnvironment[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/environments",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppEnvironmentsApi(webAppId);
      const environments = response.data.Result;
      return fulfillWithValue({ environments });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const saveWebAppEnvironmentsAsync = createAsyncThunk<
  boolean,
  { webAppId: string; envs: WebAppEnvironment[] },
  IThunkRejectValue
>(
  "web-app/environments/save",
  async ({ webAppId, envs }, { rejectWithValue }) => {
    try {
      await saveWebAppEnvironmentsApi(webAppId, envs);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getWebAppSecretFilesAsync = createAsyncThunk<
  { secretFiles: WebAppSecretFile[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/secret-files",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppSecretFilesApi(webAppId);
      const secretFiles = response.data.Result;
      return fulfillWithValue({ secretFiles });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const saveWebAppSecretFilesAsync = createAsyncThunk<
  boolean,
  { webAppId: string; secretFiles: WebAppSecretFile[] },
  IThunkRejectValue
>(
  "web-app/secret-files/save",
  async ({ webAppId, secretFiles }, { rejectWithValue }) => {
    try {
      await saveWebAppSecretFilesApi(webAppId, secretFiles);
      return true;
    } catch (e) {
      return rejectWithValue({
        message: getExtractErrors(e),
      });
    }
  }
);

export const getWebAppPreviewsAsync = createAsyncThunk<
  { previews: WebAppPreview[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/previews",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppSecretFilesApi(webAppId);
      const previews = response.data.Result;
      return fulfillWithValue({ previews });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppJobsAsync = createAsyncThunk<
  { jobs: WebAppJob[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/jobs",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppSecretFilesApi(webAppId);
      const jobs = response.data.Result;
      return fulfillWithValue({ jobs });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppSharedAsync = createAsyncThunk<
  { shared: WebAppShared[] },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/shared",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getWebAppSharedApi(webAppId);
      const shared = response.data.Result;
      return fulfillWithValue({ shared });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const createWebAppSharingAsync = createAsyncThunk<
  boolean,
  { webAppId: string; Email: string },
  IThunkRejectValue
>("web-app/sharing", async ({ webAppId, Email }, { rejectWithValue }) => {
  try {
    await createWebAppSharingApi(webAppId, { Email });
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const deleteWebAppSharingAsync = createAsyncThunk<
  { sharedId: number },
  { webAppId: string; Id: number },
  IThunkRejectValue
>(
  "web-app/shared/delete",
  async (
    { webAppId, Id },
    { rejectWithValue, dispatch, requestId, fulfillWithValue }
  ) => {
    try {
      //send extra data to pending case
      dispatch(deleteWebAppSharingAsync.pending(requestId, { webAppId, Id }));
      // await deleteWebAppSharingApi(webAppId, Id);
      return fulfillWithValue({ sharedId: Id });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const getWebAppManualScalingAsync = createAsyncThunk<
  { value: number },
  { webAppId: string },
  IThunkRejectValue
>(
  "web-app/manual-scaling",
  async ({ webAppId }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getWebAppManualScalingApi(webAppId);
      const value = res.data.Result;
      return fulfillWithValue({ value });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateWebAppManualScalingAsync = createAsyncThunk<
  { value: number },
  { webAppId: string; value: number },
  IThunkRejectValue
>(
  "web-app/manual-scaling/update",
  async ({ webAppId, value }, { rejectWithValue, fulfillWithValue }) => {
    try {
      await updateWebAppManualScalingApi(webAppId, { value });
      return fulfillWithValue({ value });
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e), code: value });
    }
  }
);

export const updateWebAppNameAsync = createAsyncThunk<
  boolean,
  { webAppId: string; Name: string },
  IThunkRejectValue
>("web-app/update/name", async ({ webAppId, Name }, { rejectWithValue }) => {
  try {
    await updateWebAppNameApi(webAppId, { Name });
    return true;
  } catch (e) {
    return rejectWithValue({ message: getExtractErrors(e) });
  }
});

export const updateWebAppDockerCommandAsync = createAsyncThunk<
  boolean,
  { webAppId: string; DockerCommand: string },
  IThunkRejectValue
>(
  "web-app/update/docker-command",
  async ({ webAppId, DockerCommand }, { rejectWithValue }) => {
    try {
      await updateWebAppDockerCommandApi(webAppId, { DockerCommand });
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateWebAppPreDeployCommandAsync = createAsyncThunk<
  boolean,
  { webAppId: string; PreDeployCommand: string },
  IThunkRejectValue
>(
  "web-app/update/pre-deploy-command",
  async ({ webAppId, PreDeployCommand }, { rejectWithValue }) => {
    try {
      await updateWebAppPreDeployCommandApi(webAppId, { PreDeployCommand });
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateWebAppHealthCheckPathAsync = createAsyncThunk<
  boolean,
  { webAppId: string; Path: string },
  IThunkRejectValue
>(
  "web-app/update/health-check-path",
  async ({ webAppId, Path }, { rejectWithValue }) => {
    try {
      await updateWebAppHealthCheckPathApi(webAppId, { Path });
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const updateWebAppPlanAsync = createAsyncThunk<
  boolean,
  { webAppId: string; InstanceId: number },
  IThunkRejectValue
>(
  "web-app/update/plan",
  async ({ webAppId, InstanceId }, { rejectWithValue }) => {
    try {
      await updateWebAppPlanApi(webAppId, { InstanceId });
      return true;
    } catch (e) {
      return rejectWithValue({ message: getExtractErrors(e) });
    }
  }
);

export const webAppSlice = createSlice({
  name: "webApp",
  initialState,
  reducers: {
    handleSetWebApp: (state, action: PayloadAction<WebApp>) => {
      state.webApp = action.payload;
    },
    handleShowWebAppUpdateImageModal: (state) => {
      state.showUpdateImageModal = true;
    },
    handleHideWebAppUpdateImageModal: (state) => {
      state.showUpdateImageModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebAppAsync.pending, (state) => {
        state.webAppLoading = true;
      })
      .addCase(getWebAppAsync.fulfilled, (state, action) => {
        state.webApp = action.payload.webApp;
        state.webAppLoading = false;
      })
      .addCase(getWebAppAsync.rejected, (state, { payload }) => {
        state.webAppLoading = false;

        //
        state.webApp = dummyWebApps[0];
        // if (payload?.code !== 404)
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppEventsAsync.pending, (state) => {
        state.eventsLoading = true;
      })
      .addCase(getWebAppEventsAsync.fulfilled, (state, action) => {
        state.events = action.payload.events;
        state.eventsLoading = false;
      })
      .addCase(getWebAppEventsAsync.rejected, (state, { payload }) => {
        state.eventsLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppDiskAsync.pending, (state) => {
        state.diskLoading = true;
      })
      .addCase(getWebAppDiskAsync.fulfilled, (state, action) => {
        state.disk = action.payload.disk;
        state.diskLoading = false;
      })
      .addCase(getWebAppDiskAsync.rejected, (state, { payload }) => {
        state.diskLoading = false;
        //TODO
        state.disk = dummyDisk;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(saveWebAppDiskAsync.pending, (state) => {
        state.diskActionLoading = true;
      })
      .addCase(saveWebAppDiskAsync.fulfilled, (state) => {
        state.diskActionLoading = false;
      })
      .addCase(saveWebAppDiskAsync.rejected, (state, { payload }) => {
        state.diskActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWebAppDiskAsync.pending, (state) => {
        state.diskActionLoading = true;
      })
      .addCase(deleteWebAppDiskAsync.fulfilled, (state) => {
        state.diskActionLoading = false;
        state.disk = undefined;
      })
      .addCase(deleteWebAppDiskAsync.rejected, (state, { payload }) => {
        state.diskActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppLogsAsync.pending, (state) => {
        state.logsLoading = true;
      })
      .addCase(getWebAppLogsAsync.fulfilled, (state, action) => {
        state.logs = action.payload.logs;
        state.logsLoading = false;
      })
      .addCase(getWebAppLogsAsync.rejected, (state, { payload }) => {
        state.logsLoading = false;
        //TODO
        state.logs = dummyLogs;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppEnvironmentsAsync.pending, (state) => {
        state.environmentsLoading = true;
      })
      .addCase(getWebAppEnvironmentsAsync.fulfilled, (state, action) => {
        state.environments = action.payload.environments;
        state.environmentsLoading = false;
      })
      .addCase(getWebAppEnvironmentsAsync.rejected, (state, { payload }) => {
        state.environmentsLoading = false;
        //TODO
        state.environments = dummyEnvironments;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(saveWebAppEnvironmentsAsync.pending, (state) => {
        state.environmentsActionLoading = true;
      })
      .addCase(saveWebAppEnvironmentsAsync.fulfilled, (state) => {
        state.environmentsActionLoading = false;
      })
      .addCase(saveWebAppEnvironmentsAsync.rejected, (state, { payload }) => {
        state.environmentsActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppSecretFilesAsync.pending, (state) => {
        state.secretFilesLoading = true;
      })
      .addCase(getWebAppSecretFilesAsync.fulfilled, (state, action) => {
        state.secretFiles = action.payload.secretFiles;
        state.secretFilesLoading = false;
      })
      .addCase(getWebAppSecretFilesAsync.rejected, (state, { payload }) => {
        state.secretFilesLoading = false;
        //TODO
        state.secretFiles = dummySecretFiles;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(saveWebAppSecretFilesAsync.pending, (state) => {
        state.secretFilesActionLoading = true;
      })
      .addCase(saveWebAppSecretFilesAsync.fulfilled, (state) => {
        state.secretFilesActionLoading = false;
      })
      .addCase(saveWebAppSecretFilesAsync.rejected, (state, { payload }) => {
        state.secretFilesActionLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppPreviewsAsync.pending, (state) => {
        state.previewsLoading = true;
      })
      .addCase(getWebAppPreviewsAsync.fulfilled, (state, action) => {
        state.previews = action.payload.previews;
        state.previewsLoading = false;
      })
      .addCase(getWebAppPreviewsAsync.rejected, (state, { payload }) => {
        state.previewsLoading = false;
        //TODO
        state.previews = dummyPreviews;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppJobsAsync.pending, (state) => {
        state.jobsLoading = true;
      })
      .addCase(getWebAppJobsAsync.fulfilled, (state, action) => {
        state.jobs = action.payload.jobs;
        state.jobsLoading = false;
      })
      .addCase(getWebAppJobsAsync.rejected, (state, { payload }) => {
        state.jobsLoading = false;
        //TODO
        state.jobs = dummyJobs;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppSharedAsync.pending, (state) => {
        state.sharedLoading = true;
      })
      .addCase(getWebAppSharedAsync.fulfilled, (state, action) => {
        state.shared = action.payload.shared;
        state.sharedLoading = false;
      })
      .addCase(getWebAppSharedAsync.rejected, (state, { payload }) => {
        state.sharedLoading = false;
        //TODO
        state.shared = dummySharing;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(createWebAppSharingAsync.pending, (state) => {
        state.sharingLoading = true;
      })
      .addCase(createWebAppSharingAsync.fulfilled, (state, { payload }) => {
        state.sharingLoading = false;
      })
      .addCase(createWebAppSharingAsync.rejected, (state, { payload }) => {
        state.sharingLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(deleteWebAppSharingAsync.pending, (state, { meta }) => {
        state.deleteShared = meta.arg.Id;
      })
      .addCase(deleteWebAppSharingAsync.fulfilled, (state, { payload }) => {
        state.deleteShared = null;
        state.shared = [
          ...state.shared.filter((i) => i.Id !== payload.sharedId),
        ];
      })
      .addCase(deleteWebAppSharingAsync.rejected, (state, { payload }) => {
        state.deleteShared = null;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(getWebAppManualScalingAsync.pending, (state) => {
        state.scalingLoading = true;
      })
      .addCase(getWebAppManualScalingAsync.fulfilled, (state, action) => {
        state.scaling = action.payload.value;
        state.scalingLoading = false;
      })
      .addCase(getWebAppManualScalingAsync.rejected, (state, { payload }) => {
        state.scalingLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateWebAppManualScalingAsync.pending, (state) => {
        state.scalingUpdateLoading = true;
      })
      .addCase(
        updateWebAppManualScalingAsync.fulfilled,
        (state, { payload }) => {
          state.scalingUpdateLoading = false;
          state.scaling = payload.value;
        }
      )
      .addCase(
        updateWebAppManualScalingAsync.rejected,
        (state, { payload }) => {
          state.scalingUpdateLoading = false;
          state.scaling = payload?.code || 1;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(updateWebAppNameAsync.pending, (state) => {
        state.updateNameLoading = true;
      })
      .addCase(updateWebAppNameAsync.fulfilled, (state, { payload }) => {
        state.updateNameLoading = false;
      })
      .addCase(updateWebAppNameAsync.rejected, (state, { payload }) => {
        state.updateNameLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });

    builder
      .addCase(updateWebAppDockerCommandAsync.pending, (state) => {
        state.updateDockerCommandLoading = true;
      })
      .addCase(updateWebAppDockerCommandAsync.fulfilled, (state) => {
        state.updateDockerCommandLoading = false;
      })
      .addCase(
        updateWebAppDockerCommandAsync.rejected,
        (state, { payload }) => {
          state.updateDockerCommandLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(updateWebAppPreDeployCommandAsync.pending, (state) => {
        state.updatePreDeployCommandLoading = true;
      })
      .addCase(updateWebAppPreDeployCommandAsync.fulfilled, (state) => {
        state.updatePreDeployCommandLoading = false;
      })
      .addCase(
        updateWebAppPreDeployCommandAsync.rejected,
        (state, { payload }) => {
          state.updatePreDeployCommandLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(updateWebAppHealthCheckPathAsync.pending, (state) => {
        state.updateHealthCheckPathLoading = true;
      })
      .addCase(updateWebAppHealthCheckPathAsync.fulfilled, (state) => {
        state.updateHealthCheckPathLoading = false;
      })
      .addCase(
        updateWebAppHealthCheckPathAsync.rejected,
        (state, { payload }) => {
          state.updateHealthCheckPathLoading = false;
          if (payload?.message)
            toast.error(() => CustomErrorToast(payload?.message));
        }
      );

    builder
      .addCase(updateWebAppPlanAsync.pending, (state) => {
        state.planUpdateLoading = true;
      })
      .addCase(updateWebAppPlanAsync.fulfilled, (state) => {
        state.planUpdateLoading = false;
      })
      .addCase(updateWebAppPlanAsync.rejected, (state, { payload }) => {
        state.planUpdateLoading = false;
        if (payload?.message)
          toast.error(() => CustomErrorToast(payload?.message));
      });
  },
});

export const selectWebApp = (state: RootState) => state.webApp.webApp;
export const selectWebAppLoading = (state: RootState) =>
  state.webApp.webAppLoading;

//events
export const selectWebAppEvents = (state: RootState) => state.webApp.events;
export const selectWebAppEventsLoading = (state: RootState) =>
  state.webApp.eventsLoading;

//logs
export const selectWebAppLogs = (state: RootState) => state.webApp.logs;
export const selectWebAppLogsLoading = (state: RootState) =>
  state.webApp.logsLoading;

//disk
export const selectWebAppDisk = (state: RootState) => state.webApp.disk;
export const selectWebAppDiskLoading = (state: RootState) =>
  state.webApp.diskLoading;
export const selectWebAppDiskActionLoading = (state: RootState) =>
  state.webApp.diskActionLoading;

//environments
export const selectWebAppEnvironments = (state: RootState) =>
  state.webApp.environments;
export const selectWebAppEnvironmentsLoading = (state: RootState) =>
  state.webApp.environmentsLoading;
export const selectWebAppEnvironmentsActionLoading = (state: RootState) =>
  state.webApp.environmentsActionLoading;

//secret-files
export const selectWebAppSecretFiles = (state: RootState) =>
  state.webApp.secretFiles;
export const selectWebAppSecretFilesLoading = (state: RootState) =>
  state.webApp.secretFilesLoading;
export const selectWebAppSecretFilesActionLoading = (state: RootState) =>
  state.webApp.secretFilesActionLoading;

//previews
export const selectWebAppPreviews = (state: RootState) => state.webApp.previews;
export const selectWebAppPreviewsLoading = (state: RootState) =>
  state.webApp.previewsLoading;

//previews
export const selectWebAppJobs = (state: RootState) => state.webApp.jobs;
export const selectWebAppJobsLoading = (state: RootState) =>
  state.webApp.jobsLoading;

//sharing
export const selectWebAppShared = (state: RootState) => state.webApp.shared;
export const selectWebAppSharedLoading = (state: RootState) =>
  state.webApp.sharedLoading;
export const selectWebAppSharingLoading = (state: RootState) =>
  state.webApp.sharingLoading;
export const selectWebAppDeleteId = (state: RootState) =>
  state.webApp.deleteShared;

//sharing
export const selectWebAppScaling = (state: RootState) => state.webApp.scaling;
export const selectWebAppScalingLoading = (state: RootState) =>
  state.webApp.scalingLoading;
export const selectWebAppScalingUpdateLoading = (state: RootState) =>
  state.webApp.scalingUpdateLoading;
export const selectWebAppManualScaling = (state: RootState) =>
  state.webApp.manualScaling;

//settings
export const selectWebAppUpdateNameLoading = (state: RootState) =>
  state.webApp.updateNameLoading;
export const selectWebAppUpdateDockerCommandLoading = (state: RootState) =>
  state.webApp.updateDockerCommandLoading;
export const selectWebAppUpdatePreDeployCommandLoading = (state: RootState) =>
  state.webApp.updatePreDeployCommandLoading;
export const selectWebAppShowUpdateImageModal = (state: RootState) =>
  state.webApp.showUpdateImageModal;
export const selectWebAppUpdateHealthCheckPathLoading = (state: RootState) =>
  state.webApp.updateHealthCheckPathLoading;

//plan
export const selectWebAppUpdatePlanLoading = (state: RootState) =>
  state.webApp.planUpdateLoading;

export const {
  handleSetWebApp,
  handleShowWebAppUpdateImageModal,
  handleHideWebAppUpdateImageModal,
} = webAppSlice.actions;
export default webAppSlice.reducer;
