import { jwtEnvAxios } from "..";
import { encodeUnicode } from "../../utils/bucket";

//access-keys urls
const GET_ACCESS_KEYS_API_URL = `/access-keys`;
const GET_ACCESS_KEY_API_URL = (accessKey: string) =>
  `/access-keys/${encodeUnicode(accessKey)}`;
const CREATE_ACCCESS_KEY_API_URL = `/access-keys`;
const UPDATE_ACCCESS_KEY_API_URL = (accessKey: string) =>
  `/access-keys/${encodeUnicode(accessKey)}`;
const DELETE_ACCCESS_KEY_API_URL = (accessKey: string) =>
  `/access-keys/${encodeUnicode(accessKey)}`;
const DELETE_MULTIPLE_ACCCESS_KEY_API_URL = `/access-keys/delete-multi`;

export function getS3AccessKeysApi() {
  return jwtEnvAxios("s3").get(GET_ACCESS_KEYS_API_URL);
}

export function getS3AccessKeyApi(accessKey: string) {
  return jwtEnvAxios("s3").get(GET_ACCESS_KEY_API_URL(accessKey));
}

export function createS3AccessKeyApi(data: any) {
  return jwtEnvAxios("s3").post(CREATE_ACCCESS_KEY_API_URL, data);
}

export function updateS3AccessKeyApi(accessKey: string, data: any) {
  return jwtEnvAxios("s3").put(UPDATE_ACCCESS_KEY_API_URL(accessKey), data);
}

export function deleteS3AccessKeyApi(accessKey: string) {
  return jwtEnvAxios("s3").delete(DELETE_ACCCESS_KEY_API_URL(accessKey));
}

export function deleteMultipleS3AccessKeyApi(data: any) {
  return jwtEnvAxios("s3").post(DELETE_MULTIPLE_ACCCESS_KEY_API_URL, data);
}
