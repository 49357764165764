import { PlansTab } from "../../plans/UniversalPlansBox";

const NftApiPlansTab = () => {
  const nftApiServiceType =
    Number(process.env.REACT_APP_NFT_API_SERVICE_TYPE) || 8;
  return (
    <>
      <PlansTab serviceType={nftApiServiceType} />
    </>
  );
};

export default NftApiPlansTab;
