import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";

import {
  getWebAppLogsAsync,
  selectWebApp,
  selectWebAppLoading,
  selectWebAppLogs,
  selectWebAppLogsLoading,
} from "../../../store/web-app/webAppSlice";
import { ReactComponent as ArrowUpIcon } from "./../../../assets/icons/arrow-up.svg";
import { ReactComponent as ArrowsPointingOutIcon } from "./../../../assets/icons/arrows-pointing-out.svg";
import { ReactComponent as ArrowsPointingInIcon } from "./../../../assets/icons/arrows-pointing-in.svg";
import { ReactComponent as SearchIcon } from "./../../../assets/icons/search.svg";
import { ReactComponent as NoticeIcon } from "./../../../assets/icons/logs/notice.svg";
import { ReactComponent as ErrorIcon } from "./../../../assets/icons/logs/error.svg";
import { Link } from "react-router-dom";
import { WebAppUrl } from "../../../utils/urls";
import { formatTimestamp } from "../../../utils/date";
import { removeANSIEscapeSequences } from "../../../utils";
import {
  Button,
  cn,
  Flex,
  Loading,
  Typography,
  Input,
  Select,
} from "djuno-design";

const logsTypesOptions = [
  {
    label: "All logs",
    value: "all_logs",
  },
  {
    label: "Application logs",
    value: "app_logs",
  },
  {
    label: "Request logs",
    value: "req_logs",
  },
];

const logsTimesOptions = [
  {
    label: "Live tail",
    value: "live",
  },
  {
    label: "Last hour",
    value: "last_hour",
  },
  {
    label: "Last 4 hours",
    value: "last_4_hours",
  },
  {
    label: "Last 24 hours",
    value: "last_24_hours",
  },
  {
    label: "Last 2 days",
    value: "last_2_days",
  },
  {
    label: "Last 7 days",
    value: "last_7_days",
  },
  {
    label: "Last 4 hours",
    value: "last_4_hours",
  },
];

const WebAppLogsTab = () => {
  const webApp = useAppSelector(selectWebApp);
  const webAppLoading = useAppSelector(selectWebAppLoading);

  const logs = useAppSelector(selectWebAppLogs);
  const logsLoading = useAppSelector(selectWebAppLogsLoading);

  const dispatch = useAppDispatch();

  //states
  const [maximize, setMaximize] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  //form states
  const [order, setOrder] = useState<"last" | "first">("last");
  const [logType, setLogType] = useState("all_logs");
  const [logTime, setLogTime] = useState("live");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (webApp) {
      // dispatch(getWebAppLogsAsync({ webAppId: webApp.Id.toString() }));
    }
  }, [dispatch, webApp]);

  const handleSearch = () => {
    if (search !== searchInputValue) {
      setSearch(searchInputValue);
      //get search
    }
  };

  const orderedLogs = useMemo(() => {
    const _logs = [...logs.logs];
    if (order === "first") {
      return _logs.reverse();
    } else {
      return _logs;
    }
  }, [logs.logs, order]);

  return (
    <div>
      <div
        className={cn("flex flex-col w-full bg-gray-100 dark:bg-dark-3", {
          "relative rounded-tl-xl rounded-tr-xl rounded-xl pb-1 h-[460px]":
            !maximize,
          "z-[2147483004] fixed top-0 left-0 h-screen overflow-y-auto":
            maximize,
        })}
      >
        {/* header */}
        {maximize && (
          <header className="py-6 px-10">
            <Flex items="center" justify="between">
              <ol className="antialiased font-sans font-normal text-sm text-gray-900 leading-6 list-none flex space-x-4 m-0 p-0">
                <li className="text-gray-400 after:content-['/'] after:ml-4">
                  <Link
                    to={WebAppUrl(webApp?.Id.toString())}
                    className="antialiased font-sans font-normal text-sm leading-6 no-underline"
                  >
                    <Typography.Text className="inline">
                      {webApp?.Name}
                    </Typography.Text>
                  </Link>
                </li>
                <li>
                  <Typography.Text size="sm">Logs</Typography.Text>
                </li>
              </ol>
              <Button
                tooltip={{ content: "Minimize", place: "bottom-end" }}
                onClick={() => setMaximize(false)}
              >
                <ArrowsPointingInIcon className="w-4 aspect-square" />
              </Button>
            </Flex>
            <Typography.Text size="xl">Logs</Typography.Text>
          </header>
        )}
        <div className="border-b border-solid border-gray-300 dark:border-gray-800">
          {/* filters */}
          <div
            className={cn(
              "flex lg:space-x-4 justify-stretch max-lg:flex-wrap items-center",
              {
                "px-10 mb-7": maximize,
                "px-3 py-3": !maximize,
              }
            )}
          >
            <div className="max-lg:order-1 max-lg:grow max-lg:mr-2">
              <Select
                options={logsTypesOptions}
                value={logType}
                onChange={(v) => setLogType(v || "all_logs")}
                buttonClassName="!border-slate-200 dark:!border-secondary-700 !pr-7"
                optionsClassName="!w-44"
              />
            </div>
            <div className="flex-1 max-lg:order-5 grow shrink max-lg:mt-2 max-lg:basis-full min-w-0">
              <Input
                className="!border-slate-200 dark:!border-secondary-700"
                placeholder="Search"
                onChange={(e: any) => setSearchInputValue(e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.code === "Enter") handleSearch();
                }}
                AfterComponent={
                  <button
                    onClick={handleSearch}
                    className={cn(
                      "w-10 flex items-center justify-center dark:text-white border-l border-solid border-transparent rounded-r-xl",
                      {
                        "bg-primary-400 text-white":
                          search !== searchInputValue,
                      }
                    )}
                  >
                    <SearchIcon className="w-4" />
                  </button>
                }
              />
            </div>
            <div className="max-lg:order-2 max-lg:grow lg:mx-0 mx-2">
              <Select
                options={logsTimesOptions}
                value={logTime}
                onChange={(v) => setLogTime(v || "live")}
                buttonClassName="!border-slate-200 dark:!border-secondary-700 !pr-7"
                optionsClassName="!w-44 !max-h-none"
              />
            </div>
            <div className="max-lg:order-3 mx-2">
              <Button
                uiSize="medium"
                tooltip={{ content: `Newest ${order}`, place: "bottom" }}
                onClick={() =>
                  setOrder((prev) => (prev === "last" ? "first" : "last"))
                }
              >
                <ArrowUpIcon
                  className={cn("w-4 aspect-square", {
                    "rotate-180": order === "first",
                  })}
                />
              </Button>
            </div>
            {!maximize && (
              <div className="max-lg:order-4 ml-2">
                <Button
                  tooltip={{ content: "Maximize", place: "bottom-end" }}
                  onClick={() => setMaximize(true)}
                >
                  <ArrowsPointingOutIcon className="w-4 aspect-square" />
                </Button>
              </div>
            )}
          </div>
        </div>
        <Flex direction="col" className="flex-1 pb-4 overflow-y-scroll">
          {/* line */}
          {!logsLoading &&
            orderedLogs.map((log, index) => {
              const status = log.labels.find((l) => l.label === "LEVEL")?.value;
              return (
                <div
                  key={index}
                  className={cn(
                    "antialiased font-mono font-normal text-xs leading-6 tracking-tight group relative space-x-4 hover:bg-gray-200 dark:hover:bg-black/10 py-1",
                    {
                      "px-10": maximize,
                      "px-3": !maximize,
                      "bg-rose-500/10 hover:!bg-rose-500/10":
                        status === "error",
                    }
                  )}
                >
                  <time>
                    <Typography.Text
                      uiType="secondary"
                      size="sm"
                      className="!inline"
                    >
                      {
                        formatTimestamp(log.timestamp, "MMM DD hh:mm:ss A")
                          .datetime
                      }
                    </Typography.Text>
                  </time>
                  <span className="inline-block space-x-1 uppercase group/line-level relative mr-2">
                    {(status === "info" || status === undefined) && (
                      <NoticeIcon className="w-3.5 aspect-square text-primary-300 align-middle" />
                    )}
                    {status === "notice" && (
                      <NoticeIcon className="w-3.5 aspect-square text-secondary-400 align-middle" />
                    )}
                    {status === "error" && (
                      <ErrorIcon className="w-3.5 aspect-square text-rose-500 align-middle" />
                    )}
                  </span>
                  <Typography.Text
                    size="xs"
                    className="whitespace-pre-wrap break-all inline"
                  >
                    {removeANSIEscapeSequences(log.text)}
                  </Typography.Text>
                </div>
              );
            })}

          {/* empty log */}
          {logs.logs.length === 0 && !logsLoading && (
            <Flex
              items="center"
              justify="center"
              className="h-full px-4 w-full"
            >
              <Flex
                direction="col"
                items="center"
                justify="center"
                className="gap-3 w-full"
              >
                <Typography.Text
                  size="base"
                  className="antialiased font-sans font-medium text-base leading-6"
                >
                  No logs to show
                </Typography.Text>
                <Typography.Text
                  size="sm"
                  className="antialiased font-sans font-normal text-sm leading-6 max-w-md"
                >
                  New log entries that match your search parameters will appear
                  here.{" "}
                </Typography.Text>
              </Flex>
            </Flex>
          )}

          {/* loading */}
          {logsLoading && (
            <div className="h-full flex items-center justify-center px-4">
              <Loading borderSize={2} />
            </div>
          )}

          {/* results count */}
          {/* <div className="antialiased font-mono font-normal text-xs leading-6 tracking-tight w-full border-dashed border-t border-gray-300 dark:border-gray-800 px-4 pt-4 mt-auto text-center">
            <Text>All 7 matching logs loaded</Text>
          </div> */}
        </Flex>
      </div>
    </div>
  );
};

export default WebAppLogsTab;
